import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUsers, useGetUsersCount } from "../../../service/Users/getUser";

import { ISolidButton } from "../../../components/SolidButton/SolidButton.type";
import { NSBreadcrumbType } from "../../../components/Breadcrumb/Breadcrumb.type";
import { NSContextMenuType } from "../../../components/ContextMenu/ContextMenu.type";
import { NSEmptyViewType } from "../../../components/EmptyView/EmptyView.type";
// eslint-disable-next-line import/no-named-as-default
import { NSTeamType } from "./Team-List.type";
import i18n from "../../../library/i18next";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";

const useTeamVm = () => {
  const [searchString, setSearchString] = useState("");

  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({ start: 0, limit: PAGINATION_DEFAULT_LIMIT });

  const { data: userListItems, rest } = useGetUsers(
    pagination?.limit,
    pagination?.start,
    `%${searchString}%`
  );
  const { data: usersCountData } = useGetUsersCount();

  const usersCount = useMemo(
    () => usersCountData?.user_aggregate.aggregate?.count ?? 0,
    [usersCountData]
  );

  const isLoading = useMemo(() => rest.isLoading, [rest.isLoading]);
  const contextMenuItems: NSContextMenuType.IContextMenuItem[] = [
    {
      iconName: "Download",
      label: i18n.t("general.projects"),
    },
  ];
  const headerList = [
    i18n.t("team.user"),
    i18n.t("team.companyName"),
    i18n.t("team.userRole"),
    i18n.t("general.createdAt"),
  ];

  const searchInput = {
    id: "dashboardSearchInputId",
    name: "dashboardSearchInputName",
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: searchString,
  };
  const navigate = useNavigate();
  const breadcrumb: NSBreadcrumbType.IBreadcrumb = {
    pageName: i18n.t("general.team"),
  };

  const dashboardHeaderButton: ISolidButton = {
    label: i18n.t("team.inviteUser"),
    onClick: () => navigate("invite-member"),
    variant: "label-w-icon-prefix",
    prefixIcon: "UserAdd",
  };

  const createTableDropdown = useCallback(
    (items: NSTeamType.ITableDropdownItem[]) =>
      items.map((item) => ({
        id: item.user_id,
        label: item.user_role_name,
      })),
    []
  );

  const onClickEmptyViewButton = useCallback(() => {
    setSearchString("");
  }, []);

  const emptyView: NSEmptyViewType.IEmptyView = {
    buttonLabel: i18n.t("general.tryAgain"),
    errorMessage: i18n.t("general.emptyViewErrorMessage"),
    onClick: onClickEmptyViewButton,
  };

  return {
    contextMenuItems,
    headerList,
    dashboardHeaderButton,
    searchInput,
    emptyView,
    breadcrumb,
    setPagination,
    userListItems,
    createTableDropdown,
    isLoading,
    usersCount,
    pagination,
  };
};

export default useTeamVm;
