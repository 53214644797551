import styled, { keyframes } from "styled-components";

import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSAnimatedSideBarStyle {
  const orbAnimation = keyframes`
    100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
  `;
  export const Container = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    border-top-right-radius: 120px;

    & > span {
      width: 960px;
      height: 960px;
      border-radius: 960px;
      backface-visibility: hidden;
      position: absolute;
      animation: ${orbAnimation};
      animation-duration: 10s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  `;

  export const GreenOrb = styled.span`
    background: radial-gradient(
      circle,
      rgba(103, 222, 129, 0.4) 0%,
      rgba(255, 255, 255, 0) 50%
    );

    top: 50%;
    left: 50%;
    animation-duration: 24s;
    transform-origin: 0;
  `;

  export const BlueOrb = styled.span`
    background: radial-gradient(
      circle,
      rgba(38, 56, 217, 0.4) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    top: 0%;
    left: 0%;
    animation-duration: 17s;
    animation-delay: 5s;
    transform-origin: 100%;
  `;

  export const InnerContainer = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(103, 222, 129, 0.2) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(38, 56, 217, 0.01) 100%
    );
  `;
  export const SideBarLabel = styled.div`
    font-family: greycliff-cf, sans-serif;
    font-weight: ${typography.fontWeights.demiBold};
    font-size: ${typography.titleh4.size};
    line-height: ${typography.titleh4.lineHeight};
    color: ${colors.dark.dark};
  `;
}
