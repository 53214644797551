import { InsertVideoInterviewScoreDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useInsertVideoInterviewScore() {
    const { mutateAsync, ...rest } = useGraphQLMutation(
        InsertVideoInterviewScoreDocument
    );

    return {
        mutateAsync,
        rest,
    };
}

export default useInsertVideoInterviewScore;
