import { useCallback, useState } from "react";

import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";
import { NSSearchModalStyle } from "./SearchModal.style";
import { NSSearchModalType } from "./SearchModal.type";
import SearchInput from "../SearchInput/SearchInput";
import colors from "../../style/colors";
import i18n from "../../library/i18next";

function SearchModal({
  isOpen,
  onClose,
  mainTitle,
  recentsSuggestionsList,
  onClickCloseButton,
  resultList,
  onChangeSearch,
  onResultSelected,
  searchPlaceholder,
}: NSSearchModalType.ISearchModal) {
  const [searchValue, setSearchValue] = useState("");

  const renderSuggestions = (itemList: NSSearchModalType.ISuggestionItem[]) => (
    <NSSearchModalStyle.SuggestionContainer>
      {itemList.map((suggestionItem) => (
        <NSSearchModalStyle.SuggestionItem
          key={suggestionItem.id}
          onClick={() => onResultSelected(suggestionItem)}
        >
          <NSSearchModalStyle.IconContainer>
            <Icon
              name="Add"
              height={24}
              width={24}
              color={colors.primary.primary}
            />
          </NSSearchModalStyle.IconContainer>
          <NSSearchModalStyle.SuggestionLabel>
            {suggestionItem.label}
          </NSSearchModalStyle.SuggestionLabel>
        </NSSearchModalStyle.SuggestionItem>
      ))}
    </NSSearchModalStyle.SuggestionContainer>
  );

  const renderResultsTitle = (
    resultTitle: string,
    resultSecondTitle?: string
  ) => (
    <NSSearchModalStyle.SubTitleContainer>
      <NSSearchModalStyle.RecentDarkTitle>
        {resultTitle}
      </NSSearchModalStyle.RecentDarkTitle>
      <NSSearchModalStyle.RecentGrayTitle>
        {resultSecondTitle}
      </NSSearchModalStyle.RecentGrayTitle>
    </NSSearchModalStyle.SubTitleContainer>
  );

  // eslint-disable-next-line consistent-return
  const renderResults = () => {
    if (searchValue) {
      if (resultList.length > 0) {
        // Renders when a search value is entered and there are results
        return (
          <NSSearchModalStyle.ResultContainer>
            {renderResultsTitle(i18n.t("general.results"))}
            {renderSuggestions(resultList)}
          </NSSearchModalStyle.ResultContainer>
        );
      }
      if (resultList.length === 0) {
        // Renders when a search value is entered and there are no results
        return (
          <NSSearchModalStyle.ResultContainer>
            {renderResultsTitle("Sorry! No result found", "Try searching for:")}
            {renderSuggestions(recentsSuggestionsList)}
          </NSSearchModalStyle.ResultContainer>
        );
      }
    } else {
      const suggestionResultTitle =
        recentsSuggestionsList.length > 0
          ? i18n.t("general.recents")
          : i18n.t("general.noSuggestionFound");

      return (
        // Renders when no search value is entered
        <NSSearchModalStyle.ResultContainer>
          {renderResultsTitle(suggestionResultTitle)}
          {renderSuggestions(recentsSuggestionsList)}
        </NSSearchModalStyle.ResultContainer>
      );
    }

    return undefined;
  };

  const searchHandler = useCallback(
    (value: string) => {
      setSearchValue(value);
      onChangeSearch(value);
    },
    [onChangeSearch]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClickCloseButton={onClickCloseButton}
      onClose={onClose}
    >
      <NSSearchModalStyle.Container>
        <NSSearchModalStyle.Title>{mainTitle}</NSSearchModalStyle.Title>
        <SearchInput
          id="searchModal"
          name="searchModal"
          placeholder={searchPlaceholder}
          onChange={searchHandler}
          value={searchValue}
        />

        {renderResults()}
      </NSSearchModalStyle.Container>
    </Modal>
  );
}

export default SearchModal;
