import styled from "styled-components";

export namespace NSStatsChartCard {
  export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dde0e5;
    border-radius: 12px;
    padding: 24px 12px;
    width: 180px;
    height: auto;
  `;

  export const Title = styled.h2`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    text-align: center;
    min-height: 56px;
  `;

  export const SpinnerContainer = styled.div`
    width: 160px;
    height: 160px;
    position: relative;
    margin: 8px 0 0;
  `;

  export const Text = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const StatValue = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    font-size: 26px;
    line-height: 34px;
  `;

  export const Separator = styled.span`
    transform: rotate(30deg);
    color: ${(props) => props.theme.colors.neutrals.grey05};
  `;

  export const TotalValue = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey05};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
  `;

  export const Badge = styled.span`
    padding: 4px 8px;
    border-radius: 8px;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    background: ${(props) => props.theme.colors.neutrals.grey02};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;
}
