import styled, { css } from "styled-components";
import { NSCardViewStyle } from "../CardView/CardView.style";

export namespace NSModal2Style {
  export const Container = styled.div<{ isFullWidth: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark02};
    display: flex;
    justify-content: center;
    backdrop-filter: blur(5px);
    align-items: center;
    z-index: 4;

    ${(props) =>
      !props.isFullWidth &&
      css`
        ${NSCardViewStyle.Container} {
          width: fit-content;
        }
      `}
  `;

  export const CloseButtonContainer = styled.div`
    position: absolute;
    right: 2rem;
    top: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark05};
    border-radius: 0.5rem;
    z-index: 3;
  `;

  export const LittleCloseButtonContainer = styled.div`
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 3;
  `;
}
