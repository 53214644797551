import styled from "styled-components";

export namespace NSCandidateInfoCardStyle {
  export const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    padding: 16px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const SelectedCandidateTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 8px;
  `;
  export const SelectedCandidateInfo = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;
}
