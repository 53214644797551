import styled from "styled-components";

export namespace NSFormHintStyle {
  export const Container = styled.div`
    padding: 24px;
    background-color: ${(props) => props.theme.colors.neutrals.grey01};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    font-family: greycliff-cf, sans-serif;
  `;
  export const HintItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    & > :last-child {
      margin-bottom: 0px;
    }
  `;

  export const Orb = styled.div<{ achived: boolean }>`
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${(props) =>
      props.achived
        ? props.theme.colors.success.green01
        : props.theme.colors.neutrals.grey04};
    margin-right: 16px;
    border: 0px;
  `;

  export const Text = styled.div``;
}
