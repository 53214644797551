import { GetCountriesDocument } from "../../gql/graphql";

import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetCountries() {
  const { data, ...rest } = useGraphQLQuery(GetCountriesDocument);

  return {
    data,
    rest,
  };
}

export default useGetCountries;
