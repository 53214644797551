import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { NSAddNoteType } from "./Add-Note.type";
import { object, string } from "yup";
import i18n from "../../../../../library/i18next";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGraphQLMutation } from "../../../../../hooks/useGraphQL";
import { InsertClientCandidateProjectNoteDocument } from "../../../../../gql/graphql";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../components/ToastAlert/ToastAlert";
import { NSCandidateListType } from "../../Candidate-List/Candidate-List.type";

const useAddNoteVm = () => {
  const { candidateProjectId, projectId, candidateId, type } = useParams();
  const navigate = useNavigate();
  const { mutateAsync: insertNote } = useGraphQLMutation(
    InsertClientCandidateProjectNoteDocument
  );

  const addNoteSchema: NSAddNoteType.IAddNoteFormValues | any = object({
    note: string().required(i18n.t("forms.addNoteError")),
  }).required();

  const { control, handleSubmit, setValue } =
    useForm<NSAddNoteType.IAddNoteFormValues>({
      resolver: yupResolver(addNoteSchema),
    });

  const handleSendBackToList = () => {
    const path = {
      appliedlist: "applied",
      longlist: "long-list",
      shortlist: "short-list",
    };

    navigate(
      `/projects/detail/${projectId}/${
        path[type as NSCandidateListType.ICandidateList["type"]]
      }`,
      {
        state: { projectId, candidateId, type },
      }
    );
  };

  const insertNoteAction = (note: string) => {
    insertNote({
      client_candidate_project_id: Number(candidateProjectId),
      note,
    })
      .then(async (_) => {
        toast(
          <ToastAlert
            description={i18n.t("projects.noteCreateSuccess")}
            type="success"
          />,

          {
            id: "noteCreateSuccess",
          }
        );
        setValue("note", "");
        handleSendBackToList();
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.noteCreateError")}
            type="error"
          />,

          {
            id: "noteCreateError",
          }
        );
      });
  };

  const onSubmit: SubmitHandler<NSAddNoteType.IAddNoteFormValues> = useCallback(
    (data, event) => {
      event?.preventDefault();
      insertNoteAction(data.note);
    },
    [insertNote]
  );

  const submitHandler = () => handleSubmit(onSubmit);

  return {
    control,
    handleSubmit: submitHandler,
  };
};

export default useAddNoteVm;
