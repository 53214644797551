import { JdPageMeta } from "../../gql/graphql";

export namespace NSJobDescriptionContextType {
  export interface ISelectedItem {
    id: string;
    label: string;
    isPrefix: boolean | undefined;
  }

  export interface IJobDescriptionContext {
    selectedRoleItem: ISelectedItem | undefined;
    selectedExperienceItem: ISelectedItem | undefined;
    selectedPositionItem: ISelectedItem | undefined;
    selectedClientName: string | undefined;
    selectRole: (role: ISelectedItem) => void;
    selectExperience: (experience: ISelectedItem | undefined) => void;
    selectPosition: (position: ISelectedItem | undefined) => void;
    selectClientName: (clientName: string | undefined) => void;
    nextQuestion: () => void;
    getFirstQuestion: (jidArg: string) => void;
    roleButtonLabel: string;
    activeQuestion: IActiveQuestion<unknown> | undefined;
    jid: string | undefined;
  }

  export const PageType = {
    SKILL_SELECTION: "skill-selection",
    SELECT_SKILL_RATE: "select-skill-rate",
    SELECT_EDUCATION: "select-education",
    SELECT_EXPERIENCE: "select-experience",
    SELECT_REQUIREMENTS: "select-requirements",
    SELECT_RESPONSIBILITES: "select-responsibilities",
    SELECT_BENEFITS: "select-benefits",
    JOB_DESCRIPTION: "job-description",
    SELECT_SALARY: "select-salary",
    SELECT_INTRODUCTION: "select-introduction",
  };

  export enum EAnswerType {
    pill,
    rate,
    pick,
    fourth,
  }

  export interface IAnswerType {
    type: EAnswerType;
    maxVisibleCount: number;
    selectAtLeast: number;
    canAddMore: boolean;
  }

  export interface IAnswer<T> {
    value: T;
    isSelected: boolean;
    body?: string | null | undefined;
    title?: string | null | undefined;
  }
  export interface IActiveQuestion<T> {
    title: string;
    subTitle?: string;
    page?: string;
    answerType: IAnswerType;
    answers: IAnswer<T>[];
    completed: number;
    meta?: JdPageMeta;
  }
}
