import { NSProgressBarStyle } from "./ProgressBar.style";
import { NSProgressBarType } from "./ProgressBar.type";

function ProgressBar({
  progress,
  totalNumber = 0,
  withoutPercentage = false,
}: NSProgressBarType.IProgressBar) {
  const progressErrorHandler = () => {
    if (progress <= 0) return 0;
    if (withoutPercentage) {
      if (progress >= totalNumber) return 100;
      return (progress / totalNumber) * 100;
    } else {
      return Math.min(progress, 100);
    }
  };

  return (
    <NSProgressBarStyle.Container>
      {!withoutPercentage && (
        <NSProgressBarStyle.ProgressNumbers>
          <NSProgressBarStyle.ProgressText>
            {progressErrorHandler()}
          </NSProgressBarStyle.ProgressText>
          <NSProgressBarStyle.ProgressText>
            {"100%"}
          </NSProgressBarStyle.ProgressText>
        </NSProgressBarStyle.ProgressNumbers>
      )}
      <NSProgressBarStyle.EmptyBar>
        <NSProgressBarStyle.ProgressThumb progress={progressErrorHandler()} />
      </NSProgressBarStyle.EmptyBar>
      <NSProgressBarStyle.TotalNumberText>
        {progress + " / " + totalNumber}
      </NSProgressBarStyle.TotalNumberText>
    </NSProgressBarStyle.Container>
  );
}

export default ProgressBar;
