import styled, { css } from "styled-components";
import { NSDropdownStyle } from "../../components/Dropdown/Dropdown.style";
import { NSFreeInputStyle } from "../../components/FreeInput/FreeInput.style";
import { NSMainInputStyle } from "../../components/MainInput/MainInput.style";
import LinkButton from "../../components/LinkButton/LinkButton";
import { Form } from "react-router-dom";
import { NSDragableCheckBoxListItemStyle } from "../../components/DragableCheckBoxListItem/DragableCheckBoxListItem.style";
import { NSDragAndDropListStyle } from "../../components/DragAndDropList/DragAndDropList.style";
import { NSCheckboxStyle } from "../../components/Checkbox/CheckBox.style";
import SolidButton from "../../components/SolidButton/SolidButton";
import { NSMainButtonStyle } from "../../components/MainButton/MainButton.style";

export namespace NSCreateInterviewStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.75rem 2rem;
  `;

  export const FormContainer = styled(Form)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.75rem 2rem;
  `;

  export const Header = styled.div<{
    hasMargin?: boolean;
  }>`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;

    ${(props) =>
      props.hasMargin &&
      css`
        margin-bottom: 40px;
      `}

    ${NSDropdownStyle.InnerDropdownTitle} {
      padding: 10px;
    }
  `;

  export const ProgressBarContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  `;

  export const ProjectNameBadge = styled.div`
    display: flex;
    padding: 12px;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};
  `;

  export const ProjectName = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.primary.primary};
  `;

  export const Fragment = styled.div``;

  export const Content = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${(props) =>
      css`
        width: ${props.fullWidth ? "100%" : "693px"};
      `}
    padding: 0 52px;
    margin-bottom: 72px;

    ${NSDragAndDropListStyle.SortableItemContainer} {
      background: ${(props) => props.theme.colors.neutrals.grey02};
      border-radius: 8px;
      padding: 0 16px;
    }

    ${NSCheckboxStyle.CheckboxBase} {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }

    ${NSDragableCheckBoxListItemStyle.Container} {
      &:hover {
        ${NSDragableCheckBoxListItemStyle.InnerContainer} {
          background-color: unset;
        }
      }
    }

    ${NSDragableCheckBoxListItemStyle.InnerContainer} {
      text-decoration: none;
    }

    ${NSDragableCheckBoxListItemStyle.Content} {
      padding: 16px;
      padding-left: 0;
    }
  `;

  export const DragAndDropListContainer = styled.div<{ height72px?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;

    ${NSDragableCheckBoxListItemStyle.Container} {
      height: 72px;
    }
  `;

  export const LanguageDropdownContainer = styled.div`
    margin-bottom: 56px;
    max-width: 360px;
  `;

  export const DatePickerContainer = styled.div`
    width: 360px;
  `;

  export const ContentTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-bottom: 4px;
  `;
  export const ContentSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-bottom: 24px;
  `;

  export const SubTitleList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    margin-left: 20px;
    margin-bottom: 24px;
  `;

  export const ListItems = styled.li`
    position: relative;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    &::before {
      content: "";
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: ${(props) => props.theme.colors.neutrals.grey07};
      border-radius: 50%;
    }
  `;

  export const Footer = styled.div`
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    align-self: flex-end;
    position: absolute;
    bottom: 2rem;
    right: 2rem;

    ${NSFreeInputStyle.Container} {
      ${NSMainInputStyle.InputWrapper} {
        width: 482px;
      }
    }
  `;

  export const FooterContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    align-items: center;
    gap: 28px;
  `;

  export const NextButtonContainer = styled.div`
    ${NSMainButtonStyle.ButtonBase} {
      width: 240px;
    }
  `;

  export const BackButton = styled(LinkButton)``;

  export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
    max-width: 572px;
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `;

  export const ModalContentItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const BoldText = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const WarningModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 372px;
  `;

  export const WarningModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.error.red01};
    margin-top: 24px;
    margin-bottom: 8px;
  `;

  export const WarningModalSubtitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin-bottom: 32px;
  `;

  export const WarningModalButton = styled(SolidButton)`
    ${NSMainButtonStyle.ButtonBase} {
      background-color: ${(props) => props.theme.colors.error.red01};
      color: ${(props) => props.theme.colors.light.light};
    }
  `;
}
