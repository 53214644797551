import Icon from "../Icon/Icon";
import { NSContextMenuStyle } from "./ContextMenu.style";
import { NSContextMenuType } from "./ContextMenu.type";
import colors from "../../style/colors";

function IconContextMenu({
  id,
  items,
  divider,
  onClickItem,
}: NSContextMenuType.IInnerContextMenu) {
  return (
    <NSContextMenuStyle.InnerComponentContainer>
      {items.map((item, index) => (
        <NSContextMenuStyle.InnerComponent key={item.label}>
          {item.isSeperatedFromTop && divider}
          <NSContextMenuStyle.ContextMenuItemContainer>
            <NSContextMenuStyle.ContextMenuItem
              isDisabled={item.isDisabled}
              key={item.label}
              onClick={(e) => {
                if (item.isDisabled) return;
                e.stopPropagation();
                item.onClick?.(index, id);
                onClickItem?.();
              }}
            >
              {item.iconName && (
                <Icon
                  color={colors.neutrals.grey04}
                  width={24}
                  height={24}
                  name={item.iconName}
                />
              )}
              <NSContextMenuStyle.ItemLabel>
                {item.label}
              </NSContextMenuStyle.ItemLabel>
            </NSContextMenuStyle.ContextMenuItem>
          </NSContextMenuStyle.ContextMenuItemContainer>
          {item.isSeperatedFromBottom && divider}
        </NSContextMenuStyle.InnerComponent>
      ))}
    </NSContextMenuStyle.InnerComponentContainer>
  );
}

export default IconContextMenu;
