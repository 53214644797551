import { useMemo } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
import { NSLanguageSelectionStyle } from "./LanguageSelection.style";
import { NSLanguageSelectionType } from "./LanguageSelection.type";
import i18n from "../../library/i18next";
import { EN, TR } from "../../utils/globalConstants";
import { NSDropdown } from "../Dropdown/Dropdown.type";

function LanguageSelection({
  isOpen = true,
  onSelect,
}: NSLanguageSelectionType.ILanguageSelection) {
  const options: NSDropdown.IDropdownItem[] = useMemo(
    () => [
      { id: EN, label: i18n.t("general.english") },
      { id: TR, label: i18n.t("general.turkish") },
    ],
    []
  );

  return (
    <NSLanguageSelectionStyle.Container isOpen={isOpen}>
      <Dropdown
        id={0}
        options={options}
        showSuffixItems={false}
        type="basic"
        onSelect={(_id, selected) => onSelect(selected?.id as string)}
        selectedDropdownItem={options[0]}
        titleWithPrefixItem={
          <NSLanguageSelectionStyle.TitleContainer>
            <NSLanguageSelectionStyle.Left>
              <NSLanguageSelectionStyle.LabelAndIconContainer>
                <Icon role="img" width={32} height={32} name="Global" />
                {isOpen && (
                  <NSLanguageSelectionStyle.Label>
                    {options.find((i) => i.id === i18n.language)?.label}
                  </NSLanguageSelectionStyle.Label>
                )}
              </NSLanguageSelectionStyle.LabelAndIconContainer>
            </NSLanguageSelectionStyle.Left>
            <Icon role="img" width={32} height={32} name="Right" />
          </NSLanguageSelectionStyle.TitleContainer>
        }
      />
    </NSLanguageSelectionStyle.Container>
  );
}

export default LanguageSelection;
