import styled from "styled-components";

export namespace NSSideMenuStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: greycliff-cf, sans-serif;
    gap: 16px;
  `;
}
