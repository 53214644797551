import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";
import DropdownWithCaption from "../DropdownWithCaption/DropdownWithCaption";
import { NSDashboardHeaderStyle } from "./DashboardHeader.style";
import { NSDashboardHeaderType } from "./DashboardHeader.type";
import SearchInput from "../SearchInput/SearchInput";
import SolidButton from "../SolidButton/SolidButton";
import SwitchButton from "../SwitchButton/SwitchButton";
import i18n from "../../library/i18next";
import Dropdown from "../Dropdown/Dropdown";

function DashboardHeader({
  breadcrumb,
  dropdowns,
  switchButton,
  searchInput,
  contextMenuHOC,
  HOCbuttonLabel,
  HOCOnButtonClick,
  buttonType,
  headerButton,
}: NSDashboardHeaderType.IDashboardHeader) {
  return (
    <NSDashboardHeaderStyle.Container>
      <NSDashboardHeaderStyle.LeftItems>
        <Breadcrumb
          dropdown={breadcrumb.dropdown}
          pageName={breadcrumb.pageName}
          innerPageTitle={breadcrumb.innerPageTitle}
          path={breadcrumb.path}
        />
      </NSDashboardHeaderStyle.LeftItems>

      <NSDashboardHeaderStyle.RightItems>
        {switchButton && (
          <SwitchButton
            label={switchButton.label}
            onChecked={switchButton.onChecked}
            checked={switchButton.checked}
          />
        )}
        {headerButton &&
          (buttonType === "dropdown" ? (
            <NSDashboardHeaderStyle.DropdownButtonContainer>
              <Dropdown
                id={headerButton.id}
                type={headerButton.type}
                options={headerButton.options}
                onSelect={headerButton.onSelect}
              >
                <SolidButton
                  size="medium"
                  label={headerButton.label}
                  suffixIcon="ChevronDown"
                  variant="label-w-icon-suffix"
                  fullWidth
                />
              </Dropdown>
            </NSDashboardHeaderStyle.DropdownButtonContainer>
          ) : (
            <SolidButton
              size="medium"
              label={headerButton.label}
              onClick={headerButton.onClick}
              prefixIcon={headerButton.prefixIcon}
              variant={headerButton.variant}
            />
          ))}

        {dropdowns &&
          dropdowns.map((dropdown) => (
            <NSDashboardHeaderStyle.DropdownContainer key={dropdown.id}>
              <DropdownWithCaption
                placeholder={dropdown.placeholder}
                prefixTitle={dropdown.prefixTitle}
                onCheckedItems={dropdown.onCheckedItems}
                id={dropdown.id}
                label={dropdown.label}
                title={dropdown.title}
                options={dropdown.options}
                type={dropdown.type}
                defaultCheckedItems={dropdown.defaultCheckedItems}
                containAddNewItemButton={dropdown.containAddNewItemButton}
                showLabel={dropdown.showLabel}
                selectedDropdownItem={dropdown.selectedDropdownItem}
                onSelect={dropdown.onSelect}
              />
            </NSDashboardHeaderStyle.DropdownContainer>
          ))}

        {searchInput && (
          <NSDashboardHeaderStyle.InputContainer>
            <SearchInput
              placeholder={i18n.t("general.search")}
              id={searchInput.id}
              name={searchInput.name}
              onChange={searchInput.onChange}
              value={searchInput.value}
              onClick={searchInput.onClick}
            />
          </NSDashboardHeaderStyle.InputContainer>
        )}
        {contextMenuHOC && (
          <ContextMenuHOC
            items={contextMenuHOC.items}
            onItemClick={contextMenuHOC.onItemClick}
            type={contextMenuHOC.type}
            buttonLabel={contextMenuHOC.buttonLabel}
            isScrollable={contextMenuHOC.isScrollable}
            title={contextMenuHOC.title}
            showSearch={contextMenuHOC.showSearch}
            containAddNewItemButton={contextMenuHOC.containAddNewItemButton}
            onCheckedItems={contextMenuHOC.onCheckedItems}
            onContextMenuOpen={contextMenuHOC.onContextMenuOpen}
          >
            {HOCbuttonLabel && HOCOnButtonClick && (
              <SolidButton
                size="medium"
                label={HOCbuttonLabel}
                onClick={HOCOnButtonClick}
              />
            )}
          </ContextMenuHOC>
        )}
      </NSDashboardHeaderStyle.RightItems>
    </NSDashboardHeaderStyle.Container>
  );
}

export default DashboardHeader;
