/* eslint-disable import/no-named-as-default */
import { useCallback, useState } from "react";
import IconButton from "../IconButton/IconButton";
import { NSAddNewItemStyle } from "./AddNewItem.style";
import { NSAddNewItemType } from "./AddNewItem.type";
import { NSContextMenuType } from "../ContextMenu/ContextMenu.type";
import { NSDropdown } from "../Dropdown/Dropdown.type";
import TextInput from "../TextInput/TextInput";
import i18n from "../../library/i18next";

function AddNewItem({ items, onClick, type }: NSAddNewItemType.IAddNewItem) {
  const [value, setValue] = useState<string>("");
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const onAddButtonClick = () => {
    const item = {
      contextMenu: {
        label: value,
        iconName: "Calendar",
      } as NSContextMenuType.IContextMenuItem,
      dropdown: {
        label: value,
        id: items.length,
      } as NSDropdown.IDropdownItem,
    };

    setValue("");
    const newPreparedItems = items;
    newPreparedItems.push(item[type]);

    onClick?.(newPreparedItems);
  };

  const onChangeEvent = useCallback((stringValue: string) => {
    setValue(stringValue);
  }, []);

  return (
    <NSAddNewItemStyle.Container isInputFocused={isInputFocused}>
      <TextInput
        id="addNewItem"
        showLabel={false}
        value={value}
        onBlur={() => setIsInputFocused(false)}
        onFocus={() => setIsInputFocused(true)}
        name="addNewItem"
        placeholder={i18n.t("general.addNewItem")}
        onChange={onChangeEvent}
      />
      <IconButton
        role="button"
        isWithBackground
        disabled={value === undefined || value === ""}
        iconButtonType="primary"
        iconButtonShape="circle"
        icon="Add"
        iconSize={17}
        onClick={onAddButtonClick}
      />
    </NSAddNewItemStyle.Container>
  );
}

export default AddNewItem;
