import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";

import { IMainInput } from "./MainInput.type";
import { NSMainInputStyle } from "./MainInput.style";

function MainInputRef(props: IMainInput, ref?: ForwardedRef<HTMLInputElement>) {
  const {
    onBlur,
    onFocus,
    errorText,
    infoText,
    id,
    variant = "basic",
    error,
    prefixItem,
    suffixItem,
    onChange,
    maxLength,
    name,
    className,
    placeholder,
    disabled = false,
    label,
    value,
    defaultValue,
    showLabel = true,
    onClick,
    readonly,
    type,
    onSuffixClick,
    onKeyUp,
    autoComplete = "new-password",
    // autoFocus,
  } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const onChangeEvent = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  const baseInput = (
    <NSMainInputStyle.BaseInput
      ref={ref}
      id={id}
      readOnly={readonly}
      role="textbox"
      onFocus={onFocus}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      className={className}
      type={type ?? "text"}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => {
        onChangeEvent(e);
      }}
      onBlur={onBlur}
      maxLength={maxLength}
      onKeyUp={onKeyUp}
      //  autoFocus={autoFocus}
    />
  );

  let inputContent;

  switch (variant) {
    case "basic":
      inputContent = baseInput;
      break;
    case "label-w-icon-prefix":
      inputContent = (
        <>
          <NSMainInputStyle.SuffixAndPrefix>
            {prefixItem}
          </NSMainInputStyle.SuffixAndPrefix>
          {baseInput}
        </>
      );
      break;
    case "label-w-icon-suffix":
      inputContent = (
        <>
          {baseInput}
          <NSMainInputStyle.SuffixAndPrefix onClick={onSuffixClick}>
            {suffixItem}
          </NSMainInputStyle.SuffixAndPrefix>
        </>
      );
      break;
    case "label-w-icon-prefix-&-suffix":
      inputContent = (
        <>
          <NSMainInputStyle.SuffixAndPrefix>
            {prefixItem}
          </NSMainInputStyle.SuffixAndPrefix>
          {baseInput}
          <NSMainInputStyle.SuffixAndPrefix>
            {suffixItem}
          </NSMainInputStyle.SuffixAndPrefix>
        </>
      );
      break;
    default:
      inputContent = baseInput;
      break;
  }

  // eslint-disable-next-line consistent-return
  const inputLabel = useMemo(() => {
    if (label) {
      return label;
    }
    if (placeholder && value) {
      return placeholder;
    }
  }, [label, placeholder, value]);

  return (
    <NSMainInputStyle.InputWrapper
      onClick={onClick}
      onFocus={() => setIsFocused(true)}
      isFocused={isFocused}
      onBlur={() => setIsFocused(false)}
    >
      {showLabel && inputLabel && (
        <NSMainInputStyle.InputLabel error={error}>
          {inputLabel}
        </NSMainInputStyle.InputLabel>
      )}

      <NSMainInputStyle.InputFlex isError={error} isDisabled={disabled}>
        {inputContent}
      </NSMainInputStyle.InputFlex>

      <NSMainInputStyle.ErrorText role="alert">
        {error && errorText}
      </NSMainInputStyle.ErrorText>

      {infoText && (
        <NSMainInputStyle.ErrorText>{infoText}</NSMainInputStyle.ErrorText>
      )}
    </NSMainInputStyle.InputWrapper>
  );
}

const MainInput = forwardRef(MainInputRef);
export default MainInput;
