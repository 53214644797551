import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { NSSettingsIntegrationType } from "./Settings-Integration.type";
import { object, string } from "yup";
import i18n from "../../../library/i18next";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import { useState } from "react";
import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import {
  GetUserKeysDocument,
  InsertApiKeyDocument,
  UpdateUserKeyDocument,
} from "../../../gql/graphql";
import { LANGUAGE_INFO, LOGIN_USER_INFO } from "../../../utils/globalConstants";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";

const expirations: NSDropdown.IDropdownItem[] = [
  {
    id: 1,
    label: i18n.t("settings.noExpiration"),
  },
  {
    id: 2,
    label: i18n.t("settings.3Months"),
  },
  {
    id: 3,
    label: i18n.t("settings.6Months"),
  },
  {
    id: 4,
    label: i18n.t("settings.1Year"),
  },
];

const useSettingsIntegrationVm = () => {
  const userInfo = JSON.parse(window.localStorage.getItem(LOGIN_USER_INFO)!);
  const [selectedExpiration, setSelectedExpiration] =
    useState<NSDropdown.IDropdownItem>(expirations[0]);
  const [newGeneratedToken, setNewGeneratedToken] =
    useState<NSSettingsIntegrationType.INewGeneratedToken>();

  const {
    data: userKeys,
    refetch,
    isSuccess: isUserKeysSuccess,
  } = useGraphQLQuery(GetUserKeysDocument, undefined, {
    userId: userInfo.sub,
  });

  const { mutateAsync } = useGraphQLMutation(InsertApiKeyDocument);
  const { mutateAsync: updateUserKey } = useGraphQLMutation(
    UpdateUserKeyDocument
  );

  const validationSchema: NSSettingsIntegrationType.IFormValues | any = object({
    tokenName: string().required(i18n.t("forms.tokenNameError")),
    expiration: object({ id: string(), label: string() })
      .required(i18n.t("forms.selectExpirationError"))
      .default(expirations[0]),
  }).required();

  const { handleSubmit, setValue, reset, control } =
    useForm<NSSettingsIntegrationType.IFormValues>({
      resolver: yupResolver(validationSchema),
      shouldFocusError: false,
    });

  const expiredAt: NSSettingsIntegrationType.IExpiredAt = {
    1: "",
    2: "3",
    3: "6",
    4: "12",
  };

  const onSubmit: SubmitHandler<NSSettingsIntegrationType.IFormValues> = (
    data,
    event
  ) => {
    event?.preventDefault();
    mutateAsync({
      expiredAt: expiredAt[parseInt(data.expiration.id as string)],
      label: data.tokenName,
    })
      .then(async (res) => {
        reset();
        toast(
          <ToastAlert
            description={i18n.t("settings.generateTokenSuccess")}
            type="success"
          />,

          {
            id: "generateTokenSuccess",
          }
        );

        setNewGeneratedToken({
          label: data.tokenName,
          key: res.insertApiKey.apiKey as string,
        });
        await refetch();
        setSelectedExpiration(expirations[0]);
        setValue("expiration", expirations[0]);
        setValue("tokenName", "");
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("settings.generateTokenError")}
            type="error"
          />,

          {
            id: "generateTokenError",
          }
        );
      });
  };

  const submitHandler = handleSubmit(onSubmit);
  const isExpirationTextVisible =
    selectedExpiration?.id === expirations[0].id || !selectedExpiration;

  const isLoading = !isUserKeysSuccess;

  const handleRevokeAccess = (id: string) => {
    updateUserKey({
      expired_at: new Date().toISOString(),
      id,
    })
      .then(async () => {
        toast(
          <ToastAlert
            description={i18n.t("settings.updateUserKeySuccess")}
            type="success"
          />,

          {
            id: "updateUserKeySuccess",
          }
        );
        await refetch();
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("settings.updateUserKeyError")}
            type="error"
          />,

          {
            id: "updateUserKeyError",
          }
        );
      });
  };

  const copyTokenToClipboard = () => {
    navigator.clipboard
      .writeText(newGeneratedToken?.key!)
      .then(async () => {
        toast(
          <ToastAlert
            description={i18n.t("settings.copyTokenSuccess")}
            type="success"
          />,

          {
            id: "copyTokenSuccess",
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("settings.copyTokenError")}
            type="error"
          />,

          {
            id: "copyTokenError",
          }
        );
      });
  };

  const languageCode = localStorage.getItem(LANGUAGE_INFO);

  return {
    isLoading,
    control,
    expirations,
    isExpirationTextVisible,
    selectedExpiration,
    newGeneratedToken,
    userKeys,
    languageCode,
    handleRevokeAccess,
    handleSubmit: submitHandler,
    setSelectedExpiration,
    copyTokenToClipboard,
  };
};

export default useSettingsIntegrationVm;
