import { NSStatusBadgeStyle } from "./StatusBadge.style";
import colors from "../../style/colors";
import { NSStatusBadgeType } from "./StatusBadge.type";
import {
  Project_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../gql/graphql";
import i18n from "../../library/i18next";

function StatusBadge({
  status,
  label,
  color,
  type,
}: NSStatusBadgeType.IStatusBadge) {
  let statusTypeProps;
  switch (status) {
    case Project_Status_Enum.Active:
      statusTypeProps = {
        label: i18n.t("general.active"),
        color: colors.success.green01,
      };
      break;
    case Project_Status_Enum.Cancelled:
      statusTypeProps = {
        label: i18n.t("general.cancelled"),
        color: colors.error.red01,
      };
      break;
    case Project_Status_Enum.Archived:
      statusTypeProps = {
        label: i18n.t("general.archived"),
        color: colors.neutrals.grey04,
      };
      break;
    case Project_Status_Enum.Done:
      statusTypeProps = {
        label: i18n.t("general.done"),
        color: colors.information.blue01,
      };
      break;
    default:
      statusTypeProps = {
        label: label ?? "",
        color: color ?? NSStatusBadgeType.dotColor.green,
      };
      break;
  }

  let InterviewTypeProps;
  switch (type) {
    case Video_Interview_Type_Enum.CompetencyAssessment:
      InterviewTypeProps = {
        label: i18n.t("projects.competencyInterview"),
      };
      break;
    case Video_Interview_Type_Enum.EnglishAssessment:
      InterviewTypeProps = {
        label: i18n.t("projects.englishTest"),
      };
      break;
    default:
      InterviewTypeProps = {
        label: "",
      };
      break;
  }

  return (
    <NSStatusBadgeStyle.Container type={type}>
      <NSStatusBadgeStyle.StatusDot dotColor={statusTypeProps?.color} />
      <NSStatusBadgeStyle.TextContainer>
        <NSStatusBadgeStyle.StatusLabel>
          {statusTypeProps?.label}
        </NSStatusBadgeStyle.StatusLabel>
        <NSStatusBadgeStyle.InterviewType>
          {InterviewTypeProps?.label}
        </NSStatusBadgeStyle.InterviewType>
      </NSStatusBadgeStyle.TextContainer>
    </NSStatusBadgeStyle.Container>
  );
}

export default StatusBadge;
