import styled, { css } from "styled-components";
import { NSRadioButtonStyle } from "../RadioButton/RadioButton.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSLinkButtonStyle } from "../LinkButton/LinkButton.style";

export namespace NSAnswerOptionStyle {
  export const AnswerLabel = styled.div<{ maxWidth?: number }>`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    width: 100%;
    max-width: ${(props) => props.maxWidth}px;
  `;

  export const Container = styled.button<{
    isSelected: boolean;
    isAISelected?: boolean;
    isOnlyAISelected?: boolean;
    maxWidth?: number;
  }>`
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    border: 0;
    transition: 0.3s all;
    background-color: transparent;
    max-width: ${(props) => props.maxWidth}px;

    ${NSLinkButtonStyle.LinkButtonBase} {
      ${NSMainButtonStyle.ButtonBase} {
        color: ${(props) => props.theme.colors.neutrals.grey05};
        font-size: ${(props) => props.theme.typography.smallText1.size};
        line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
        font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
        width: max-content;
      }

      ${NSMainButtonStyle.IconContainer} {
        margin: 0 4px 0 0;
      }
    }

    :hover {
      ${AnswerLabel} {
        color: ${(props) => props.theme.colors.dark.dark};
      }
      background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};
    }

    ${(props) =>
      props.isAISelected &&
      props.isOnlyAISelected === true &&
      css`
        background-color: ${props.theme.colors.primary.tint};
      `}

    ${(props) =>
      props.isAISelected &&
      props.isOnlyAISelected === false &&
      css`
        background-color: transparent;
      `}  

    ${(props) =>
      props.isSelected &&
      css`
        background-color: ${props.theme.colors.primary.tint02};

        ${NSRadioButtonStyle.RadioButtonInput} {
          :checked {
            background-color: ${props.theme.colors.light.light};
            border-color: ${props.theme.colors.light.light};
          }
        }

        ${AnswerLabel} {
          color: ${props.theme.colors.light.light};
        }
      `}
  `;

  export const AnswerLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: left;
  `;

  export const AIIconContainer = styled.div<{ isOnlyAISelected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: ${(props) => props.theme.colors.primary.tint};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    gap: 4px;
    border-radius: 8px;
  `;
}
