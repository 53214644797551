import { useParams } from "react-router-dom";
import CardView from "../../components/CardView/CardView";
import FormErrorBox from "../../components/FormErrorBox/FormErrorBox";
import FormHint from "../../components/FormHint/FormHint";
import Logo from "../../components/Logo/Logo";
import { NSResetPasswordStyle } from "./Reset-Password.style";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import PasswordSuccessCard from "../../components/PasswordSuccessCard/PasswordSuccessCard";
import SolidButton from "../../components/SolidButton/SolidButton";
import useResetPasswordVm from "./ResetPasswordVm";
import SuccessIllustration from "../../assets/illustration/password_created.png";
import i18n from "../../library/i18next";

function ResetPassword() {
  const { token, email } = useParams();
  const ResetPasswordVm = useResetPasswordVm(email, token);

  return (
    <NSResetPasswordStyle.Container>
      <Logo />
      <CardView>
        <PasswordSuccessCard
          image={SuccessIllustration}
          header={i18n.t("resetPassword.passwordCreateSuccess")}
          description={i18n.t("resetPassword.passwordCreateDesc")}
          isShown={ResetPasswordVm.result?.success === true}
          onClick={() => ResetPasswordVm.navigate("/auth")}
        />

        <NSResetPasswordStyle.FormContainer
          isShown={
            !ResetPasswordVm.result ||
            (ResetPasswordVm.result && ResetPasswordVm.result.success === false)
          }
        >
          <NSResetPasswordStyle.UpContainer>
            <NSResetPasswordStyle.Title>
              {i18n.t("resetPassword.hello")}
            </NSResetPasswordStyle.Title>
            <NSResetPasswordStyle.Description>
              {i18n.t("resetPassword.description")}
            </NSResetPasswordStyle.Description>
          </NSResetPasswordStyle.UpContainer>
          <FormErrorBox
            isShown={
              ResetPasswordVm.result && ResetPasswordVm.result.success === false
            }
            onClickClose={() => ResetPasswordVm.setResult(undefined)}
            error="Error"
            description={i18n.t("resetPassword.passwordResetError")}
          />
          <PasswordInput
            id="set-password"
            name="set-password"
            placeholder={i18n.t("resetPassword.setPassword")}
            onChange={ResetPasswordVm.passwordChangeHandler}
            error={ResetPasswordVm.passwordControll}
            errorText={i18n.t("resetPassword.setPasswordErrorText")}
          />
          <FormHint hintItems={ResetPasswordVm.hintIems} />
          <PasswordInput
            id="confirm-password"
            name="confirm-password"
            placeholder={i18n.t("resetPassword.confirmPassword")}
            onChange={(value) => ResetPasswordVm.setConfirmedPassword(value)}
            error={ResetPasswordVm.passwordControll}
            errorText={i18n.t("resetPassword.setPasswordErrorText")}
          />
          <SolidButton
            backgroundColor="hsla(234, 70%, 50%, 1)"
            label={i18n.t("resetPassword.resetPassword")}
            onClick={ResetPasswordVm.submitHandler}
            size="large"
            variant="basic"
            fullWidth
          />
        </NSResetPasswordStyle.FormContainer>
      </CardView>
    </NSResetPasswordStyle.Container>
  );
}

export default ResetPassword;
