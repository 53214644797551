import { IGhostIconButton } from "./GhostIconButton.type";
import IconButton from "../IconButton/IconButton";
import { NSGhostIconButtonStyle } from "./GhostIconButton.style";

function GhostIconButton({
  disabled,
  onClick,
  isLoading,
  size,
  iconColor,
  icon,
}: IGhostIconButton) {
  return (
    <NSGhostIconButtonStyle.GhostIconButtonBase>
      <IconButton
        icon={icon}
        size={size}
        iconColor={iconColor}
        disabled={disabled}
        isLoading={isLoading}
        onClick={onClick}
      />
    </NSGhostIconButtonStyle.GhostIconButtonBase>
  );
}
export default GhostIconButton;
