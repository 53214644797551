import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import i18n from "../../../../library/i18next";
import { NSCandidateListType } from "../Candidate-List/Candidate-List.type";

type urlLinksType = {
  [key: number]: string;
};

const useNoteDetailVm = () => {
  const navigate = useNavigate();
  const { projectId, candidateId, type } = useParams();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const start = searchParams.get("start");
  const limit = searchParams.get("limit");
  const handleCloseDrawer = () => {
    const path = {
      appliedlist: "applied",
      longlist: "long-list",
      shortlist: "short-list",
    };

    navigate(
      `/projects/detail/${projectId}/${
        path[type as NSCandidateListType.ICandidateList["type"]]
      }?start=${start}&limit=${limit}`,
      {
        state: { projectId, candidateId, type },
      }
    );
  };

  const urlLinks: urlLinksType = useMemo(
    () => ({
      1: "all-notes",
      2: "add-note",
    }),
    []
  );

  const onTabChange = useCallback(
    (tab: number) => {
      setActiveTab(tab);
      navigate(`${urlLinks[tab]}?start=${start}&limit=${limit}`, {
        state: {
          projectId,
          candidateId,
        },
      });
    },
    [candidateId, navigate, projectId, urlLinks]
  );

  const [activeTab, setActiveTab] = useState(2);

  const tabItems = [
    {
      id: 1,
      label: i18n.t("projects.allNotes"),
    },
    {
      id: 2,
      label: i18n.t("projects.addNewNoteTitle"),
    },
  ];

  return {
    isLoading: false,
    activeTab,
    tabItems,
    handleCloseDrawer,
    onTabChange,
  };
};

export default useNoteDetailVm;
