import { NSCircularProgressBar } from "./CircularProgressBar.style";
import { NSCircularProgressBarType } from "./CircularProgressBar.type";

function CircularProgressBar({
  percentage,
}: NSCircularProgressBarType.ICircularProgressBar) {
  return (
    <NSCircularProgressBar.SVG
      data-testid="circular-progress-bar-svg"
      viewBox="0 0 100 100"
    >
      <NSCircularProgressBar.Circle cx={50} cy={50} r={40} />
      <NSCircularProgressBar.ProgressCircle
        percentage={percentage}
        cx={50}
        cy={50}
        r={40}
      />
    </NSCircularProgressBar.SVG>
  );
}
export default CircularProgressBar;
