import { NSHistoryTooltipType } from "./HistoryTooltip.type";
import { NSHistoryTooltipStyle } from "./HistoryTooltip.style";
import Tooltip from "../Tooltip/Tooltip";

function HistoryTooltip({
  children,
  id,
  label,
  placement = "right",
  clickable = false,
}: NSHistoryTooltipType.IHistoryTooltip) {
  return (
    <NSHistoryTooltipStyle.HistoryTooltipBase>
      <NSHistoryTooltipStyle.HistoryTooltipLabel
        data-tooltip-id={id}
        data-tooltip-place={placement}
        data-tooltip-content={children ? "" : "Hello world!"}
      >
        {label}
      </NSHistoryTooltipStyle.HistoryTooltipLabel>
      <Tooltip clickable={clickable} id={id}>
        <NSHistoryTooltipStyle.HistoryTooltipContent>
          {children}
        </NSHistoryTooltipStyle.HistoryTooltipContent>
      </Tooltip>
    </NSHistoryTooltipStyle.HistoryTooltipBase>
  );
}

export default HistoryTooltip;
