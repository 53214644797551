import i18n from "../../../../library/i18next";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import { NSDatePickerType } from "../../../../components/DatePicker/DatePicker.type";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import Logo from "../../../../components/Logo/Logo";
import Icon from "../../../../components/Icon/Icon";
import { Controller } from "react-hook-form";
import { NSCreateInterviewStyle } from "../../../Interview-Create/Interview-Create.style";
import useInterviewDetailVM from "../../Interview-DetailVM";
import Loading from "../../../../components/Loading/Loading";

const DateSelectionDetail = () => {
  const {
    projectName,
    controlDate,
    defaultDates,
    isLoading,
    handleSubmitDate,
  } = useInterviewDetailVM();

  return (
    <NSCreateInterviewStyle.FormContainer onSubmit={handleSubmitDate()}>
      <NSCreateInterviewStyle.Header>
        <Logo type="onlyIcon" />
        <NSCreateInterviewStyle.ProjectNameBadge>
          <Icon name="Folders" />
          <NSCreateInterviewStyle.ProjectName>
            {projectName}
          </NSCreateInterviewStyle.ProjectName>
        </NSCreateInterviewStyle.ProjectNameBadge>
      </NSCreateInterviewStyle.Header>

      {isLoading ? (
        <Loading />
      ) : (
        <NSCreateInterviewStyle.Content>
          <NSCreateInterviewStyle.ContentTitle>
            {i18n.t("interview.interviewDate")}
          </NSCreateInterviewStyle.ContentTitle>
          <NSCreateInterviewStyle.ContentSubTitle>
            {i18n.t("interview.interviewDateSubTitle")}
          </NSCreateInterviewStyle.ContentSubTitle>
          <NSCreateInterviewStyle.DatePickerContainer>
            <Controller
              name="timePeriod"
              control={controlDate}
              render={({ field }) => {
                const { ref, onChange, ...fieldProps } = field;
                return (
                  <DatePicker
                    minDate={new Date()}
                    placeholder={i18n.t("forms.startDateEndDate")}
                    onChange={(dates) => onChange(dates)}
                    defaultDates={defaultDates as NSDatePickerType.Dates}
                    {...fieldProps}
                  />
                );
              }}
            />
          </NSCreateInterviewStyle.DatePickerContainer>
        </NSCreateInterviewStyle.Content>
      )}
      <NSCreateInterviewStyle.Fragment />
      <NSCreateInterviewStyle.Footer>
        <SolidButton type="submit" label={i18n.t("general.save")} />
      </NSCreateInterviewStyle.Footer>
    </NSCreateInterviewStyle.FormContainer>
  );
};

export default DateSelectionDetail;
