import styled, { css } from "styled-components";

import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSMainInputStyle } from "../MainInput/MainInput.style";

export namespace NSPasswordInputStyle {
  export const SearchInputBase = styled.div<{
    showPassword: boolean;
    value?: string;
  }>`
    ${NSMainButtonStyle.ButtonBase} {
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
      padding: 8px 0;

      &:hover {
        text-decoration: none;
      }
    }

    ${(props) =>
      !props.showPassword &&
      props?.value &&
      props.value.length > 0 &&
      css`
        ${NSMainInputStyle.InputFlex} {
          input[type="password"] {
            font-size: 35px;
          }
        }
      `}
  `;
}
