/* eslint-disable react/jsx-props-no-spreading */
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { NSDragAndDropListType } from "./DragAndDropList.type";
import DragableCheckBoxListItem from "../DragableCheckBoxListItem/DragableCheckBoxListItem";
import { NSDragableCheckBoxListItemStyle } from "../DragableCheckBoxListItem/DragableCheckBoxListItem.style";
import Icon from "../Icon/Icon";
import { NSDragAndDropListStyle } from "./DragAndDropList.style";

export default function SortableItem(
  props: NSDragAndDropListType.ISortableItemComponent
) {
  const {
    name,
    isSelected,
    id,
    language,
    isEditable = true,
    withMaxDuration,
    maxDuration,
    isShownToCandidate,
    onCheckboxClicked,
    onMaxDurationUpdated,
    onValueUpdated,
    onEdit,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <NSDragAndDropListStyle.SortableItemContainer
      ref={setNodeRef}
      style={style}
    >
      <NSDragableCheckBoxListItemStyle.DraggerContainer
        {...listeners}
        {...attributes}
      >
        <Icon name="Draggable" />
      </NSDragableCheckBoxListItemStyle.DraggerContainer>
      <DragableCheckBoxListItem
        id={id}
        language={language}
        maxDuration={maxDuration}
        onCheckboxClicked={() => onCheckboxClicked(id)}
        onValueUpdated={(value) => onValueUpdated(id, value)}
        checked={isSelected}
        dragableCheckBoxListItemTextValue={name}
        inputId={id.toString()}
        isEditable={isEditable}
        withMaxDuration={withMaxDuration}
        onMaxDurationUpdated={(value: number) =>
          onMaxDurationUpdated(id, value)
        }
        onEdit={onEdit}
        isShownToCandidate={isShownToCandidate}
      />
    </NSDragAndDropListStyle.SortableItemContainer>
  );
}
