import { NSRadioButtonStyle } from "./RadioButton.style";
import { NSRadioButtonType } from "./RadioButton.type";

function RadioButton({
  checked = false,
  label,
  id,
  labelContent,
  disabled = false,
}: NSRadioButtonType.IRadioButton) {
  return (
    <NSRadioButtonStyle.Container>
      <NSRadioButtonStyle.RadioButtonInput
        type="radio"
        value="Male"
        name="gender"
        id={id}
        checked={checked}
        readOnly
        disabled={disabled}
      />
      {label && (
        <NSRadioButtonStyle.RadioButtonLabel htmlFor={id}>
          {label}
        </NSRadioButtonStyle.RadioButtonLabel>
      )}
      {labelContent && (
        <NSRadioButtonStyle.RadioButtonLabel htmlFor={id}>
          {labelContent}
        </NSRadioButtonStyle.RadioButtonLabel>
      )}
    </NSRadioButtonStyle.Container>
  );
}

export default RadioButton;
