// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from "react-player";
import styled, { css } from "styled-components";

import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSProgressBarStyle } from "../ProgressBar/ProgressBar.style";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";

export namespace NSVideoPlayerStyle {
  export const VideoPlayer = styled(ReactPlayer)`
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 5px 5px;
    width: 100% !important;

    background-color: transparent;
  `;

  export const SpeedControls = styled.div`
    svg {
      path {
        stroke: ${(props) => props.theme.colors.light.light};
      }
    }

    ${NSDropdownStyle.DropdownItemsContainer} {
      width: 100px;
    }
  `;

  export const IconContainer = styled.div`
    display: flex;
    position: absolute;
    top: 50%;
    left: 398px;
    gap: 32px;
  `;

  export const Time = styled.div`
    position: absolute;
    bottom: 32px;
    left: 24px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.light.light};
  `;

  export const InformationContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.dark.dark};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    height: 56px;
    border-radius: 0px 0px 20px 20px;
  `;

  export const Name = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const Job = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const ExpandIconContainer = styled.div`
    position: absolute;
    bottom: 32px;
    right: 24px;
  `;

  export const Frame = styled.div<{ showIcons: boolean }>`
    @keyframes fadeIn {
      from {
        opacity: 100%;
      }
      to {
        opacity: 0%;
      }
    }

    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;

    ${(props) =>
      !props.showIcons &&
      css`
        animation: fadeIn 1s;
        opacity: 0;
      `}
  `;

  export const Container = styled.div<{ isSmall: boolean }>`
    box-sizing: border-box;
    left: calc(50% - 415px);
    width: auto !important;
    height: 256px !important;
    max-height: 476px !important;
    border-radius: 20px 20px 5px 5px;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08));

    ${NSProgressBarStyle.EmptyBar} {
      background-color: ${(props) => props.theme.colors.neutrals.grey02};
      border-radius: 0px 0px 32px 32px;
    }

    ${NSProgressBarStyle.ProgressThumb} {
      background-color: ${(props) => props.theme.colors.error.red01};
      border-radius: 0px 0px 0px 32px;
    }

    ${NSProgressBarStyle.Container} {
      opacity: 0.8;
      position: absolute;
      left: 0;
      max-height: 476px !important;
      bottom: -4px;
      border-radius: 20px;
      width: 837px;
      height: 7px;
    }
    ${NSProgressBarStyle.ProgressText} {
      display: none;
    }

    ${NSMainButtonStyle.ButtonBase} {
      width: 40px;
      height: 40px;
      padding: 0;
      svg {
        fill: transparent;
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }

    ${(props) =>
      props.isSmall &&
      css`
        position: absolute;
        width: 352px !important;
        height: auto !important;
        bottom: 24px;

        top: 0;
        left: 0;
        background-color: ${props.theme.colors.light.light};
        ${NSProgressBarStyle.Container} {
          height: 3px;
          top: 197px;
        }

        ${NSProgressBarStyle.EmptyBar} {
          border-radius: 0;
        }

        ${NSProgressBarStyle.ProgressThumb} {
          border-radius: 0;
        }

        ${VideoPlayer} {
          border-radius: 20px 20px 0 0;
          width: 352px !important;
          height: 200px !important;
        }

        ${IconContainer} {
          top: 90px;
          left: 60px;
          gap: 24px;
        }

        ${NSMainButtonStyle.ButtonBase} {
          width: fit-content;
          height: fit-content;
          svg {
            width: 24px;
            height: 24px;
          }
        }
        ${Time} {
          bottom: 75px;
          left: 12px;
          font-size: ${props.theme.typography.smallText2.size};
          line-height: ${props.theme.typography.smallText2.lineHeight};
          font-weight: ${props.theme.typography.fontWeights.regular};
        }

        ${InformationContainer} {
          display: flex;
        }

        ${ExpandIconContainer} {
          bottom: 75px;
          right: 12px;

          ${NSMainButtonStyle.ButtonBase} {
            width: fit-content;
            height: fit-content;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      `}
  `;
}
