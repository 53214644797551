import { IButton } from "../MainButton/MainButton.type";
import icons from "../../assets/icons";

export interface IIconButton extends IButton {
  iconButtonType?: IconButtonType;
  iconButtonShape?: IconButtonShape;
  isWithBackground?: boolean;
  iconSize?: number;
  isCloseButton?: boolean;
  icon: keyof typeof icons;
}

export type IconButtonType = "primary" | "secondary" | "grey";

export type IconButtonShape = "rounded" | "circle";

export const IconButtonColor = {
  primary: "hsla(235, 70%, 94%, 1)",
  secondary: "hsla(240, 14%, 89%, 1)",
  grey: "hsla(210, 6%, 94%, 1)",
};

export const IconButtonHoverColor = {
  primary: "hsla(234, 70%, 50%, 1)",
  secondary: "hsla(240, 75%, 15%, 1)",
  grey: "hsla(200, 5%, 87%, 1)",
};

export const IconColor = {
  primary: "hsla(234, 70%, 50%, 1)",
  secondary: "hsla(240, 75%, 15%, 1)",
  grey: "hsla(207, 5%, 56%, 1)",
};
