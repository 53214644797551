import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";
import FreeInput from "../../../../../components/FreeInput/FreeInput";
import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSTagType } from "../../../../../components/Tag/Tag.type";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import TagStack from "../../../../../components/TagStack/TagStack";
import i18n from "../../../../../library/i18next";

const EducationBackgroundContainer = styled.div``;

const EducationBackground = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const [pillItems, setPillItems] = useState<NSTagType.ITag[]>(
    () =>
      activeQuestion?.answers.map((item) => ({
        value: item.value as string,
        isSelected: item.isSelected,
        id: item.value as string,
      })) ?? []
  );

  const [freeInputValue, setFreeInputValue] = useState<string>("");
  const selectPillHandler = useCallback((tagList: NSTagType.ITag[]) => {
    setPillItems((prevList) =>
      prevList.map((i) => ({
        id: i.id,
        value: i.value,
        isSelected: tagList.find((tag) => tag.id === i.id) !== undefined,
      }))
    );
  }, []);

  const getAnswersAsJson = useCallback(() => pillItems, [pillItems]);

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
    }),
    [getAnswersAsJson]
  );

  const onClickAddFunction = useCallback(() => {
    if (freeInputValue !== "") {
      const newItem: NSTagType.ITag = {
        id: freeInputValue,
        value: freeInputValue,
        isSelected: true,
      };

      const findIndex = pillItems.findIndex(
        (item) => item.value === freeInputValue
      );
      const newItems =
        findIndex === -1 ? [newItem].concat(pillItems) : pillItems;
      setPillItems(newItems);
      setFreeInputValue("");
    }
  }, [freeInputValue, pillItems]);

  return (
    <EducationBackgroundContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
          <TagStack
            setTaglist={setPillItems}
            tagList={pillItems}
            numberOfVisibleTag={8}
            onSelectedChanged={selectPillHandler}
          />
        </NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
      </NSCreateJobDescriptionStyle.WQuestionContainer>
      <NSCreateJobDescriptionStyle.Footer>
        <FreeInput
          id="educationalBackgroundId"
          name="educationalBackgroundName"
          onClickAdd={onClickAddFunction}
          value={freeInputValue}
          onChange={(value) => setFreeInputValue(value)}
        />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            backgroundColor={ButtonColorVariant.grey}
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </EducationBackgroundContainer>
  );
});

EducationBackground.displayName = "EducationBackground";

export default EducationBackground;
