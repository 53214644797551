import { Outlet } from "react-router-dom";
import { NSSettingsStyle } from "./Settings.style";
import TabbedView from "../../components/TabbedView/TabbedView";
import useSettingsVm from "./SettingsVM";

function Settings() {
  const { tabItems, activeTab, onTabChange } = useSettingsVm();

  return (
    <NSSettingsStyle.Container>
      <NSSettingsStyle.PageContent>
        <TabbedView
          onTabChange={(tab) => onTabChange(tab)}
          tabItems={tabItems}
          activeTab={activeTab}
        />
        <Outlet />
      </NSSettingsStyle.PageContent>
    </NSSettingsStyle.Container>
  );
}

export default Settings;
