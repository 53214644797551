import { IOutlinedButton } from "./OutlinedButton.type";
import MainButton from "../MainButton/MainButton";
import { NSOutlinedButtonStyle } from "./OutlinedButton.style";

function OutlinedButton({
  variant = "basic",
  fullWidth,
  disabled,
  onClick,
  size,
  label,
  iconColor,
  suffixIcon,
  prefixIcon,
  type,
}: IOutlinedButton) {
  return (
    <NSOutlinedButtonStyle.OutlinedButtonBase fullWidth={fullWidth}>
      <MainButton
        fullWidth={fullWidth}
        variant={variant}
        type={type}
        prefixIcon={prefixIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
        label={label}
        iconColor={iconColor}
        suffixIcon={suffixIcon}
      />
    </NSOutlinedButtonStyle.OutlinedButtonBase>
  );
}
export default OutlinedButton;
