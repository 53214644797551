import CardView from "../../components/CardView/CardView";
import GhostButton from "../../components/GhostButton/GhostButton";
import Logo from "../../components/Logo/Logo";
import { NSForgotPasswordStyle } from "./ForgotPassword.style";
import PasswordSuccessCard from "../../components/PasswordSuccessCard/PasswordSuccessCard";
import SolidButton from "../../components/SolidButton/SolidButton";
import TextInput from "../../components/TextInput/TextInput";
import useForgotPasswordVm from "./ForgotPasswordVm";
import i18n from "../../library/i18next";

function ForgotPassword() {
  const forgotPasswordVm = useForgotPasswordVm();

  return (
    <NSForgotPasswordStyle.Container>
      <Logo />
      <CardView>
        <PasswordSuccessCard
          header={i18n.t("forgotPassword.header")}
          description={i18n.t("forgotPassword.description")}
          isShown={!!forgotPasswordVm.result}
          onClick={() => forgotPasswordVm.navigate("/auth")}
        />

        <NSForgotPasswordStyle.FormContainer isShown={!forgotPasswordVm.result}>
          <NSForgotPasswordStyle.UpContainer>
            <NSForgotPasswordStyle.Title>
              {i18n.t("forgotPassword.forgotPassword")}
            </NSForgotPasswordStyle.Title>
            <NSForgotPasswordStyle.Description>
              {i18n.t("forgotPassword.forgotPasswordDesc")}
            </NSForgotPasswordStyle.Description>
          </NSForgotPasswordStyle.UpContainer>

          <NSForgotPasswordStyle.Form
            onSubmit={forgotPasswordVm.submitHandler()}
          >
            <TextInput
              id="email"
              placeholder={i18n.t("forms.emailAddress")}
              error={forgotPasswordVm.isErrorBarActive}
              errorText={
                forgotPasswordVm.errors.email?.message ??
                i18n.t("forms.validEmailError")
              }
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...forgotPasswordVm.register("email", { required: true })}
              onChange={(value) => {
                forgotPasswordVm.setEmailValue(value);
                forgotPasswordVm.setValue?.("email", value);
              }}
            />
            <NSForgotPasswordStyle.ButtonContainer>
              <SolidButton
                fullWidth
                label={i18n.t("forgotPassword.buttonLabel")}
                type="submit"
              />
              <GhostButton
                fullWidth
                label={i18n.t("forgotPassword.backToLogin")}
                onClick={forgotPasswordVm.onClickBack}
              />
            </NSForgotPasswordStyle.ButtonContainer>
          </NSForgotPasswordStyle.Form>
        </NSForgotPasswordStyle.FormContainer>
      </CardView>
    </NSForgotPasswordStyle.Container>
  );
}

export default ForgotPassword;
