import styled from "styled-components";
import { NSVideoPlayerStyle } from "../../../../components/VideoPlayer/VideoPlayer.style";

export namespace NSVideoPreviewStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};

    padding: 32px 28px;

    ${NSVideoPlayerStyle.Container} {
      top: 215px;
    }

    ${NSVideoPlayerStyle.IconContainer} {
      left: calc(50% - 165px);
    }
  `;

  export const VideoContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 476px;
    width: 100%;
  `;
}
