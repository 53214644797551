import { NSFormHintStyle } from "./FormHint.style";
import { NSFormHintType } from "./FormHint.type";

function FormHint({ hintItems }: NSFormHintType.IFormHint) {
  const renderHintItems = () =>
    hintItems.map((hintItem) => (
      <NSFormHintStyle.HintItem key={hintItem.label}>
        <NSFormHintStyle.Orb achived={hintItem.achived} />
        <NSFormHintStyle.Text>{hintItem.label}</NSFormHintStyle.Text>
      </NSFormHintStyle.HintItem>
    ));

  return (
    <NSFormHintStyle.Container>{renderHintItems()}</NSFormHintStyle.Container>
  );
}

export default FormHint;
