import Drawer from "../../../../components/Drawer/Drawer";

import { NSEditCandidateStyle } from "./Edit-Candidate.style";
import useEditCandidateVm from "./Edit-CandidateVm";

import TextInput from "../../../../components/TextInput/TextInput";
import PhoneNumber from "../../../../components/PhoneNumber/PhoneNumber";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import Loading from "../../../../components/Loading/Loading";
import i18n from "../../../../library/i18next";
import parsePhoneNumberHelper from "../../../../utils/parsePhoneNumberHelper";

export default function EditCandidate() {
  const editCandidateVm = useEditCandidateVm();

  return editCandidateVm.isLoading ? (
    <Loading />
  ) : (
    <Drawer title={i18n.t("projects.editCandidate")}>
      <NSEditCandidateStyle.Container>
        <NSEditCandidateStyle.EditCandidateForm
          onSubmit={editCandidateVm.handleSubmit?.()}
        >
          <TextInput
            id="candidateNameId"
            value={editCandidateVm.candidateName}
            placeholder={i18n.t("forms.name")}
            error={!!editCandidateVm.formErrors.candidateName}
            errorText={editCandidateVm.formErrors?.candidateName?.message}
            onChange={editCandidateVm.onChangeCandidateNameInput}
            name="candidateName"
          />
          <TextInput
            id="candidateSurnameId"
            placeholder={i18n.t("forms.surname")}
            value={editCandidateVm.candidateSurname}
            error={!!editCandidateVm.formErrors.candidateSurname}
            errorText={editCandidateVm.formErrors?.candidateSurname?.message}
            onChange={editCandidateVm.onChangeSurnameInput}
            name="candidateSurname"
          />

          <TextInput
            value={editCandidateVm.emailAddress}
            id="emailAddressId"
            placeholder={i18n.t("forms.emailAddress")}
            error={!!editCandidateVm.formErrors.emailAddress}
            errorText={editCandidateVm.formErrors?.emailAddress?.message}
            onChange={editCandidateVm.onChangeEmailInput}
            name="emailAddress"
          />

          <PhoneNumber
            id="phoneNumberId"
            defaultValue={
              parsePhoneNumberHelper(
                editCandidateVm.phoneCode,
                editCandidateVm.getValues("phoneNumber")
              ).plainPhoneNumber
            }
            name="phoneNumber"
            onChange={editCandidateVm.onChangePhoneInput}
            dropdownItems={editCandidateVm.phoneCode}
            error={!!editCandidateVm.formErrors.phoneNumber}
            errorText={editCandidateVm.formErrors.phoneNumber?.message}
            defaultSelectedItem={
              parsePhoneNumberHelper(
                editCandidateVm.phoneCode,
                editCandidateVm.getValues("phoneNumber")
              ).codeFromArray
            }
          />
          <FileUpload
            onUpload={(url, name) => {
              editCandidateVm.onUploadFile(url, name);
            }}
            onClickDownload={() => {
              editCandidateVm.downloadFile();
            }}
            uploadedFileName={editCandidateVm.cvFileName}
            onClickClose={() => editCandidateVm.setCvFileName(undefined)}
            onError={(message) => editCandidateVm.onErrorFileUpload(message!)}
          />
          <SolidButton
            label={i18n.t("projects.editCandidate")}
            type="submit"
            fullWidth
          />
        </NSEditCandidateStyle.EditCandidateForm>
      </NSEditCandidateStyle.Container>
    </Drawer>
  );
}
