import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  GetProjectsDropdownDocument,
  InsertJobDescriptionDocument,
} from "../../../../gql/graphql";
import {
  useGraphQLMutation,
  useGraphQLQuery,
} from "../../../../hooks/useGraphQL";

import { NSDropdown } from "../../../../components/Dropdown/Dropdown.type";
import i18n from "../../../../library/i18next";
import icons from "../../../../assets/icons";
import rolesData from "../../../../data/roles";
import { useJobDescriptionContext } from "../../../../context/JobDescriptionContext/JobDescriptionContext";

function useFirstJobCreationVm() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const projectIdFromLocation = useMemo(
    () => state?.projectId ?? undefined,
    [state]
  );

  const {
    selectedRoleItem,
    roleButtonLabel,
    getFirstQuestion,
    selectedPositionItem,
    selectedExperienceItem,
    selectClientName,
  } = useJobDescriptionContext();

  const { data: projectsData } = useGraphQLQuery(GetProjectsDropdownDocument);

  const { mutateAsync: insertJobDescription, isLoading } = useGraphQLMutation(
    InsertJobDescriptionDocument
  );

  const [isStartButtonLoading, setIsStartButtonLoading] = useState(isLoading);

  const projects: NSDropdown.IDropdownItem[] | undefined = useMemo(
    () =>
      projectsData?.project.map((project) => ({
        id: project.id,
        label: project.project_name,
      })),
    [projectsData?.project]
  );

  const [onClickedDismiss, setOnClickedDismiss] = useState(false);

  const [onClickedCreateProjectDismiss, setOnClickedCreateProjectDismiss] =
    useState(false);

  const defaultSelectedProject = useMemo(() => {
    let project;
    if (projectIdFromLocation !== undefined) {
      project = projects?.find((i) => i.id === projectIdFromLocation);
    } else if (projects?.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      project = projects[0];
    } else {
      project = projects?.find(
        (i) =>
          i.id === projectsData?.project.find((item) => item.is_default)?.id
      );
    }
    return project;
  }, [projectIdFromLocation, projects, projectsData?.project]);

  const [selectedProject, setSelectedProject] = useState(
    defaultSelectedProject
  );

  const selectedProjectClient = useMemo(
    () =>
      selectedProject !== undefined
        ? projectsData?.project.find((i) => i.id === selectedProject?.id)
            ?.client.client_name
        : projectsData?.project.find((i) => i.id === defaultSelectedProject?.id)
            ?.client.client_name,

    [defaultSelectedProject?.id, projectsData?.project, selectedProject]
  );

  const isShownTitleOrExperienceModalButton = useMemo(
    () =>
      !onClickedDismiss &&
      selectedPositionItem === undefined &&
      selectedExperienceItem === undefined &&
      selectedRoleItem &&
      (rolesData.find((i) => i.id === selectedRoleItem.id)?.experience ===
        null ||
        rolesData.find((i) => i.id === selectedRoleItem.id)?.title === null),
    [
      onClickedDismiss,
      selectedExperienceItem,
      selectedPositionItem,
      selectedRoleItem,
    ]
  );
  const navigate = useNavigate();

  const createJobDescription = useCallback(() => {
    const jdProject = selectedProject ?? defaultSelectedProject;
    if (selectedRoleItem && jdProject) {
      insertJobDescription({
        projectId: jdProject.id,
        title: roleButtonLabel,
        clientName: selectedProjectClient,
      }).then(({ insert_job_description }) => {
        setIsStartButtonLoading(true);
        // get next question and render with-question-job-description
        // getFirstQuestion(insert_job_description?.returning[0].id);
        selectClientName(selectedProjectClient);
        setSearchParams(
          { jdId: insert_job_description?.returning[0].id },
          { state }
        );
      });
    } else {
      alert("Missing role or project");
    }
  }, [
    selectedProject,
    defaultSelectedProject,
    selectedRoleItem,
    insertJobDescription,
    roleButtonLabel,
    selectClientName,
    selectedProjectClient,
    setSearchParams,
    state,
  ]);

  const isStartButtonDisabled = useMemo(
    () => roleButtonLabel === i18n.t("jd.addYourRoleHere"),
    [roleButtonLabel]
  );

  const buttonIcon: keyof typeof icons = useMemo(
    () => (selectedRoleItem ? "Chevron" : "AddCircleOutlined"),
    [selectedRoleItem]
  );

  const showRoles = useCallback(() => {
    navigate("role", { state });
  }, [navigate, state]);

  const showExperienceAndTitle = useCallback(() => {
    navigate("experience-position", { state });
  }, [navigate, state]);

  const onClickCreateProject = useCallback(() => {
    navigate("/projects/create", {
      state: { prevPath: "/job-description/create ", ...state },
    });
  }, [navigate, state]);

  const onSelectProject = useCallback(
    (_id: number, selected?: NSDropdown.IDropdownItem) => {
      setSelectedProject(selected);
    },
    []
  );

  const isPageLoading = useMemo(
    () => defaultSelectedProject === undefined,
    [defaultSelectedProject]
  );

  return {
    defaultSelectedProject,
    isPageLoading,
    onSelectProject,
    getFirstQuestion,
    isStartButtonLoading,
    createJobDescription,
    projects,
    selectedProject,
    roleButtonLabel,
    buttonIcon,
    isStartButtonDisabled,
    showRoles,
    showExperienceAndTitle,
    isShownTitleOrExperienceModalButton,
    setOnClickedDismiss,
    selectedProjectClient,
    setOnClickedCreateProjectDismiss,
    onClickedCreateProjectDismiss,
    onClickCreateProject,
  };
}

export default useFirstJobCreationVm;
