/* eslint-disable react/jsx-props-no-spreading */
import { NSToastAlertStyle } from "./ToastAlert.style";
import { NSToastAlertType } from "./ToastAlert.type";
import icons from "../../assets/icons";
import Icon from "../Icon/Icon";
import SolidButton from "../SolidButton/SolidButton";
import LinkButton from "../LinkButton/LinkButton";
import i18n from "../../library/i18next";

function ToastAlert({
  label,
  type,
  description,
  firstButton,
  secondButton,
}: NSToastAlertType.IToastAlert) {
  const alertIcon = {
    success: "CheckCircleFilled",
    info: "WarningCircleInfo",
    warning: "WarningCircle",
    error: "CloseCircleOutlined",
  };

  const alertLabels = {
    success: i18n.t("general.success"),
    info: i18n.t("general.info"),
    warning: i18n.t("general.warning"),
    error: i18n.t("general.error"),
  };

  return (
    <NSToastAlertStyle.Container label={label} type={type}>
      <NSToastAlertStyle.IconContainer>
        <Icon name={alertIcon[type] as keyof typeof icons} />
      </NSToastAlertStyle.IconContainer>
      <NSToastAlertStyle.RightContainer>
        <NSToastAlertStyle.AlertText>
          {label ?? alertLabels[type]}
        </NSToastAlertStyle.AlertText>
        {description && (
          <NSToastAlertStyle.DescriptionText>
            {description}
          </NSToastAlertStyle.DescriptionText>
        )}
        {firstButton && (
          <NSToastAlertStyle.ButtonContainer>
            <SolidButton {...firstButton} />
            {secondButton && <LinkButton {...secondButton} />}
          </NSToastAlertStyle.ButtonContainer>
        )}
      </NSToastAlertStyle.RightContainer>
    </NSToastAlertStyle.Container>
  );
}

export default ToastAlert;
