import Drawer from "../../../components/Drawer/Drawer";
import ScoreBadge from "../../../components/ScoreBadge/ScoreBadge";
import i18n from "../../../library/i18next";
import { NSQuestionDetailStyle } from "./QuestionDetail.style";
import useQuestionDetailVm from "./QuestionDetailVM";

const QuestionDetail = () => {
  const { question } = useQuestionDetailVm();
  return (
    <Drawer title="">
      <NSQuestionDetailStyle.Container>
        <NSQuestionDetailStyle.MetricBadge>
          {question?.review_metric.description}
        </NSQuestionDetailStyle.MetricBadge>
        <NSQuestionDetailStyle.Title>
          {question?.question || question?.description}
        </NSQuestionDetailStyle.Title>
        <NSQuestionDetailStyle.SubTitle>
          {i18n.t("interview.description")}
        </NSQuestionDetailStyle.SubTitle>
        <NSQuestionDetailStyle.Description>
          {question?.reviewer_description}
        </NSQuestionDetailStyle.Description>
        <NSQuestionDetailStyle.SubTitle>
          {i18n.t("interview.responses")}
        </NSQuestionDetailStyle.SubTitle>
        <NSQuestionDetailStyle.ResponseBadgeContainer>
          {question?.review_metric_question_responses.map((item) => (
            <ScoreBadge description={item.description} score={item.score} />
          ))}
        </NSQuestionDetailStyle.ResponseBadgeContainer>
      </NSQuestionDetailStyle.Container>
    </Drawer>
  );
};

export default QuestionDetail;
