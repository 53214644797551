import { NSEmptyViewStyle } from "./EmptyView.style";
import { NSEmptyViewType } from "./EmptyView.type";
import NoResult from "../../assets/illustration/no_result.png";
import SolidButton from "../SolidButton/SolidButton";
import i18n from "../../library/i18next";

function EmptyView({
  buttonLabel,
  onClick,
  errorMessage,
}: NSEmptyViewType.IEmptyView) {
  return (
    <NSEmptyViewStyle.Container>
      <NSEmptyViewStyle.Illustration src={NoResult} />
      <NSEmptyViewStyle.NoResultTitle>
        {i18n.t("general.noResult")}
      </NSEmptyViewStyle.NoResultTitle>
      <NSEmptyViewStyle.NoResultLabel>
        {errorMessage}
      </NSEmptyViewStyle.NoResultLabel>
      <SolidButton label={buttonLabel} onClick={onClick} />
    </NSEmptyViewStyle.Container>
  );
}

export default EmptyView;
