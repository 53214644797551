import { useState } from "react";
import BasicDropdown from "./BasicDropdown";
import CheckBoxDropdown from "./CheckboxDropdown";
import Icon from "../Icon/Icon";
import { NSDropdown } from "./Dropdown.type";
import { NSDropdownStyle } from "./Dropdown.style";
import { NSMainInputStyle } from "../MainInput/MainInput.style";
import { useDetectClickOutside } from "../../hooks/useDetectClickOutside";

function Dropdown({
  id,
  label,
  options,
  onSelect,
  prefixItem,
  showLabel,
  showSuffixItems = true,
  title,
  selectedDropdownItem,
  containAddNewItemButton,
  placeholder,
  suffixItem,
  titleWithPrefixItem,
  type = "basic",
  onOpen,
  onCheckedItems,
  error,
  errorText,
  defaultCheckedItems,
  addNewItemButtonNode,
  children,
  showHoverIcon,
  isSearchActive,
  position = "bottom",
  shouldBehaveLikeMenu,
  onSearchChange,
}: Readonly<NSDropdown.IDropdown>) {
  const [searchText, setSearchText] = useState<string | undefined>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSearchChange = (value: string | undefined) => {
    onSearchChange ? onSearchChange?.(value) : setSearchText(value);
  };

  const dropdownContentVisibleChangeHandler = (status: boolean) => {
    onOpen?.(status);
    setIsOpen(status);
    setSearchText("");
    onSearchChange?.("");
  };

  const ref = useDetectClickOutside({
    onTrigger: () => {
      dropdownContentVisibleChangeHandler(false);
    },
  });

  const generatedSuffixItem = (
    <NSDropdownStyle.Suffix>
      {isOpen ? (
        <Icon width={24} height={24} name="ChevronUp" />
      ) : (
        <Icon width={24} height={24} name="ChevronDown" />
      )}
    </NSDropdownStyle.Suffix>
  );
  const onSelectItem = (selected?: NSDropdown.IDropdownItem) => {
    onSelect?.(id, selected);
    dropdownContentVisibleChangeHandler(type !== "basic");
  };

  const content = {
    basic: (
      <BasicDropdown
        shouldBehaveLikeMenu={shouldBehaveLikeMenu}
        position={position}
        isSearchActive={isSearchActive}
        searchText={searchText}
        setSearchText={handleSearchChange}
        onSelect={onSelectItem}
        error={error}
        showLabel={showLabel}
        options={options}
        isOpen={isOpen}
        suffixItem={suffixItem ?? generatedSuffixItem}
        titleWithPrefixItem={titleWithPrefixItem}
        onClickTitle={() => dropdownContentVisibleChangeHandler(!isOpen)}
        label={label}
        prefixItem={prefixItem}
        placeholder={placeholder}
        containAddNewItemButton={containAddNewItemButton}
        title={title}
        showHoverIcon={showHoverIcon}
        selectedDropdownItem={selectedDropdownItem}
        showSuffixItems={showSuffixItems}
        addNewItemButtonNode={addNewItemButtonNode}
      >
        {children}
      </BasicDropdown>
    ),
    withCheckbox: (
      <CheckBoxDropdown
        onSelect={onSelectItem}
        showSuffixItems={showSuffixItems}
        error={error}
        defaultCheckedItems={defaultCheckedItems}
        titleWithPrefixItem={titleWithPrefixItem}
        options={options}
        showLabel={showLabel}
        prefixItem={prefixItem}
        suffixItem={suffixItem ?? generatedSuffixItem}
        isOpen={isOpen}
        onClickTitle={() => dropdownContentVisibleChangeHandler(!isOpen)}
        label={label}
        placeholder={placeholder}
        containAddNewItemButton={containAddNewItemButton}
        title={title}
        selectedDropdownItem={selectedDropdownItem}
        onCheckedItems={onCheckedItems}
      />
    ),
  };

  return (
    <NSDropdownStyle.DropdownBase ref={ref} isOpen={isOpen}>
      {content[type]}
      {error && (
        <NSMainInputStyle.ErrorText role="alert">
          {errorText}
        </NSMainInputStyle.ErrorText>
      )}
    </NSDropdownStyle.DropdownBase>
  );
}

export default Dropdown;
