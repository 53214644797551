import { useGraphQLQuery } from "../../hooks/useGraphQL";
import {
  Client_Candidate_Project_Bool_Exp,
  Client_Candidate_Project_Order_By,
  GetClientCandidateDocument,
  GetClientCandidateProjectWithOrderAndFilterDocument,
  GetClientCandidateScoreDocument,
  GetClientCandidateOverviewDocument,
  Client_Candidate_Project_Candidate_List_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../gql/graphql";

export function useGetClientCandidate(searchString: string) {
  const { data, ...rest } = useGraphQLQuery(
    GetClientCandidateDocument,
    undefined,
    {
      searchString,
    }
  );

  return {
    rest,
    data,
  };
}

export function useGetClientCandidateWithOrderAndFilter(
  limit: number,
  offset: number,
  projectId: string,
  candidate_list_status: Client_Candidate_Project_Candidate_List_Status_Enum,
  listFilter: Client_Candidate_Project_Bool_Exp,
  listOrder: Client_Candidate_Project_Order_By,
  options?: any
) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetClientCandidateProjectWithOrderAndFilterDocument,
    options,
    {
      limit,
      offset,
      projectId,
      candidate_list_status,
      listFilter,
      listOrder,
    }
  );

  return {
    rest,
    refetch,
    data,
  };
}

export function useGetClientCandidateScore(
  clientCandidateId: string,
  projectId: string
) {
  const { data, ...rest } = useGraphQLQuery(
    GetClientCandidateScoreDocument,
    undefined,
    {
      clientCandidateId,
      projectId,
    }
  );

  return {
    rest,
    data,
  };
}

export function useGetClientCandidateOverview(
  id: string,
  interviewType: Video_Interview_Type_Enum
) {
  const { data, isSuccess, refetch, ...rest } = useGraphQLQuery(
    GetClientCandidateOverviewDocument,
    undefined,
    {
      id,
      interviewType,
    }
  );

  return {
    rest,
    isSuccess,
    refetch,
    data,
  };
}
