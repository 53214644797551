/* eslint-disable react/jsx-props-no-spreading */
import { IButton } from "./MainButton.type";
import Icon from "../Icon/Icon";
import { NSMainButtonStyle } from "./MainButton.style";
import { useId } from "react";

function MainButton({
  children,
  onClick,
  disabled,
  fullWidth,
  variant = "basic",
  label,
  isIconButton,
  suffixIcon,
  iconColor,
  prefixIcon,
  type,
  isLoading = false,
  loadingContent,
  ...props
}: IButton) {
  const id = useId();
  let buttonContent;
  if (isLoading) {
    buttonContent = loadingContent;
  } else if (isIconButton) {
    buttonContent = children;
  } else {
    switch (variant) {
      case "basic":
        buttonContent = (
          <NSMainButtonStyle.ButtonLabel>{label}</NSMainButtonStyle.ButtonLabel>
        );
        break;

      case "label-w-icon-prefix":
        buttonContent = (
          <>
            {prefixIcon && (
              <NSMainButtonStyle.IconContainer iconColor={iconColor}>
                <Icon width={24} height={24} name={prefixIcon} />
              </NSMainButtonStyle.IconContainer>
            )}
            <NSMainButtonStyle.ButtonLabel>
              {label}
            </NSMainButtonStyle.ButtonLabel>
          </>
        );
        break;
      case "label-w-icon-suffix":
        buttonContent = (
          <>
            <NSMainButtonStyle.ButtonLabel>
              {label}
            </NSMainButtonStyle.ButtonLabel>
            {suffixIcon && (
              <NSMainButtonStyle.IconContainer iconColor={iconColor} isSuffix>
                <Icon width={24} height={24} name={suffixIcon} />
              </NSMainButtonStyle.IconContainer>
            )}
          </>
        );
        break;
      case "label-w-icon-prefix-&-suffix":
        buttonContent = (
          <>
            {prefixIcon && (
              <NSMainButtonStyle.IconContainer iconColor={iconColor}>
                <Icon width={24} height={24} name={prefixIcon} />
              </NSMainButtonStyle.IconContainer>
            )}
            <NSMainButtonStyle.ButtonLabel>
              {label}
            </NSMainButtonStyle.ButtonLabel>
            {suffixIcon && (
              <NSMainButtonStyle.IconContainer iconColor={iconColor} isSuffix>
                <Icon width={24} height={24} name={suffixIcon} />
              </NSMainButtonStyle.IconContainer>
            )}
          </>
        );
        break;
      default:
        buttonContent = label;
        break;
    }
  }

  return (
    <NSMainButtonStyle.ButtonBase
      onClick={onClick}
      id={id}
      disabled={disabled || isLoading}
      role="button"
      fullWidth={fullWidth}
      isLoading={isLoading}
      type={type ?? "button"}
      {...props}
    >
      {buttonContent}
    </NSMainButtonStyle.ButtonBase>
  );
}
export default MainButton;
