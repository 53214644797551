import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import Avatar from "../Avatar/Avatar";
import DashboardMainButton from "../DashboardMainButton/DashboardMainButton";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import Logo from "../Logo/Logo";
import { NSDashboardMenuSidebarStyle } from "./DashboardMenuSidebar.style";
import { NSDashboardMenuSidebarType } from "./DashboardMenuSidebar.type";
import SideMenu from "../SideMenu/SideMenu";
import User from "../User/User";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import i18n, { changeSiteLanguage } from "../../library/i18next";
import SideMenuItem from "../SideMenuItem/SideMenuItem";
import capitalizeFirstLetterHelper from "../../utils/capitalizeFirstLetterHelper";

function DashboardMenuSidebar({
  isDashboardOpen,
  navbarTopItems,
  navbarBottomItems,
  user,
  activePath,
  onClickLogout,
  contextMenu,
}: NSDashboardMenuSidebarType.IDashboardMenuSidebar) {
  const navigate = useNavigate();

  const onSelect = useCallback(
    (selected: string) => {
      changeSiteLanguage(selected);
      navigate(0);
    },
    [navigate]
  );

  return (
    <NSDashboardMenuSidebarStyle.Container isOpen={isDashboardOpen}>
      <NSDashboardMenuSidebarStyle.TopItemsContainer isOpen={isDashboardOpen}>
        <NSDashboardMenuSidebarStyle.LogoContainer>
          <Logo type={isDashboardOpen ? "basic" : "onlyIcon"} />
        </NSDashboardMenuSidebarStyle.LogoContainer>
        <SideMenu
          isOpen={isDashboardOpen}
          items={navbarTopItems}
          activePath={activePath}
        />
      </NSDashboardMenuSidebarStyle.TopItemsContainer>
      <NSDashboardMenuSidebarStyle.BottomItemsContainer>
        <NSDashboardMenuSidebarStyle.UserContainer>
          {isDashboardOpen ? (
            <>
              {user && (
                <User
                  avatarSize="small"
                  name={capitalizeFirstLetterHelper(user.name!)}
                  job={user.job}
                  avatarUrl={user.avatarUrl}
                  username={user.username}
                  date={user.date}
                  isAvatarInLeft={user.isAvatarInLeft}
                  isAvatarCircle={user.isAvatarCircle}
                  avatarType={user.avatarType}
                  avatarInfoMargin={user.avatarInfoMargin}
                  companyName={user.companyName}
                />
              )}
              <GhostIconButton
                id="logoutButton"
                onClick={onClickLogout}
                iconSize={24}
                icon="Logout"
              />
            </>
          ) : (
            <Avatar size="small" avatarUrl={user?.avatarUrl ?? ""} />
          )}
        </NSDashboardMenuSidebarStyle.UserContainer>
        <NSDashboardMenuSidebarStyle.SettingsContainer>
          <SideMenuItem
            activePath={activePath}
            key={"settings"}
            icon={"Settings"}
            name={i18n.t("settings.settings")}
            path={"settings/profile"}
            customPath={"settings"}
            isOpen={isDashboardOpen}
          />
        </NSDashboardMenuSidebarStyle.SettingsContainer>

        <NSDashboardMenuSidebarStyle.LanguageSelectionContainer>
          <LanguageSelection
            onSelect={(selected) => onSelect(selected)}
            isOpen={isDashboardOpen}
          />
        </NSDashboardMenuSidebarStyle.LanguageSelectionContainer>

        <SideMenu
          isOpen={isDashboardOpen}
          activePath={activePath}
          items={navbarBottomItems}
        />
      </NSDashboardMenuSidebarStyle.BottomItemsContainer>
      {isDashboardOpen && (
        <NSDashboardMenuSidebarStyle.DashboardButtonoContainer>
          <DashboardMainButton
            items={contextMenu.items}
            onItemClick={contextMenu.onItemClick}
            type={contextMenu.type}
            buttonLabel={contextMenu.buttonLabel}
            isScrollable={contextMenu.isScrollable}
            title={contextMenu.title}
            showSearch={contextMenu.showSearch}
            containAddNewItemButton={contextMenu.containAddNewItemButton}
            onCheckedItems={contextMenu.onCheckedItems}
            onContextMenuOpen={contextMenu.onContextMenuOpen}
          />
        </NSDashboardMenuSidebarStyle.DashboardButtonoContainer>
      )}
    </NSDashboardMenuSidebarStyle.Container>
  );
}

export default DashboardMenuSidebar;
