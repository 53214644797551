import { useCallback, useRef, useState } from "react";
import ReactPlayer from "react-player";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { NSVideoPlayerStyle } from "./VideoPlayer.style";
import { NSVideoPlayerType } from "./VideoPlayer.type";
import Dropdown from "../Dropdown/Dropdown";

function VideoPlayer({
  urls,
  title,
  subtitle,
  isSmall,
}: Readonly<NSVideoPlayerType.IVideoPlayer>) {
  const [isPlayed, setIsPlayed] = useState(false);
  const [showIcons, setShowIcons] = useState(true);
  const [activeUrlIndex, setActiveUrlIndex] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);

  const playerRef = useRef<ReactPlayer>(null);

  const onClickPlay = useCallback(() => {
    setIsPlayed(!isPlayed);
  }, [isPlayed]);

  const onRestartClick = useCallback(() => {
    playerRef.current?.seekTo(0, "seconds");
  }, [playerRef]);

  const onSeekBackward = useCallback(() => {
    const currentTime = playerRef.current?.getCurrentTime();
    if (currentTime && !isNaN(currentTime)) {
      playerRef.current?.seekTo(currentTime - 10);
    }
  }, [playerRef]);

  const onSeekForward = useCallback(() => {
    const currentTime = playerRef.current?.getCurrentTime();
    if (currentTime && !isNaN(currentTime)) {
      playerRef.current?.seekTo(currentTime + 10);
    }
  }, [playerRef]);

  const onMouseEnter = useCallback(() => {
    setShowIcons(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowIcons(false);
  }, []);

  const onEnded = useCallback(() => {
    setActiveUrlIndex(
      activeUrlIndex !== urls.length - 1 ? activeUrlIndex + 1 : activeUrlIndex
    );
  }, [activeUrlIndex, urls.length]);

  const changePlaybackRate = (rate: number, id: number) => {
    setSelectedSpeed(
      speedControlsContextMenu.find((item) => item.id === id) as any
    );
    setPlaybackRate(rate);
  };

  ReactPlayer.removeCustomPlayers();

  const speedControlsContextMenu = [
    {
      id: 0,
      label: "0.5x",
      onClick: () => changePlaybackRate(0.5, 0),
    },
    {
      id: 1,
      label: "Normal",
      onClick: () => changePlaybackRate(1, 1),
    },
    {
      id: 2,
      label: "1.5x",
      onClick: () => changePlaybackRate(1.5, 2),
    },
    {
      id: 3,
      label: "2x",
      onClick: () => changePlaybackRate(2, 3),
    },
  ];

  const [selectedSpeed, setSelectedSpeed] = useState(
    speedControlsContextMenu[1]
  );

  return (
    <NSVideoPlayerStyle.Container isSmall={isSmall!}>
      <NSVideoPlayerStyle.VideoPlayer
        ref={playerRef}
        className="react-player"
        url={urls[activeUrlIndex]}
        onEnded={onEnded}
        playing={isPlayed}
        playbackRate={playbackRate}
        controls
        playsInline
        config={{
          file: {
            attributes: {
              disablePictureInPicture: true,
              controlsList: "nodownload",
            },
          },
        }}
        onError={() => setActiveUrlIndex(activeUrlIndex + 1)}
      />
      <NSVideoPlayerStyle.Frame
        showIcons={showIcons}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <NSVideoPlayerStyle.IconContainer>
          <GhostIconButton icon="Rewind" onClick={onSeekBackward} />
          <NSVideoPlayerStyle.SpeedControls>
            <Dropdown
              id={1}
              type="basic"
              options={speedControlsContextMenu}
              selectedDropdownItem={selectedSpeed}
              shouldBehaveLikeMenu
              // @ts-ignore
              onSelect={(e, value) => value?.onClick()}
            >
              <GhostIconButton icon="Settings" />
            </Dropdown>
          </NSVideoPlayerStyle.SpeedControls>
          <GhostIconButton
            id="Player"
            icon={isPlayed ? "Pause" : "Play"}
            onClick={onClickPlay}
          />
          <GhostIconButton icon="ArrowRestart" onClick={onRestartClick} />
          <GhostIconButton icon="Forward" onClick={onSeekForward} />
        </NSVideoPlayerStyle.IconContainer>
        {/*  <NSVideoPlayerStyle.ExpandIconContainer>
          <GhostIconButton icon="ScreenExpand" onClick={onClickExpand} />
        </NSVideoPlayerStyle.ExpandIconContainer> */}
      </NSVideoPlayerStyle.Frame>
      {isSmall && (
        <NSVideoPlayerStyle.InformationContainer>
          <NSVideoPlayerStyle.Name>{title}</NSVideoPlayerStyle.Name>
          <NSVideoPlayerStyle.Job>{subtitle}</NSVideoPlayerStyle.Job>
        </NSVideoPlayerStyle.InformationContainer>
      )}
    </NSVideoPlayerStyle.Container>
  );
}

export default VideoPlayer;
