import Loading from "../../../../components/Loading/Loading";
import { NSProjectDetailOverviewStyle } from "./Overview.style";
import useOverviewVm from "./OverviewVM";
import StatsOverview from "../../../../components/StatsOverview/StatsOverview";
import StatsChartList from "../../../../components/StatsChartList/StatsChartList";
import SummarySidebar from "../../../../components/SummarySidebar/SummarySidebar";
import NotificationBadge from "../../../../components/NotificationBadge/NotificationBadge";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import i18n from "../../../../library/i18next";

function Overview() {
  const overviewVm = useOverviewVm();

  return overviewVm.isOverviewLoading ? (
    <Loading />
  ) : (
    <NSProjectDetailOverviewStyle.Container>
      <NSProjectDetailOverviewStyle.OverviewContainer>
        {!overviewVm.projectsInterviewTemplate && (
          <NotificationBadge>
            <NSProjectDetailOverviewStyle.BadgeContainer>
              <NSProjectDetailOverviewStyle.BadgeTitle>
                {i18n.t("interview.createVideoInterviewContent")}
              </NSProjectDetailOverviewStyle.BadgeTitle>
              <NSProjectDetailOverviewStyle.BadgeSubTitle>
                {i18n.t("interview.createVideoInterviewContentSubtitle")}
              </NSProjectDetailOverviewStyle.BadgeSubTitle>
              <SolidButton
                onClick={overviewVm.onClickCreateInterview}
                label={i18n.t("interview.createInterview")}
                size="medium"
              />
            </NSProjectDetailOverviewStyle.BadgeContainer>
          </NotificationBadge>
        )}
        <NSProjectDetailOverviewStyle.OverviewInfo>
          <StatsOverview data={overviewVm.longListOverviewData} />
          <StatsOverview data={overviewVm.shortListOverviewData} />
        </NSProjectDetailOverviewStyle.OverviewInfo>
        <StatsChartList data={overviewVm.chartListOverviewData} />
      </NSProjectDetailOverviewStyle.OverviewContainer>
      <NSProjectDetailOverviewStyle.SummaryContent>
        <SummarySidebar
          status={overviewVm.projectDetailOverViewData.summary.status}
          clientName={overviewVm.projectDetailOverViewData.summary.client}
          deadline={overviewVm.projectDetailOverViewData.summary.deadline}
          user={overviewVm.projectDetailOverViewData.summary.user}
          created={overviewVm.projectDetailOverViewData.summary.created}
          onChangeStatus={(status) => overviewVm.onChangeStatus(status)}
        />
      </NSProjectDetailOverviewStyle.SummaryContent>
    </NSProjectDetailOverviewStyle.Container>
  );
}

export default Overview;
