import styled from "styled-components";
import MainButton from "../MainButton/MainButton";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import { NSGhostIconButtonStyle } from "../GhostIconButton/GhostIconButton.style";
import { NSContextMenuStyle } from "../ContextMenu/ContextMenu.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSBatchActionStyle {
  export const BatchProcessContainer = styled.div`
    position: absolute;
    right: 20px;
    top: -40px;
    display: flex;
    align-items: center;
    gap: 8px;
  `;

  export const ClearButton = styled(MainButton)`
    background: none;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 16px 8px;
  `;

  export const BatchProcessText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.neutrals.grey09};
  `;

  export const BoldText = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const DropdownContainer = styled.div`
    position: relative;
    min-width: 182px;

    ${NSDropdownStyle.DropdownItemsContainer} {
      display: none;
    }

    ${NSDropdownStyle.DropdownTitle} {
      height: 32px;
    }

    ${NSDropdownStyle.InnerDropdownTitle} {
      height: 32px;
      padding: 0 12px;
    }

    ${NSContextMenuStyle.ContextMenuContent} {
      left: 0;
      right: unset;
      top: -6px;
      z-index: 10;
    }

    ${NSGhostIconButtonStyle.GhostIconButtonBase} {
      position: absolute;
      opacity: 0;
      top: -32px;
      z-index: 10;
    }

    ${NSMainButtonStyle.ButtonBase} {
      width: 182px;
      height: 32px;
    }
  `;
}
