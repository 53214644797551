import dayjs from "dayjs";
import { faker } from "@faker-js/faker";
import { useCallback, useState } from "react";
import StatusBadge from "../StatusBadge/StatusBadge";

import Dropdown from "../Dropdown/Dropdown";
import { NSDropdown } from "../Dropdown/Dropdown.type";
import { NSSummarySidebarStyle } from "./SummarySidebar.style";
import { NSSummarySidebarType } from "./SummarySidebar.type";
import User from "../User/User";
import { Project_Status_Enum } from "../../gql/graphql";
import i18n from "../../library/i18next";

const statusDropdownItem = [
  { id: Project_Status_Enum.Active, label: i18n.t("general.active") },
  { id: Project_Status_Enum.Archived, label: i18n.t("general.archived") },
  { id: Project_Status_Enum.Done, label: i18n.t("general.done") },
  { id: Project_Status_Enum.Cancelled, label: i18n.t("general.cancelled") },
];

function SummarySidebar({
  status,
  clientName,
  user,
  deadline,
  created,
  onChangeStatus,
}: NSSummarySidebarType.ISummarySidebar) {
  const [selectedStatus, setSelectedStatus] = useState(status);

  const onChangeStatusFunction = useCallback(
    (_id: number, item?: NSDropdown.IDropdownItem) => {
      setSelectedStatus(item?.id as Project_Status_Enum);
      onChangeStatus?.(item?.id as Project_Status_Enum);
    },

    [onChangeStatus]
  );

  return (
    <NSSummarySidebarStyle.Container>
      <NSSummarySidebarStyle.Header>
        {i18n.t("projects.summary")}
      </NSSummarySidebarStyle.Header>
      <NSSummarySidebarStyle.Content>
        <NSSummarySidebarStyle.Information>
          <NSSummarySidebarStyle.InfoName>
            {i18n.t("general.client")}
          </NSSummarySidebarStyle.InfoName>

          <NSSummarySidebarStyle.ClientName>
            {clientName}
          </NSSummarySidebarStyle.ClientName>
        </NSSummarySidebarStyle.Information>

        <NSSummarySidebarStyle.Information>
          <NSSummarySidebarStyle.InfoName>
            {i18n.t("projects.status")}
          </NSSummarySidebarStyle.InfoName>

          <NSSummarySidebarStyle.InfoContent>
            <Dropdown
              id={parseInt(faker.random.numeric(1), 10)}
              titleWithPrefixItem={<StatusBadge status={selectedStatus} />}
              options={statusDropdownItem}
              onSelect={onChangeStatusFunction}
              type="basic"
            />
          </NSSummarySidebarStyle.InfoContent>
        </NSSummarySidebarStyle.Information>
        <NSSummarySidebarStyle.Information>
          <NSSummarySidebarStyle.InfoName>
            {i18n.t("projects.assigned")}
          </NSSummarySidebarStyle.InfoName>

          <NSSummarySidebarStyle.InfoContent>
            <User
              name={user.name}
              job={user.job}
              avatarUrl={user.avatarUrl}
              username={user.username}
              date={user.date}
              avatarSize="xSmall"
              isAvatarCircle
              avatarInfoMargin="small"
            />
          </NSSummarySidebarStyle.InfoContent>
        </NSSummarySidebarStyle.Information>

        <NSSummarySidebarStyle.Information>
          <NSSummarySidebarStyle.InfoName>
            {i18n.t("projects.deadline")}
          </NSSummarySidebarStyle.InfoName>
          <NSSummarySidebarStyle.InfoContent>
            {deadline ? (
              <NSSummarySidebarStyle.DateString>
                {`${dayjs(deadline).format("DD MMM YYYY [at] hh:mm")}`}
              </NSSummarySidebarStyle.DateString>
            ) : (
              <NSSummarySidebarStyle.NotSet>
                {i18n.t("general.notSet")}
              </NSSummarySidebarStyle.NotSet>
            )}
          </NSSummarySidebarStyle.InfoContent>
        </NSSummarySidebarStyle.Information>

        <NSSummarySidebarStyle.Information>
          <NSSummarySidebarStyle.InfoName>
            {i18n.t("general.createdAt")}
          </NSSummarySidebarStyle.InfoName>
          <NSSummarySidebarStyle.InfoContent>
            <NSSummarySidebarStyle.DateString>
              {`${dayjs(created).format("DD MMM YYYY [at] hh:mm")}`}
            </NSSummarySidebarStyle.DateString>
          </NSSummarySidebarStyle.InfoContent>
        </NSSummarySidebarStyle.Information>
      </NSSummarySidebarStyle.Content>
    </NSSummarySidebarStyle.Container>
  );
}

export default SummarySidebar;
