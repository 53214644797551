import styled from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSJDRoleButtonStyle {
  export const Container = styled.div`
    ${NSMainButtonStyle.ButtonBase} {
      font-size: ${(props) => props.theme.typography.titleh2.size};
      line-height: ${(props) => props.theme.typography.titleh2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.bold};
      border: 0;
      height: 72px;
      color: ${(props) => props.theme.colors.primary.primary};
      background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};

      :hover {
        background-color: ${(props) => props.theme.colors.shadesOfdark.dark05};
        svg {
          path {
            stroke: ${(props) => props.theme.colors.primary.primary};
          }
          circle {
            stroke: ${(props) => props.theme.colors.primary.primary};
          }
        }
      }

      svg {
        width: 48px;
        height: 48px;
      }
    }
  `;
}
