import { Outlet, redirect } from "react-router-dom";

import JwtHelper from "../../utils/jwtHelper";
import LocalStorageHelper from "../../utils/localStorageHelper";
import Login from "./Login/Login";
import LoginPng from "../../assets/login.png";
import { NSAuthStyle } from "./Auth.style";
import i18n from "../../library/i18next";
import useLoginVm from "./Login/LoginVm";

// this function works before Auth renders
async function loader() {
  if (JwtHelper.isTokenValid(LocalStorageHelper.getAuthorizationToken())) {
    return redirect("/");
  }
  return true;
}

function Auth() {
  return (
    <NSAuthStyle.Container>
      <NSAuthStyle.LeftSideContainer>
        <Login useLoginVm={useLoginVm} />
      </NSAuthStyle.LeftSideContainer>
      <NSAuthStyle.RightSideContainer imgSrc={LoginPng}>
        <NSAuthStyle.GradientOverlay />
        <NSAuthStyle.DescriptionContainer>
          <NSAuthStyle.WhiteTitle>{i18n.t("auth.title")}</NSAuthStyle.WhiteTitle>
          <NSAuthStyle.ColoredTitle>
            {i18n.t("auth.coloredTitle")}
          </NSAuthStyle.ColoredTitle>
          <NSAuthStyle.SubTitle>{i18n.t("auth.description")}</NSAuthStyle.SubTitle>
        </NSAuthStyle.DescriptionContainer>
      </NSAuthStyle.RightSideContainer>
      <Outlet />
    </NSAuthStyle.Container>
  );
}

export { loader };
export default Auth;
