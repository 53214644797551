import styled, { css } from "styled-components";
// eslint-disable-next-line import/no-extraneous-dependencies
import { darken } from "polished";
import { NSContextMenuStyle } from "../ContextMenu/ContextMenu.style";

export namespace NSDashboardMainButtonStyle {
  export const Container = styled.div`
    ${NSContextMenuStyle.ContextMenuContent} {
      left: 85px;
      width: max-content;
    }
    z-index: 4;
  `;

  export const ButtonContainer = styled.button<{ isContextMenuOpen: boolean }>`
    width: 64px;

    height: 64px;
    background-color: ${(props) => props.theme.colors.primary.primary};
    border-radius: 32px;
    cursor: pointer;
    border: 0;
    svg {
      color: ${(props) => props.theme.colors.light.light};
    }

    &:hover {
      background-color: ${(props) =>
        darken(0.24, props.theme.colors.primary.primary)};
    }

    ${(props) =>
      props.isContextMenuOpen &&
      css`
        background-color: ${props.theme.colors.light.light};
        svg {
          color: ${props.theme.colors.primary.primary};
        }
        &:hover {
          background-color: ${props.theme.colors.light.light};
        }
      `}
  `;
}
