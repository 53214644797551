import LanguageAndDateSelection from "./ModalViews/LanguageAndDateSelection";
import ReviewMetricSelection from "./ModalViews/ReviewMetricSelection";
import {
  InterviewPageType,
  useInterviewContext,
} from "../../../context/InterviewContext/Interview-Create/InterviewContext";
import ReviewMetricQuestionSelection from "./ModalViews/ReviewMetricQuestionSelection";

function InterviewModalRenderer() {
  const { activePage } = useInterviewContext();

  const renderPages = () => {
    switch (activePage) {
      case InterviewPageType.LANGUAGE_DATE_SELECTION:
        return <LanguageAndDateSelection />;
      case InterviewPageType.REVIEW_METRIC_SELECTION:
        return <ReviewMetricSelection />;
      case InterviewPageType.REVIEW_METRIC_QUESTION_SELECTION:
        return <ReviewMetricQuestionSelection />;
      default:
        break;
    }
  };

  return renderPages();
}

export default InterviewModalRenderer;
