import { SubmitHandler, useForm } from "react-hook-form";
import { object, string } from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useGraphQLMutation,
  useGraphQLQuery,
} from "../../../../hooks/useGraphQL";
import { NSEditCandidateType } from "./Edit-Candidate.type";
import useGetClientCandidateCVs from "../../../../service/ClientsCandidate/getClientCandidateCVs";
import {
  GetClientCandidateByPkDocument,
  GetSignedUrlDocument,
  UpdateClientCandidateDocument,
} from "../../../../gql/graphql";
import i18n from "../../../../library/i18next";
import fileTypeHelper from "../../../../utils/fileTypeHelper";
import { CV, PUT } from "../../../../utils/globalConstants";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import { NSFileUploadType } from "../../../../components/FileUpload/FileUpload.type";

const editCandidateSchema = object({
  candidateName: string().required("Please enter candidate name"),
  candidateSurname: string().required("Please enter candidate surname"),
  emailAddress: string().required("Please enter email address"),
  phoneNumber: string().required("Please enter phone number"),
  uploadedFileName: string(),
}).required();

const useEditCandidateVm = () => {
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<NSEditCandidateType.IEditCandidateFormValues>({
    resolver: yupResolver(editCandidateSchema),
    shouldFocusError: false,
  });

  const navigate = useNavigate();
  const [cvFile, setFile] = useState<Blob>();

  const [cvFileName, setCvFileName] = useState<string>();
  const [candidateName, setCandidateName] = useState<string>();
  const [candidateSurname, setCandidateSurname] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>();

  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [contentType, setContentType] = useState<string>();
  const [fileNames, setFileNames] = useState<string[]>();

  const { state } = useLocation();

  const clientCandidateId = useMemo(() => state?.candidateId, [state]);

  const projectId = useMemo(() => state.projectId, [state]);

  const { isSuccess, refetch } = useGraphQLQuery(
    GetClientCandidateByPkDocument,
    undefined,
    {
      clientCandidateId,
    }
  );

  const onErrorFileUpload = (message: NSFileUploadType.IError) => {
    toast(<ToastAlert type="error" description={message.label} />);
  };

  const onUploadFile = useCallback(
    (url?: Blob, name?: string) => {
      setFile(url);
      setValue("uploadedFileName", name);
      const type = fileTypeHelper(name!);
      setContentType(type.type);
      setFileNames(type.fileNames);
    },
    [setValue]
  );

  const { refetch: cvFileUrlsRefetch } = useGetClientCandidateCVs(
    cvFileName ? [cvFileName] : []
  );

  const onChangeCandidateNameInput = (value: string) => {
    setCandidateName(value);
    setValue("candidateName", value);
  };

  const onChangeSurnameInput = (value: string) => {
    setValue("candidateSurname", value);
    setCandidateSurname(value);
  };
  const onChangeEmailInput = (value: string) => {
    setValue("emailAddress", value);
    setEmailAddress(value);
  };
  const onChangePhoneInput = (value: string) => {
    setValue("phoneNumber", value);
    setPhoneNumber(value);
  };

  useEffect(() => {
    const setValues = async () => {
      const clientCandidate = await refetch();
      setValue(
        "candidateName",
        clientCandidate?.data?.client_candidate_by_pk?.name ?? ""
      );
      setCandidateName(
        clientCandidate?.data?.client_candidate_by_pk?.name ?? ""
      );
      setValue(
        "candidateSurname",
        clientCandidate?.data?.client_candidate_by_pk?.surname ?? ""
      );
      setCandidateSurname(
        clientCandidate?.data?.client_candidate_by_pk?.surname ?? ""
      );
      setValue(
        "emailAddress",
        clientCandidate?.data?.client_candidate_by_pk?.email_address ?? ""
      );
      setEmailAddress(
        clientCandidate?.data?.client_candidate_by_pk?.email_address ?? ""
      );
      setValue(
        "phoneNumber",
        clientCandidate?.data?.client_candidate_by_pk?.mobile_number ?? ""
      );
      setPhoneNumber(
        clientCandidate?.data?.client_candidate_by_pk?.mobile_number ?? ""
      );
      setValue(
        "uploadedFileName",
        clientCandidate?.data?.client_candidate_by_pk?.cv_file_name ?? ""
      );
      setValue(
        "uploadedFileUri",
        clientCandidate?.data?.client_candidate_by_pk?.cv_file_uri ?? ""
      );
      setCvFileName(
        clientCandidate?.data?.client_candidate_by_pk?.cv_file_name ?? ""
      );
    };
    setValues();
  }, [refetch, setValue]);

  const { mutateAsync } = useGraphQLMutation(UpdateClientCandidateDocument);

  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  const sendFileTo = useCallback(
    async (contentTypeProps: string, fileNamesProps: string[]) => {
      await getSignedUrl({
        signedUrlInput: {
          contentType: contentTypeProps,
          filenames: fileNamesProps,
          folder: CV,
          operation: PUT,
        },
      })
        .then(async (signedUrlResponse) => {
          if (signedUrlResponse?.getSignedUrl) {
            const response = await fetch(
              `${signedUrlResponse?.getSignedUrl?.signedUrls[0]}`,
              {
                headers: {
                  "x-amz-acl": "private",
                  "Content-Type": contentTypeProps,
                },
                method: "PUT",
                body: cvFile,
              }
            );

            if (response.ok) {
              setCvFileName(response.url);
              setValue("uploadedFileUri", response.url);
            } else {
              setCvFileName(undefined);
            }
          }
        })
        .catch(() => {});
    },
    [cvFile, getSignedUrl]
  );

  const onSubmit: SubmitHandler<NSEditCandidateType.IEditCandidateFormValues> =
    useCallback(
      async (data, event) => {
        event?.preventDefault();
        await sendFileTo(contentType!, fileNames!);
        mutateAsync({
          clientCandidateId,
          name: data.candidateName,
          surname: data.candidateSurname,
          mobile_number: data.phoneNumber,
          email_address: data.emailAddress,
          cv_file_uri: data.uploadedFileUri ?? cvFileName,
          cv_file_name: data.uploadedFileName ?? fileNames?.[0],
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.candidateUpdateSuccess")}
                type="success"
              />,

              {
                id: "addAndEditCandidateSuccess",
              }
            );

            navigate(`/projects/detail/${projectId}/long-list`, {
              state: { projectId },
            });
          })

          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.candidateUpdateError")}
                type="error"
              />,
              {
                id: "addAndEditCandidateError",
              }
            );
          });
      },
      [
        clientCandidateId,
        contentType,
        cvFileName,
        fileNames,
        mutateAsync,
        navigate,
        projectId,
        sendFileTo,
      ]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const phoneCode = [
    {
      label: "+90",
      id: 90,
    },
    {
      label: "+56",
      id: 56,
    },
    {
      label: "+86",
      id: 86,
    },
    {
      label: "+45",
      id: 45,
    },
  ];

  const isLoading = useMemo(
    () => !isSuccess || candidateName === undefined || candidateName === "",
    [candidateName, isSuccess]
  );

  const downloadFile = useCallback(async () => {
    const fileUrl = await cvFileUrlsRefetch();
    if (fileUrl && cvFileName) {
      const el = document.createElement("a");
      el.href = fileUrl.data?.getSignedUrl?.signedUrls[0] as string;
      el.target = "_blank";
      el.download = cvFileName as string;
      el.click();
      el.remove();
    }
  }, [cvFileName, cvFileUrlsRefetch]);

  return {
    isLoading,
    handleSubmit: submitHandler,
    formErrors: errors,
    onChangeCandidateNameInput,
    onChangeSurnameInput,
    onChangeEmailInput,
    onChangePhoneInput,
    phoneCode,
    setFile,
    setValue,
    cvFileName,
    downloadFile,
    candidateName,
    candidateSurname,
    emailAddress,
    phoneNumber,
    setCvFileName,
    getValues,
    onUploadFile,
    onErrorFileUpload,
  };
};

export default useEditCandidateVm;
