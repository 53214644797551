import { SVGProps, memo } from "react";

import icons from "../../assets/icons";

export interface IIcon extends SVGProps<SVGSVGElement> {
  name: keyof typeof icons;
}

function Icon({ name, ...props }: IIcon) {
  return icons[name]({ ...props });
}

export default memo(Icon);
