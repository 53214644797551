import Checkbox from "../../../components/Checkbox/CheckBox";
import Drawer from "../../../components/Drawer/Drawer";
import Dropdown from "../../../components/Dropdown/Dropdown";
import GhostIconButton from "../../../components/GhostIconButton/GhostIconButton";
import { NSCreateClientStyle } from "./Create-Client.style";
import PhoneNumber from "../../../components/PhoneNumber/PhoneNumber";
import SolidButton from "../../../components/SolidButton/SolidButton";
import SwitchButton from "../../../components/SwitchButton/SwitchButton";
import TextArea from "../../../components/TextArea/TextArea";
import TextInput from "../../../components/TextInput/TextInput";
import useCreateClientVm from "./Create-ClientVm";
import i18n from "../../../library/i18next";
import { Controller } from "react-hook-form";

export default function CreateClient() {
  const createClientVm = useCreateClientVm();
  const subscribeMultiLocation = createClientVm.watch("hasMultiLocation");
  const { control } = createClientVm;

  return (
    <Drawer title={i18n.t("clients.createNewClient")}>
      <NSCreateClientStyle.Container>
        <NSCreateClientStyle.CreateClientForm
          onSubmit={createClientVm.handleSubmit()}
        >
          <Controller
            name="clientName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="clientName"
                placeholder={i18n.t("forms.clientName")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <PhoneNumber
                id="phoneNumber"
                dropdownItems={createClientVm.phoneCode}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="country"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="basic"
                  placeholder={i18n.t("forms.country")}
                  options={createClientVm.country ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="city"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="basic"
                  placeholder={i18n.t("forms.city")}
                  options={createClientVm.citiesItems ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="bussinessAddress"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="bussinessAddress"
                placeholder={i18n.t("forms.bussinessAddress")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <NSCreateClientStyle.Title>
            {i18n.t("forms.setClientProfile")}
          </NSCreateClientStyle.Title>

          <NSCreateClientStyle.Description>
            {i18n.t("forms.setClientProfileDesc")}
          </NSCreateClientStyle.Description>

          <Controller
            name="sector"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={3}
                  type="basic"
                  placeholder={i18n.t("forms.sector")}
                  options={createClientVm.sectorField}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="typeOfCompany"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={4}
                  type="basic"
                  placeholder={i18n.t("forms.typeOfCompany")}
                  options={createClientVm.typeOfCompany}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="companySize"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={5}
                  type="basic"
                  placeholder={i18n.t("forms.companySize")}
                  options={createClientVm.companySize}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="aboutCompany"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextArea
                  id="aboutCompany"
                  placeholder={i18n.t("forms.aboutCompany")}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="hasMultiLocation"
            control={control}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <SwitchButton
                  checked={subscribeMultiLocation}
                  label={i18n.t("forms.hasMultiLocation")}
                  onChecked={(value) => field.onChange(value)}
                  {...fieldProps}
                />
              );
            }}
          />

          {subscribeMultiLocation && (
            <NSCreateClientStyle.AddLocationContainer>
              {createClientVm.checkboxList.length > 0 &&
                createClientVm.checkboxList.map((item, key) => (
                  <NSCreateClientStyle.CheckboxContainer key={item.id}>
                    <Checkbox
                      checked={item.checked}
                      label={item.label}
                      onClick={() => createClientVm.onClickCheckbox(key)}
                    />
                  </NSCreateClientStyle.CheckboxContainer>
                ))}

              <TextInput
                id="addLocationInput"
                name="AddLocationInput"
                placeholder={i18n.t("forms.addLocation")}
                variant="label-w-icon-suffix"
                value={createClientVm.inputValue}
                suffixItem={
                  <NSCreateClientStyle.InputSuffixItem
                    isInputNotEmpty={!!createClientVm.inputValue}
                  >
                    <GhostIconButton
                      disabled={createClientVm.inputValue === ""}
                      onClick={createClientVm.onClickInputSuffix}
                      icon="ArrowDownLeft"
                    />
                  </NSCreateClientStyle.InputSuffixItem>
                }
                onChange={(e) => createClientVm.setInputValue(e)}
              />
            </NSCreateClientStyle.AddLocationContainer>
          )}

          <SolidButton
            label={i18n.t("general.createClient")}
            type="submit"
            fullWidth
          />
        </NSCreateClientStyle.CreateClientForm>
      </NSCreateClientStyle.Container>
    </Drawer>
  );
}
