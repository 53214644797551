import { ChangeEvent, useEffect, useState } from "react";

import { NSSwitchButtonStyle } from "./SwitchButton.style";
import { NSSwitchButtonType } from "./SwitchButton.type";

function SwitchButton({
  onChecked,
  checked = false,
  label,
  isLabelInRight = false,
}: NSSwitchButtonType.ISwitchButton) {
  const [checkedProps, setChecked] = useState(checked);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChecked(e.target.checked);
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <NSSwitchButtonStyle.Container>
      {!isLabelInRight && (
        <NSSwitchButtonStyle.SwitchButtonLabel>
          {label}
        </NSSwitchButtonStyle.SwitchButtonLabel>
      )}
      <NSSwitchButtonStyle.Label>
        <NSSwitchButtonStyle.Input
          checked={checkedProps}
          type="checkbox"
          alt="switchButton"
          onChange={handleChange}
        />
        <NSSwitchButtonStyle.Switch role="switch" />
      </NSSwitchButtonStyle.Label>

      {isLabelInRight && (
        <NSSwitchButtonStyle.SwitchButtonLabel>
          {label}
        </NSSwitchButtonStyle.SwitchButtonLabel>
      )}
    </NSSwitchButtonStyle.Container>
  );
}

export default SwitchButton;
