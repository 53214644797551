import { useParams } from "react-router-dom";
import { useGraphQLQuery } from "../../../hooks/useGraphQL";
import { GetReviewMetricQuestionByIdDocument } from "../../../gql/graphql";

const useQuestionDetailVm = () => {
  const { questionId } = useParams();

  const { data: questionData } = useGraphQLQuery(
    GetReviewMetricQuestionByIdDocument,
    undefined,
    {
      id: Number(questionId),
    }
  );
  return {
    question: questionData?.review_metric_question[0],
  };
};

export default useQuestionDetailVm;
