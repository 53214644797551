import styled from "styled-components";
import { NSMainInputStyle } from "../MainInput/MainInput.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSSearchInputStyle {
  export const SearchInputBase = styled.div`
    ${NSMainInputStyle.InputFlex} {
      padding: 0 12px;
    }

    ${NSMainButtonStyle.ButtonBase} {
      padding: 0;
      width: fit-content;
      height: fit-content;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  `;
}
