import styled, { css } from "styled-components";
import colors from "../../style/colors";

export namespace NSTextAreaStyle {
  export const InputLabel = styled.label<{ error?: boolean }>`
    color: ${(props) =>
      props.error
        ? props.theme.colors.error.red01
        : props.theme.colors.neutrals.grey05};
    position: absolute;
    top: -10px;
    z-index: 2;
    left: 16px;
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 4px 8px;
  `;

  export const BaseInput = styled.textarea`
    width: 100%;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    border: 0;
    background-color: transparent;
    resize: none;
    height: 100%;
    font-family: greycliff-cf, sans-serif;
    &:focus {
      outline: none;

      &::placeholder {
        color: ${(props) => props.theme.colors.primary.primary};
      }
    }

    &::placeholder {
      color: ${(props) => props.theme.colors.neutrals.grey05};
    }

    ::-webkit-scrollbar {
      width: 8px;
      margin-left: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.shadesOfdark.dark06};
      border-radius: 4px;
      margin-left: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.shadesOfdark.dark05};
    }
  `;

  export const InputFlex = styled.div<{
    isDisabled?: boolean;
    isError?: boolean;
  }>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey03};
    width: 100%;
    height: 126px;
    gap: 8px;

    &:hover {
      border-color: ${(props) => props.theme.colors.primary.primary};
    }

    ${(props) =>
      props.isDisabled &&
      css`
        border-color: ${props.theme.colors.neutrals.grey03};
        background-color: ${props.theme.colors.neutrals.grey02};

        &:hover {
          border-color: ${props.theme.colors.neutrals.grey03};
        }
      `}

    ${(props) =>
      props.isError &&
      css`
        border-color: ${props.theme.colors.error.red01};
        &:hover {
          border-color: ${props.theme.colors.error.red01};
        }
      `}
  `;

  export const InputWrapper = styled.div<{
    isFocused?: boolean;
  }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 32px);
    font-family: greycliff-cf, sans-serif;
    position: relative;

    ${(props) =>
      props.isFocused &&
      css`
        ${InputFlex} {
          color: ${props.theme.colors.primary.primary};
          border-color: ${props.theme.colors.primary.primary};
        }
        ${InputLabel} {
          color: ${props.theme.colors.primary.primary};
        }
      `}
  `;

  export const ErrorText = styled.div`
    color: ${(props) => props.theme.colors.neutrals.grey05};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    text-align: right;
    position: absolute;
    bottom: -24px;
    left: 16px;
    user-select: none;
  `;

  export const SuffixAndPrefix = styled.div`
    display: flex;
    align-items: center;
  `;
}
