import styled, { css } from "styled-components";

import colors from "../../style/colors";

export namespace NSJDGInputStyle {
  export const IconContainer = styled.div<{ isSelected: boolean }>`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    background-color: ${colors.primary.primary};
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    opacity: ${(props) => (props.isSelected ? 1 : 0)};
    transition: 0.3s opacity;
  `;

  export const Container = styled.button<{
    isSelected: boolean;
    disabled: boolean;
  }>`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    font-family: greycliff-cf, sans-serif;
    padding: ${(props) =>
      props.isSelected ? "40px 56px 40px 16px" : "40px 36px"};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: 2px solid;
    position: relative;
    cursor: pointer;
    transition: 0.3s all;
    white-space: none;
    overflow: hidden;
    text-overflow: ellipsis;

    border-color: ${(props) =>
      props.isSelected ? colors.primary.primary : colors.dark.dark};
    color: ${(props) =>
      props.isSelected ? colors.primary.primary : colors.dark.dark};
    background-color: transparent;

    &:hover {
      border-color: ${colors.primary.primary};
      color: ${colors.light.light};
      background-color: ${colors.primary.primary};
      ${IconContainer} {
        opacity: 0;
      }
      padding: 40px 36px;
    }
    ${(props) =>
      props.disabled &&
      css`
        color: ${colors.neutrals.grey05} !important;
        border-color: ${colors.neutrals.grey05} !important;
        background-color: transparent !important;
        cursor: not-allowed;
        padding: 40px 36px;
        ${IconContainer} {
          opacity: 0;
        }
      `}
  `;
}
