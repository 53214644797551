import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import Dropdown from "../../../components/Dropdown/Dropdown";
import EmptyView from "../../../components/EmptyView/EmptyView";
import Loading from "../../../components/Loading/Loading";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";
import { NSTeamStyle } from "../Team.style";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";
import Pagination from "../../../components/Pagination/Pagination";
import TableList from "../../../components/TableList/TableList";
import User from "../../../components/User/User";
import dayjs from "../../../library/dayjs";
import { useMemo } from "react";
import useTeamVm from "./Team-ListVM";
import { NSTableListStyle } from "../../../components/TableList/TableList.style";
import ContextMenuHOC from "../../../components/ContextMenuHOC/ContextMenuHOC";

function TeamList() {
  const teamVM = useTeamVm();
  const tableItem = useMemo(() => {
    const items = teamVM?.userListItems?.user.map((userItem, index) => ({
      id: index.toString(),
      informations: [
        {
          id: "1",
          information: (
            <User
              avatarUrl={userItem.avatar || ""}
              name={`${userItem.name} ${userItem.surname}`}
              email={userItem.email}
              isAvatarCircle
            />
          ),
        },
        {
          id: "2",
          information: userItem.company.company_name ?? "",
        },
        {
          id: "3",
          information: (
            <Dropdown
              id={userItem.id}
              selectedDropdownItem={
                teamVM.createTableDropdown(userItem?.user_roles ?? [])[0]
              }
              options={teamVM.createTableDropdown(userItem.user_roles)}
              type="basic"
            />
          ),
        },
        {
          id: "4",
          information: dayjs(userItem.created_at).format(
            "DD MMM YYYY [at] hh:mm"
          ),
        },
        {
          id: "5",
          information: (
            <NSTableListStyle.ContextMenuColumn>
              {teamVM.contextMenuItems &&
                teamVM.contextMenuItems.length > 0 && (
                  <ContextMenuHOC
                    id={userItem.id}
                    type="withIcon"
                    items={teamVM.contextMenuItems}
                  />
                )}
            </NSTableListStyle.ContextMenuColumn>
          ),
        },
      ],
    }));

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamVM?.userListItems?.user]);

  return (
    <NSTeamStyle.Container>
      <NSDashboardLayoutStyle.HeaderContainer>
        <DashboardHeader
          headerButton={teamVM.dashboardHeaderButton}
          breadcrumb={teamVM.breadcrumb}
          searchInput={teamVM.searchInput}
        />
      </NSDashboardLayoutStyle.HeaderContainer>

      {tableItem && tableItem.length !== 0 ? (
        <NSTeamStyle.TableContainer>
          {teamVM.isLoading ? (
            <Loading />
          ) : (
            <TableList
              headerItemList={teamVM.headerList}
              tableItemList={tableItem}
            />
          )}
        </NSTeamStyle.TableContainer>
      ) : (
        <NSTeamStyle.EmptyContainer>
          <EmptyView
            errorMessage={teamVM.emptyView.errorMessage}
            onClick={teamVM.emptyView.onClick}
            buttonLabel={teamVM.emptyView.buttonLabel}
          />
        </NSTeamStyle.EmptyContainer>
      )}

      {teamVM.usersCount >= PAGINATION_DEFAULT_LIMIT && (
        <NSTeamStyle.PaginationContainer>
          <Pagination
            pagination={teamVM.pagination}
            isLoading={teamVM.isLoading}
            itemCount={teamVM.usersCount}
            updateQueryLimit={(start, limit) =>
              teamVM.setPagination({ start, limit })
            }
          />
        </NSTeamStyle.PaginationContainer>
      )}
    </NSTeamStyle.Container>
  );
}

export default TeamList;
