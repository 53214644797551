/* eslint-disable react/no-array-index-key */
import Avatar from "../Avatar/Avatar";
import NSAvatarGroupStyle from "./AvatarGroup.style";
import { NSAvatarGroupType } from "./AvatarGroup.type";

function AvatarGroup({
  isAvatarCircle = true,
  avatarItems,
  size,
}: NSAvatarGroupType.IAvatarGroup) {
  return (
    <NSAvatarGroupStyle.AvatarGroupContainer>
      {avatarItems.map(
        (item, index) =>
          index < 5 && (
            <NSAvatarGroupStyle.ImageAvatarContainer
              key={`${item.name}${index}`}
            >
              <Avatar
                type="image"
                status={item.status}
                letter={item.letter}
                avatarUrl={item.avatarUrl}
                size={size}
                isAvatarCircle={isAvatarCircle}
              />
            </NSAvatarGroupStyle.ImageAvatarContainer>
          )
      )}
      {avatarItems.length > 5 && (
        <NSAvatarGroupStyle.AvatarContainer>
          <Avatar
            type="white"
            size={size}
            letter={`${avatarItems.length - 5}+`}
            isAvatarCircle={isAvatarCircle}
          />
        </NSAvatarGroupStyle.AvatarContainer>
      )}
    </NSAvatarGroupStyle.AvatarGroupContainer>
  );
}

export default AvatarGroup;
