import styled from "styled-components";
import { NSTabbedViewStyle } from "../../components/TabbedView/TabbedView.style";

export namespace NSSettingsStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
  `;

  export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    height: fit-content;
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 24px 32px;
    border-radius: 16px;

    ${NSTabbedViewStyle.HeaderContainer} {
      gap: 48px;
    }
  `;
}
