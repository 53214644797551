import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { NSBreadcrumbType } from "../../../components/Breadcrumb/Breadcrumb.type";
import useGetClient from "../../../service/Clients/getClient";
import { useGetClientDropdown } from "../../../service/Clients/getClients";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import companySizeHelper from "../../../utils/companySizeHelper";
import { NSContextMenuHOCType } from "../../../components/ContextMenuHOC/ContextMenuHOC.type";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import { DeleteClientDocument } from "../../../gql/graphql";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";

const useClientDetailVm = () => {
  const { state } = useLocation();

  const clientIdFromLocation = useMemo(() => state.clientId, [state]);
  const [clientId, setClientId] = useState<string>(clientIdFromLocation);

  const { data } = useGetClient(clientId);

  const { clients } = useGetClientDropdown();

  const clientDropdown: NSDropdown.IDropdownItem[] = useMemo(
    () =>
      clients?.map((item) => ({
        label: item.dropdown.label,
        id: item.dropdown.id,
      })) ?? [],
    [clients]
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const contextMenuItems: NSContextMenuHOCType.IContextMenuHOC = {
    type: "withIcon",
    items: [
      {
        iconName: "Edit",
        label: i18n.t("general.edit"),
        onClick: () => {
          navigate(`/clients/edit/${clientId}`, {
            state: {
              clientId,
            },
          });
        },
      },
      {
        iconName: "Trash",
        label: i18n.t("general.delete"),
        onClick: () => {
          setIsDeleteModalOpen(true);
        },
      },
    ],
  };

  const clientInfo = useMemo(
    () => ({
      clientName: data?.client_by_pk?.client_name ?? "",
      description: data?.client_by_pk?.description ?? "",
      companySize: companySizeHelper(data?.client_by_pk?.employee_count ?? 1),
      address: data?.client_by_pk?.client_locations[0]?.address,
      phoneNumber: data?.client_by_pk?.client_locations[0]?.address_phone,
      locations: data?.client_by_pk?.client_locations.map((item) => ({
        cityName: item.city?.city_name,
        cityId: item.city?.id,
      })),
    }),
    [data]
  );

  const breadcrumb: NSBreadcrumbType.IBreadcrumb = useMemo(
    () => ({
      pageName: i18n.t("general.client"),
      innerPageTitle: clientInfo.clientName,
      dropdown: {
        id: 1,
        type: "basic",
        placeholder: "ClientName",
        options: clientDropdown,
        title: i18n.t("general.clients"),
        showLabel: false,
        onSelect(_id, selected) {
          setClientId(selected?.id as string);
        },
      },
    }),
    [clientDropdown, clientInfo.clientName]
  );

  const { mutateAsync: deleteClient } =
    useGraphQLMutation(DeleteClientDocument);

  const onDeleteClient = () => {
    deleteClient({ id: clientId })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("general.clientDeleteSucces")}
            type="success"
          />,

          {
            id: "deleteClientSuccess",
          }
        );
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("general.clientDeleteError")}
            type="error"
          />,

          {
            id: "deleteClientError",
          }
        );
      });
  };
  const onClickCloseButton = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  return {
    breadcrumb,
    clientInfo,
    contextMenuItems,
    onDeleteClient,
    isDeleteModalOpen,
    onClickCloseButton,
  };
};

export default useClientDetailVm;
