import { NSStatsOverviewLabel } from "./StatsOverviewLabel.style";
import { NSStatsOverviewLabelType } from "./StatsOverviewLabel.type";

function StatsOverviewLabel({
  label,
  color,
}: NSStatsOverviewLabelType.IStatsOverviewLabel) {
  return (
    <NSStatsOverviewLabel.Label color={color}>
      {label}
    </NSStatsOverviewLabel.Label>
  );
}
export default StatsOverviewLabel;
