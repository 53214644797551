import styled, { css } from "styled-components";

export namespace NSMetricPreviewBarStyle {
  export const Container = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    padding: 8px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const BarButtonLabel = styled.div<{ isPassed: boolean }>`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.neutrals.grey06};
  `;

  export const BarButton = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 8px 12px;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    ${(props) =>
      props.selected &&
      css`
        background-color: ${props.theme.colors.primary.primary};
        ${BarButtonLabel} {
          color: ${props.theme.colors.light.light};
        }
      `};
  `;
}
