import styled from "styled-components";
// eslint-disable-next-line import/no-extraneous-dependencies
import { darken } from "polished";
import { NSToastAlertType } from "./ToastAlert.type";

import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSSolidButtonStyle } from "../SolidButton/SolidButton.style";
import { NSLinkButtonStyle } from "../LinkButton/LinkButton.style";

export namespace NSToastAlertStyle {
  export const Container = styled.div<NSToastAlertType.IToastAlert>`
    padding: 12px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    min-width: 270px;
    max-width: fit-content;
    background-color: ${(props) =>
      NSToastAlertType.TypeColorVariant[props.type]};

    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${(props) =>
          NSToastAlertType.TypeIconAndButtonColorVariant[props.type]};
        stroke: ${(props) => props.theme.colors.light.light};
      }
      circle {
        fill: ${(props) =>
          NSToastAlertType.TypeIconAndButtonColorVariant[props.type]};
        stroke: ${(props) =>
          NSToastAlertType.TypeIconAndButtonColorVariant[props.type]};
      }
    }
    ${NSSolidButtonStyle.SolidButtonBase} {
      ${NSMainButtonStyle.ButtonBase} {
        background-color: ${(props) =>
          NSToastAlertType.TypeIconAndButtonColorVariant[props.type]};
        height: 32px;
        padding: 4px 8px;
        font-size: ${(props) => props.theme.typography.smallText1.size};
        line-height: ${(props) => props.theme.typography.smallText1.lineHeight};

        &:hover {
          background-color: ${(props) =>
            darken(
              0.24,
              NSToastAlertType.TypeIconAndButtonColorVariant[props.type]
            )};
        }
      }
    }

    ${NSLinkButtonStyle.LinkButtonBase} {
      ${NSMainButtonStyle.ButtonBase} {
        padding: 4px;
        color: ${(props) => props.theme.colors.dark.dark};
        font-size: ${(props) => props.theme.typography.smallText1.size};
        line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
        font-weight: ${(props) => props.theme.typography.fontWeights.medium};
        &:hover {
          color: ${(props) =>
            NSToastAlertType.TypeIconAndButtonColorVariant[props.type]};
        }
      }
    }
  `;

  export const IconContainer = styled.div`
    width: 24px;
    height: 24px;
  `;

  export const AlertText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const DescriptionText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 4px;
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    gap: 4px;
    margin-top: 8px;
    align-items: center;
  `;
}
