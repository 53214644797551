import { Form } from "react-router-dom";
import styled from "styled-components";

export namespace NSEditCandidateStyle {
  export const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;
  `;

  export const EditCandidateForm = styled(Form)`
    gap: 32px;
    display: flex;
    flex-direction: column;
  `;
}
