import { useCallback, useMemo, useState } from "react";

import Dropdown from "../Dropdown/Dropdown";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { NSDropdown } from "../Dropdown/Dropdown.type";
import { NSPaginationStyle } from "./Pagination.style";
import { NSPaginationType } from "./Pagination.type";
import {
  DEFAULT_PAGINATION_OPTIONS,
  PAGINATION_DEFAULT_LIMIT,
} from "../../utils/globalConstants";
import i18n from "../../library/i18next";

function Pagination({
  itemCount,
  paginationOptions,
  updateQueryLimit,
  isLoading,
  pagination,
}: Readonly<NSPaginationType.IPagination>) {
  const options: NSDropdown.IDropdownItem[] = useMemo(
    () => paginationOptions ?? DEFAULT_PAGINATION_OPTIONS,
    [paginationOptions]
  );

  const defaultSelectedOption = options.find(
    (option) => option.label === pagination.limit.toString()
  );

  const [selectedOption, setSelectedOption] = useState<
    NSDropdown.IDropdownItem | undefined
  >(defaultSelectedOption ?? options[3]);

  // eslint-disable-next-line consistent-return
  const rowsPerPage = useMemo(
    () =>
      parseInt(
        selectedOption?.label ?? PAGINATION_DEFAULT_LIMIT.toString(),
        10
      ),
    [selectedOption]
  );
  const isPrevDisabled = useMemo(() => {
    return isLoading || pagination.start <= 0;
  }, [isLoading, pagination]);

  const isNextDisabled = useMemo(() => {
    return isLoading || pagination.start + rowsPerPage >= itemCount;
  }, [isLoading, pagination, itemCount]);

  const nextClick = useCallback(() => {
    updateQueryLimit(pagination.start + rowsPerPage, rowsPerPage);
  }, [rowsPerPage, updateQueryLimit]);

  const prevClick = useCallback(() => {
    updateQueryLimit(pagination.start - rowsPerPage, rowsPerPage);
  }, [rowsPerPage, updateQueryLimit]);

  const onSelectDropdown = useCallback(
    (_id: number, selected?: NSDropdown.IDropdownItem) => {
      setSelectedOption(selected);
      updateQueryLimit(
        0,
        parseInt(
          (selected?.label as string) ?? PAGINATION_DEFAULT_LIMIT.toString(),
          10
        )
      );
    },
    [updateQueryLimit]
  );
  const itemLabel = useMemo(() => {
    const pagingMaxRange = pagination.start + rowsPerPage;
    return i18n.t("general.pagination", {
      start: pagination.start == 0 ? 1 : pagination.start,
      end: pagingMaxRange > itemCount ? itemCount : pagingMaxRange,
      total: itemCount,
    });
  }, [itemCount, rowsPerPage, pagination]);

  return (
    <NSPaginationStyle.Container>
      <NSPaginationStyle.Label>
        {i18n.t("general.rowsPerPage")}
      </NSPaginationStyle.Label>
      <NSPaginationStyle.DropdownContainer>
        <Dropdown
          selectedDropdownItem={selectedOption}
          type="basic"
          position="top"
          id={1}
          options={options}
          onSelect={(_id, selected) => onSelectDropdown(_id, selected)}
        />
      </NSPaginationStyle.DropdownContainer>

      <NSPaginationStyle.SecondLabel>{itemLabel}</NSPaginationStyle.SecondLabel>
      <NSPaginationStyle.IconContainer>
        <GhostIconButton
          icon="Left"
          size="small"
          disabled={isPrevDisabled}
          iconSize={24}
          onClick={prevClick}
        />
        <GhostIconButton
          icon="Right"
          size="small"
          disabled={isNextDisabled}
          iconSize={24}
          onClick={nextClick}
        />
      </NSPaginationStyle.IconContainer>
    </NSPaginationStyle.Container>
  );
}

export default Pagination;
