import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";
import { NSClientInfoCardStyle } from "./ClientInfoCard.style";
import { NSClientInfoCardType } from "./ClientInfoCard.type";
import i18n from "../../library/i18next";

function ClientInfoCard({
  companyName,
  sector,
  information,
  location,
  isDefault,
  contextMenu,
  onClick,
}: NSClientInfoCardType.IClientInfoCard) {
  return (
    <NSClientInfoCardStyle.Container onClick={onClick}>
      <NSClientInfoCardStyle.HeaderContainer>
        {contextMenu && (
          <ContextMenuHOC
            items={contextMenu.items}
            onItemClick={contextMenu.onItemClick}
            type={contextMenu.type}
            buttonLabel={contextMenu.buttonLabel}
            isScrollable={contextMenu.isScrollable}
            title={contextMenu.title}
            showSearch={contextMenu.showSearch}
            containAddNewItemButton={contextMenu.containAddNewItemButton}
            onCheckedItems={contextMenu.onCheckedItems}
            onContextMenuOpen={contextMenu.onContextMenuOpen}
            shouldStopPropagation
          >
            <NSClientInfoCardStyle.HeaderLeft>
              {companyName}
              {isDefault && (
                <NSClientInfoCardStyle.DefaultBadge>
                  {i18n.t("general.default")}
                </NSClientInfoCardStyle.DefaultBadge>
              )}
            </NSClientInfoCardStyle.HeaderLeft>
          </ContextMenuHOC>
        )}
      </NSClientInfoCardStyle.HeaderContainer>
      <NSClientInfoCardStyle.InnerText>
        {sector}
      </NSClientInfoCardStyle.InnerText>
      <NSClientInfoCardStyle.InnerText>
        {information}
      </NSClientInfoCardStyle.InnerText>
      <NSClientInfoCardStyle.Location>
        {location}
      </NSClientInfoCardStyle.Location>
    </NSClientInfoCardStyle.Container>
  );
}

export default ClientInfoCard;
