import { NSLoadingStyle } from "./Loading.style";

function Loading() {
  return (
    <NSLoadingStyle.Container>
      <NSLoadingStyle.Svg>
        <NSLoadingStyle.Circle
          cx="50"
          cy="50"
          r="40"
          fill="none"
          strokeWidth="15"
        />
      </NSLoadingStyle.Svg>
    </NSLoadingStyle.Container>
  );
}

export default Loading;
