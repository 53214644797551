import styled from "styled-components";
import { NSNotificationBadgeStyle } from "../../../../components/NotificationBadge/NotificationBadge.style";

export namespace NSProjectDetailOverviewStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 32px;
    margin-top: 32px;

    @media screen and (min-width: 1440px) {
      flex-direction: row;
    }
  `;

  export const OverviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${NSNotificationBadgeStyle.Container} {
      max-width: 790px;
    }
  `;

  export const OverviewInfo = styled.div`
    display: flex;
    column-gap: 24px;
  `;

  export const SummaryContent = styled.div`
    @media screen and (min-width: 1440px) {
      border-left: 1px solid ${(props) => props.theme.colors.neutrals.grey02};
    }
  `;

  export const BadgeContainer = styled.div`
    width: 80%;
  `;

  export const BadgeTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};

    margin-bottom: 4px;
  `;

  export const BadgeSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-bottom: 16px;
  `;
}
