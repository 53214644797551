import { Form } from "react-router-dom";
import styled from "styled-components";
import MainButton from "../../../components/MainButton/MainButton";
import { NSMainInputStyle } from "../../../components/MainInput/MainInput.style";
import { EN } from "../../../utils/globalConstants";

export namespace NSSettingsIntegrationStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 32px;
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Subtitle = styled.div`
    margin-top: 4px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const TokenTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const TokenSubtitle = styled.div`
    margin-top: 4px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const TitleContainer = styled.div`
    margin-top: 32px;
  `;

  export const FormContainer = styled(Form)`
    margin-top: 32px;
    display: flex;
    gap: 24px;
    max-width: 680px;
  `;

  export const DropdownContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
  `;

  export const IntegrationText = styled.div`
    position: absolute;
    bottom: -20px;
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const CopyTokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
    background-color: ${(props) => props.theme.colors.warning.yellow02};
    border-radius: 16px;
    margin-top: 36px;
    max-width: 680px;
  `;

  export const CopyTokenTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const InputContainer = styled.div<{ language: string }>`
    position: relative;

    ${NSMainInputStyle.InputFlex} {
      height: 40px;
      padding-left: 8px;
      padding-right: ${(props) => (props.language === EN ? "93px" : "130px")};
      border: 1px solid ${(props) => props.theme.colors.neutrals.grey04};
      background-color: ${(props) => props.theme.colors.light.light};
    }

    ${NSMainInputStyle.BaseInput} {
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      color: ${(props) => props.theme.colors.dark.dark};
      font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    }

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary.primary};
      }
    }
  `;

  export const CopyTokenButton = styled(MainButton)`
    position: absolute;
    top: -8px;
    right: 0;
    padding-left: 28px;
    background: none;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    margin-left: 4px;
  `;

  export const InputText = styled.div`
    margin-top: 4px;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const TokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 56px;
    max-width: 680px;
  `;

  export const GeneratedTokenContainer = styled.div``;

  export const GeneratedTokenItem = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
  `;

  export const GeneratedTokenLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
  `;

  export const ExpirationInfo = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin-left: 27px;
  `;

  export const GeneratedTokenRight = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;

    ${CopyTokenButton} {
      color: ${(props) => props.theme.colors.error.red01};
      position: static;
      padding: 0;
      height: 21px;
    }
  `;

  export const UsageInfo = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey06};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;
}
