import styled from "styled-components";

export namespace NSLogoStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6.73px;
    align-items: center;
    justify-content: center;
    width: fit-content;
  `;

  export const SideBySideLogoContainer = styled.div`
    display: flex;

    gap: 7px;
    align-items: center;
    justify-content: center;
    width: fit-content;
  `;
}
