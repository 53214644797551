import styled from "styled-components";

export namespace NSInterviewStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  `;

  export const InterviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 682px;
  `;

  export const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.neutrals.grey04};

    &:last-child {
      border-bottom: none;
    }
  `;

  export const InfoItemLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const CheckboxContainer = styled.div`
    display: flex;
    align-items: start;
  `;

  export const CheckboxLabel = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const CheckboxSubItem = styled.div`
    display: flex;
    margin-top: 8px;
  `;

  export const InfoItemTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-bottom: 4px;
  `;

  export const InfoItemSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    width: 550px;
    word-break: break-all;
  `;

  export const DrawerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  `;

  export const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const NotFoundTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-bottom: 4px;
  `;

  export const NotFoundSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    margin-bottom: 16px;
  `;
}
