/* eslint-disable react/jsx-props-no-spreading */

import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { NSDragAndDropListType } from "./DragAndDropList.type";
import SortableItem from "./SortableItem";

function DragAndDropList({
  isEditable = true,
  list,
  withMaxDuration,
  setItems,
  onEditItem,
}: NSDragAndDropListType.IDragAndDropList) {
  const handleDragEnd = (event: DragEndEvent) => {
    let chagedItems = list;
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activeId = active?.id as number;
      const overId = over?.id as number;
      const oldIndex = list.findIndex((item) => item.id === activeId);
      const newIndex = list.findIndex((item) => item.id === overId);
      const movedUpdatedItems = arrayMove(list, oldIndex, newIndex);
      chagedItems = movedUpdatedItems;
    }

    setItems(chagedItems);
  };

  const onValueUpdated = (id: number, value: string) => {
    const updatedItems = list.map((item) => {
      if (item.id === id) {
        return { ...item, name: value, isEdited: true };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const onMaxDurationUpdated = (id: number, value: number) => {
    const updatedItems = list.map((item) => {
      if (item.id === id) {
        return { ...item, maxDuration: value };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const onCheckboxClicked = (id: number) => {
    const updatedItems = list.map((item) => {
      if (item.id === id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return item;
    });

    setItems(updatedItems);
  };
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={list} strategy={verticalListSortingStrategy}>
        {list.map((item) => (
          <SortableItem
            key={item.id}
            isSelected={item.isSelected}
            name={item.name}
            id={item.id}
            language={item.language}
            onCheckboxClicked={onCheckboxClicked}
            onValueUpdated={onValueUpdated}
            onMaxDurationUpdated={onMaxDurationUpdated}
            isEditable={isEditable}
            withMaxDuration={withMaxDuration}
            maxDuration={item.maxDuration}
            onEdit={onEditItem as (id: number) => void}
            isShownToCandidate={item.isShownToCandidate}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default DragAndDropList;
