import styled from "styled-components";

export namespace NSQuestionDetailStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const MetricBadge = styled.div`
    padding: 8px;
    width: fit-content;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};

    margin-bottom: 8px;
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-bottom: 32px;
  `;

  export const SubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-bottom: 4px;
  `;

  export const Description = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-bottom: 40px;
  `;

  export const ResponseBadgeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `;
}
