import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";
import { NSNoteCardStyle } from "./NoteCard.style";
import { NSNoteCardType } from "./NoteCard.type";

function NoteCard({
  note,
  user,
  createdAt,
  contextMenu,
  isEditing = false,
}: Readonly<NSNoteCardType.INoteCard>) {
  return (
    <NSNoteCardStyle.Container isActive={isEditing}>
      <NSNoteCardStyle.HeaderContainer>
        <NSNoteCardStyle.Note>{note}</NSNoteCardStyle.Note>
        {contextMenu && (
          <ContextMenuHOC
            items={contextMenu.items}
            onItemClick={contextMenu.onItemClick}
            type={contextMenu.type}
            buttonLabel={contextMenu.buttonLabel}
            isScrollable={contextMenu.isScrollable}
            title={contextMenu.title}
            showSearch={contextMenu.showSearch}
            containAddNewItemButton={contextMenu.containAddNewItemButton}
            onCheckedItems={contextMenu.onCheckedItems}
            onContextMenuOpen={contextMenu.onContextMenuOpen}
            shouldStopPropagation
          />
        )}
      </NSNoteCardStyle.HeaderContainer>
      <NSNoteCardStyle.HeaderInfo>
        {user} {createdAt}
      </NSNoteCardStyle.HeaderInfo>
    </NSNoteCardStyle.Container>
  );
}

export default NoteCard;
