import styled from "styled-components";

export namespace NSEmptyViewStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  `;

  export const Illustration = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 25px;
  `;

  export const NoResultTitle = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 8px;
  `;

  export const NoResultLabel = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 32px;
  `;
}
