import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { EN, LANGUAGE_INFO, TR } from "../utils/globalConstants";
import enTranslations from "../translations/language/en.json";
/* eslint-disable import/no-extraneous-dependencies */
import trTranslations from "../translations/language/tr.json";

const localizeErrorMessages = { en: {}, tr: {} };

i18n.use(initReactI18next).init({
  resources: {
    tr: {
      translation: { ...trTranslations, ...localizeErrorMessages.tr },
    },
    en: {
      translation: { ...enTranslations, ...localizeErrorMessages.en },
    },
  },

  fallbackLng: EN,
  react: {
    useSuspense: true,
  },
});

i18n.languages = [TR, EN];

if (globalThis && globalThis.navigator) {
  // getting language from localstorage
  const languageFromCookie = localStorage.getItem(LANGUAGE_INFO);
  i18n.changeLanguage(languageFromCookie ?? EN);
}
const changeSiteLanguage = (language: string) => {
  localStorage.setItem(LANGUAGE_INFO, language);
  i18n.changeLanguage(language);
};

export default i18n;

export { changeSiteLanguage };
