import AnswerOption from "../../../components/AnswerOption/AnswerOption";

import { NSCandidateReviewerQuestionStyle } from "./Candidate-Reviewer-Question.style";
import { NSCandidateReviewerQuestionType } from "./Candidate-Reviewer-Question.type";

function Question({
  question,
  description,
  answerOptions,
  isOnlyAISelected,
  selectedAnswer,
  onClickReasonButton,
  onClickOptionItem,
}: NSCandidateReviewerQuestionType.IQuestion) {
  console.log(description, "description");
  return (
    <NSCandidateReviewerQuestionStyle.QuestionContainer>
      <NSCandidateReviewerQuestionStyle.Question>
        {question}
      </NSCandidateReviewerQuestionStyle.Question>

      {/*  {description && (
        <NSCandidateReviewerQuestionStyle.QuestionDescription>
          {description}
        </NSCandidateReviewerQuestionStyle.QuestionDescription>
      )} */}
      <NSCandidateReviewerQuestionStyle.OptionsContainer>
        {answerOptions?.map((item) => (
          <AnswerOption
            userInfo={item.userInfo}
            key={item.id}
            id={item.id.toString()}
            label={item.label}
            isSelected={
              selectedAnswer ? item.id === selectedAnswer : item.isSelected
            }
            onClick={() => onClickOptionItem(item.id)}
            isAISelected={item.isAISelected}
            isOnlyAISelected={isOnlyAISelected}
            onClickReasonButton={() => onClickReasonButton?.(item)}
            hasReason={(item.isAISelected || item.isSelected) && item.hasReason}
          />
        ))}
      </NSCandidateReviewerQuestionStyle.OptionsContainer>
    </NSCandidateReviewerQuestionStyle.QuestionContainer>
  );
}

export default Question;
