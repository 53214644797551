import { Outlet } from "react-router-dom";
import { Trans } from "react-i18next";
import DashboardNotification from "../../../../components/DashboardNotification/DashboardNotification";
import GhostButton from "../../../../components/GhostButton/GhostButton";
import JDRoleButton from "../../../../components/JDRoleButton/JDRoleButton";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import Loading from "../../../../components/Loading/Loading";
import Logo from "../../../../components/Logo/Logo";
import { NSCreateJobDescriptionStyle } from "../CreateJobDescription.style";
import { NSCreateJobDescriptionType } from "../CreateJobDescription.type";
import OutlinedButton from "../../../../components/OutlinedButton/OutlinedButton";
import ProjectSelection from "../../../../components/ProjectSelection/ProjectSelection";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import { ButtonColorVariant } from "../../../../components/MainButton/MainButton.type";
import i18n from "../../../../library/i18next";

function FirstJobCreation({
  vm,
}: NSCreateJobDescriptionType.IFirstJobDescription) {
  const firstJobCreationVm = vm();

  return firstJobCreationVm.isPageLoading ? (
    <Loading />
  ) : (
    <NSCreateJobDescriptionStyle.FJContainer>
      <NSCreateJobDescriptionStyle.FJHeader>
        <Logo type="onlyIcon" />
        {firstJobCreationVm.projects &&
          firstJobCreationVm.defaultSelectedProject && (
            <ProjectSelection
              id={1}
              projects={firstJobCreationVm.projects}
              selectedProject={firstJobCreationVm.defaultSelectedProject}
              onSelect={firstJobCreationVm.onSelectProject}
            />
          )}
        {!firstJobCreationVm.onClickedCreateProjectDismiss && (
          <>
            <DashboardNotification
              message={i18n.t("jd.createAnotherProjectLabel")}
              typeLabel={i18n.t("jd.didYouKnow")}
              type="dark"
              borderColor="suggestion"
            />
            <NSCreateJobDescriptionStyle.FooterLeftContainerButtonGroup>
              <OutlinedButton
                label={i18n.t("jd.justWhatINeed")}
                onClick={firstJobCreationVm.onClickCreateProject}
              />
              <LinkButton
                label={i18n.t("general.dismiss")}
                onClick={() =>
                  firstJobCreationVm.setOnClickedCreateProjectDismiss(true)
                }
              />
            </NSCreateJobDescriptionStyle.FooterLeftContainerButtonGroup>
          </>
        )}
      </NSCreateJobDescriptionStyle.FJHeader>

      <NSCreateJobDescriptionStyle.FJContent>
        <NSCreateJobDescriptionStyle.FQuestion>
          <Trans
            i18nKey="jd.selectJDRoleLabel"
            values={{
              clientName: firstJobCreationVm.selectedProjectClient,
            }}
            components={{
              container: (
                <NSCreateJobDescriptionStyle.FQQuestionButtonContainer />
              ),
              roleButton: (
                <JDRoleButton
                  label={firstJobCreationVm.roleButtonLabel}
                  onClick={firstJobCreationVm.showRoles}
                  prefixIcon="CloseShort"
                  size="large"
                  suffixIcon={firstJobCreationVm.buttonIcon}
                  variant="label-w-icon-suffix"
                />
              ),
            }}
          />
        </NSCreateJobDescriptionStyle.FQuestion>
      </NSCreateJobDescriptionStyle.FJContent>
      <NSCreateJobDescriptionStyle.Footer isInTheFirstPage>
        <NSCreateJobDescriptionStyle.FooterLeftContainer>
          {firstJobCreationVm.isShownTitleOrExperienceModalButton && (
            <>
              <DashboardNotification
                message={i18n.t("jd.addExperienceLevelorTitle")}
                typeLabel="Suggestion"
                type="suggestion"
              />
              <NSCreateJobDescriptionStyle.FooterLeftContainerButtonGroup>
                <OutlinedButton
                  size="medium"
                  label={i18n.t("jd.yes")}
                  onClick={firstJobCreationVm.showExperienceAndTitle}
                />
                <LinkButton
                  label={i18n.t("general.dismiss")}
                  onClick={() => firstJobCreationVm.setOnClickedDismiss(true)}
                />
              </NSCreateJobDescriptionStyle.FooterLeftContainerButtonGroup>
            </>
          )}
        </NSCreateJobDescriptionStyle.FooterLeftContainer>
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            label={i18n.t("jd.letsStart")}
            disabled={firstJobCreationVm.isStartButtonDisabled}
            onClick={firstJobCreationVm.createJobDescription}
            isLoading={firstJobCreationVm.isStartButtonLoading}
            isIconButton
            backgroundColor={ButtonColorVariant.grey}
            suffixIcon="ArrowDown"
            variant="label-w-icon-suffix"
          />
          <GhostButton
            variant="label-w-icon-suffix"
            label={i18n.t("jd.orPressEnter")}
            isIconButton
            suffixIcon="DownLeft"
            disabled={firstJobCreationVm.isStartButtonDisabled}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
      <Outlet />
    </NSCreateJobDescriptionStyle.FJContainer>
  );
}

export default FirstJobCreation;
