import { NSNotFoundStyle } from "./Not-Found.style";
import i18n from "../../library/i18next";
import Logo from "../../components/Logo/Logo";
import CardView from "../../components/CardView/CardView";
import SolidButton from "../../components/SolidButton/SolidButton";

function NotFound() {
  return (
    <NSNotFoundStyle.Container>
      <Logo />
      <CardView>
        <NSNotFoundStyle.UpContainer>
          <NSNotFoundStyle.Title>
            {i18n.t("general.somethingWentWrong")}
          </NSNotFoundStyle.Title>
          <NSNotFoundStyle.Description>
            {i18n.t("general.somethingWentWrongDescription")}
          </NSNotFoundStyle.Description>
          <SolidButton
            fullWidth
            size="medium"
            label={i18n.t("general.goBack")}
            onClick={() => window.history.go(-1)}
          />
        </NSNotFoundStyle.UpContainer>
      </CardView>
    </NSNotFoundStyle.Container>
  );
}

export default NotFound;
