const companySizeHelper = (employeeCount: number) => {
  let size = "";
  if (employeeCount) {
    if (employeeCount < 9) {
      size = "1-9 employess";
    } else if (employeeCount < 51) {
      size = "10-50 employess";
    } else if (employeeCount < 100) {
      size = "50-100 employess";
    } else {
      size = "1000+ employess";
    }
  }

  return size;
};
export default companySizeHelper;
