import StatsOverviewLabel from "../StatsOverviewLabel/StatsOverviewLabel";
import { NSStatsOverview } from "./StatsOverview.style";
import { NSStatsOverviewType } from "./StatsOverview.type";

function StatsOverview({
  data,
}: {
  data: NSStatsOverviewType.IStatsOverview[];
}) {
  return (
    <NSStatsOverview.Container>
      {data.map((item) => (
        <NSStatsOverview.OverviewItem key={item.text} data-testid="stats-label">
          <NSStatsOverview.Text>{item.text}</NSStatsOverview.Text>
          <StatsOverviewLabel label={item.label} color={item.color} />
        </NSStatsOverview.OverviewItem>
      ))}
    </NSStatsOverview.Container>
  );
}
export default StatsOverview;
