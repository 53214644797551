import { GraphQLClient, Variables } from "graphql-request";
import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import LocalStorageHelper from "../utils/localStorageHelper";
import Config from "../config.json";
import JwtHelper from "../utils/jwtHelper";

const getVideoToken = (): string | null => {
  const params = new URL(window.location.href).searchParams;
  return params.get("token");
};

const accessToken =
  getVideoToken() || LocalStorageHelper.getAuthorizationToken();
const headers = { "Content-Type": "application/json" } as Record<
  string,
  string
>;

if (accessToken && JwtHelper.isTokenValid(accessToken)) {
  headers.Authorization = `Bearer ${accessToken}`;
}

const client = new GraphQLClient(Config.REACT_APP_API_BASE_URL, {
  headers: () => headers,
});

export function useGraphQLQuery<TResult, TVariables>(
  document: TypedDocumentNode<TResult, TVariables>,
  opts?: UseQueryOptions<TResult, any, any, any>,
  ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
): UseQueryResult<TResult> {
  return useQuery(
    [(document.definitions[0] as any).name.value, variables],
    async ({ queryKey }) =>
      client.request(document, queryKey[1] ? queryKey[1] : undefined),
    opts
  );
}

export function useGraphQLMutation<TResult, TError, TVariables, TContext>(
  document: TypedDocumentNode<TResult, TVariables>,
  sideEffects?: UseMutationOptions<TResult, TError, TVariables, TContext>
): UseMutationResult<TResult, TError, TVariables, TContext> {
  return useMutation(
    [(document.definitions[0] as any).name.value],
    (args) => client.request(document, args as Variables),
    sideEffects
  );
}
