import { NSStepProgressBarStyle } from "./StepProgressBar.style";
import { NSStepProgressBarType } from "./StepProgressBar.type";

function StepProgressBar({
  barItems,
  activeId,
}: NSStepProgressBarType.IStepProgressBar) {
  return (
    <NSStepProgressBarStyle.Container>
      {barItems.map((item, index) => (
        <NSStepProgressBarStyle.BarContainer key={`${item.id}-bar`}>
          <NSStepProgressBarStyle.BarItem
            isActive={item.id === activeId}
            key={item.id}
          >
            <NSStepProgressBarStyle.BarItemOrder>
              {index + 1}
            </NSStepProgressBarStyle.BarItemOrder>
            <NSStepProgressBarStyle.BarItemLabel>
              {item.label}
            </NSStepProgressBarStyle.BarItemLabel>
          </NSStepProgressBarStyle.BarItem>
          {barItems.length - 1 !== index && (
            <NSStepProgressBarStyle.Divider key={`${item.id}-divider`} />
          )}
        </NSStepProgressBarStyle.BarContainer>
      ))}
    </NSStepProgressBarStyle.Container>
  );
}

export default StepProgressBar;
