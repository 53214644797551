import Checkbox from "../../../components/Checkbox/CheckBox";
import Drawer from "../../../components/Drawer/Drawer";
import Dropdown from "../../../components/Dropdown/Dropdown";
import GhostIconButton from "../../../components/GhostIconButton/GhostIconButton";
import Loading from "../../../components/Loading/Loading";
import { NSEditClientStyle } from "./Edit-Client.style";
import PhoneNumber from "../../../components/PhoneNumber/PhoneNumber";
import SolidButton from "../../../components/SolidButton/SolidButton";
import SwitchButton from "../../../components/SwitchButton/SwitchButton";
import TextArea from "../../../components/TextArea/TextArea";
import TextInput from "../../../components/TextInput/TextInput";
import parsePhoneNumberHelper from "../../../utils/parsePhoneNumberHelper";
import useEditClientVm from "./Edit-ClientVm";
import i18n from "../../../library/i18next";
import { Controller } from "react-hook-form";

export default function EditClient() {
  const editClientVm = useEditClientVm();
  const subscribeMultiLocation = editClientVm.watch("hasMultiLocation");
  const { control } = editClientVm;

  return editClientVm.isLoading ? (
    <Loading />
  ) : (
    <Drawer title={i18n.t("clients.editClient")}>
      <NSEditClientStyle.Container>
        <NSEditClientStyle.EditClientForm
          onSubmit={editClientVm.handleSubmit()}
        >
          <Controller
            name="clientName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="clientName"
                placeholder={i18n.t("forms.clientName")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => (
              <PhoneNumber
                id="phoneNumber"
                defaultSelectedItem={
                  parsePhoneNumberHelper(
                    editClientVm.phoneCode,
                    defaultValues?.phoneNumber
                  ).codeFromArray
                }
                defaultValue={
                  parsePhoneNumberHelper(
                    editClientVm.phoneCode,
                    defaultValues?.phoneNumber
                  ).plainPhoneNumber
                }
                dropdownItems={editClientVm.phoneCode}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name="country"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="basic"
                  placeholder={i18n.t("forms.country")}
                  selectedDropdownItem={defaultValues?.country}
                  options={editClientVm.country ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="city"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={2}
                  type="basic"
                  placeholder={i18n.t("forms.city")}
                  options={editClientVm.citiesItems ?? []}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  selectedDropdownItem={defaultValues?.city}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="bussinessAddress"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                id="bussinessAddress"
                placeholder={i18n.t("forms.bussinessAddress")}
                error={!!error?.message}
                errorText={error?.message}
                {...field}
              />
            )}
          />

          <NSEditClientStyle.Title>
            {i18n.t("forms.setClientProfile")}
          </NSEditClientStyle.Title>

          <NSEditClientStyle.Description>
            {i18n.t("forms.setClientProfileDesc")}
          </NSEditClientStyle.Description>

          <Controller
            name="sector"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={3}
                  type="basic"
                  placeholder={i18n.t("forms.sector")}
                  options={editClientVm.sectorField}
                  selectedDropdownItem={defaultValues?.sector}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="typeOfCompany"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={4}
                  type="basic"
                  placeholder={i18n.t("forms.typeOfCompany")}
                  selectedDropdownItem={defaultValues?.typeOfCompany}
                  options={editClientVm.typeOfCompany}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="companySize"
            control={control}
            render={({
              formState: { defaultValues },
              field,
              fieldState: { error },
            }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={5}
                  type="basic"
                  placeholder={i18n.t("forms.companySize")}
                  selectedDropdownItem={defaultValues?.companySize}
                  options={editClientVm.companySize}
                  onSelect={(_id, selected) => field.onChange(selected)}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="aboutCompany"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <TextArea
                  id="aboutCompany"
                  placeholder={i18n.t("forms.aboutCompany")}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />

          <Controller
            name="hasMultiLocation"
            control={control}
            render={({ field }) => {
              const { ref, ...fieldProps } = field;
              return (
                <SwitchButton
                  checked={subscribeMultiLocation}
                  label={i18n.t("forms.hasMultiLocation")}
                  onChecked={(value) => field.onChange(value)}
                  {...fieldProps}
                />
              );
            }}
          />

          {subscribeMultiLocation && (
            <NSEditClientStyle.AddLocationContainer>
              {editClientVm.checkboxList.length > 0 &&
                editClientVm.checkboxList.map((item, key) => (
                  <NSEditClientStyle.CheckboxContainer key={item.id}>
                    <Checkbox
                      checked={item.checked}
                      label={item.label}
                      onClick={() => editClientVm.onClickCheckbox(key)}
                    />
                  </NSEditClientStyle.CheckboxContainer>
                ))}

              <TextInput
                id="addLocationInput"
                name="AddLocationInput"
                placeholder={i18n.t("forms.addLocation")}
                variant="label-w-icon-suffix"
                value={editClientVm.inputValue}
                suffixItem={
                  <NSEditClientStyle.InputSuffixItem
                    isInputNotEmpty={!!editClientVm.inputValue}
                  >
                    <GhostIconButton
                      disabled={editClientVm.inputValue === ""}
                      onClick={editClientVm.onClickInputSuffix}
                      icon="ArrowDownLeft"
                    />
                  </NSEditClientStyle.InputSuffixItem>
                }
                onChange={(e) => editClientVm.setInputValue(e)}
              />
            </NSEditClientStyle.AddLocationContainer>
          )}

          <SolidButton
            label={i18n.t("clients.editClient")}
            type="submit"
            fullWidth
          />
        </NSEditClientStyle.EditClientForm>
      </NSEditClientStyle.Container>
    </Drawer>
  );
}
