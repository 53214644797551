import jwt_decode, { JwtPayload } from "jwt-decode";

import { NSUserType } from "../components/User/User.type";

class JwtHelper {
  static getUserInfo = (accessToken: string): NSUserType.IDbUser => {
    const decoded = jwt_decode(accessToken) as NSUserType.IDbUser;
    return decoded;
  };

  static isTokenValid = (accessToken: string | null): boolean => {
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken) as JwtPayload;
    return !!((decoded.exp && decoded.exp > Math.floor(new Date().getTime() / 1000)))
  };
}

export default JwtHelper;
