import i18n from "../../../../../library/i18next";
import useAddNoteVm from "./Add-NoteVM";
import { NSAddNoteStyle } from "./Add-Note.style";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import TextArea from "../../../../../components/TextArea/TextArea";
import { Controller } from "react-hook-form";

export default function AddNote() {
  const { control, handleSubmit } = useAddNoteVm();
  return (
    <NSAddNoteStyle.Container>
      <NSAddNoteStyle.FormContainer onSubmit={handleSubmit()}>
        <Controller
          name="note"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const { ref, ...fieldProps } = field;
            return (
              <TextArea
                id="noteId"
                placeholder={i18n.t("projects.addNewNote")}
                error={!!error?.message}
                errorText={error?.message}
                {...fieldProps}
              />
            );
          }}
        />
        <NSAddNoteStyle.ButtonContainer>
          <SolidButton
            type="submit"
            label={i18n.t("projects.addNewNote")}
            variant="label-w-icon-prefix"
            prefixIcon="Add"
          />
        </NSAddNoteStyle.ButtonContainer>
      </NSAddNoteStyle.FormContainer>
    </NSAddNoteStyle.Container>
  );
}
