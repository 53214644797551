import styled from "styled-components";
import colors from "../../style/colors";
import { NSMainInputStyle } from "../MainInput/MainInput.style";

export namespace NSDragableCheckBoxListItemStyle {
  export const InnerContainer = styled.div<{ checked: boolean }>`
    display: flex;
    align-items: center;

    border-radius: 8px;
    transition: background-color 0.2s;
    text-decoration: ${(props) => (props.checked ? "none" : "line-through")};
    color: ${(props) =>
      props.checked ? colors.dark.dark : colors.neutrals.grey05};
  `;

  export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    padding: 12px;
  `;

  export const WarningText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.error.red01};
  `;

  export const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.2s;
    margin-left: 12px;
    width: 48px;
  `;
  export const DraggerContainer = styled.div`
    padding: 4px;
    cursor: pointer;
  `;
  export const IconContainer = styled.div`
    padding: 4px;
    cursor: pointer;
    margin-right: 8px;
    &:last-child {
      margin-right: 0px;
    }

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary.primary};
      }
    }
  `;

  export const FreeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 75%;
  `;
  export const Container = styled.div<{ withMaxDuration?: boolean }>`
    font-family: greycliff-cf, sans-serif;
    display: flex;
    align-items: center;
    width: 100%;

    ${(props) => props.withMaxDuration && `justify-content: space-between;`}

    &:hover {
      ${InnerContainer} {
        background-color: ${colors.shadesOfdark.dark05};
      }
      ${IconsContainer} {
        opacity: 1;
        pointer-events: auto;
      }
    }
    ${NSMainInputStyle.InputFlex} {
      height: 50px;
    }
  `;

  export const MaxDurationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    ${NSMainInputStyle.InputFlex} {
      width: 113px;
      height: 48px;
      font-size: ${(props) => props.theme.typography.pharagraph2.size};
      line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    }

    svg {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  `;

  export const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
  `;

  export const CustomInputValue = styled.div`
    position: absolute;
    right: 56px;
    margin-bottom: 2px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const LanguageLabel = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey05};
  `;

  export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    max-width: 320px;
  `;

  export const ModalContent = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    text-align: center;
    margin-bottom: 20px;
  `;
}
