import colors from "../../style/colors";

export namespace NSAvatarType {
  export interface IAvatar {
    avatarUrl?: string;
    isAvatarCircle?: boolean;
    size?: AvatarSizeVariant;
    type?: AvatarType;
    letter?: string;
    status?: AvatarStatus;
    name?: string;
  }

  export const AvatarSizeTypes = {
    large: "96px",
    medium: "56px",
    small: "40px",
    xSmall: "32px",
  };

  export const CircleBorderRadiusTypes = {
    large: "48px",
    medium: "28px",
    small: "20px",
    xSmall: "16px",
  };

  export const RoundedBorderRadiusTypes = {
    large: "24px",
    medium: "16px",
    small: "8px",
    xSmall: "8px",
  };

  export const FontSizeTypes = {
    large: "2rem",
    medium: "1.25rem",
    small: "0.875rem",
    xSmall: "0.75rem",
  };

  export const LineHeightTypes = {
    large: "2.6rem",
    medium: "1.85rem",
    small: "1.295rem",
    xSmall: "1.35rem",
  };

  export type AvatarSizeVariant = "xSmall" | "small" | "medium" | "large";

  export type AvatarType = "image" | "white" | "solid";

  export type AvatarStatus = "offline" | "online" | "away" | "do_not_disturb";

  export const StatusColorTypes = {
    offline: colors.neutrals.grey04,
    online: colors.success.green01,
    away: colors.warning.yellow01,
    do_not_disturb: colors.error.red01,
  };
}
