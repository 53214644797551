import { Link } from "react-router-dom";
import styled from "styled-components";

export namespace NSSettingsMyPlansStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 32px;
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const PlanName = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const PlansContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  `;

  export const NotFoundText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const SupportButton = styled(Link)`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.primary.primary};
    text-decoration: none;
  `;
}
