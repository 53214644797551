import { NSTabbedViewStyle } from "./TabbedView.style";
import { NSTabbedViewType } from "./TabbedView.type";

function TabbedView({
  tabItems,
  activeTab,
  onTabChange,
}: NSTabbedViewType.ITabbedView) {
  const onClickTab = (id: number) => {
    onTabChange?.(id);
  };
  return (
    <NSTabbedViewStyle.Container>
      <NSTabbedViewStyle.HeaderContainer>
        {tabItems.map((headerItem) => (
          <NSTabbedViewStyle.Header key={headerItem.id}>
            <NSTabbedViewStyle.HeaderItem
              selected={activeTab === headerItem.id}
              onClick={() => onClickTab(headerItem.id)}
            >
              {headerItem.label}
            </NSTabbedViewStyle.HeaderItem>
            {headerItem.hasSeperator && (
              <NSTabbedViewStyle.Separator key="tab-items-seperator" />
            )}
          </NSTabbedViewStyle.Header>
        ))}
      </NSTabbedViewStyle.HeaderContainer>
    </NSTabbedViewStyle.Container>
  );
}

export default TabbedView;
