import styled from "styled-components";

import { Form } from "react-router-dom";
import { NSDashboardHeaderStyle } from "../../components/DashboardHeader/DashboardHeader.style";
import { NSMainButtonStyle } from "../../components/MainButton/MainButton.style";
import { NSDropdownStyle } from "../../components/Dropdown/Dropdown.style";

export namespace NSTeamStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    ${NSDashboardHeaderStyle.Container} {
      ${NSMainButtonStyle.IconContainer} {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }
  `;

  export const TableContainer = styled.div`
    width: 100%;
    height: 508px;
    display: flex;
    justify-content: flex-end;

    ${NSDropdownStyle.InnerDropdownTitle} {
      border: 0;
    }
  `;

  export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    ${NSDropdownStyle.DropdownItemsContainer} {
      top: 24px;
    }
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: -24px;
  `;

  export const ModalLabel = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 390px;
    gap: 32px;
  `;

  export const EmptyContainer = styled.div`
    width: 100%;
    height: calc(100% - 90px);
  `;

  export const InviteMemberForm = styled(Form)`
    gap: 32px;
    display: flex;
    flex-direction: column;
  `;
}
