import Icon from "../Icon/Icon";
import { NSCandidateSearchEmptyViewStyle } from "./CandidateSearchEmptyView.style";
import i18n from "../../library/i18next";

function CandidateSearchEmptyView() {
  return (
    <NSCandidateSearchEmptyViewStyle.Container>
      <Icon name="TriangleWarning" />
      <NSCandidateSearchEmptyViewStyle.Content>
        <NSCandidateSearchEmptyViewStyle.Title>
          {i18n.t("candidate.noResult")}
        </NSCandidateSearchEmptyViewStyle.Title>
        <NSCandidateSearchEmptyViewStyle.Information>
          {i18n.t("candidate.noResultInfo")}
        </NSCandidateSearchEmptyViewStyle.Information>
      </NSCandidateSearchEmptyViewStyle.Content>
    </NSCandidateSearchEmptyViewStyle.Container>
  );
}

export default CandidateSearchEmptyView;
