import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSExperienceSelectionModalStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 40vw;
    max-width: 60vw;
    max-height: 80vh;
    font-family: greycliff-cf, sans-serif;
    gap: 2rem;
  `;
  export const TitleContainer = styled.div`
    display: inline-block;
    text-align: center;
    padding: 12px 16px;
    background-color: ${colors.neutrals.grey02};
    border-radius: 8px;
    font-size: ${typography.titleh3.size};
    line-height: ${typography.titleh3.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    color: ${colors.primary.primary};
  `;
  export const SubTitle = styled.div`
    font-size: ${typography.pharagraph1.size};
    line-height: ${typography.pharagraph1.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    color: ${colors.secondary.secondary};
  `;

  export const PillContainer = styled.div`
    max-height: 18rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
    gap: 1.5rem;

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.shadesOfdark.dark06};
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.shadesOfdark.dark05};
    }
  `;
  export const PrefixSuffix = styled.span`
    color: ${colors.tertiary.tertiary};
  `;
}
