/* eslint-disable react/jsx-props-no-spreading */

import { NSJDRoleButtonStyle } from "./JDRoleButton.style";
import { NSJDRoleButtonType } from "./JDRoleButton.type";

import MainButton from "../MainButton/MainButton";

function JDRoleButton({ ...props }: NSJDRoleButtonType.IJDRoleButton) {
  return (
    <NSJDRoleButtonStyle.Container>
      <MainButton {...props} />
    </NSJDRoleButtonStyle.Container>
  );
}

export default JDRoleButton;
