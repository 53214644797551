import { ReactNode } from "react";

export namespace NSDashboardNotificationType {
  export interface IDashboardNotification {
    message: string | ReactNode;
    typeLabel: string;
    type: NotificationTypeVariant;
    borderColor?: NotificationTypeVariant;
  }

  export type NotificationTypeVariant =
    | "warning"
    | "suggestion"
    | "success"
    | "dark"
    | "error";

  export enum TypeColorVariant {
    warning = "hsla(39, 85%, 59%, 1)",
    suggestion = "hsla(209, 100%, 46%, 1)",
    success = "hsla(137, 93%, 38%, 1)",
    error = "hsla(349, 73%, 56%, 1)",
    dark = "hsla(0, 0%, 0%, 1)",
  }
}
