import { Trans } from "react-i18next";
import { NSBatchActionStyle } from "./BatchAction.style";
import DropdownWithCaption from "../DropdownWithCaption/DropdownWithCaption";
import { NSBatchActionType } from "./BatchAction.type";
import i18n from "../../library/i18next";
import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";

function BatchAction({
  candidateCount,
  dropdownOptions,
  onClickClearButton,
}: NSBatchActionType.IBatchAction) {
  return (
    <NSBatchActionStyle.BatchProcessContainer>
      <NSBatchActionStyle.BatchProcessText>
        <Trans
          i18nKey="projects.candidateChoosen"
          values={{
            candidate: candidateCount,
          }}
          components={{
            bold: <NSBatchActionStyle.BoldText />,
          }}
        />
      </NSBatchActionStyle.BatchProcessText>
      <NSBatchActionStyle.DropdownContainer>
        <DropdownWithCaption
          id={1}
          placeholder={i18n.t("projects.choose")}
          prefixTitle={i18n.t("projects.batchAction")}
          options={[]}
          type="basic"
        />
        {dropdownOptions && dropdownOptions.length > 0 && (
          <ContextMenuHOC id="1" type="cascading" items={dropdownOptions} />
        )}
      </NSBatchActionStyle.DropdownContainer>
      <NSBatchActionStyle.ClearButton
        size="small"
        key="clearButton"
        label={i18n.t("projects.clear")}
        onClick={onClickClearButton}
      />
    </NSBatchActionStyle.BatchProcessContainer>
  );
}

export default BatchAction;
