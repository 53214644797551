import { Video_Interview_Status_Enum } from "../../gql/graphql";
import i18n from "../../library/i18next";
import { NSCandidateListStyle } from "../../routes/Project/Detail/Candidate-List/Candidate-List.style";

import candidateStatusHelper from "../../utils/candidateStatusHelper";
import StatusBadge from "../StatusBadge/StatusBadge";
import { NSOtherProjectStyle } from "./OtherProject.style";
import { IOtherProject } from "./OtherProject.type";

function OtherProject({ items }: IOtherProject) {
  return (
    <NSOtherProjectStyle.Container>
      <NSOtherProjectStyle.Title>
        {i18n.t("projects.otherProjects")}
      </NSOtherProjectStyle.Title>
      {items.map((item) => {
        return (
          <NSOtherProjectStyle.OtherProjectItem key={item.projectId}>
            <NSOtherProjectStyle.ProjectTitle>
              {item.projectName}
            </NSOtherProjectStyle.ProjectTitle>
            {item.status &&
            item.status !== Video_Interview_Status_Enum.NotSet ? (
              <StatusBadge
                label={
                  candidateStatusHelper(
                    item.status as string,
                    item.score.toString()
                  ).label
                }
                color={
                  candidateStatusHelper(
                    item.status as string,
                    item.score.toString()
                  ).color
                }
              />
            ) : (
              <NSCandidateListStyle.NotActionStatus>
                {i18n.t("general.notActionYet")}
              </NSCandidateListStyle.NotActionStatus>
            )}
          </NSOtherProjectStyle.OtherProjectItem>
        );
      })}
    </NSOtherProjectStyle.Container>
  );
}

export default OtherProject;
