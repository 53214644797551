import styled from "styled-components";

export namespace NSScoreBadgeStyle {
  export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 8px 12px;
    border-radius: 8px;
    min-height: 50px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const Description = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
    max-width: 75%;
    text-align: left;
  `;

  export const Badge = styled.div<{ color: string; backgroundColor: string }>`
    display: flex;
    align-items: center;
    min-width: 61px;
    width: fit-content;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;
}
