import { NSTableListStyle } from "./TableList.style";
import { NSTableListType } from "./TableList.type";

function TableList({
  headerItemList,
  tableItemList,
}: NSTableListType.ITableList) {
  return (
    <NSTableListStyle.Base>
      <NSTableListStyle.Container>
        <NSTableListStyle.Header>
          <NSTableListStyle.HeaderLine>
            {headerItemList.map((headerItem, index) => (
              <NSTableListStyle.HeaderColumn key={index}>
                {headerItem}
              </NSTableListStyle.HeaderColumn>
            ))}
          </NSTableListStyle.HeaderLine>
        </NSTableListStyle.Header>
        <NSTableListStyle.TableListTbody>
          <NSTableListStyle.SpaceElement />
          {tableItemList.map((tableItem) => (
            <NSTableListStyle.Line
              key={tableItem.id}
              checked={tableItem.informations?.[0]?.information?.props?.checked}
            >
              {tableItem.informations?.map((tablemItemInformation, index) => (
                <NSTableListStyle.Column
                  key={tablemItemInformation.id}
                  isInTheFirstColumn={index === 0}
                >
                  {tablemItemInformation.information}
                </NSTableListStyle.Column>
              ))}
            </NSTableListStyle.Line>
          ))}
        </NSTableListStyle.TableListTbody>
      </NSTableListStyle.Container>
    </NSTableListStyle.Base>
  );
}

export default TableList;
