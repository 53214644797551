import { Form } from "react-router-dom";
import styled from "styled-components";
import MainButton from "../../../../../components/MainButton/MainButton";
import { NSMainButtonStyle } from "../../../../../components/MainButton/MainButton.style";

export namespace NSAddNoteStyle {
  export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
    flex-direction: column;
  `;

  export const NotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `;

  export const FormContainer = styled(Form)`
    gap: 32px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const CancelButton = styled(MainButton)`
    background: none !important;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 16px 8px;
  `;

  export const ButtonContainer = styled.div`
    ${NSMainButtonStyle.ButtonBase} {
      padding: 16px 42px;
    }
  `;
}
