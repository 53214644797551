import { Outlet } from "react-router-dom";

import { NSProjectsStyle } from "./Projects.style";
import { ProjectDetailProvider } from "../../context/ProjectDetailContext/ProjectDetailContext";

function ProjectsPage() {
  return (
    <ProjectDetailProvider>
      <NSProjectsStyle.Container>
        <Outlet />
      </NSProjectsStyle.Container>
    </ProjectDetailProvider>
  );
}

export default ProjectsPage;
