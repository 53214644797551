import styled, { css } from "styled-components";

export namespace NSBarChartStyle {
  export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
  `;

  export const Label = styled.div`
    display: flex;
    width: 20%;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const EmptyBar = styled.div`
    width: 560px;
    height: 42px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    position: relative;
  `;

  export const ScoreContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

  export const Value = styled.div`
    position: absolute;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
    right: 41px;
  `;

  export const ScoreThumb = styled(EmptyBar)<{
    backgroundColor: string;
    width: number;
  }>`
    width: ${(props) => props.width}px;
    animation: progress 800ms ease-in 1;
    position: absolute;
    border-radius: 8px 0px 0px 8px;

    background-color: ${(props) => props.backgroundColor};
    ${(props) =>
      props.width === 560 &&
      css`
        border-radius: 8px;
      `}

    @keyframes progress {
      from {
        border: 0;
        width: 0;
        border-radius: 8px;

        background-color: transparent;
      }
    }
  `;
}
