import dayjs from "dayjs";
import {
  Currency_Enum,
  GetVideoInterviewsByDateDocument,
} from "../../../gql/graphql";
import { useGraphQLQuery } from "../../../hooks/useGraphQL";
import useHomeVm from "../../Home/HomeVM";
import i18n from "../../../library/i18next";

const useSettingsMyPlansVm = () => {
  const { companySubscriptionData, isCompanySubscriptionSuccess } = useHomeVm();

  const { data: videoInterviewData, isSuccess: isVideoInterviewSuccess } =
    useGraphQLQuery(
      GetVideoInterviewsByDateDocument,
      {
        enabled:
          !!companySubscriptionData?.company_subscription[0]?.starts_at &&
          !!companySubscriptionData?.company_subscription[0]?.ends_at,
      },
      {
        startedAt: companySubscriptionData?.company_subscription[0]?.starts_at,
        endedAt: companySubscriptionData?.company_subscription[0]?.ends_at,
      }
    );

  const isLoading = !isCompanySubscriptionSuccess && !isVideoInterviewSuccess;

  const currencySymbols = {
    [Currency_Enum.Try]: "₺",
    [Currency_Enum.Usd]: "$",
  };

  const price = `${
    currencySymbols[
      companySubscriptionData?.company_subscription[0]?.product_price
        ?.currency as Currency_Enum
    ]
  }${companySubscriptionData?.company_subscription[0]?.product_price?.price}`;

  const endDate = companySubscriptionData?.company_subscription[0]?.ends_at
    ? dayjs(companySubscriptionData?.company_subscription[0]?.ends_at).format(
        "MMM DD, YYYY"
      )
    : i18n.t("general.uncertain");

  const startDate = companySubscriptionData?.company_subscription[0]?.starts_at
    ? dayjs(companySubscriptionData?.company_subscription[0]?.starts_at).format(
        "MMM DD, YYYY"
      )
    : i18n.t("general.uncertain");

  const hasCompanySubscription =
    companySubscriptionData?.company_subscription.length;

  return {
    isLoading,
    endDate,
    startDate,
    price,
    totalCredit:
      companySubscriptionData?.company_subscription[0]?.product_price
        .max_credit ?? 0,
    usedCredit: videoInterviewData?.video_interview_aggregate.aggregate?.count,
    hasCompanySubscription,
  };
};

export default useSettingsMyPlansVm;
