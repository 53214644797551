import Icon from "../Icon/Icon";
import { NSDropdown } from "./Dropdown.type";
import { NSDropdownStyle } from "./Dropdown.style";

function CountryBadge({ item, onClickClose }: NSDropdown.ICountryBadge) {
  return (
    <NSDropdownStyle.CountryBadgeContainer>
      <NSDropdownStyle.BadgeLabel>{item.label}</NSDropdownStyle.BadgeLabel>
      <NSDropdownStyle.CloseIconContainer
        onClick={() => onClickClose(item)}
        role="button"
      >
        <Icon width={24} height={24} name="Close" />
      </NSDropdownStyle.CloseIconContainer>
    </NSDropdownStyle.CountryBadgeContainer>
  );
}

export default CountryBadge;
