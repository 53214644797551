import styled, { css } from "styled-components";
import { NSDropdownStyle } from "../../components/Dropdown/Dropdown.style";
import { NSFreeInputStyle } from "../../components/FreeInput/FreeInput.style";
import { NSMainInputStyle } from "../../components/MainInput/MainInput.style";
import LinkButton from "../../components/LinkButton/LinkButton";
import { Form } from "react-router-dom";
import { NSDragableCheckBoxListItemStyle } from "../../components/DragableCheckBoxListItem/DragableCheckBoxListItem.style";
import { NSDragAndDropListStyle } from "../../components/DragAndDropList/DragAndDropList.style";
import { NSCheckboxStyle } from "../../components/Checkbox/CheckBox.style";
import { NSHistoryTooltipStyle } from "../../components/HistoryTooltip/HistoryTooltip.style";
import { NSScoreBadgeStyle } from "../../components/ScoreBadge/ScoreBadge.style";

export namespace NSInterviewDetailStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.75rem 2rem;
  `;

  export const FormContainer = styled(Form)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.75rem 2rem;
  `;

  export const Header = styled.div<{
    hasMargin?: boolean;
  }>`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;

    ${(props) =>
      props.hasMargin &&
      css`
        margin-bottom: 40px;
      `}

    ${NSDropdownStyle.InnerDropdownTitle} {
      padding: 10px;
    }
  `;

  export const ProgressBarContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  `;

  export const ProjectNameBadge = styled.div`
    display: flex;
    padding: 12px;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};
  `;

  export const ProjectName = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.primary.primary};
  `;

  export const Fragment = styled.div``;

  export const Content = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${(props) =>
      css`
        width: ${props.fullWidth ? "100%" : "693px"};
      `}
    padding: 0 52px;
    margin-bottom: 72px;

    ${NSDragAndDropListStyle.SortableItemContainer} {
      background: ${(props) => props.theme.colors.neutrals.grey02};
      border-radius: 8px;
      padding: 0 16px;
    }

    ${NSCheckboxStyle.CheckboxBase} {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }

    ${NSDragableCheckBoxListItemStyle.Container} {
      &:hover {
        ${NSDragableCheckBoxListItemStyle.InnerContainer} {
          background-color: unset;
        }
      }
    }

    ${NSDragableCheckBoxListItemStyle.InnerContainer} {
      text-decoration: none;
      padding: 16px;
      padding-left: 0;
    }
  `;

  export const DragAndDropListContainer = styled.div<{ height72px?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${NSDragableCheckBoxListItemStyle.Container} {
      height: 72px;
    }
  `;

  export const LanguageDropdownContainer = styled.div`
    margin-bottom: 56px;
    max-width: 360px;
  `;

  export const DatePickerContainer = styled.div`
    width: 360px;
  `;

  export const ContentTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-bottom: 4px;
  `;
  export const ContentSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-bottom: 24px;
  `;

  export const SubTitleList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 4px;
    list-style: none;
    margin-left: 20px;
    margin-bottom: 24px;
  `;

  export const ListItems = styled.li`
    position: relative;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    &::before {
      content: "";
      position: absolute;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px; /* Noktanın genişliği */
      height: 2px; /* Noktanın yüksekliği */
      background-color: ${(props) => props.theme.colors.neutrals.grey07};
      border-radius: 50%; /* Noktayı yuvarlak yapıyoruz */
    }
  `;

  export const Footer = styled.div`
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    align-self: flex-end;
    position: absolute;
    bottom: 2rem;
    right: 2rem;

    ${NSFreeInputStyle.Container} {
      ${NSMainInputStyle.InputWrapper} {
        width: 482px;
      }
    }
  `;

  export const FooterContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    align-items: center;
    gap: 28px;
  `;

  export const BackButton = styled(LinkButton)``;

  export const PreviewContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  `;

  export const PreviewHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  export const PreviewCandidateName = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    font-size: ${(props) => props.theme.typography.titleh3.size};
    line-height: ${(props) => props.theme.typography.titleh3.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const PreviewSubtitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const PreviewIcon = styled.div`
    margin-top: 24px;
    width: 224px;
    height: 40px;
  `;

  export const PreviewRemainingTime = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 200px;
    font-size: ${(props) => props.theme.typography.titleh3.size};
    line-height: ${(props) => props.theme.typography.titleh3.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    padding-right: 60px;
  `;

  export const PreviewContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 720px;
  `;

  export const PreviewContentTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};

    margin-bottom: 16px;
  `;

  export const PreviewContentQuestion = styled.div`
    font-size: ${(props) => props.theme.typography.titleh3.size};
    line-height: ${(props) => props.theme.typography.titleh3.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};

    margin-bottom: 16px;
  `;

  export const PreviewTooltip = styled.div`
    ${NSHistoryTooltipStyle.HistoryTooltipLabel} {
      font-size: ${(props) => props.theme.typography.pharagraph2.size};
      line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
      color: ${(props) => props.theme.colors.neutrals.grey07};
      text-align: left;
    }

    ${NSScoreBadgeStyle.Container} {
      background-color: ${(props) => props.theme.colors.light.light};
      padding-right: 0;
      padding-left: 0;
      width: 337px;
    }

    margin-bottom: 40px;
  `;

  export const PreviewTooltipTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
    text-align: left;
    margin-bottom: 8px;
  `;

  export const PreviewTooltipScoreBadgeContainer = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
    &:last-child {
      border-bottom: none;
    }
  `;

  export const PreviewButton = styled.div`
    width: 406px;
  `;

  export const PreviewFooter = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
}
