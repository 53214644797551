import { GetUserByPkDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetUserByPk(userId: string) {
  const { data, refetch, isSuccess, ...rest } = useGraphQLQuery(
    GetUserByPkDocument,
    undefined,
    {
      userId,
    }
  );

  return {
    rest,
    isSuccess,
    refetch,
    data,
  };
}

export default useGetUserByPk;
