import { NSCreateInterviewStyle } from "../../Interview-Create.style";
import i18n from "../../../../library/i18next";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import useCreateInterviewVm from "../../Interview-CreateVM";
import { NSDatePickerType } from "../../../../components/DatePicker/DatePicker.type";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import Logo from "../../../../components/Logo/Logo";
import Icon from "../../../../components/Icon/Icon";
import { Controller } from "react-hook-form";
import { useInterviewContext } from "../../../../context/InterviewContext/Interview-Create/InterviewContext";

const LanguageAndDateSelection = () => {
  const {
    controlLanguageAndDate,
    projectName,
    languageOptions,
    defaultDates,
    initialLanguages,
    handleSubmitLanguageAndDate,
  } = useCreateInterviewVm();
  const { selectedLanguages } = useInterviewContext();

  return (
    <NSCreateInterviewStyle.FormContainer
      onSubmit={handleSubmitLanguageAndDate()}
    >
      <NSCreateInterviewStyle.Header>
        <Logo type="onlyIcon" />
        <NSCreateInterviewStyle.ProjectNameBadge>
          <Icon name="Folders" />
          <NSCreateInterviewStyle.ProjectName>
            {projectName}
          </NSCreateInterviewStyle.ProjectName>
        </NSCreateInterviewStyle.ProjectNameBadge>
      </NSCreateInterviewStyle.Header>

      <NSCreateInterviewStyle.Content>
        <NSCreateInterviewStyle.ContentTitle>
          {i18n.t("interview.languageInterview")}
        </NSCreateInterviewStyle.ContentTitle>
        <NSCreateInterviewStyle.ContentSubTitle>
          {i18n.t("interview.languageInterviewSubTitle")}
        </NSCreateInterviewStyle.ContentSubTitle>
        <NSCreateInterviewStyle.LanguageDropdownContainer>
          <Controller
            name="language"
            control={controlLanguageAndDate}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="withCheckbox"
                  placeholder={i18n.t("forms.language")}
                  options={languageOptions}
                  onCheckedItems={(items) => field.onChange(items)}
                  defaultCheckedItems={
                    selectedLanguages.length
                      ? selectedLanguages
                      : initialLanguages
                  }
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
        </NSCreateInterviewStyle.LanguageDropdownContainer>

        <NSCreateInterviewStyle.ContentTitle>
          {i18n.t("interview.interviewDate")}
        </NSCreateInterviewStyle.ContentTitle>
        <NSCreateInterviewStyle.ContentSubTitle>
          {i18n.t("interview.interviewDateSubTitle")}
        </NSCreateInterviewStyle.ContentSubTitle>
        <NSCreateInterviewStyle.DatePickerContainer>
          <Controller
            name="timePeriod"
            control={controlLanguageAndDate}
            render={({ field }) => {
              const { ref, onChange, ...fieldProps } = field;
              return (
                <DatePicker
                  placeholder={i18n.t("forms.startDateEndDate")}
                  onChange={(dates) => onChange(dates)}
                  defaultDates={defaultDates as NSDatePickerType.Dates}
                  {...fieldProps}
                />
              );
            }}
          />
        </NSCreateInterviewStyle.DatePickerContainer>
      </NSCreateInterviewStyle.Content>
      <NSCreateInterviewStyle.Fragment />
      <NSCreateInterviewStyle.Footer>
        <NSCreateInterviewStyle.FooterContainer>
          <NSCreateInterviewStyle.BackButton
            label={i18n.t("general.back")}
            isIconButton
            prefixIcon="ArrowRight"
            disabled
            variant="label-w-icon-prefix"
          />
          <SolidButton
            label={i18n.t("interview.goToRoleStep")}
            suffixIcon="ArrowLeft"
            type="submit"
            variant="label-w-icon-suffix"
          />
        </NSCreateInterviewStyle.FooterContainer>
      </NSCreateInterviewStyle.Footer>
    </NSCreateInterviewStyle.FormContainer>
  );
};

export default LanguageAndDateSelection;
