import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  Client_Candidate_Project_Candidate_List_Status_Enum,
  Client_Candidate_Project_Constraint,
  UpsertClientCandidateDocument,
} from "../../../../gql/graphql";
import { useGraphQLMutation } from "../../../../hooks/useGraphQL";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import i18n from "../../../../library/i18next";
import { NSAddCandidateFromExcelType } from "./Add-Candidate-From-Excel.type";
import { string, object } from "zod";
import { excelExporter } from "../../../../utils/excelExporter";
import { NSFileUploadType } from "../../../../components/FileUpload/FileUpload.type";

const nonNullNonEmptyString = (value: string | null | undefined) => {
  return typeof value === "string" && value.trim() !== "";
};

const validationSchema = object({
  name: string().refine((value) => nonNullNonEmptyString(value), {
    message: i18n.t("candidate.nameRequired"),
  }),
  surname: string().refine((value) => nonNullNonEmptyString(value), {
    message: i18n.t("candidate.surnameRequired"),
  }),
  email: string()
    .email({ message: i18n.t("candidate.invalidEmail") })
    .refine((value) => nonNullNonEmptyString(value), {
      message: i18n.t("candidate.emailRequired"),
    }),
  phone: string()
    .refine((value) => nonNullNonEmptyString(value), {
      message: i18n.t("candidate.phoneRequired"),
    })
    .transform((value) => value?.replace(/\D/g, ""))
    .refine((value) => /^[0-9]{10,12}$/.test(value), {
      message: i18n.t("candidate.invalidPhone"),
    }),
});

const useAddCandidateFromExcelVm = () => {
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const [candidateList, setCandidateList] = useState<
    NSAddCandidateFromExcelType.ICandidateData[]
  >([]);
  const [candidatesNotSent, setCandidatesNotSent] = useState<
    NSAddCandidateFromExcelType.ICandidateData[]
  >([]);
  const { mutateAsync } = useGraphQLMutation(UpsertClientCandidateDocument);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { projectId } = state;
  const clientId = state.clientId;

  const onUploadFile = useCallback(async (url?: File) => {
    setIsFileUploaded(true);
    const arrayBuffer = await url?.arrayBuffer();
    const workbook = XLSX.read(new Uint8Array(arrayBuffer!), {
      type: "array",
    });

    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];

    const expectedColumnHeaders = ["name", "surname", "email", "phone"];

    const data: string[][] =
      XLSX.utils.sheet_to_json(sheet, { header: 1 }) ?? [];
    const dataWithoutEmptyRow = data?.filter((row) =>
      row.some((item) => item.trim())
    );

    const header = dataWithoutEmptyRow[0]?.filter(Boolean) ?? [];

    if (
      header.length !== expectedColumnHeaders.length ||
      !header.every(
        (header, index) =>
          header.toLowerCase().trim() === expectedColumnHeaders[index]
      )
    ) {
      setCandidateList([]);
      setCandidatesNotSent([]);
      return;
    }

    const rows = dataWithoutEmptyRow.slice(1);

    const formattedData = rows.map((row) => {
      const candidateData: NSAddCandidateFromExcelType.ICandidateData = {
        name: row[0]?.toString() || "",
        surname: row[1]?.toString() || "",
        email: row[2]?.toString().trim().toLocaleLowerCase() || "",
        phone: row[3]?.toString() || "",
        errors: [],
      };

      try {
        validationSchema.parse(candidateData);
      } catch (validationError: any) {
        candidateData.errors = validationError.errors.map(
          (error: any) => error.message
        );
      }

      return candidateData;
    });

    const candidates = formattedData.filter((item) => item.errors.length === 0);
    const failedCandidates = formattedData.filter(
      (item) => item.errors.length > 0
    );

    setCandidateList(candidates);
    setCandidatesNotSent(failedCandidates);
  }, []);

  const onErrorFileUpload = (message: NSFileUploadType.IError) => {
    toast(<ToastAlert type="error" description={message.label} />);
  };

  const onClickUploadCandidate = useCallback(async () => {
    try {
      const candidatesToInsert = candidateList.map((candidateData) => {
        const client_candidate_projects = {
          project_id: projectId,
          candidate_list_status:
            Client_Candidate_Project_Candidate_List_Status_Enum.InLonglist,
        };

        return {
          client_id: clientId,
          name: candidateData.name,
          surname: candidateData.surname,
          mobile_number: candidateData.phone,
          email_address: candidateData.email,
          client_candidate_projects: {
            data: [client_candidate_projects],
            on_conflict: {
              constraint:
                Client_Candidate_Project_Constraint.ClientCandidateProjectProjectIdClientCandidateIdKey,
              update_columns: [],
            },
          },
        };
      });

      await mutateAsync({
        input: candidatesToInsert,
      });

      toast(
        <ToastAlert
          description={i18n.t("projects.createCandidateSuccess")}
          type="success"
        />,
        {
          id: "addAndCreateCandidateSuccess",
        }
      );

      navigate(`/projects/detail/${projectId}/long-list`, {
        state: { clientId, projectId },
      });
    } catch (error) {
      toast(
        <ToastAlert
          description={i18n.t("projects.createCandidateError")}
          type="error"
        />,
        {
          id: "addAndCreateCandidateError",
        }
      );
    }
  }, [clientId, candidateList, mutateAsync, navigate, projectId]);

  const onClickDownloadExampleFile = useCallback(() => {
    const exampleFileData = [
      {
        name: "John",
        surname: "Laberty",
        email: "john@example.com",
        phone: "5999999999",
      },
      {
        name: "John David",
        surname: "Laberty",
        email: "johndavid@example.com",
        phone: "5999999999",
      },
    ];

    excelExporter(exampleFileData, "example.xlsx");
  }, []);

  const inappropriateFile = candidateList.length === 0 && isFileUploaded;
  const candidateWithMissingInfo =
    candidateList.length > 0 && candidatesNotSent.length > 0;

  return {
    isFileUploaded,
    inappropriateFile,
    candidateList,
    candidatesNotSent,
    candidateWithMissingInfo,
    onClickUploadCandidate,
    onUploadFile,
    onClickDownloadExampleFile,
    onErrorFileUpload,
  };
};

export default useAddCandidateFromExcelVm;
