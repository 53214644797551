import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { NSTooltipType } from "./Tooltip.type";

function CustomTooltip({
  id,
  children,
  clickable = false,
}: NSTooltipType.ITooltip) {
  return (
    <Tooltip clickable={clickable} id={id}>
      {children}
    </Tooltip>
  );
}

export default CustomTooltip;
