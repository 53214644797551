import { ForwardedRef, forwardRef, useState } from "react";

import { IPasswordInput } from "./PasswordInput.type";
import LinkButton from "../LinkButton/LinkButton";
import MainInput from "../MainInput/MainInput";
import { NSPasswordInputStyle } from "./PasswordInput.style";
import i18n from "../../library/i18next";

function PasswordInputRef(
  props: IPasswordInput,
  ref?: ForwardedRef<HTMLInputElement>
) {
  const {
    onBlur,
    errorText,
    infoText,
    id,
    error,
    prefixItem,
    onChange,
    maxLength,
    name,
    placeholder,
    disabled = false,
    onFocus,
    label,
    onClick,
    value,
    readonly,
    showLabel,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const suffix = (
    <LinkButton
      role="button"
      size="small"
      onClick={() => setShowPassword(!showPassword)}
      label={showPassword ? i18n.t("general.hide") : i18n.t("general.show")}
    />
  );

  return (
    <NSPasswordInputStyle.SearchInputBase
      value={value}
      showPassword={showPassword}
    >
      <MainInput
        showLabel={showLabel}
        ref={ref}
        variant="label-w-icon-suffix"
        type={showPassword ? "text" : "password"}
        suffixItem={suffix}
        onBlur={onBlur}
        errorText={errorText}
        infoText={infoText}
        id={id}
        error={error}
        value={value}
        prefixItem={prefixItem}
        maxLength={maxLength}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={onFocus}
        label={label}
        onClick={onClick}
        readonly={readonly}
        onChange={onChange}
      />
    </NSPasswordInputStyle.SearchInputBase>
  );
}

const PasswordInput = forwardRef(PasswordInputRef);
export default PasswordInput;
