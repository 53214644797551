import { GetLanguagesDropdownDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetLanguagesDropdown() {
  const { data, ...rest } = useGraphQLQuery(GetLanguagesDropdownDocument);

  const languages = data?.language.map((languageItem) => ({
    dropdown: {
      id: languageItem.language,
      label: languageItem.language.toUpperCase(),
    },
  }));

  return {
    languages,
    rest,
  };
}

export default useGetLanguagesDropdown;
