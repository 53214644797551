import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSRateSliderStyle {
  export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
  `;

  export const RateLabel = styled.div`
    font-family: greycliff-cf, sans-serif;
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.demiBold};
    color: ${colors.dark.dark};
    width: 140px;
    margin-right: 8px;
  `;
  export const RatePill = styled.button<{ isActive: boolean }>`
    width: 80px;
    height: 16px;
    background-color: ${(props) =>
      props.isActive ? colors.primary.primary : colors.neutrals.grey03};
    margin-right: 4px;
    transition: background-color 200ms linear;
    cursor: pointer;
    outline: none;
    padding: 0;
    border: none;
  `;
  export const RatePillContainer = styled.div`
    display: flex;
    flex-direction: row;
    & > :last-child {
      margin-right: 0px;
      border-radius: 0px 4px 4px 0px;
    }
    & > :first-child {
      border-radius: 4px 0px 0px 4px;
    }
  `;
}
