import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import Modal from "../../../components/Modal/Modal";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import SolidButton from "../../../components/SolidButton/SolidButton";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";
import { NSClientListStyle } from "../List/Client-List.style";
import { NSClientDetailStyle } from "./Client-Detail.style";
import useClientDetailVm from "./Client-DetailVM";
import i18n from "../../../library/i18next";

export default function ClientDetail() {
  const clientDetailVM = useClientDetailVm();

  return (
    <>
      <NSClientDetailStyle.Container>
        <NSDashboardLayoutStyle.HeaderContainer>
          <DashboardHeader
            breadcrumb={clientDetailVM.breadcrumb}
            contextMenuHOC={clientDetailVM.contextMenuItems}
          />
        </NSDashboardLayoutStyle.HeaderContainer>

        <NSClientDetailStyle.ContentContainer>
          <NSClientDetailStyle.InfoBox>
            <NSClientDetailStyle.SecondaryTitle>
              {i18n.t("clients.about")}
            </NSClientDetailStyle.SecondaryTitle>

            <NSClientDetailStyle.Information>
              {clientDetailVM.clientInfo.companySize}
            </NSClientDetailStyle.Information>

            <NSClientDetailStyle.Information>
              {clientDetailVM.clientInfo.description}
            </NSClientDetailStyle.Information>
          </NSClientDetailStyle.InfoBox>

          <NSClientDetailStyle.InfoBox>
            <NSClientDetailStyle.SecondaryTitle>
              {i18n.t("clients.contact")}
            </NSClientDetailStyle.SecondaryTitle>

            <NSClientDetailStyle.Information>
              {clientDetailVM.clientInfo.address}
            </NSClientDetailStyle.Information>

            <NSClientDetailStyle.Information>
              {clientDetailVM.clientInfo.phoneNumber}
            </NSClientDetailStyle.Information>
          </NSClientDetailStyle.InfoBox>

          <NSClientDetailStyle.InfoBox>
            <NSClientDetailStyle.SecondaryTitle>
              {i18n.t("clients.locations")}
            </NSClientDetailStyle.SecondaryTitle>

            <NSClientDetailStyle.Information>
              <NSClientDetailStyle.CityNameContainer>
                {clientDetailVM.clientInfo.locations?.map((item, index) => (
                  <NSClientDetailStyle.CityName key={item.cityId}>
                    {index !== clientDetailVM.clientInfo.locations?.length
                      ? `${item.cityName}, `
                      : `${item.cityName}`}
                  </NSClientDetailStyle.CityName>
                ))}
              </NSClientDetailStyle.CityNameContainer>
            </NSClientDetailStyle.Information>
          </NSClientDetailStyle.InfoBox>
        </NSClientDetailStyle.ContentContainer>
      </NSClientDetailStyle.Container>

      <Modal
        isDarkBackgroundActive
        isOpen={clientDetailVM.isDeleteModalOpen}
        onClickCloseButton={clientDetailVM.onClickCloseButton}
      >
        <NSClientListStyle.ModalContainer>
          <NSClientListStyle.ModalTitle>
            {i18n.t("clients.deleteClient")}
          </NSClientListStyle.ModalTitle>
          {i18n.t("clients.deleteClientLabel")}
          <NSClientListStyle.ModalButtonContainer>
            <SolidButton
              fullWidth
              label={i18n.t("general.delete")}
              onClick={() => clientDetailVM.onDeleteClient()}
            />

            <OutlinedButton
              fullWidth
              label={i18n.t("general.giveUp")}
              onClick={() => clientDetailVM.onClickCloseButton()}
            />
          </NSClientListStyle.ModalButtonContainer>
        </NSClientListStyle.ModalContainer>
      </Modal>
    </>
  );
}
