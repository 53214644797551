import styled, { css } from "styled-components";
import {
  IIconButton,
  IconButtonColor,
  IconButtonHoverColor,
  IconColor,
} from "./IconButton.type";

import { ButtonHeightBySizeTypes } from "../MainButton/MainButton.type";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSIconButtonStyle {
  export const IconButtonBase = styled.div<IIconButton>`
    ${NSMainButtonStyle.ButtonBase} {
      background-color: ${(props) =>
        props.isWithBackground
          ? IconButtonColor[props.iconButtonType!]
          : "transparent"};
      border-width: 0;
      height: ${(props) =>
        props.size ? ButtonHeightBySizeTypes[props.size] : "3.5rem"};
      width: ${(props) =>
        props.size ? ButtonHeightBySizeTypes[props.size] : "3.5rem"};
      padding: 16px;

      border-radius: ${(props) =>
        props.iconButtonShape === "rounded" ? "0.5rem" : "1.875rem"};

      svg {
        color: ${(props) =>
          props.iconColor
            ? props.iconColor
            : IconColor[props.iconButtonType ?? "primary"]};
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.secondary.light};
      }

      &:hover {
        svg {
          color: ${(props) =>
            props.iconButtonType !== "grey"
              ? `${props.theme.colors.neutrals.grey09}`
              : IconColor[props.iconButtonType]};
        }
        background-color: ${(props) =>
          props.iconButtonType && IconButtonHoverColor[props.iconButtonType]};
      }

      ${(props) =>
        props.iconButtonShape === "circle" &&
        css`
          &:focus {
            background-color: ${props.theme.colors.primary.primary};
            svg {
              color: ${props.theme.colors.light.light};
            }
          }

          &:hover {
            svg {
              color: ${props.theme.colors.light.light};
            }
          }
        `}
    }
  `;
}
