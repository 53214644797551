import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetClientCandidateOverviewQuery,
  GetProjectInterviewTemplateByIdDocument,
  Project_Status_Enum,
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../../../gql/graphql";
import { useGetClientCandidateOverview } from "../../../../service/ClientsCandidate/getClientCandidate";
import { NSStatsOverviewType } from "../../../../components/StatsOverview/StatsOverview.type";
import { NSStatsChartListType } from "../../../../components/StatsChartList/StatsChartList.type";
import i18n from "../../../../library/i18next";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import toast from "react-hot-toast";
import useUpdateProjectStatus from "../../../../service/Project/updateProjectStatus";
import { useGraphQLQuery } from "../../../../hooks/useGraphQL";

interface VideoInterview {
  score: number;
  video_interview_statuses: { status: Video_Interview_Status_Enum }[];
}

const useOverviewVm = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  function calculateAverageScoreWithStatus(
    videoInterviews: VideoInterview[]
  ): number {
    const scoredInterviews = videoInterviews.filter((interview) =>
      interview.video_interview_statuses.some(
        (status) => status.status === Video_Interview_Status_Enum.Scored
      )
    );

    if (!scoredInterviews || scoredInterviews.length === 0) {
      return 0;
    }

    const totalScore = scoredInterviews.reduce((acc, interview) => {
      return acc + interview.score;
    }, 0);

    return Number((totalScore / scoredInterviews.length).toFixed(1));
  }

  function countCandidateByStatus(
    videoInterviews: VideoInterview[],
    interviewStatus: Video_Interview_Status_Enum
  ): number {
    const statsCount = videoInterviews.filter((interview) =>
      interview.video_interview_statuses.some(
        (status) => status.status === interviewStatus
      )
    ).length;

    return statsCount;
  }

  function countCandidateByLastStatus(
    videoInterviews: VideoInterview[],
    interviewStatus: Video_Interview_Status_Enum
  ): number {
    const statsCount = videoInterviews.filter((interview) => {
      const lastStatus = interview.video_interview_statuses.slice(-1)[0];
      return lastStatus && lastStatus.status === interviewStatus;
    }).length;

    return statsCount;
  }

  const {
    data: candidateOverviewData,
    isSuccess: isCandidateOverviewDataSuccess,
    refetch,
  } = useGetClientCandidateOverview(
    projectId!,
    Video_Interview_Type_Enum.CompetencyAssessment
  );
  const { data: projectsInterviewTemplate } = useGraphQLQuery(
    GetProjectInterviewTemplateByIdDocument,
    undefined,
    {
      id: projectId,
    }
  );
  const { mutateAsync: updateStatus } = useUpdateProjectStatus();
  const mergedOverviewData = useMemo(() => {
    if (
      candidateOverviewData?.video_interview_long_list &&
      candidateOverviewData?.video_interview_short_list
    ) {
      function mergeOverviewData(
        candidateOverviewData: GetClientCandidateOverviewQuery | undefined
      ) {
        const mergedList = {
          video_interview: [
            ...(candidateOverviewData?.video_interview_long_list ?? []),
            ...(candidateOverviewData?.video_interview_short_list ?? []),
          ],
          client_candidate_aggregate: {
            aggregate: {
              count:
                (candidateOverviewData?.client_candidate_aggregate_long_list
                  .aggregate?.count ?? 0) +
                (candidateOverviewData?.client_candidate_aggregate_short_list
                  .aggregate?.count ?? 0),
            },
          },
        };
        return mergedList;
      }

      return mergeOverviewData(candidateOverviewData);
    }
  }, [candidateOverviewData]);

  const longListOverviewData: NSStatsOverviewType.IStatsOverview[] = [
    {
      text: i18n.t("projects.numberOfLonglistedCandidates"),
      label: String(
        candidateOverviewData?.client_candidate_aggregate_long_list.aggregate
          ?.count
      ),
    },
    {
      text: i18n.t("projects.averageScoreOfLonglistedCanditates"),
      label: `%${calculateAverageScoreWithStatus(
        candidateOverviewData?.video_interview_long_list ?? []
      )}`,
    },
  ];

  const shortListOverviewData: NSStatsOverviewType.IStatsOverview[] = [
    {
      text: i18n.t("projects.numberOfShortlistedCandidates"),
      label: String(
        candidateOverviewData?.client_candidate_aggregate_short_list.aggregate
          ?.count
      ),
      color: "green",
    },
    {
      text: i18n.t("projects.averageScoreOfShortlistedCanditates"),
      label: `%${calculateAverageScoreWithStatus(
        candidateOverviewData?.video_interview_short_list ?? []
      )}`,
      color: "green",
    },
  ];

  const chartListOverviewData: NSStatsChartListType.IStatChartList = [
    {
      title: i18n.t("projects.numberOfInvitationsFromLongList"),
      totalValue:
        candidateOverviewData?.client_candidate_aggregate_long_list.aggregate
          ?.count ?? 0,
      statValue: countCandidateByStatus(
        candidateOverviewData?.video_interview_long_list ?? [],
        Video_Interview_Status_Enum.LinkSent
      ),
    },
    {
      title: i18n.t("projects.numberOfCandidatesSelectedJobSearchNotActive"),
      totalValue:
        mergedOverviewData?.client_candidate_aggregate.aggregate?.count ?? 0,
      statValue: countCandidateByLastStatus(
        mergedOverviewData?.video_interview ?? [],
        Video_Interview_Status_Enum.CandidateNotInterested
      ),
    },
    {
      title: i18n.t("projects.numberOfCandidatesReferredForInterview"),
      totalValue:
        mergedOverviewData?.client_candidate_aggregate.aggregate?.count ?? 0,
      statValue: countCandidateByStatus(
        mergedOverviewData?.video_interview ?? [],
        Video_Interview_Status_Enum.CandidateInProgress
      ),
    },
    {
      title: i18n.t("projects.numberOfCandidatesCompletedInterview"),
      totalValue:
        mergedOverviewData?.client_candidate_aggregate.aggregate?.count ?? 0,
      statValue: countCandidateByStatus(
        mergedOverviewData?.video_interview ?? [],
        Video_Interview_Status_Enum.Scored
      ),
    },
  ];

  const projectDetailOverViewData = useMemo(
    () => ({
      projectName:
        candidateOverviewData?.project_by_pk?.project_name ?? ("" as string),
      description:
        candidateOverviewData?.project_by_pk?.description ?? ("" as string),
      clientId: candidateOverviewData?.project_by_pk?.client.id,
      summary: {
        deadline: candidateOverviewData?.project_by_pk?.end_date as Date,
        client: candidateOverviewData?.project_by_pk?.client.client_name ?? "",
        status: candidateOverviewData?.project_by_pk
          ?.status as Project_Status_Enum,
        user: {
          avatarUrl: candidateOverviewData?.project_by_pk?.owner.avatar ?? "",
          name: `${candidateOverviewData?.project_by_pk?.owner.name} ${candidateOverviewData?.project_by_pk?.owner.surname}`,
        },
        created: candidateOverviewData?.project_by_pk?.created_at as Date,
      },
    }),
    [candidateOverviewData]
  );

  const onChangeStatus = useCallback(
    (status: Project_Status_Enum) => {
      updateStatus({ status, projectId })
        .then(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.statusChangeSuccess")}
              type="success"
            />,

            {
              id: "statusChangeSuccess",
            }
          );
          refetch();
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.statusChangeError")}
              type="error"
            />,

            {
              id: "statusChangeSJdError",
            }
          );
        });
    },
    [projectId, refetch, updateStatus]
  );

  const onClickCreateInterview = () => {
    navigate(`/projects/create-interview?projectId=${projectId}`);
  };

  const isOverviewLoading = useMemo(
    () => !isCandidateOverviewDataSuccess || !mergedOverviewData,
    [isCandidateOverviewDataSuccess]
  );

  return {
    isOverviewLoading,
    longListOverviewData,
    shortListOverviewData,
    chartListOverviewData,
    projectDetailOverViewData,
    projectsInterviewTemplate:
      projectsInterviewTemplate?.project_by_pk?.interview_template_id,
    onChangeStatus,
    onClickCreateInterview,
  };
};

export default useOverviewVm;
