import { useCallback, useState } from "react";
import ContextMenu from "../ContextMenu/ContextMenu";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { NSContextMenuHOCStyle } from "./ContextMenuHOC.style";
import { NSContextMenuHOCType } from "./ContextMenuHOC.type";
import { NSContextMenuType } from "../ContextMenu/ContextMenu.type";
import { DEFAULT_LOCALE } from "../../utils/globalConstants";

function ContextMenuHOC({
  id,
  children,
  items,
  type,
  buttonLabel,
  isScrollable,
  title,
  showSearch,
  containAddNewItemButton,
  shouldStopPropagation,
  customButton,
  onItemClick,
  onCheckedItems,
  onContextMenuOpen,
}: NSContextMenuHOCType.IContextMenuHOC) {
  const [searchText, setSearchText] = useState<string>("");
  const [contextMenuItems, setContexMenuItems] =
    useState<NSContextMenuType.IContextMenuItem[]>(items);

  const setSearchTextFunction = useCallback(
    (text: string) => {
      setSearchText(text);
      const filteredItems = items
        .filter((i: NSContextMenuType.IContextMenuItem) =>
          i.label
            ?.toLocaleLowerCase(DEFAULT_LOCALE)
            .includes(text.toLocaleLowerCase(DEFAULT_LOCALE))
        )
        .map((item: NSContextMenuType.IContextMenuItem) => ({
          ...item,
        }));
      setContexMenuItems(filteredItems);
    },
    [items]
  );

  const onAddNewItem = useCallback(
    (newItems: NSContextMenuType.IContextMenuItem[]) => {
      setContexMenuItems(newItems);
    },
    []
  );

  return (
    <NSContextMenuHOCStyle.Container>
      {children}
      {contextMenuItems && (
        <ContextMenu
          id={id}
          items={contextMenuItems}
          onItemClick={onItemClick}
          type={type}
          buttonLabel={buttonLabel}
          isScrollable={isScrollable}
          title={title}
          showSearch={showSearch}
          containAddNewItemButton={containAddNewItemButton}
          onCheckedItems={onCheckedItems}
          onContextMenuOpen={onContextMenuOpen}
          searchText={searchText}
          setSearchTextFunction={setSearchTextFunction}
          onAddNewItem={onAddNewItem}
          shouldStopPropagation={shouldStopPropagation}
        >
          {customButton ?? (
            <GhostIconButton
              onClick={() => setContexMenuItems(items)}
              icon="KebabVertical"
              iconSize={24}
            />
          )}
        </ContextMenu>
      )}
    </NSContextMenuHOCStyle.Container>
  );
}

export default ContextMenuHOC;
