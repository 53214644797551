import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { object, string } from "yup";
import i18n from "../../../../../library/i18next";
import { useCallback, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useGraphQLMutation,
  useGraphQLQuery,
} from "../../../../../hooks/useGraphQL";
import {
  DeleteClientCandidateProjectNoteDocument,
  GetClientCandidateProjectNoteDocument,
  UpdateClientCandidateProjectNoteDocument,
} from "../../../../../gql/graphql";
import toast from "react-hot-toast";
import ToastAlert from "../../../../../components/ToastAlert/ToastAlert";
import { NSAddNoteType } from "../Add-Note/Add-Note.type";

const useAllNotesVm = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { candidateProjectId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, candidateId } = useParams();
  const selectedNoteId = Number(searchParams.get("noteId"));
  const { data: notesData, refetch } = useGraphQLQuery(
    GetClientCandidateProjectNoteDocument,
    undefined,
    {
      id: Number(candidateProjectId),
    }
  );

  const { mutateAsync: updateNote } = useGraphQLMutation(
    UpdateClientCandidateProjectNoteDocument
  );

  const { mutateAsync: deleteNote } = useGraphQLMutation(
    DeleteClientCandidateProjectNoteDocument
  );

  const addNoteSchema: NSAddNoteType.IAddNoteFormValues | any = object({
    note: string().required(i18n.t("forms.addNoteError")),
  }).required();

  const { control, handleSubmit, setValue } =
    useForm<NSAddNoteType.IAddNoteFormValues>({
      resolver: yupResolver(addNoteSchema),
    });

  const updateNoteAction = (note: string) => {
    updateNote({
      id: Number(selectedNoteId),
      note,
    })
      .then(async (_) => {
        toast(
          <ToastAlert
            description={i18n.t("projects.noteUpdateSuccess")}
            type="success"
          />,

          {
            id: "noteUpdateSuccess",
          }
        );
        setValue("note", "");
        setIsEditing(false);
        navigate(window.location.pathname.split("?")[0], {
          state: { projectId, candidateId },
        });
        await refetch();
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("projects.noteUpdateError")}
            type="error"
          />,

          {
            id: "noteUpdateError",
          }
        );
      });
  };

  const onSubmit: SubmitHandler<NSAddNoteType.IAddNoteFormValues> = useCallback(
    (data, event) => {
      event?.preventDefault();
      updateNoteAction(data.note);
    },
    [updateNote, isEditing]
  );

  const submitHandler = () => handleSubmit(onSubmit);

  const handleEditNote = (id: number) => {
    navigate(`?noteId=${id}`, {
      state: { projectId, candidateId },
    });
    const note =
      notesData?.client_candidate_project[0].client_candidate_project_notes.find(
        (item) => item.id === id
      )?.note;

    setIsEditing(true);
    setValue("note", note!);
  };

  const handleDeleteNote = useCallback(
    async (id: number) => {
      deleteNote({
        id,
      })
        .then(async () => {
          toast(
            <ToastAlert
              description={i18n.t("projects.noteDeleteSuccess")}
              type="success"
            />,

            {
              id: "noteDeleteSuccess",
            }
          );
          await refetch();
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.noteDeleteError")}
              type="error"
            />,
            {
              id: "noteDeleteError",
            }
          );
        });
    },

    [deleteNote]
  );

  return {
    notes:
      notesData?.client_candidate_project[0].client_candidate_project_notes,
    control,
    selectedNoteId,
    handleSubmit: submitHandler,
    handleEditNote,
    handleDeleteNote,
  };
};

export default useAllNotesVm;
