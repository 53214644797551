import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { Video_Interview_Status_Enum } from "../../../gql/graphql";
import i18n from "../../../library/i18next";
import useGetVideoInterview from "../../../service/VideoInterview/getVideoInterview";
import useGetVideoInterviewVideos from "../../../service/VideoInterview/getVideoInterviewVideos";
import useInsertVideoInterviewStatus from "../../../service/VideoInterview/insertVideoInterviewStatus";

const useCandidateVideoPreviewVm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const interviewId = useMemo(() => state.interviewId, [state]);
  const { projectId } = state;

  const { mutateAsync } = useInsertVideoInterviewStatus();

  const { data } = useGetVideoInterview(interviewId);

  const closeHandler = useCallback(() => {
    navigate(-1);
  }, [interviewId, navigate, projectId]);

  const onClickStartButton = useCallback(() => {
    navigate(
      `/projects/detail/${projectId}/candidate/candidate-reviewer-questions/${interviewId}`,
      {
        state: { projectId, interviewId },
      }
    );
  }, [interviewId, navigate, projectId]);

  const onClickInsufficientContent = useCallback(() => {
    mutateAsync({
      vid: interviewId,
      status: Video_Interview_Status_Enum.InsufficientContent,
    })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("videoInterview.insufficientContentSuccess")}
            type="success"
          />,

          {
            id: "insufficientContentSuccess",
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("videoInterview.insufficientContentError")}
            type="error"
          />,

          {
            id: "insufficientContentError",
          }
        );
      });
  }, [interviewId, mutateAsync]);

  const onClickInappropriateContent = useCallback(() => {
    mutateAsync({
      vid: interviewId,
      status: Video_Interview_Status_Enum.InappropriateContent,
    })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("videoInterview.inappropriateContentSuccess")}
            type="success"
          />,

          {
            id: "inappropriateContentSuccess",
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("videoInterview.inappropriateContentError")}
            type="error"
          />,

          {
            id: "inappropriateContentError",
          }
        );
      });
  }, [interviewId, mutateAsync]);

  const candidateName = useMemo(
    () =>
      `${data?.video_interview_by_pk?.client_candidate_project.client_candidate.name} ${data?.video_interview_by_pk?.client_candidate_project.client_candidate.surname}`,
    [
      data?.video_interview_by_pk?.client_candidate_project.client_candidate
        .name,
      data?.video_interview_by_pk?.client_candidate_project.client_candidate
        .surname,
    ]
  );

  const description = i18n.t("candidate.description");
  const videoFilenames = data?.video_interview_by_pk?.video_interview_questions
    .filter((q) => q.review_metric_question.is_shown_to_candidate)
    .map(
      (viteoItem) =>
        `${data?.video_interview_by_pk?.id}-${viteoItem.review_metric_question.id}.mp4`
    );

  const { data: videoUrls } = useGetVideoInterviewVideos(videoFilenames || []);

  return {
    closeHandler,
    description,
    candidateName,
    videoUrls,
    onClickStartButton,
    onClickInappropriateContent,
    onClickInsufficientContent,
  };
};

export default useCandidateVideoPreviewVm;
