import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { jsPDF } from "jspdf";
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from "html2canvas";
import useGetVideoInterview from "../../../service/VideoInterview/getVideoInterview";
import i18n from "../../../library/i18next";
import dayjs from "../../../library/dayjs";
import useGetTestInterview from "../../../service/TestInterview/getTestInterview.graphql";
import { NSCandidateListType } from "../../Project/Detail/Candidate-List/Candidate-List.type";

// eslint-disable-next-line import/no-extraneous-dependencies

const useCandidateScoreResultVm = () => {
  const navigate = useNavigate();
  const { projectId, interviewId, baseType } = useParams();
  const { data: videoInterviewData } = useGetVideoInterview(
    interviewId as string
  );
  const { data: testInterviewData } = useGetTestInterview(
    interviewId as string
  );

  const levels = {
    30: "A1",
    50: "A2",
    60: "B1",
    75: "B2",
    84: "C1",
    100: "C2",
  };

  const getLevel = (score: number): string => {
    const levelKeys = Object.keys(levels) as unknown as (keyof typeof levels)[];
    const levelKey = levelKeys.find(
      (key) => score <= Number(key)
    ) as keyof typeof levels;
    return levels[levelKey];
  };

  const data =
    baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
      ? testInterviewData
      : videoInterviewData;
  const closeHandler = useCallback(() => {
    navigate(`/projects/detail/${projectId}/long-list`, {
      state: { projectId },
    });
  }, [navigate, projectId]);

  const metricsAndReviewerSelectedOptions = useMemo(() => {
    const metricsAndReviewerData =
      baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
        ? testInterviewData?.video_interview_criterion_score.map((item) => {
            return {
              label: item.interview_criterion.name,
              response: item.score_description,
              weight: 1,
              value: item.score,
              isUserReview: false,
            };
          })
        : videoInterviewData?.video_interview_by_pk?.video_interview_questions.map(
            (item, index) => {
              const userSelectAnswer = item.video_interview_score.find(
                (i) => i.is_ai_score === false
              )?.review_metric_question_response_id;

              const isUserSelect = item.video_interview_score.length === 2;

              const aiSelectAnswer = item.video_interview_score.find(
                (i) => i.is_ai_score === true
              )?.review_metric_question_response_id;

              const userSelectAnswerValue =
                item.review_metric_question.review_metric_question_responses.find(
                  (i) => i.id === userSelectAnswer
                )?.score;

              const aiSelectAnswerValue =
                item.review_metric_question.review_metric_question_responses.find(
                  (i) => i.id === aiSelectAnswer
                )?.score;

              return {
                label: item.review_metric_question.review_metric.description,
                response: isUserSelect
                  ? item.video_interview_score.find(
                      (i) => i.is_ai_score === false
                    )?.score_description
                  : item.video_interview_score.find(
                      (i) => i.is_ai_score === true
                    )?.score_description,
                isUserReview: item.video_interview_score.some(
                  (i) => i.is_ai_score === false
                ),
                value: userSelectAnswerValue ?? aiSelectAnswerValue ?? 0,
                weight:
                  videoInterviewData?.video_interview_by_pk
                    ?.client_candidate_project.project.interview_template
                    ?.interview_template_review_metric_questions[index]
                    ?.weight ?? 1,
              };
            }
          );

    return metricsAndReviewerData ?? [];
  }, [data]);

  const barChartData = useMemo(() => {
    const barChart = metricsAndReviewerSelectedOptions
      ?.filter((item) => item?.weight > 0)
      .map((item) => ({
        label: item.label,
        value: item.value,
      }));
    return barChart ?? [];
  }, [metricsAndReviewerSelectedOptions]);

  interface explanationDataType {
    label: string;
    response: string;
    isUserReview: boolean;
  }
  const explanitionData: explanationDataType[] | undefined = useMemo(() => {
    const explanition = metricsAndReviewerSelectedOptions?.map((item) => ({
      label: item.label,
      response: item.response ?? "",
      isUserReview: item.isUserReview ?? false,
    }));
    return explanition ?? [];
  }, [metricsAndReviewerSelectedOptions]);

  const score = videoInterviewData?.video_interview_by_pk?.score ?? 0;

  const scoreText = useMemo(() => {
    const scoreTexts = {
      0: i18n.t("projects.candidateScoreText0"),
      1: i18n.t("projects.candidateScoreText1"),
      2: i18n.t("projects.candidateScoreText2"),
      3: i18n.t("projects.candidateScoreText3"),
    } as Record<number, string>;
    const index = Math.abs(Math.floor((score - 1) / 25));
    return scoreTexts[index];
  }, [score]);
  const projectName = useMemo(
    () =>
      videoInterviewData?.video_interview_by_pk?.client_candidate_project
        .project.project_name,
    [videoInterviewData]
  );
  const candidateNameSurname = useMemo(
    () =>
      `${videoInterviewData?.video_interview_by_pk?.client_candidate_project.client_candidate.name} ${videoInterviewData?.video_interview_by_pk?.client_candidate_project.client_candidate.surname}`,
    [videoInterviewData]
  );
  const candidateClientAndProjectName = useMemo(
    () =>
      `${videoInterviewData?.video_interview_by_pk?.client_candidate_project.client_candidate.client.client_name} - ${videoInterviewData?.video_interview_by_pk?.client_candidate_project.project.project_name}`,
    [videoInterviewData]
  );

  const interviewTime = useMemo(
    () =>
      `${dayjs(videoInterviewData?.video_interview_by_pk?.completed_at).format(
        "DD/MM/YYYY[,] hh:mma"
      )}`,
    [videoInterviewData]
  );

  const reviewerText = useMemo(() => {
    const isReviewed =
      videoInterviewData?.video_interview_by_pk?.video_interview_questions
        .find((i) => i.video_interview_score.length > 1)
        ?.video_interview_score.find((item) => item.is_ai_score === false)
        ?.video_interview_scored_by?.name;

    const text = isReviewed
      ? `Reviewed by AI + ${isReviewed}`
      : `Reviewed by AI`;

    return text;
  }, [videoInterviewData]);

  const isLoading = !barChartData.length;

  const downloadButton = () => {
    const content = document.getElementById("content") as HTMLElement;
    const innerContainer = document.getElementById(
      "innerContainer"
    ) as HTMLElement;
    const fullHeight = innerContainer.offsetHeight + 224;

    html2canvas(content, {
      height: fullHeight,
      windowHeight: fullHeight,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("portrait", "pt", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, 600, 850);
        pdf.save(
          `${candidateNameSurname.replace(" ", "") ?? "generatedJob"}.pdf`
        );
      })
      .then(() => {})
      .catch(() => {});
  };

  const onClickRestartButton = useCallback(() => {
    navigate(
      `/projects/detail/${projectId}/candidate/candidate-reviewer-questions/${interviewId}`,
      {
        state: { projectId, interviewId },
      }
    );
  }, [interviewId, navigate, projectId]);

  const onClickVideoPreview = useCallback(() => {
    navigate(
      `/projects/detail/${projectId}/${
        baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
          ? ""
          : "candidate/candidate-"
      }video-preview/${interviewId}`,
      {
        state: { projectId, interviewId },
      }
    );
  }, [interviewId, navigate, projectId]);

  return {
    barChartData,
    isLoading,
    score,
    level: getLevel(score),
    baseType,
    scoreText,
    projectName,
    candidateNameSurname,
    explanitionData,
    candidateClientAndProjectName,
    interviewTime,
    reviewerText,
    onClickRestartButton,
    onClickVideoPreview,
    closeHandler,
    downloadButton,
  };
};

export default useCandidateScoreResultVm;
