import { NSRateSliderStyle } from "./RateSlider.style";
import { NSRateSliderType } from "./RateSlider.type";

function RateSlider({
  label,
  onRateClicked,
  selectedRate,
}: NSRateSliderType.IRateSlider) {
  const pills = [...Array(5).keys()];

  const pillClickedHandler = (clickedRate: number) => {
    onRateClicked?.(clickedRate);
  };

  return (
    <NSRateSliderStyle.Container>
      <NSRateSliderStyle.RateLabel>{label}</NSRateSliderStyle.RateLabel>
      <NSRateSliderStyle.RatePillContainer>
        {pills.map((item) => (
          <NSRateSliderStyle.RatePill
            key={item}
            onClick={() => pillClickedHandler(item + 1)}
            isActive={selectedRate >= item + 1}
          />
        ))}
      </NSRateSliderStyle.RatePillContainer>
    </NSRateSliderStyle.Container>
  );
}

export default RateSlider;
