import styled from "styled-components";

export namespace NSJobDescriptionListItemStyle {
  export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.neutrals.grey01};
    font-family: greycliff-cf, sans-serif;
    padding: 18px 14px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    border: 2px solid ${(props) => props.theme.colors.light.light};

    :hover {
      border-color: ${(props) => props.theme.colors.primary.primary};
      background-color: ${(props) => props.theme.colors.light.light};
    }
  `;

  export const LeftItems = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  `;

  export const JobDescriptionName = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: hsla(240, 7%, 16%, 1);
  `;
}
