import {
  Project_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../gql/graphql";

export namespace NSStatusBadgeType {
  export interface IStatusBadge {
    status?: Project_Status_Enum;
    label?: string;
    color?: dotColor;
    type?: Video_Interview_Type_Enum;
  }

  export enum dotColor {
    green = "hsla(137, 93%, 38%, 1)",
    yellow = "hsla(39, 85%, 59%, 1)",
    red = "hsla(349, 73%, 56%, 1)",
    grey = "hsla(217, 12%, 78%, 1)",
    blue = "hsla(209, 100%, 46%, 1)",
  }
}
