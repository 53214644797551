import { useCallback } from "react";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useInviteMember } from "../../../service/Users/inviteMember";
import { NSInviteMemberType } from "./Invite-Member.type";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";

const inviteMemberSchema = object({
  memberName: string().required("Please enter member name"),
  memberSurname: string().required("Please enter member surname"),
  emailAddress: string().required("Please enter email address"),
}).required();

const useInviteMemberVm = () => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<NSInviteMemberType.IInviteMemberFormValues>({
    resolver: yupResolver(inviteMemberSchema),
    shouldFocusError: false,
  });

  const navigate = useNavigate();
  const closeHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { inviteMember } = useInviteMember();

  const onSubmit: SubmitHandler<NSInviteMemberType.IInviteMemberFormValues> =
    useCallback(
      async (data, event) => {
        event?.preventDefault();
        await inviteMember({
          email: data.emailAddress,
          name: data.memberName,
          surname: data.memberSurname,
        })
          .then((res) => {
            if (res.inviteMember?.success) {
              toast(
                <ToastAlert
                  description={i18n.t("team.memberInviteSuccess")}
                  type="success"
                />,
                {
                  id: "inviteMemberSuccess",
                }
              );
              navigate(-1);
            } else {
              toast(
                <ToastAlert
                  description={i18n.t("team.memberInviteError")}
                  type="error"
                />,
                {
                  id: "inviteMemberError",
                }
              );
            }
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("team.memberInviteError")}
                type="error"
              />,
              {
                id: "inviteMemberError",
              }
            );
          });
      },
      [inviteMember, navigate]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  return {
    closeHandler,
    formErrors: errors,
    setValue,
    handleSubmit: submitHandler,
  };
};

export default useInviteMemberVm;
