import { GetTestInterviewDocument } from "../../gql/graphql";

import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetTestInterview(id: string) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetTestInterviewDocument,
    undefined,
    { id }
  );

  return {
    refetch,
    data,
    rest,
  };
}
export default useGetTestInterview;
