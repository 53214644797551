import { useCallback } from "react";
import Dropdown from "../Dropdown/Dropdown";
import TextInput from "../TextInput/TextInput";
import { NSJDGSalarySelectionStyle } from "./JDGSalarySelection.style";
import { NSJDGSalarySelectionType } from "./JDGSalarySelection.type";
import i18n from "../../library/i18next";

function JDGSalarySelection({
  onSelectPeriod,
  onSelectCurrency,
  onChangeMinSalary,
  onChangeMaxSalary,
  isValidationErrorActive,
  minSalary,
  maxSalary,
}: NSJDGSalarySelectionType.IJDGSalarySelection) {
  const timeDropdown = [
    { id: "monthly", label: i18n.t("jd.monthly") },
    { id: "yearly", label: i18n.t("jd.yearly") },
  ];

  const currencyDropdown = [
    { id: "usd", label: "$" },
    { id: "euro", label: "€" },
    { id: "tl", label: "₺" },
  ];

  const onChangeNumberInput = useCallback(
    (value: string) => value.replace(/[^\d,.]/g, ""),
    []
  );

  return (
    <NSJDGSalarySelectionStyle.Container>
      <Dropdown
        id={1}
        selectedDropdownItem={timeDropdown[0]}
        options={timeDropdown}
        type="basic"
        onSelect={onSelectPeriod}
      />
      <Dropdown
        id={2}
        selectedDropdownItem={currencyDropdown[0]}
        options={currencyDropdown}
        type="basic"
        onSelect={onSelectCurrency}
      />
      <NSJDGSalarySelectionStyle.InputContainer>
        <TextInput
          value={minSalary ? minSalary?.toString() : ""}
          id="minSalaryInputId"
          name="minSalaryInputName"
          placeholder={i18n.t("jd.min")}
          onChange={(value) => onChangeMinSalary(onChangeNumberInput(value))}
          error={isValidationErrorActive}
          errorText={i18n.t("jd.minError")}
        />
      </NSJDGSalarySelectionStyle.InputContainer>

      <NSJDGSalarySelectionStyle.InputContainer>
        <TextInput
          value={maxSalary ? maxSalary?.toString() : ""}
          id="maxSalaryInputId"
          name="maxSalaryInputName"
          placeholder={i18n.t("jd.max")}
          onChange={(value) => onChangeMaxSalary(onChangeNumberInput(value))}
          error={isValidationErrorActive}
          errorText={i18n.t("jd.maxError")}
        />
      </NSJDGSalarySelectionStyle.InputContainer>
    </NSJDGSalarySelectionStyle.Container>
  );
}

export default JDGSalarySelection;
