import { ButtonColorVariant } from "../MainButton/MainButton.type";
import { ISolidButton } from "./SolidButton.type";
import MainButton from "../MainButton/MainButton";
import { NSSolidButtonStyle } from "./SolidButton.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

function SolidButton({
  backgroundColor = ButtonColorVariant.primary,
  variant = "basic",
  fullWidth,
  disabled,
  onClick,
  size,
  label,
  iconColor,
  suffixIcon,
  type,
  isLoading = false,
  prefixIcon,
}: ISolidButton) {
  const loadingContent = (
    <NSMainButtonStyle.ProgressBar>
      <NSMainButtonStyle.CircleLoader />
    </NSMainButtonStyle.ProgressBar>
  );

  return (
    <NSSolidButtonStyle.SolidButtonBase
      fullWidth={fullWidth}
      isLoading={isLoading}
      backgroundColor={backgroundColor}
    >
      <MainButton
        loadingContent={loadingContent}
        fullWidth={fullWidth}
        variant={variant}
        prefixIcon={prefixIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
        label={label}
        type={type}
        iconColor={iconColor}
        suffixIcon={suffixIcon}
        isLoading={isLoading}
      />
    </NSSolidButtonStyle.SolidButtonBase>
  );
}
export default SolidButton;
