import { NSStatusBadgeType } from "../components/StatusBadge/StatusBadge.type";
import i18n from "../library/i18next";
import { NSCandidateListType } from "../routes/Project/Detail/Candidate-List/Candidate-List.type";

const candidateStatusHelper = (status: string, score?: string) => {
  let statusTypeProps;
  switch (status) {
    case NSCandidateListType.InterviewStatusType.CANDIDATE_IN_PROGRESS:
      statusTypeProps = {
        label: i18n.t("projects.interviewStarted"),
        color: NSStatusBadgeType.dotColor.yellow,
      };
      break;
    case NSCandidateListType.InterviewStatusType.EXPIRED:
      statusTypeProps = {
        label: i18n.t("projects.expired"),
        color: NSStatusBadgeType.dotColor.grey,
      };
      break;
    case NSCandidateListType.InterviewStatusType.INAPPROPRIATE_CONTENT:
      statusTypeProps = {
        label: i18n.t("projects.inappropriateContent"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;
    case NSCandidateListType.InterviewStatusType.INSUFFICIENT_CONTENT:
      statusTypeProps = {
        label: i18n.t("projects.insufficientContent"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.InterviewStatusType.IN_REVIEW:
      statusTypeProps = {
        label: i18n.t("projects.inReview"),
        color: NSStatusBadgeType.dotColor.yellow,
      };
      break;

    case NSCandidateListType.InterviewStatusType.LINK_SENT:
      statusTypeProps = {
        label: i18n.t("projects.invitationSend"),
        color: NSStatusBadgeType.dotColor.blue,
      };
      break;

    case NSCandidateListType.InterviewStatusType.CANDIDATE_NOT_INTERESTED:
      statusTypeProps = {
        label: i18n.t("projects.candidateNotInterested"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.InterviewStatusType.NOT_SET:
      statusTypeProps = {
        label: i18n.t("projects.notActionYet"),
        color: NSStatusBadgeType.dotColor.grey,
      };
      break;

    case NSCandidateListType.InterviewStatusType.SCORED:
      statusTypeProps = {
        label: i18n.t("projects.score", {
          score,
        }),
        color: NSStatusBadgeType.dotColor.green,
      };
      break;

    case NSCandidateListType.InterviewStatusType.VIDEO_SENT:
      statusTypeProps = {
        label: i18n.t("projects.interviewCompleted"),
        color: NSStatusBadgeType.dotColor.blue,
      };
      break;

    case NSCandidateListType.InterviewStatusType.VIDEO_RECEIVED:
      statusTypeProps = {
        label: i18n.t("projects.interviewCompleted"),
        color: NSStatusBadgeType.dotColor.blue,
      };
      break;

    case NSCandidateListType.InterviewStatusType.NOT_COMPLETED:
      statusTypeProps = {
        label: i18n.t("projects.notCompleted"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.CANDIDATE_WITHDREW:
      statusTypeProps = {
        label: i18n.t("projects.candidateWithdrew"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.DOES_NOT_MEET_CRITERIA:
      statusTypeProps = {
        label: i18n.t("projects.doesNotMeetCriteria"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.HIRED:
      statusTypeProps = {
        label: i18n.t("projects.hired"),
        color: NSStatusBadgeType.dotColor.green,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.INTERVIEW_SUCCESSFUL:
      statusTypeProps = {
        label: i18n.t("projects.interviewSuccessful"),
        color: NSStatusBadgeType.dotColor.green,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.INTERVIEW_UNSUCCESSFUL:
      statusTypeProps = {
        label: i18n.t("projects.interviewUnsuccessful"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.NO_SHOW:
      statusTypeProps = {
        label: i18n.t("projects.noShow"),
        color: NSStatusBadgeType.dotColor.grey,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.OFFER_DECLINED:
      statusTypeProps = {
        label: i18n.t("projects.offerDeclined"),
        color: NSStatusBadgeType.dotColor.red,
      };
      break;

    case NSCandidateListType.CandidateResolutionType.OFFER_MADE:
      statusTypeProps = {
        label: i18n.t("projects.offerMade"),
        color: NSStatusBadgeType.dotColor.blue,
      };
      break;

    default:
      statusTypeProps = {
        label: i18n.t("projects.notActionYet"),
        color: NSStatusBadgeType.dotColor.grey,
      };
      break;
  }

  return statusTypeProps;
};
export default candidateStatusHelper;
