import i18n from "../../../../library/i18next";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import Logo from "../../../../components/Logo/Logo";
import Icon from "../../../../components/Icon/Icon";
import DragAndDropList from "../../../../components/DragAndDropList/DragAndDropList";
import { NSCreateInterviewStyle } from "../../../Interview-Create/Interview-Create.style";
import useInterviewDetailVM from "../../Interview-DetailVM";
import Loading from "../../../../components/Loading/Loading";

const ReviewMetricSelectionDetail = () => {
  const {
    projectName,
    metricItems,
    isSelectedCompetenciesValid,
    isLoadingReviewMetrics,
    setMetricItems,
    onClickGoToQuestionSelectionStep,
  } = useInterviewDetailVM();
  return isLoadingReviewMetrics ? (
    <Loading />
  ) : (
    <NSCreateInterviewStyle.Wrapper>
      <NSCreateInterviewStyle.Header hasMargin>
        <Logo type="onlyIcon" />
        <NSCreateInterviewStyle.ProjectNameBadge>
          <Icon name="Folders" />
          <NSCreateInterviewStyle.ProjectName>
            {projectName}
          </NSCreateInterviewStyle.ProjectName>
        </NSCreateInterviewStyle.ProjectNameBadge>
      </NSCreateInterviewStyle.Header>

      <NSCreateInterviewStyle.Content>
        <NSCreateInterviewStyle.ContentTitle>
          {i18n.t("interview.roleCompetencies")}
        </NSCreateInterviewStyle.ContentTitle>
        <NSCreateInterviewStyle.ContentSubTitle>
          {i18n.t("interview.roleCompetenciesSubTitle")}
        </NSCreateInterviewStyle.ContentSubTitle>
        <NSCreateInterviewStyle.DragAndDropListContainer>
          <DragAndDropList
            isEditable={false}
            list={metricItems}
            setItems={setMetricItems}
          />
        </NSCreateInterviewStyle.DragAndDropListContainer>
      </NSCreateInterviewStyle.Content>
      <NSCreateInterviewStyle.Fragment />
      <NSCreateInterviewStyle.Footer>
        <NSCreateInterviewStyle.FooterContainer>
          <SolidButton
            label={i18n.t("interview.goToQuestionSelectionStep")}
            suffixIcon="ArrowLeft"
            disabled={!isSelectedCompetenciesValid}
            onClick={onClickGoToQuestionSelectionStep}
            variant="label-w-icon-suffix"
          />
        </NSCreateInterviewStyle.FooterContainer>
      </NSCreateInterviewStyle.Footer>
    </NSCreateInterviewStyle.Wrapper>
  );
};

export default ReviewMetricSelectionDetail;
