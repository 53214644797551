import { useCallback, useEffect, useRef, useState } from "react";
import Icon from "../Icon/Icon";
import { NSContextMenuStyle } from "./ContextMenu.style";
import { NSContextMenuType } from "./ContextMenu.type";
import colors from "../../style/colors";

function CascadingContextMenu({
  id,
  items,
  divider,
  onClickItem,
}: NSContextMenuType.IInnerContextMenu) {
  const [hoveredItem, setHoveredItem] = useState<number>();
  const [contextMenuWidth, setContextMenuWidth] = useState<number>();
  const [openDirection, setOpenDirection] = useState<"left" | "right">("right");

  const contextMenu = useRef(null as null | HTMLDivElement);

  useEffect(() => {
    setContextMenuWidth(contextMenu.current?.offsetWidth);

    const handleResize = () => {
      setContextMenuWidth(contextMenu.current?.offsetWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const checkOpenDirection = () => {
      const contextMenuRect = contextMenu.current?.getBoundingClientRect();
      if (contextMenuRect) {
        const windowWidth = window.innerWidth;
        if (contextMenuRect.right + (contextMenuWidth ?? 0) > windowWidth) {
          setOpenDirection("left");
        } else {
          setOpenDirection("right");
        }
      }
    };

    checkOpenDirection();
  }, [hoveredItem, contextMenuWidth]);

  const setHoveredItemFunction = useCallback((index: number) => {
    setHoveredItem(index);
  }, []);

  return (
    <NSContextMenuStyle.InnerComponentContainer key={Math.random()}>
      {items.map((item, index) => (
        <NSContextMenuStyle.InnerComponent key={Math.random()}>
          <div key={item.label}>
            {item.isSeperatedFromTop && divider}

            <NSContextMenuStyle.CascadingContextMenuItem
              isDisabled={item.isDisabled}
              key={item.label}
              onMouseEnter={() => setHoveredItemFunction(index)}
              onClick={(e) => {
                if (item.isDisabled) return;
                e.stopPropagation();
                item.onClick?.(index, id);
                onClickItem?.();
              }}
              ref={contextMenu}
            >
              {item.iconName && (
                <Icon
                  color={colors.neutrals.grey04}
                  width={24}
                  height={24}
                  name={item.iconName}
                />
              )}
              <NSContextMenuStyle.ItemLabel>
                {item.label}
              </NSContextMenuStyle.ItemLabel>
              {item.cascadingItem && (
                <Icon width={24} height={24} name="Right" />
              )}
            </NSContextMenuStyle.CascadingContextMenuItem>

            {hoveredItem === index && item.cascadingItem && (
              <NSContextMenuStyle.CascadingMenuContent
                width={contextMenuWidth}
                itemIndex={index}
                openDirection={openDirection}
              >
                {item.cascadingItem.map((cascadingItem) => (
                  <NSContextMenuStyle.ContextMenuItem
                    isDisabled={cascadingItem.isDisabled}
                    key={cascadingItem.label}
                    onClick={(e) => {
                      if (cascadingItem.isDisabled) return;
                      e.stopPropagation();
                      cascadingItem?.onClick?.(index, id as string);
                      onClickItem?.();
                    }}
                  >
                    {cascadingItem.iconName && (
                      <Icon
                        color={colors.neutrals.grey04}
                        width={24}
                        height={24}
                        name={cascadingItem.iconName}
                      />
                    )}
                    <NSContextMenuStyle.ItemLabel>
                      {cascadingItem.label}
                    </NSContextMenuStyle.ItemLabel>
                  </NSContextMenuStyle.ContextMenuItem>
                ))}
              </NSContextMenuStyle.CascadingMenuContent>
            )}
            {item.isSeperatedFromBottom && divider}
          </div>
        </NSContextMenuStyle.InnerComponent>
      ))}
    </NSContextMenuStyle.InnerComponentContainer>
  );
}

export default CascadingContextMenu;
