import styled from "styled-components";

export namespace NSDragAndDropListStyle {
  export const Container = styled.button``;
  export const SortableItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    //margin-bottom: 12px;
  `;
}
