import { Suspense, useMemo } from "react";

import CandidateInfoCard from "../../../../components/CandidateInfoCard/CandidateInfoCard";

import Drawer from "../../../../components/Drawer/Drawer";
import Icon from "../../../../components/Icon/Icon";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import { NSAddCandidateStyle } from "./Add-Candidate.style";
import useAddCandidateVm from "./Add-CandidateVm";
import CandidateSearchEmptyView from "../../../../components/CandidateSearchEmptyView/CandidateSearchEmptyView";
import i18n from "../../../../library/i18next";

export default function AddCandidate() {
  const addCandidateVM = useAddCandidateVm();

  const content = useMemo(() => {
    let innerContent;

    if (addCandidateVM.selectedCandidate) {
      innerContent = (
        <>
          <CandidateInfoCard
            candidateName={`${
              addCandidateVM.candidateData?.find(
                (item) => item.id === addCandidateVM.selectedCandidate
              )?.name ?? ""
            } ${
              addCandidateVM.candidateData?.find(
                (item) => item.id === addCandidateVM.selectedCandidate
              )?.surname ?? ""
            }`}
            phoneNumber={
              addCandidateVM.candidateData?.find(
                (item) => item.id === addCandidateVM.selectedCandidate
              )?.mobile_number ?? ""
            }
            emailAddress={
              addCandidateVM.candidateData?.find(
                (item) => item.id === addCandidateVM.selectedCandidate
              )?.email_address ?? ""
            }
          />

          <SolidButton
            onClick={addCandidateVM.onClickAddCandidate}
            variant="label-w-icon-prefix"
            prefixIcon="Add"
            label={i18n.t("projects.addCandidate")}
            fullWidth
          />
        </>
      );
    } else {
      innerContent = (
        <>
          <NSAddCandidateStyle.InputContainer>
            <SearchInput
              onChange={addCandidateVM.searchInput.onChange}
              showPrefix={addCandidateVM.searchInput.showLabel}
              label={addCandidateVM.searchInput.label}
              placeholder={addCandidateVM.searchInput.placeholder}
              id={addCandidateVM.searchInput.id}
              name={addCandidateVM.searchInput.name}
              showLabel={addCandidateVM.searchInput.showLabel}
              suffixItem={<Icon name="Search" />}
              value={addCandidateVM.searchInput.value}
            />
          </NSAddCandidateStyle.InputContainer>

          {addCandidateVM.searchString && (
            <>
              <NSAddCandidateStyle.SearchResultText>
                {i18n.t("general.searchResult")}
              </NSAddCandidateStyle.SearchResultText>
              <Suspense>
                <NSAddCandidateStyle.ResultContainer>
                  {addCandidateVM.candidateData?.map((item) => (
                    <NSAddCandidateStyle.ResultItem
                      onClick={() => addCandidateVM.onSelectCandidate(item.id)}
                      key={item.id}
                    >
                      {item.name} {item.surname}
                    </NSAddCandidateStyle.ResultItem>
                  ))}
                </NSAddCandidateStyle.ResultContainer>
              </Suspense>
            </>
          )}

          {addCandidateVM.isEmpty && <CandidateSearchEmptyView />}
          <SolidButton
            onClick={addCandidateVM.onClickCreateCandidate}
            label={i18n.t("projects.createNewCandidate")}
            fullWidth
          />
        </>
      );
    }
    return innerContent;
  }, [addCandidateVM]);

  return (
    <Drawer title={i18n.t("projects.addCandidate")}>
      <NSAddCandidateStyle.Container>{content}</NSAddCandidateStyle.Container>
    </Drawer>
  );
}
