import { GetInterviewTemplatesDropdownDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetInterviewTemplatesDropdown(companyId: string) {
  const { data, ...rest } = useGraphQLQuery(
    GetInterviewTemplatesDropdownDocument,
    undefined,
    {
      companyId,
    }
  );

  const interviewTemplates = data?.interview_template.map((item) => ({
    dropdown: {
      id: item.id,
      label: item.template_name,
    },
  }));

  return {
    interviewTemplates,
    rest,
  };
}

export default useGetInterviewTemplatesDropdown;
