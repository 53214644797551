import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";

export namespace NSDropdownWithCaptionStyle {
  export const Container = styled.div`
    width: 100%;
    ${NSDropdownStyle.Placeholder} {
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
      color: ${(props) => props.theme.colors.neutrals.grey09};
    }

    ${NSDropdownStyle.Placeholder} {
      color: ${(props) => props.theme.colors.neutrals.grey09};
    }
  `;

  export const Caption = styled.div`
    color: ${(props) => props.theme.colors.neutrals.grey05};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin-right: 8px;
  `;

  export const Seperator = styled.div`
    height: 24px;
    width: 1px;
    background-color: ${(props) => props.theme.colors.neutrals.grey04};
  `;

  export const PrefixContainer = styled.div`
    margin-right: 6px;
    display: flex;
    align-items: center;
  `;

  export const Badge = styled.div`
    display: flex;
    float: right;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    width: 32px;
    height: 32px;
    background-color: ${(props) => props.theme.colors.primary.primary};
    color: ${(props) => props.theme.colors.light.light};
  `;
}
