import { useLocation, useNavigate } from "react-router-dom";

import { useCallback } from "react";
import { JobDescriptionProvider } from "../../../context/JobDescriptionContext/JobDescriptionContext";
import Modal2 from "../../../components/Modal/Modal2";
import { NSCreateJobDescriptionStyle } from "./CreateJobDescription.style";
import QuestionModalRenderer from "./Views/QuestionModalRenderer";

function CreateJobDescription() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const closeHandler = useCallback(() => {
    document.body.style.overflow = "auto";
    navigate(-1);
  }, [navigate, state?.projectId]);

  document.body.style.overflow = "hidden";
  return (
    <Modal2 onClose={closeHandler} isFullWidth>
      <NSCreateJobDescriptionStyle.Container>
        <JobDescriptionProvider>
          <QuestionModalRenderer />
        </JobDescriptionProvider>
      </NSCreateJobDescriptionStyle.Container>
    </Modal2>
  );
}

export default CreateJobDescription;
