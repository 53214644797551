import { IButton } from "../MainButton/MainButton.type";

export namespace NSToastAlertType {
  export interface IToastAlert {
    label?: string;
    type: IToastAlertType;
    description?: string;
    firstButton?: IButton;
    secondButton?: IButton;
  }

  export type IToastAlertType = "success" | "info" | "warning" | "error";

  export enum TypeColorVariant {
    warning = "hsla(44, 100%, 95%, 1)",
    info = "hsla(209, 100%, 96%, 1)",
    success = "hsla(136, 100%, 96%, 1)",
    error = "hsla(357, 71%, 95%, 1)",
  }

  export enum TypeIconAndButtonColorVariant {
    warning = "hsla(39, 85%, 59%, 1)",
    info = "hsla(209, 100%, 46%, 1)",
    success = "hsla(137, 93%, 38%, 1)",
    error = "hsla(349, 73%, 56%, 1)",
  }
}
