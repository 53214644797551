import styled from "styled-components";

export namespace NSLoadingStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.light.light};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const Svg = styled.svg`
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 100px;
    height: 100px;
    stroke: ${(props) => props.theme.colors.primary.primary};
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  export const Circle = styled.circle`
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;

    @keyframes dash {
      0% {
        stroke-dasharray: 0, 360;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 180, 360;
        stroke-dashoffset: -40;
      }
      100% {
        stroke-dasharray: 180, 360;
        stroke-dashoffset: -160;
      }
    }
  `;
}
