import styled from "styled-components";

export namespace NSTagStyle {
  export const IconContainer = styled.div`
    position: absolute;
    right: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `;
  export const CheckContainer = styled(IconContainer)``;
  export const CloseContainer = styled(IconContainer)`
    margin-top: 5px;
    display: none;
  `;

  export const Container = styled.button<{ isSelected: boolean }>`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    font-family: greycliff-cf, sans-serif;
    padding-left: 16px;
    padding-right: 48px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: 2px solid;
    height: 48px;
    position: relative;
    cursor: pointer;
    transition: 0.3s all;

    border-color: ${(props) =>
      props.isSelected
        ? props.theme.colors.primary.primary
        : props.theme.colors.dark.dark};
    color: ${(props) =>
      props.isSelected
        ? props.theme.colors.light.light
        : props.theme.colors.dark.dark};
    background-color: ${(props) =>
      props.isSelected ? props.theme.colors.primary.primary : "transparent"};

    &:hover {
      border-color: ${(props) =>
        props.isSelected
          ? props.theme.colors.primary.dark
          : props.theme.colors.primary.primary};
      color: ${(props) =>
        props.isSelected
          ? props.theme.colors.light.light
          : props.theme.colors.primary.primary};
      background-color: ${(props) =>
        props.isSelected ? props.theme.colors.primary.dark : "transparent"};
      ${CheckContainer} {
        display: none;
      }
      ${CloseContainer} {
        display: block;
      }
    }
  `;
}
