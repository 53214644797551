import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AddNewItem from "../AddNewItem/AddNewItem";
import Checkbox from "../Checkbox/CheckBox";
import CountryBadge from "./CountryBadge";
import { NSDropdown } from "./Dropdown.type";
import { NSDropdownStyle } from "./Dropdown.style";
import { NSMainInputStyle } from "../MainInput/MainInput.style";

function CheckBoxDropdown({
  containAddNewItemButton,
  options,
  isOpen,
  onClickTitle,
  prefixItem,
  onCheckedItems,
  titleWithPrefixItem,
  label,
  placeholder,
  showLabel = true,
  error,
  suffixItem,
  onSelect,
  showSuffixItems,
  title,
  children,
  defaultCheckedItems,
}: NSDropdown.IInnerDropdown) {
  const dropdown = useRef(null as null | HTMLDivElement);
  const [checkboxDropdownOptions, setCheckboxDropdownOptions] = useState<
    NSDropdown.ICheckboxtItem[]
  >(
    options.map((item) => ({
      item,
      isChecked: defaultCheckedItems
        ? defaultCheckedItems.some((i) => i.id === item.id)
        : false,
    }))
  );

  useEffect(() => {
    options && checkboxDropdownOptions.length === 0
      ? setCheckboxDropdownOptions(
          options.map((item) => ({
            item,
            isChecked: defaultCheckedItems
              ? defaultCheckedItems.some((i) => i.id === item.id)
              : false,
          }))
        )
      : setCheckboxDropdownOptions(checkboxDropdownOptions);
  }, [options]);

  const changeChecked = useCallback(
    (item: NSDropdown.IDropdownItem) => {
      const newCheckedDropdownItems = checkboxDropdownOptions;
      const index = newCheckedDropdownItems.findIndex((i) => i.item === item);
      newCheckedDropdownItems[index].isChecked =
        !newCheckedDropdownItems[index].isChecked;

      setCheckboxDropdownOptions([...newCheckedDropdownItems]);

      const checkeItems = newCheckedDropdownItems
        .filter((i) => i.isChecked)
        .map((option) => ({
          id: option.item.id,
          label: option.item?.label,
        }));
      onCheckedItems?.(checkeItems);
    },
    [checkboxDropdownOptions, onCheckedItems]
  );

  // eslint-disable-next-line consistent-return
  const dropdownLabel = useMemo(() => {
    if (label) {
      return label;
    }
    if (placeholder && checkboxDropdownOptions.some((i) => i.isChecked)) {
      return placeholder;
    }
  }, [label, placeholder, checkboxDropdownOptions]);

  return (
    <NSDropdownStyle.InnerContainer>
      {children ?? (
        <NSDropdownStyle.DropdownTitle onClick={onClickTitle} ref={dropdown}>
          {showLabel && dropdownLabel && (
            <NSMainInputStyle.InputLabel>
              {dropdownLabel}
            </NSMainInputStyle.InputLabel>
          )}
          <NSDropdownStyle.InnerDropdownTitle isError={error}>
            {prefixItem}
            <NSDropdownStyle.InnerTitle>
              {titleWithPrefixItem ??
                (checkboxDropdownOptions &&
                checkboxDropdownOptions.some((i) => i.isChecked) ? (
                  checkboxDropdownOptions
                    .filter((i) => i.isChecked)
                    .map((item) => (
                      <CountryBadge
                        key={item.item.id}
                        item={item.item}
                        onClickClose={(closedItem) => changeChecked(closedItem)}
                      />
                    ))
                ) : (
                  <NSDropdownStyle.Placeholder>
                    {placeholder}
                  </NSDropdownStyle.Placeholder>
                ))}
            </NSDropdownStyle.InnerTitle>

            {(checkboxDropdownOptions.filter((i) => i.isChecked).length < 3 ||
              showSuffixItems) && (
              <NSDropdownStyle.SuffixItemContainer>
                {suffixItem}
              </NSDropdownStyle.SuffixItemContainer>
            )}
          </NSDropdownStyle.InnerDropdownTitle>
        </NSDropdownStyle.DropdownTitle>
      )}
      {isOpen && (
        <NSDropdownStyle.DropdownItemsContainer
          topPosition={dropdown.current?.offsetHeight}
        >
          <NSDropdownStyle.ScrollableArea>
            {title && (
              <NSDropdownStyle.MenuTitle>{title}</NSDropdownStyle.MenuTitle>
            )}
            {checkboxDropdownOptions?.map((option, _) => (
              <NSDropdownStyle.DropdownItemContainer key={option.item.id}>
                <NSDropdownStyle.DropdownItem
                  onClick={() => onSelect?.(option.item)}
                >
                  <Checkbox
                    onClick={(e) => {
                      e.stopPropagation();
                      changeChecked(option.item);
                    }}
                    label={option.item.label}
                    checked={option.isChecked}
                  />
                </NSDropdownStyle.DropdownItem>
              </NSDropdownStyle.DropdownItemContainer>
            ))}

            {containAddNewItemButton && (
              <NSDropdownStyle.AddNewItemButtonContainer>
                <AddNewItem type="dropdown" items={options} />
              </NSDropdownStyle.AddNewItemButtonContainer>
            )}
          </NSDropdownStyle.ScrollableArea>
        </NSDropdownStyle.DropdownItemsContainer>
      )}
    </NSDropdownStyle.InnerContainer>
  );
}

export default CheckBoxDropdown;
