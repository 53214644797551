import { useGraphQLQuery } from "../../hooks/useGraphQL";
import { GetClientDocument } from "../../gql/graphql";

function useGetClient(id: string) {
  const { data, ...rest } = useGraphQLQuery(GetClientDocument, undefined, {
    id,
  });

  return {
    rest,
    data,
  };
}

export default useGetClient;
