import styled, { css } from "styled-components";

import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSAddNewItemStyle {
  export const Container = styled.div<{ isInputFocused: boolean }>`
    display: flex;
    align-items: center;
    gap: 4px;
    ${NSMainButtonStyle.ButtonBase} {
      height: 40px;
      width: 40px;
      padding: 0;
      &:focus {
        background-color: ${(props) => props.theme.colors.light.light};
        svg {
          color: ${(props) => props.theme.colors.primary.primary};
        }
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.light.light};
        svg {
          color: ${(props) => props.theme.colors.primary.primary};
        }
      }
    }

    ${(props) =>
      props.isInputFocused &&
      css`
        ${NSMainButtonStyle.ButtonBase} {
          background-color: ${props.theme.colors.primary.primary};
          svg {
            color: ${props.theme.colors.light.light};
          }
        }
      `}
  `;
}
