import {
  GetProjectsDocument,
  GetProjectsDropdownDocument,
  Project_Bool_Exp,
  Project_Order_By,
} from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useProjects(
  limit: number,
  offset: number,
  projectOrder: [Project_Order_By],
  projectFilter: Project_Bool_Exp
) {
  const { data, ...rest } = useGraphQLQuery(GetProjectsDocument, undefined, {
    limit,
    offset,
    projectOrder,
    projectFilter,
  });

  return {
    projects: data,
    rest,
  };
}

export function useGetProjectsDropdown() {
  const { data, ...rest } = useGraphQLQuery(GetProjectsDropdownDocument);

  const projects = data?.project.map((projectItem) => ({
    dropdown: {
      id: projectItem.id,
      label: projectItem.project_name,
    },
    clientId: projectItem.client.id,
  }));

  return {
    projects,
    rest,
  };
}
