import styled from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSFormErrorBoxStyle {
  export const Container = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.neutrals.grey01};
    padding: 1.5rem 4rem;
    box-sizing: border-box;
    position: relative;
  `;

  export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;
  `;

  export const ErrorText = styled.p`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.error.red01};
  `;

  export const Description = styled.p`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const CloseContainer = styled.div`
    position: absolute;
    top: 1.25rem;
    right: 1rem;

    ${NSMainButtonStyle.ButtonBase} {
      width: 24px;
      height: 24px;
      padding: 0;
    }

    & > svg {
      color: ${(props) => props.theme.colors.neutrals.grey04};
    }
  `;
}
