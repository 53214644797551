import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";

import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import i18n from "../../../../../library/i18next";
import TextArea from "../../../../../components/TextArea/TextArea";

const IntroductionContainer = styled.div``;

const SelectIntroduction = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const introValues = activeQuestion?.answers.map(
    // eslint-disable-next-line arrow-body-style
    (item) => {
      return {
        isSelected: item.isSelected,
        value: item.value as string,
      };
    }
  );

  const [introValue, setIntroValue] = useState<string>(
    introValues ? introValues[0].value : ""
  );

  const getAnswersAsJson = useCallback(() => {
    const obj = [
      {
        value: introValue,
        isSelected: introValues?.[0].isSelected ?? false,
      },
    ];

    return obj;
  }, [introValue, introValues]);

  const getAnswerAsString = useCallback(() => introValue, [introValue]);

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
      getAnswerAsString,
    }),
    [getAnswerAsString, getAnswersAsJson]
  );

  return (
    <IntroductionContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
          <NSCreateJobDescriptionStyle.IntroductionAnswerContainer>
            <TextArea
              value={introValue}
              onChange={(e) => setIntroValue(e.target.value)}
              id="selectIntro"
              name="SelectIntro"
              placeholder="Introduction "
            />
          </NSCreateJobDescriptionStyle.IntroductionAnswerContainer>
        </NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
      </NSCreateJobDescriptionStyle.WQuestionContainer>

      <NSCreateJobDescriptionStyle.Footer>
        <NSCreateJobDescriptionStyle.FooterLeftContainer />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            backgroundColor={ButtonColorVariant.grey}
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </IntroductionContainer>
  );
});

SelectIntroduction.displayName = "SelectIntroduction";

export default SelectIntroduction;
