import { NSCandidateResultInfoCardStyle } from "./CandidateResultInfoCard.style";
import { NSCandidateResultInfoCardType } from "./CandidateResultInfoCard.type";

function CandidateResultInfoCard({
  header,
  innerHeader,
  innerText,
  suffixItem,
  children,
}: NSCandidateResultInfoCardType.ICandidateResultInfoCard) {
  return (
    <NSCandidateResultInfoCardStyle.Container>
      <NSCandidateResultInfoCardStyle.Header>
        {header}
      </NSCandidateResultInfoCardStyle.Header>
      {children ? (
        <NSCandidateResultInfoCardStyle.Content>
          {children}
        </NSCandidateResultInfoCardStyle.Content>
      ) : (
        <NSCandidateResultInfoCardStyle.Content>
          <NSCandidateResultInfoCardStyle.TextContent>
            <NSCandidateResultInfoCardStyle.InnerHeader>
              {innerHeader}
            </NSCandidateResultInfoCardStyle.InnerHeader>
            <NSCandidateResultInfoCardStyle.InnerText>
              {innerText}
            </NSCandidateResultInfoCardStyle.InnerText>
          </NSCandidateResultInfoCardStyle.TextContent>

          {suffixItem && (
            <NSCandidateResultInfoCardStyle.SuffixContainer>
              {suffixItem}
            </NSCandidateResultInfoCardStyle.SuffixContainer>
          )}
        </NSCandidateResultInfoCardStyle.Content>
      )}
    </NSCandidateResultInfoCardStyle.Container>
  );
}

export default CandidateResultInfoCard;
