import { useCallback, useState } from "react";
import ContextMenu from "../ContextMenu/ContextMenu";
import Icon from "../Icon/Icon";
import { NSDashboardMainButtonStyle } from "./DashboardMainButton.style";
import { NSDashboardMainButtonType } from "./DashboardMainButton.type";

function DashboardMainButton({
  items,
  onContextMenuOpen,
}: NSDashboardMainButtonType.IDashboardMainButton) {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const setContextMenuOpenFunction = useCallback(
    (isOpen: boolean) => {
      setIsContextMenuOpen(isOpen);
      onContextMenuOpen?.(isOpen);
    },
    [onContextMenuOpen]
  );

  return (
    <NSDashboardMainButtonStyle.Container>
      <ContextMenu
        onContextMenuOpen={(value) => setContextMenuOpenFunction(value)}
        items={items}
      >
        <NSDashboardMainButtonStyle.ButtonContainer
          isContextMenuOpen={isContextMenuOpen}
        >
          <Icon
            width={24}
            height={24}
            name={isContextMenuOpen ? "Close" : "Add"}
          />
        </NSDashboardMainButtonStyle.ButtonContainer>
      </ContextMenu>
    </NSDashboardMainButtonStyle.Container>
  );
}

export default DashboardMainButton;
