import styled, { css } from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSContextMenuHOCStyle } from "../ContextMenuHOC/ContextMenuHOC.style";

export namespace NSNoteCardStyle {
  export const Container = styled.div<{ isActive: boolean }>`
    box-sizing: border-box;
    font-family: greycliff-cf, sans-serif;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    width: 100%;
    padding: 12px 16px;
    border-radius: 16px;
    border: 2px solid ${(props) => props.theme.colors.light.light};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    ${(props) =>
      props.isActive &&
      css`
        border-color: ${(props) => props.theme.colors.primary.primary};
      `}
    gap: 4px;
  `;

  export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};

    ${NSMainButtonStyle.ButtonBase} {
      margin-right: 0;
    }

    ${NSContextMenuHOCStyle.Container} {
      width: fit-content;
      align-items: flex-start;
    }
  `;

  export const HeaderInfo = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey06};
  `;

  export const Note = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey08};
  `;
}
