import LinkButton from "../../../../components/LinkButton/LinkButton";
import Loading from "../../../../components/Loading/Loading";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import i18n from "../../../../library/i18next";
import { NSInterviewStyle } from "./Interview.style";
import useInterviewVm from "./InterviewVM";

const Interview = () => {
  const {
    isLoading,
    /* interviewLanguage, */
    interviewValidityDate,
    isInterviewEditable,
    projectsInterviewTemplate,
    /* shouldSendEmail,
    emailContent,
    shouldSendSMS,
    smsContent,
    shouldSendEmailBefore3Days,
    emailContentBefore3Days, 
    setShouldSendEmailBefore3Days,
    setShouldSendSMS,
    closeDrawer,
    setShouldSendEmail,
    drawerContentType,
    textAreaValue,
    setTextAreaValue,
    setEmailContent,
    setSmsContent,
    setEmailContentBefore3Days,
    openDrawer, */
    onClickCreateInterview,
    /* onClickLanguageEdit, */
    onClickDateEdit,
    onClickQuestionsEdit,
    onClickSeeInPreview,
  } = useInterviewVm();

  /* const renderDrawerContent = () => {
    switch (drawerContentType) {
      case "email":
        return (
          <>
            <TextArea
              label={i18n.t("interview.emailContent")}
              id="emailContent"
              name="emailContent"
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
            />
            <SolidButton
              label={i18n.t("general.save")}
              fullWidth
              onClick={() => {
                setEmailContent(textAreaValue);
                closeDrawer();
              }}
            />
          </>
        );
      case "sms":
        return (
          <>
            <TextArea
              label={i18n.t("interview.smsContent")}
              id="smsContent"
              name="smsContent"
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
            />
            <SolidButton
              label={i18n.t("general.save")}
              fullWidth
              onClick={() => {
                setSmsContent(textAreaValue);
                closeDrawer();
              }}
            />
          </>
        );
      case "emailBefore3Days":
        return (
          <>
            <TextArea
              label={i18n.t("interview.emailContentBefore3Days")}
              id="emailBefore3DaysContent"
              name="emailBefore3DaysContent"
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
            />
            <SolidButton
              label={i18n.t("general.save")}
              fullWidth
              onClick={() => {
                setEmailContentBefore3Days(textAreaValue);
                closeDrawer();
              }}
            />
          </>
        );
      default:
        return null;
    }
  };

  const renderDrawerTitle = () => {
    switch (drawerContentType) {
      case "email":
        return i18n.t("interview.emailContent");
      case "sms":
        return i18n.t("interview.smsContent");
      case "emailBefore3Days":
        return i18n.t("interview.emailContentBefore3Days");
      default:
        return "";
    }
  }; */

  return isLoading ? (
    <Loading />
  ) : (
    <NSInterviewStyle.Container>
      {projectsInterviewTemplate ? (
        <NSInterviewStyle.InterviewContainer>
          <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.InfoItemLeftContainer>
              <NSInterviewStyle.InfoItemTitle>
                {i18n.t("interview.interviewFlow")}
              </NSInterviewStyle.InfoItemTitle>
              <NSInterviewStyle.InfoItemSubTitle>
                {i18n.t("interview.interviewFlowSubtitle")}
              </NSInterviewStyle.InfoItemSubTitle>
            </NSInterviewStyle.InfoItemLeftContainer>
            <LinkButton
              role="button"
              label={i18n.t("interview.seeInPreview")}
              onClick={onClickSeeInPreview}
            />
          </NSInterviewStyle.InfoItem>
          <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.InfoItemLeftContainer>
              <NSInterviewStyle.InfoItemTitle>
                {i18n.t("interview.roleMetricsAndQuestions")}
              </NSInterviewStyle.InfoItemTitle>
              <NSInterviewStyle.InfoItemSubTitle>
                {i18n.t("interview.roleMetricsAndQuestionsSubtitle")}
              </NSInterviewStyle.InfoItemSubTitle>
            </NSInterviewStyle.InfoItemLeftContainer>
            <LinkButton
              disabled={!isInterviewEditable}
              role="button"
              label={i18n.t("interview.change")}
              onClick={onClickQuestionsEdit}
            />
          </NSInterviewStyle.InfoItem>
          {/*   <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.InfoItemLeftContainer>
              <NSInterviewStyle.InfoItemTitle>
                {i18n.t("interview.interviewLanguage")}
              </NSInterviewStyle.InfoItemTitle>
              <NSInterviewStyle.InfoItemSubTitle>
                {interviewLanguage}
              </NSInterviewStyle.InfoItemSubTitle>
            </NSInterviewStyle.InfoItemLeftContainer>
            <LinkButton
              disabled={!isInterviewEditable}
              role="button"
              label={i18n.t("interview.edit")}
              onClick={onClickLanguageEdit}
            />
          </NSInterviewStyle.InfoItem> */}
          <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.InfoItemLeftContainer>
              <NSInterviewStyle.InfoItemTitle>
                {i18n.t("interview.interviewValidityDate")}
              </NSInterviewStyle.InfoItemTitle>
              <NSInterviewStyle.InfoItemSubTitle>
                {interviewValidityDate[0]} / {interviewValidityDate[1]}
              </NSInterviewStyle.InfoItemSubTitle>
            </NSInterviewStyle.InfoItemLeftContainer>
            <LinkButton
              role="button"
              label={i18n.t("interview.edit")}
              onClick={onClickDateEdit}
            />
          </NSInterviewStyle.InfoItem>
          {/* <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.CheckboxContainer>
              <Checkbox
                icon="CheckWhite"
                checked={shouldSendEmail}
                onClick={() => setShouldSendEmail(!shouldSendEmail)}
              />
              <NSInterviewStyle.CheckboxLabel>
                {i18n.t("interview.sendEmail")}
                {shouldSendEmail && !emailContent ? (
                  <NSInterviewStyle.CheckboxSubItem>
                    <SolidButton
                      label={i18n.t("interview.addEmailContent")}
                      onClick={() => openDrawer("email")}
                    />
                  </NSInterviewStyle.CheckboxSubItem>
                ) : emailContent ? (
                  <NSInterviewStyle.CheckboxSubItem>
                    <NSInterviewStyle.InfoItemSubTitle>
                      {emailContent}
                    </NSInterviewStyle.InfoItemSubTitle>
                  </NSInterviewStyle.CheckboxSubItem>
                ) : null}
              </NSInterviewStyle.CheckboxLabel>
            </NSInterviewStyle.CheckboxContainer>
            {emailContent && (
              <LinkButton
                label={i18n.t("interview.edit")}
                onClick={() => {
                  setTextAreaValue(emailContent);
                  openDrawer("email");
                }}
              />
            )}
          </NSInterviewStyle.InfoItem>
          <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.CheckboxContainer>
              <Checkbox
                icon="CheckWhite"
                checked={shouldSendSMS}
                onClick={() => setShouldSendSMS(!shouldSendSMS)}
              />
              <NSInterviewStyle.CheckboxLabel>
                {i18n.t("interview.sendSms")}
                {shouldSendSMS && !smsContent ? (
                  <NSInterviewStyle.CheckboxSubItem>
                    <SolidButton
                      label={i18n.t("interview.addSmsContent")}
                      onClick={() => openDrawer("sms")}
                    />
                  </NSInterviewStyle.CheckboxSubItem>
                ) : smsContent ? (
                  <NSInterviewStyle.CheckboxSubItem>
                    <NSInterviewStyle.InfoItemSubTitle>
                      {smsContent}
                    </NSInterviewStyle.InfoItemSubTitle>
                  </NSInterviewStyle.CheckboxSubItem>
                ) : null}
              </NSInterviewStyle.CheckboxLabel>
            </NSInterviewStyle.CheckboxContainer>
            {smsContent && (
              <LinkButton
                label={i18n.t("interview.edit")}
                onClick={() => {
                  setTextAreaValue(smsContent);
                  openDrawer("sms");
                }}
              />
            )}
          </NSInterviewStyle.InfoItem>
          <NSInterviewStyle.InfoItem>
            <NSInterviewStyle.CheckboxContainer>
              <Checkbox
                icon="CheckWhite"
                checked={shouldSendEmailBefore3Days}
                onClick={() =>
                  setShouldSendEmailBefore3Days(!shouldSendEmailBefore3Days)
                }
              />
              <NSInterviewStyle.CheckboxLabel>
                {i18n.t("interview.sendEmailBefore3Days")}
                {shouldSendEmailBefore3Days && !emailContentBefore3Days ? (
                  <NSInterviewStyle.CheckboxSubItem>
                    <SolidButton
                      label={i18n.t("interview.addEmailContent")}
                      onClick={() => openDrawer("emailBefore3Days")}
                    />
                  </NSInterviewStyle.CheckboxSubItem>
                ) : emailContentBefore3Days ? (
                  <NSInterviewStyle.CheckboxSubItem>
                    <NSInterviewStyle.InfoItemSubTitle>
                      {emailContentBefore3Days}
                    </NSInterviewStyle.InfoItemSubTitle>
                  </NSInterviewStyle.CheckboxSubItem>
                ) : null}
              </NSInterviewStyle.CheckboxLabel>
            </NSInterviewStyle.CheckboxContainer>
            {emailContentBefore3Days && (
              <LinkButton
                label={i18n.t("interview.edit")}
                onClick={() => {
                  setTextAreaValue(emailContentBefore3Days);
                  openDrawer("emailBefore3Days");
                }}
              />
            )}
          </NSInterviewStyle.InfoItem>
          {drawerContentType && (
            <Drawer
              isAddedSelfCloseFunction
              title={renderDrawerTitle()}
              onClickCloseIcon={closeDrawer}
            >
              <NSInterviewStyle.DrawerContent>
                {renderDrawerContent()}
              </NSInterviewStyle.DrawerContent>
            </Drawer>
          )} */}
        </NSInterviewStyle.InterviewContainer>
      ) : (
        <NSInterviewStyle.NotFoundContainer>
          <NSInterviewStyle.NotFoundTitle>
            {i18n.t("interview.notFoundTitle")}
          </NSInterviewStyle.NotFoundTitle>
          <NSInterviewStyle.NotFoundSubTitle>
            {i18n.t("interview.notFoundSubTitle")}
          </NSInterviewStyle.NotFoundSubTitle>
          <SolidButton
            onClick={onClickCreateInterview}
            size="medium"
            label={i18n.t("interview.createInterview")}
          />
        </NSInterviewStyle.NotFoundContainer>
      )}
    </NSInterviewStyle.Container>
  );
};

export default Interview;
