import { KeyboardEvent, useRef, useState } from "react";
import Checkbox from "../Checkbox/CheckBox";
import Icon from "../Icon/Icon";
import { NSDragableCheckBoxListItemStyle } from "./DragableCheckBoxListItem.style";
import { NSDragableCheckBoxListItemType } from "./DragableCheckBoxListItem.type";
import FreeInput from "../FreeInput/FreeInput";
import i18n from "../../library/i18next";
import TextInput from "../TextInput/TextInput";
import LinkButton from "../LinkButton/LinkButton";
import Modal from "../Modal/Modal";
import SolidButton from "../SolidButton/SolidButton";

function DragableCheckBoxListItem({
  onCheckboxClicked,
  onValueUpdated,
  checked,
  dragableCheckBoxListItemTextValue,
  inputId,
  isEditable = true,
  isShownToCandidate = true,
  onMaxDurationUpdated,
  withMaxDuration,
  maxDuration = 5,
  id,
  language,
  onEdit,
  messageOnEditItem = i18n.t("interview.sureToChangePermanently"),
}: NSDragableCheckBoxListItemType.IDragableCheckBoxListItem) {
  const [editable, setEditable] = useState(false);
  const [listItemValue, setListItemValue] = useState(
    dragableCheckBoxListItemTextValue
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);

  const focusTextInputOnClick = () => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  };
  const saveUpdatedValue = () => {
    setEditable(false);
    onValueUpdated(listItemValue);
    onEdit?.(id as number);
  };

  const toggleOffWithoutSaving = () => {
    setEditable(false);
    setListItemValue(dragableCheckBoxListItemTextValue);
  };

  const onChangeHandler = (e: string) => {
    setListItemValue(e);
  };

  const renderTextAndCheckBox = () => (
    <NSDragableCheckBoxListItemStyle.Content>
      <NSDragableCheckBoxListItemStyle.InnerContainer checked={checked}>
        <Checkbox checked={checked} onClick={onCheckboxClicked} />
        {listItemValue}{" "}
        {isEditable && !editable && (
          <NSDragableCheckBoxListItemStyle.IconsContainer>
            <NSDragableCheckBoxListItemStyle.IconContainer
              onClick={() => setIsModalOpen(true)}
            >
              <Icon name="EditCandidateInfo" />
            </NSDragableCheckBoxListItemStyle.IconContainer>
          </NSDragableCheckBoxListItemStyle.IconsContainer>
        )}
      </NSDragableCheckBoxListItemStyle.InnerContainer>
      {!isShownToCandidate && (
        <NSDragableCheckBoxListItemStyle.WarningText>
          {i18n.t("projects.notShownToCandidate")}
        </NSDragableCheckBoxListItemStyle.WarningText>
      )}
    </NSDragableCheckBoxListItemStyle.Content>
  );

  const onKeyUpHandler = (
    event: KeyboardEvent<HTMLInputElement> | undefined
  ) => {
    if (event?.code === "Enter") {
      saveUpdatedValue();
    }
    if (event?.code === "Escape") {
      toggleOffWithoutSaving();
    }
  };

  const renderTextInput = () => (
    <NSDragableCheckBoxListItemStyle.FreeInputContainer>
      <FreeInput
        id={inputId}
        name={inputId}
        value={listItemValue}
        showLabel={false}
        onChange={onChangeHandler}
        onBlur={saveUpdatedValue}
        onKeyUp={onKeyUpHandler}
        variant="basic"
        type="text"
      />
      <LinkButton onClick={saveUpdatedValue} label={i18n.t("general.save")} />
    </NSDragableCheckBoxListItemStyle.FreeInputContainer>
  );

  const renderInnerContainer = () => {
    if (editable) {
      return renderTextInput();
    }
    return renderTextAndCheckBox();
  };

  const handleMaxDurationChange = (value: string) => {
    if (/^[1-5]$/.test(value)) {
      onMaxDurationUpdated?.(parseInt(value));
    } else if (textInputRef.current) {
      textInputRef.current.value = "";
    }
  };

  const handleBlur = () => {
    if (textInputRef.current && textInputRef.current.value === "") {
      textInputRef.current.value = "5";
      onMaxDurationUpdated?.(5);
    }
  };

  return (
    <NSDragableCheckBoxListItemStyle.Container withMaxDuration>
      {renderInnerContainer()}
      {withMaxDuration && (
        <NSDragableCheckBoxListItemStyle.MaxDurationContainer>
          <NSDragableCheckBoxListItemStyle.LanguageLabel>
            {i18n.t("interview.languagePreference")}: {language}
          </NSDragableCheckBoxListItemStyle.LanguageLabel>
          <NSDragableCheckBoxListItemStyle.InputContainer>
            <TextInput
              ref={textInputRef}
              id="maxDuration"
              name="maxDuration"
              suffixItem={<Icon name="Clock" />}
              defaultValue={String(maxDuration)}
              variant="label-w-icon-suffix"
              onChange={handleMaxDurationChange}
              onBlur={handleBlur}
              maxLength={1}
            />
            <NSDragableCheckBoxListItemStyle.CustomInputValue
              onClick={focusTextInputOnClick}
            >
              {i18n.t("interview.minute")}
            </NSDragableCheckBoxListItemStyle.CustomInputValue>
          </NSDragableCheckBoxListItemStyle.InputContainer>
          <Icon
            name="WarningCircleInfo"
            onClick={() => withMaxDuration.onClickInfoIcon(id!)}
          />
        </NSDragableCheckBoxListItemStyle.MaxDurationContainer>
      )}
      <Modal isOpen={isModalOpen} hiddenCloseButton>
        <NSDragableCheckBoxListItemStyle.ModalContainer>
          <NSDragableCheckBoxListItemStyle.ModalContent>
            {messageOnEditItem}
          </NSDragableCheckBoxListItemStyle.ModalContent>
          <SolidButton
            fullWidth
            onClick={() => {
              setEditable(true);
              setIsModalOpen(false);
            }}
            label={i18n.t("general.edit")}
          />
          <LinkButton
            label={i18n.t("general.cancel")}
            onClick={() => setIsModalOpen(false)}
          />
        </NSDragableCheckBoxListItemStyle.ModalContainer>
      </Modal>
    </NSDragableCheckBoxListItemStyle.Container>
  );
}

export default DragableCheckBoxListItem;
