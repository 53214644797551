import { SubmitHandler, useForm } from "react-hook-form";
import { object, string } from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGraphQLMutation } from "../../../../hooks/useGraphQL";

import { NSCreateCandidateType } from "./Create-Candidate.type";
import {
  Client_Candidate_Project_Candidate_List_Status_Enum,
  Client_Candidate_Project_Constraint,
  GetSignedUrlDocument,
  InsertClientCandidateDocument,
} from "../../../../gql/graphql";
import i18n from "../../../../library/i18next";
import { CV, PUT } from "../../../../utils/globalConstants";
import fileTypeHelper from "../../../../utils/fileTypeHelper";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import { NSFileUploadType } from "../../../../components/FileUpload/FileUpload.type";

const createCandidateSchema = object({
  candidateName: string().required("Please enter candidate name"),
  candidateSurname: string().required("Please enter candidate surname"),
  emailAddress: string()
    .required("Please enter email address")
    .email("Enter a valid email"),
  phoneNumber: string().required("Please enter phone number"),
  uploadedFileName: string(),
}).required();

const useCreateCandidateVm = () => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm<NSCreateCandidateType.ICreateCandidateFormValues>({
    resolver: yupResolver(createCandidateSchema),
    shouldFocusError: false,
  });
  const { state } = useLocation();

  const { mutateAsync } = useGraphQLMutation(InsertClientCandidateDocument);
  const clientId = useMemo(() => state.clientId, [state]);
  const projectId = useMemo(() => state.projectId, [state]);

  const navigate = useNavigate();
  const [cvFile, setFile] = useState<Blob>();
  const [cvFileName, setCvFileName] = useState<string>();
  const [contentType, setContentType] = useState<string>();
  const [fileNames, setFileNames] = useState<string[]>();

  const onUploadFile = useCallback(
    (url?: Blob, name?: string) => {
      setFile(url);
      setValue("uploadedFileName", name);
      const type = fileTypeHelper(name!);
      setContentType(type.type);
      setFileNames(type.fileNames);
    },
    [setValue]
  );

  const onErrorFileUpload = (message: NSFileUploadType.IError) => {
    toast(<ToastAlert type="error" description={message.label} />);
  };

  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  const sendFileTo = useCallback(
    async (contentTypeProps: string, fileNamesProps: string[]) => {
      await getSignedUrl({
        signedUrlInput: {
          contentType: contentTypeProps,
          filenames: fileNamesProps,
          folder: CV,
          operation: PUT,
        },
      })
        .then(async (signedUrlResponse) => {
          if (signedUrlResponse.getSignedUrl) {
            const response = await fetch(
              `${signedUrlResponse?.getSignedUrl?.signedUrls[0]}`,
              {
                headers: {
                  "x-amz-acl": "private",
                  "Content-Type": contentTypeProps!,
                },
                method: "PUT",
                body: cvFile,
              }
            );

            if (response.ok) {
              // TODO: file upload successful needs to be shown
              setCvFileName(response.url);
            } else {
              setCvFileName(undefined);
            }
          }
        })
        .catch(() => {});
    },
    [cvFile, getSignedUrl]
  );

  useEffect(() => {
    setFocus("candidateName");
  }, [setFocus]);

  const onSubmit: SubmitHandler<NSCreateCandidateType.ICreateCandidateFormValues> =
    useCallback(
      async (data, event) => {
        event?.preventDefault();
        await sendFileTo(contentType!, fileNames!);
        mutateAsync({
          input: [
            {
              client_id: clientId,
              name: data.candidateName,
              surname: data.candidateSurname,
              mobile_number: data.phoneNumber,
              email_address: data.emailAddress,
              cv_file_uri: cvFileName,
              cv_file_name: fileNames?.[0],
              client_candidate_projects: {
                data: [
                  {
                    project_id: projectId,
                    candidate_list_status:
                      Client_Candidate_Project_Candidate_List_Status_Enum.InLonglist,
                  },
                ],
                on_conflict: {
                  constraint:
                    Client_Candidate_Project_Constraint.ClientCandidateProjectProjectIdClientCandidateIdKey,
                  update_columns: [],
                },
              },
            },
          ],
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.createCandidateSuccess")}
                type="success"
              />,

              {
                id: "addAndCreateCandidateSuccess",
              }
            );

            navigate(`/projects/detail/${projectId}/long-list`, {
              state: { clientId, projectId },
            });
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.createCandidateError")}
                type="error"
              />,

              {
                id: "addAndCreateCandidateError",
              }
            );
          });
      },
      [
        clientId,
        contentType,
        cvFileName,
        fileNames,
        mutateAsync,
        navigate,
        projectId,
        sendFileTo,
      ]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const phoneCode = [
    {
      label: "+90",
      id: 90,
    },
    {
      label: "+56",
      id: 56,
    },
    {
      label: "+86",
      id: 86,
    },
    {
      label: "+45",
      id: 45,
    },
  ];

  return {
    handleSubmit: submitHandler,
    formErrors: errors,
    phoneCode,
    setFile,
    setValue,
    onUploadFile,
    onErrorFileUpload,
  };
};

export default useCreateCandidateVm;
