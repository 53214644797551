import dayjs from "dayjs";
import {
  GetJobDescriptionAggregateDocument,
  GetJobDescriptionDocument,
  Job_Description_Bool_Exp,
} from "../../gql/graphql";

import { NSTableListType } from "../../components/TableList/TableList.type";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetJobDescription(
  limit: number,
  offset: number,
  jobDescriptionFilter?: Job_Description_Bool_Exp
) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetJobDescriptionDocument,
    undefined,
    {
      limit,
      offset,
      jobDescriptionFilter,
    }
  );

  const { data: aggregateData } = useGraphQLQuery(
    GetJobDescriptionAggregateDocument
  );

  const jdListItems: NSTableListType.ITableListItem[] | undefined =
    data?.job_description.map((item) => ({
      id: item.id,
      informations: [
        { id: item.id + 1, information: item?.title ?? "" },
        { id: item.id + 2, information: item?.project?.project_name ?? "" },
        {
          id: item.id + 3,
          information: item?.project?.client.client_name ?? "",
        },
        {
          id: item.id + 4,
          information: `${dayjs(item?.created_at).format("MMM DD[,] YYYY")}`,
        },
      ],
    }));

  return {
    refetch,
    jdListItems,
    rest,
    totalItemCount: aggregateData?.job_description_aggregate.aggregate?.count,
  };
}
export default useGetJobDescription;
