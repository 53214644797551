import { useCallback, useMemo, useState } from "react";

import { string } from "yup";
import { useNavigate } from "react-router-dom";
import { ActionResponse } from "../../types/ActionResponse";
import { NSResetPasswordType } from "./Reset-Password.type";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import i18n from "../../library/i18next";

const items: NSResetPasswordType.ITestableHint[] = [
  {
    label: i18n.t("passwordCreate.passwordHint1"),
    achived: false,
    schema: string().min(6).max(12),
  },
  {
    label: i18n.t("passwordCreate.passwordHint2"),
    achived: false,
    schema: string().matches(/[0-9]/),
  },
  {
    label: i18n.t("passwordCreate.passwordHint3"),
    achived: false,
    schema: string().matches(/[a-z]/),
  },
  {
    label: i18n.t("resetPassword.passwordHint4"),
    achived: false,
    schema: string().matches(/[A-Z]/),
  },
];

const useResetPasswordVm = (
  email: string | undefined,
  token: string | undefined
) => {
  const navigate = useNavigate();
  const [passwordHints, setPasswordHints] = useState(() => items);
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [result, setResult] = useState<ActionResponse>();

  const authContext = useAuthContext();
  const checkPasswordSecurity = (
    enteredPassword: string,
    hints: NSResetPasswordType.ITestableHint[]
  ): Promise<NSResetPasswordType.ITestableHint>[] => {
    const validationResult: Promise<NSResetPasswordType.ITestableHint>[] =
      hints.map(async (hint) => {
        const achived = await hint.schema.isValid(enteredPassword);
        const { label } = hint;
        const { schema } = hint;
        return Promise.resolve({ achived, label, schema });
      });

    return validationResult;
  };

  const passwordChangeHandler = useCallback(
    (enteredPassword: string) => {
      setPassword(enteredPassword);
      Promise.all(checkPasswordSecurity(enteredPassword, passwordHints)).then(
        (res) => {
          setPasswordHints(res);
        }
      );
    },

    [passwordHints]
  );

  const hintIems = useMemo(() => {
    const r = passwordHints.map((hint) => ({
      label: hint.label,
      achived: hint.achived,
    }));
    return r;
  }, [passwordHints]);

  const passwordControll = useMemo(
    () =>
      password !== "" &&
      confirmedPassword !== "" &&
      password !== confirmedPassword,
    [confirmedPassword, password]
  );
  const submitHandler = useCallback(async () => {
    if (
      password === confirmedPassword &&
      passwordHints.every((hint) => hint.achived) &&
      token &&
      email
    ) {
      const responseResetPassword = await authContext.resetPassword(
        email,
        token,
        password
      );

      setResult(responseResetPassword);
    }
  }, [password, confirmedPassword, passwordHints, authContext, token, email]);

  return {
    items,
    passwordControll,
    hintIems,
    result,
    passwordChangeHandler,
    submitHandler,
    setConfirmedPassword,
    checkPasswordSecurity,
    navigate,
    setResult,
  };
};

export default useResetPasswordVm;
