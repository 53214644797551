import styled from "styled-components";
import {
  ButtonHeightBySizeTypes,
  ButtonPaddingBySizeTypes,
  IButton,
} from "./MainButton.type";

export namespace NSMainButtonStyle {
  export const ButtonLabel = styled.div``;

  export const IconContainer = styled.div<{
    isSuffix?: boolean;
    iconColor?: string;
  }>`
    margin: ${(props) => (props.isSuffix ? "0 0 0 8px" : "0 8px 0 0")};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        stroke: ${(props) => props.iconColor};
      }
    }
  `;

  export const ProgressBar = styled.div`
    position: relative;
    width: 46px;
    height: 46px;
  `;

  export const CircleLoader = styled.div`
    height: 100%;
    width: 100%;

    border: solid 5px ${(props) => props.theme.colors.neutrals.grey04};
    border-top-color: ${(props) => props.theme.colors.light.light};

    border-radius: 50%;

    transform: rotate(135deg);
    animation: spin 1.3s infinite;
    animation: spin 1.3s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `;

  export const ButtonBase = styled.button<IButton>`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-family: greycliff-cf;
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    font-size: ${(props) =>
      props.size && props.size === "small" ? "0.875rem" : "1rem"};
    width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
    padding: ${(props) =>
      props.size ? ButtonPaddingBySizeTypes[props.size] : "1rem"};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.colors.light.light};
    height: ${(props) =>
      props.size ? ButtonHeightBySizeTypes[props.size] : "3.5rem"};

    ${ButtonLabel} {
      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }

    ${IconContainer} {
      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    }

    ${ProgressBar} {
      padding: 5px;
      width: calc(
        ${(props) =>
            props.size ? ButtonHeightBySizeTypes[props.size] : "3.5rem"} - 10px
      );
      height: calc(
        ${(props) =>
            props.size ? ButtonHeightBySizeTypes[props.size] : "3.5rem"} - 10px
      );
    }
  `;
}
