import styled from "styled-components";

export namespace NSLayoutStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const Sidebar = styled.section`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2.5rem 1.5rem;
    border-radius: 1rem;
  `;

  export const Content = styled.section`
    display: flex;
    width: 100%;
    flex: 3.5;
    padding: 2rem 4.25rem;
  `;
}
