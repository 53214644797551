import { GetSignedUrlDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetClientCandidateCVs(filenames: string[]) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetSignedUrlDocument,
    { enabled: false },
    {
      signedUrlInput: {
        contentType: "application/pdf",
        filenames,
        folder: "cv",
        operation: "get",
      },
    }
  );

  return {
    refetch,
    data,
    rest,
  };
}
export default useGetClientCandidateCVs;
