import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { NSInterviewContextType } from "./InterviewContext.type";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import { NSStepProgressBarType } from "../../../components/StepProgressBar/StepProgressBar.type";
import { NSDragAndDropListType } from "../../../components/DragAndDropList/DragAndDropList.type";
import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import {
  GetReviewMetricQuestionsByIdsDocument,
  InsertInterviewTemplateDocument,
  InsertReviewMetricQuestionDocument,
  UpdateProjectInterviewTemplateDocument,
} from "../../../gql/graphql";
import useCreateInterviewVm from "../../../routes/Interview-Create/Interview-CreateVM";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import i18n from "../../../library/i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const InterviewContext =
  createContext<NSInterviewContextType.IInterviewContext>({
    activePage: "",
    selectedLanguages: [],
    stepProgressBarItems: [],
    activeStepProgressBarIndex: 0,
    activeStepId: 0,
    isAllMetricQuestionsSelected: false,
    isNoticeModalOpen: false,
    metricItems: [],
    metricQuestionItems: [],
    warningModalIsOpen: false,
    isValidQuestions: false,
    setIsValidQuestions: () => {},
    setWarningModalIsOpen: () => {},
    setMetricQuestionItems: () => {},
    setMetricItems: () => {},
    onClickModalClose: () => {},
    setActiveStepProgressBarIndex: () => {},
    setStepProgressBarItems: () => {},
    changePage: () => {},
    setSelectedLanguages: () => {},
    getNextMetricsQuestions: () => {},
    getPreviousMetricsQuestions: () => {},
  });

export const InterviewPageType = {
  LANGUAGE_DATE_SELECTION: "language-date-selection",
  REVIEW_METRIC_SELECTION: "review-metric-selection",
  REVIEW_METRIC_QUESTION_SELECTION: "review-metric-question-selection",
};

function InterviewProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId") as string;

  const navigate = useNavigate();

  const { projectName } = useCreateInterviewVm();
  const [activePage, setActivePage] = useState(
    InterviewPageType.LANGUAGE_DATE_SELECTION
  );

  const [stepProgressBarItems, setStepProgressBarItems] = useState<
    NSStepProgressBarType.IStepProgressBarItem[]
  >([]);

  const [metricItems, setMetricItems] = useState<
    NSDragAndDropListType.ISortableItem[]
  >([]);

  const [metricQuestionItems, setMetricQuestionItems] = useState<any>(null);

  const [activeStepProgressBarIndex, setActiveStepProgressBarIndex] =
    useState<number>(0);
  const [selectedLanguages, setSelectedLanguages] = useState<
    NSDropdown.IDropdownItem[]
  >([]);
  const [isNoticeModalOpen, setNoticeIsModalOpen] = useState<boolean>(
    activeStepProgressBarIndex === 0
  );
  const [isValidQuestions, setIsValidQuestions] = useState<boolean>(true);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false);
  const [editedQuestionsIds, setEditedQuestionsIds] = useState<number[]>([]);
  const [isDataReadyToUpdate, setIsDataReadyToUpdate] =
    useState<boolean>(false);

  const activeStepId = stepProgressBarItems[activeStepProgressBarIndex]?.id;
  const isAllMetricQuestionsSelected =
    stepProgressBarItems.length === activeStepProgressBarIndex + 1;

  const { mutateAsync: insertInterview } = useGraphQLMutation(
    InsertInterviewTemplateDocument
  );
  const { mutateAsync: updateProjectTemplateId } = useGraphQLMutation(
    UpdateProjectInterviewTemplateDocument
  );

  const { mutateAsync: mutateInsertQuestion } = useGraphQLMutation(
    InsertReviewMetricQuestionDocument
  );

  const { data: questionsData, refetch: refetchQuestionsData } =
    useGraphQLQuery(
      GetReviewMetricQuestionsByIdsDocument,
      { enabled: false },
      {
        ids: editedQuestionsIds,
      }
    );

  const onClickModalClose = () => {
    setNoticeIsModalOpen(false);
  };

  const createInterview = (data: any) => {
    const counts = Object.values(data).map(
      (arr: any) => arr.filter((item: any) => item.isSelected).length
    );

    const isValid = counts.every((count) => count === counts[0]);
    setIsValidQuestions(isValid);

    const allSelectedQuestions = Object.values(data).flatMap((items: any) =>
      items.filter((item: any) => item.isSelected)
    );

    const weight =
      allSelectedQuestions.length > 0
        ? (1 / allSelectedQuestions.length).toFixed(3)
        : 0;

    const filteredData = allSelectedQuestions.map((item, index) => ({
      review_metric_question_id: item.id,
      weight: weight,
      max_duration: item.maxDuration,
      question_order: index + 1,
    }));

    if (isValid) {
      insertInterview({
        objects: {
          template_name: projectName,
          interview_template_review_metric_questions: {
            data: filteredData,
          },
        },
      }).then((res) => {
        updateProjectTemplateId({
          id: projectId,
          interview_template_id: res.insert_interview_template?.returning[0]
            .id as number,
        })
          .then(() => {
            navigate("/dashboard/projects", {
              state: { projectId: projectId },
            });
          })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("interview.insertTemplateSuccess")}
                type="success"
              />,

              {
                id: "insertTemplateSuccess",
              }
            );

            navigate(`/projects/detail/${projectId}/interview`, {
              state: { projectId: projectId },
            });
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("interview.insertTemplateError")}
                type="error"
              />,

              {
                id: "insertTemplateError",
              }
            );
          });
      });
    } else {
      setIsDataReadyToUpdate(false);
    }
  };

  useEffect(() => {
    if (isDataReadyToUpdate) {
      createInterview(metricQuestionItems);
    }
  }, [isDataReadyToUpdate]);

  useEffect(() => {
    if (editedQuestionsIds.length > 0) {
      refetchQuestionsData();
    }
  }, [editedQuestionsIds]);

  useEffect(() => {
    if (questionsData) {
      const getNewQuestionName = (data: any, id: number) => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const items = data[key];
            for (const item of items) {
              if (item.id === id) {
                return item.name;
              }
            }
          }
        }
        return null;
      };

      const newQuestions = questionsData.review_metric_question.map(
        ({
          id,
          review_metric,
          review_metric_question_responses,
          company_id,
          description,
          question,
          ...item
        }) => ({
          ...item,
          review_metric_id: review_metric.id,
          description: getNewQuestionName(metricQuestionItems, id),
          question: getNewQuestionName(metricQuestionItems, id),
          review_metric_question_responses: {
            data: review_metric_question_responses.map(({ id, ...item }) => ({
              ...item,
            })),
          },
        })
      );
      mutateInsertQuestion({
        objects: newQuestions,
      })
        .then((res: any) => {
          const newIds = res.insert_review_metric_question?.returning.map(
            (item: any) => item.id
          );

          let idIndex = 0;
          const updatedMetricQuestionItems = Object.keys(
            metricQuestionItems
          ).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = metricQuestionItems[key].map((item) => {
              if (item.isEdited && item.isSelected) {
                if (idIndex < newIds.length) {
                  return {
                    ...item,
                    id: newIds[idIndex++],
                  };
                }
              }
              return item;
            });
            return acc;
          }, {});
          setMetricQuestionItems(updatedMetricQuestionItems);
        })
        .finally(() => {
          setIsDataReadyToUpdate(true);
        });
    }
  }, [questionsData]);

  const checkQuestionSelection = () => {
    const items = metricQuestionItems[activeStepId] || [];

    const selectedCount = items.filter((item: any) => item.isSelected).length;

    return selectedCount >= 1 && selectedCount <= 2;
  };

  const getNextMetricsQuestions = () => {
    if (!checkQuestionSelection()) {
      setWarningModalIsOpen(true);
      return;
    } else if (isAllMetricQuestionsSelected) {
      const editedQuestions = Object.values(metricQuestionItems)
        .flatMap((items: any) =>
          items.filter((item: any) => item.isSelected && item.isEdited)
        )
        .map((item: any) => item.id);

      if (editedQuestions.length === 0) {
        setIsDataReadyToUpdate(true);
        return;
      }
      setEditedQuestionsIds(editedQuestions);
    } else {
      setActiveStepProgressBarIndex(activeStepProgressBarIndex + 1);
    }
  };

  const getPreviousMetricsQuestions = () => {
    activeStepProgressBarIndex === 0
      ? changePage(InterviewPageType.REVIEW_METRIC_SELECTION)
      : setActiveStepProgressBarIndex(activeStepProgressBarIndex - 1);
  };

  const changePage = (page: string) => {
    setActivePage(page);
  };

  const value = useMemo(
    () => ({
      activePage,
      stepProgressBarItems,
      selectedLanguages,
      activeStepProgressBarIndex,
      activeStepId,
      isAllMetricQuestionsSelected,
      isNoticeModalOpen,
      metricItems,
      metricQuestionItems,
      warningModalIsOpen,
      isValidQuestions,
      setIsValidQuestions,
      setWarningModalIsOpen,
      setMetricQuestionItems,
      setMetricItems,
      onClickModalClose,
      setActiveStepProgressBarIndex,
      setSelectedLanguages,
      setStepProgressBarItems,
      changePage,
      getNextMetricsQuestions,
      getPreviousMetricsQuestions,
    }),
    [activePage, changePage]
  );

  return (
    <InterviewContext.Provider value={value}>
      {children}
    </InterviewContext.Provider>
  );
}

function useInterviewContext() {
  const context = useContext(InterviewContext);
  if (!context) {
    throw Error(" please use in InterviewProvider");
  }

  return context;
}

export { InterviewProvider, useInterviewContext };
