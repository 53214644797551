import { faker } from "@faker-js/faker";

export type fileType = "pdf" | "docx" | "doc";
const fileTypeHelper = (fileName: string) => {
  let type;
  let fileNames;
  if (fileName) {
    const dotIndex = fileName.lastIndexOf(".");
    const extension = fileName.substring(dotIndex + 1);
    const typeAndName = {
      pdf: {
        type: "application/pdf",
        fileNames: [`${faker.datatype.uuid()}.pdf`],
      },
      docx: {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        fileNames: [`${faker.datatype.uuid()}.docx`],
      },
      doc: {
        type: "application/msword",
        fileNames: [`${faker.datatype.uuid()}.doc`],
      },
      png: {
        type: "image/png",
        fileNames: [`${faker.datatype.uuid()}.png`],
      },
      jpeg: {
        type: "image/jpeg",
        fileNames: [`${faker.datatype.uuid()}.jpeg`],
      },
      jpg: {
        type: "image/jpg",
        fileNames: [`${faker.datatype.uuid()}.jpg`],
      },
    };
    type = typeAndName[extension as fileType].type;
    fileNames = typeAndName[extension as fileType].fileNames;
  }

  return {
    type,
    fileNames,
  };
};
export default fileTypeHelper;
