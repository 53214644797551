import Icon from "../Icon/Icon";
import { NSTagStyle } from "./Tag.style";
import { NSTagType } from "./Tag.type";

function Tag({ value, isSelected, onClick, id }: NSTagType.ITag) {
  return (
    <NSTagStyle.Container key={id} isSelected={isSelected} onClick={onClick}>
      {value}
      {!isSelected && (
        <NSTagStyle.IconContainer>
          <Icon id="Add" name="Add" height={24} width={24} />
        </NSTagStyle.IconContainer>
      )}
      {isSelected && (
        <>
          <NSTagStyle.CloseContainer>
            <Icon id="Close" name="Close" height={24} width={24} />
          </NSTagStyle.CloseContainer>
          <NSTagStyle.CheckContainer>
            <Icon id="Check" name="Check" height={12} width={18} />
          </NSTagStyle.CheckContainer>
        </>
      )}
    </NSTagStyle.Container>
  );
}

export default Tag;
