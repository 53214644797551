import styled, { css } from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSFileUploadStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
  `;

  export const InfoLabel = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey05};
    margin-top: 12px;
  `;

  export const UploadContainer = styled.div<{ dragging: boolean }>`
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 31px 47px;
    border: 2px dotted ${(props) => props.theme.colors.neutrals.grey03};
    background-color: ${(props) => props.theme.colors.light.light};

    svg {
      width: 24px;
      height: 24px;
    }

    ${(props) =>
      props.dragging &&
      css`
        background-color: ${props.theme.colors.primary.light};
        border-color: ${props.theme.colors.primary.primary};

        ${InfoLabel} {
          color: ${props.theme.colors.dark.dark};
        }
        svg {
          path {
            stroke: ${props.theme.colors.primary.primary};
          }
        }
      `}
  `;

  export const Label = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 12px 0;

    ${NSMainButtonStyle.ButtonLabel} {
      color: ${(props) => props.theme.colors.information.blue01};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    }
  `;

  export const UploadedFileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    height: auto;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 8px;
    svg {
      width: 24px;
      height: 24px;
    }
    ${NSMainButtonStyle.ButtonBase} {
      width: fit-content;
      height: fit-content;
      padding: 0;
    }
  `;

  export const UploadedFileName = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    cursor: pointer;
  `;
}
