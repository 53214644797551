import { NSDashboardNotificationStyle } from "./DashboardNotification.style";
import { NSDashboardNotificationType } from "./DashboardNotification.type";

function DashboardNotification({
  message,
  typeLabel,
  type,
  borderColor,
}: NSDashboardNotificationType.IDashboardNotification) {
  return (
    <NSDashboardNotificationStyle.Container
      message={message}
      typeLabel={typeLabel}
      type={type}
      borderColor={borderColor}
    >
      <NSDashboardNotificationStyle.TypeLabelText>
        {typeLabel}
      </NSDashboardNotificationStyle.TypeLabelText>
      {message}
    </NSDashboardNotificationStyle.Container>
  );
}

export default DashboardNotification;
