import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ObjectSchema, array, number, object, string } from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Address_Type_Enum,
  Client_Location_Insert_Input,
  GetClientDocument,
  UpdateClientDocument,
} from "../../../gql/graphql";
import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import { NSEditClientType } from "./Edit-Client.type";
import useGetCountries from "../../../service/Countries/getCountries";
import useGetSectors from "../../../service/Sector/getSector";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";

// TODO: change any
const editClientSchema: ObjectSchema<
  NSEditClientType.IEditClientFormValues | any
> = object()
  .shape({
    clientName: string().required(i18n.t("forms.clientNameError")),
    phoneNumber: string().required(i18n.t("forms.phoneNumberError")),
    country: object({ id: number(), label: string() })
      .required(i18n.t("forms.countryError"))
      .default(undefined),
    city: object({ id: number(), label: string() })
      .required(i18n.t("forms.cityError"))
      .default(undefined),
    bussinessAddress: string()
      .required(i18n.t("forms.bussinessAddrError"))
      .default(undefined),
    sector: object({ id: number(), label: string() })
      .required(i18n.t("forms.sectorError"))
      .default(undefined),
    typeOfCompany: object({ id: number(), label: string() })
      .required(i18n.t("forms.companyTypeError"))
      .default(undefined),
    companySize: object({ id: number(), label: string() })
      .required(i18n.t("forms.companySizeError"))
      .default(undefined),
    aboutCompany: string().notRequired(),
    multiLocation: array().notRequired(),
  })
  .required();

const companySize = [
  { id: 1, label: "10" },
  { id: 2, label: "50" },
  { id: 3, label: "100" },
  { id: 4, label: "1000" },
];

const phoneCode = [
  {
    label: "+90",
    id: 90,
  },
  {
    label: "+56",
    id: 56,
  },
  {
    label: "+86",
    id: 86,
  },
  {
    label: "+45",
    id: 45,
  },
];

const typeOfCompany = [
  { id: "1", label: i18n.t("clients.partnership") },
  { id: "2", label: i18n.t("clients.cooperative") },
  { id: "3", label: i18n.t("clients.corporation") },
  { id: "4", label: i18n.t("clients.limited") },
];

const useEditClientVm = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [checkboxList, setCheckboxList] = useState<
    NSEditClientType.ICheckboxWithID[]
  >([]);

  const { state } = useLocation();
  const navigate = useNavigate();
  const clientId = useMemo(() => state.clientId, [state]);
  const {
    data: clientData,
    isSuccess,
    isLoading: clientLoading,
  } = useGraphQLQuery(GetClientDocument, undefined, {
    id: clientId,
  });

  const { sectors, rest: sectorRest } = useGetSectors();
  const { data: countriesData, rest: countriesRest } = useGetCountries();
  const country = useMemo(
    () =>
      countriesData?.country.map((countryItem) => ({
        id: countryItem.id,
        label: countryItem.country_name,
      })),
    [countriesRest]
  );
  const citiesItems = useMemo(
    () =>
      countriesData?.country[0]?.cities.map((citiesItem) => ({
        id: citiesItem.id,
        label: citiesItem.city_name,
      })),
    [countriesRest]
  );
  const sectorField = useMemo(() => sectors, [sectors]);

  const { mutateAsync } = useGraphQLMutation(UpdateClientDocument);

  const initialCompanySize =
    clientData?.client_by_pk?.employee_count &&
    companySize.find(
      (i) => clientData?.client_by_pk?.employee_count === parseInt(i.label, 10)
    );

  const initialTypeOfCompany =
    clientData?.client_by_pk?.working_type &&
    typeOfCompany.find((i) => clientData?.client_by_pk?.working_type === i.id);

  const initialValues = {
    clientName: clientData?.client_by_pk?.client_name ?? "",
    phoneNumber:
      clientData?.client_by_pk?.client_locations?.[0]?.address_phone ?? "",
    country: clientData?.client_by_pk?.client_locations?.[0]?.city?.country.id
      ? {
          id: clientData?.client_by_pk?.client_locations?.[0]?.city?.country.id,
          label:
            clientData?.client_by_pk?.client_locations?.[0]?.city?.country
              .country_name,
        }
      : undefined,
    city: clientData?.client_by_pk?.client_locations?.[0]?.city?.id
      ? {
          id: clientData?.client_by_pk?.client_locations?.[0]?.city?.id,
          label:
            clientData?.client_by_pk?.client_locations?.[0]?.city?.city_name,
        }
      : undefined,
    bussinessAddress:
      clientData?.client_by_pk?.client_locations?.[0]?.address || "",
    sector: clientData?.client_by_pk?.client_sectors?.[0]?.id
      ? {
          id: clientData?.client_by_pk?.client_sectors?.[0]?.sector_id,
          label:
            clientData?.client_by_pk?.client_sectors?.[0]?.sector.sector_name,
          sector_id: clientData?.client_by_pk?.client_sectors?.[0]?.id,
        }
      : undefined,
    companySize: initialCompanySize
      ? {
          id: initialCompanySize.id,
          label: initialCompanySize.label,
        }
      : undefined,
    aboutCompany: clientData?.client_by_pk?.description ?? "",
    hasMultiLocation: clientData?.client_by_pk?.has_multilocation,
    typeOfCompany: initialTypeOfCompany
      ? {
          id: initialTypeOfCompany.id,
          label: initialTypeOfCompany.label,
        }
      : undefined,
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    watch,
    reset,
    control,
  } = useForm<NSEditClientType.IEditClientFormValues>({
    resolver: yupResolver(editClientSchema),
    shouldFocusError: false,
    defaultValues: initialValues,
    values: initialValues,
  });

  const setInitialCheckboxList = () => {
    const extraLocations = clientData?.client_by_pk?.client_locations?.slice(1);
    const initialChecboxList = extraLocations?.map((location, index) => {
      return {
        id: JSON.stringify(index),
        label: location.address ?? "",
        checked: true,
      };
    });
    setCheckboxList(initialChecboxList ?? []);
  };

  useEffect(() => {
    reset(initialValues);
    setInitialCheckboxList();
  }, [clientData]);

  const createUpdatedLocation = (
    location: NSEditClientType.IEditClientLocations,
    id?: string
  ) => {
    const { address, address_phone, city_id, address_type } = location;
    const newLocationObj = {
      id: id,
      address,
      address_phone,
      client_id: clientId,
      city_id,
      address_type,
    };

    return newLocationObj;
  };

  const removeUndefinedIds = (
    updatedLocations: Client_Location_Insert_Input[]
  ) => {
    return updatedLocations.map((location) => {
      if (location.id === undefined) {
        const { id, ...locationWithoutId } = location;
        return locationWithoutId;
      }
      return location;
    });
  };

  const updateLocations = (
    firstLocation?: NSEditClientType.IEditClientLocations,
    checkboxList?: NSEditClientType.ICheckboxWithID[]
  ) => {
    if (!firstLocation && (!checkboxList || checkboxList.length === 0)) {
      return [];
    }

    const updatedLocations = [];

    if (firstLocation) {
      const idForFirstLocation =
        clientData?.client_by_pk?.client_locations[0]?.id;

      updatedLocations.push(
        createUpdatedLocation(firstLocation, idForFirstLocation)
      );
    }

    if (checkboxList) {
      checkboxList.map((checkboxItem: NSEditClientType.ICheckboxWithID) => {
        if (checkboxItem.checked) {
          const idForLocation =
            clientData?.client_by_pk?.client_locations[
              parseInt(checkboxItem.id, 10) + 1
            ]?.id;

          const newLocation = {
            ...firstLocation,
            address: checkboxItem.label ?? "",
            address_type: Address_Type_Enum.Branch,
          };

          updatedLocations.push(
            createUpdatedLocation(
              newLocation as NSEditClientType.IEditClientLocations,
              idForLocation
            )
          );
        }
      });
    }

    return removeUndefinedIds(
      updatedLocations as Client_Location_Insert_Input[]
    );
  };

  const onSubmit: SubmitHandler<NSEditClientType.IEditClientFormValues> =
    useCallback(
      (data, event) => {
        const firstLocation = {
          address: data?.bussinessAddress,
          address_phone: data?.phoneNumber,
          city: {
            city_name: data.city?.label,
            id: data.city?.id,
            country: {
              country_name: data.country?.label,
              id: data.country?.id,
            },
          },
          city_id: data.city?.id,
          address_type: Address_Type_Enum.Main,
        };

        const locations = updateLocations(
          firstLocation as NSEditClientType.IEditClientLocations,
          checkboxList
        );

        const variables: NSEditClientType.IEditClientQueryVariables = {
          client_id: clientId,
          client_name: data.clientName,
          description: data.aboutCompany,
          employee_count: parseInt(data.companySize?.label ?? "0", 10),
          has_multilocation: data.hasMultiLocation && checkboxList.length > 0,
          working_type: String(data.typeOfCompany?.id),
          client_locations: locations,
          client_sectors: {
            client_id: clientId,
            sector_id: Number(data.sector?.id),
          },
        };

        event?.preventDefault();
        mutateAsync(variables)
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.clientUpdateSuccess")}
                type="success"
              />,
              {
                id: "updateClientSuccess",
              }
            );
            navigate(-1);
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.clientUpdateError")}
                type="error"
              />,
              {
                id: "updateClientError",
              }
            );
          });
      },
      [
        clientId,
        mutateAsync,
        navigate,
        clientData?.client_by_pk?.client_locations,
        checkboxList,
      ]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const onClickInputSuffix = () => {
    const newChecboxList = checkboxList;
    newChecboxList.push({
      id: checkboxList.length.toString(),
      checked: true,
      label: inputValue,
    });
    setCheckboxList([...newChecboxList]);
    setInputValue("");
  };

  const onClickCheckbox = (key: number) => {
    const newChecboxList = checkboxList;
    newChecboxList[key].checked = !newChecboxList[key].checked;
    setCheckboxList([...newChecboxList]);
  };

  const isLoading =
    !isSuccess &&
    clientLoading &&
    countriesRest.isLoading &&
    sectorRest.isLoading;

  return {
    formErrors: errors,
    citiesItems,
    country,
    isLoading,
    phoneCode,
    inputValue,
    checkboxList,
    sectorField,
    companySize,
    typeOfCompany,
    control,
    handleSubmit: submitHandler,
    setInputValue,
    setValue,
    getValues,
    watch,
    onClickInputSuffix,
    onClickCheckbox,
  };
};

export default useEditClientVm;
