import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import { NSStepProgressBarType } from "../../../components/StepProgressBar/StepProgressBar.type";
import { NSInterviewDetailContextType } from "./InterviewDetailContext.type";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NSDragAndDropListType } from "../../../components/DragAndDropList/DragAndDropList.type";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import i18n from "../../../library/i18next";
import {
  DeleteInterviewTemplateDocument,
  DeleteReviewMetricQuestionsRelatedInterviewTemplateDocument,
  GetProjectEditInfoDocument,
  GetReviewMetricQuestionsByIdsDocument,
  InsertInterviewTemplateDocument,
  InsertReviewMetricQuestionDocument,
  UpdateProjectInterviewTemplateDocument,
} from "../../../gql/graphql";
import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import useInterviewDetailVM from "../../../routes/Interview-Detail/Interview-DetailVM";

const InterviewDetailContext =
  createContext<NSInterviewDetailContextType.IInterviewDetailContext>({
    activePage: "",
    selectedLanguages: [],
    metricQuestionItems: [],
    activeStepId: 0,
    stepProgressBarItems: [],
    activeStepProgressBarIndex: 0,
    metricItems: [],
    isAllMetricQuestionsSelected: false,
    isValidQuestions: false,
    warningModalIsOpen: false,
    isNoticeModalOpen: false,
    onClickModalClose: () => {},
    setNoticeIsModalOpen: () => {},
    setWarningModalIsOpen: () => {},
    setIsValidQuestions: () => {},
    getNextMetricsQuestions: () => {},
    getPreviousMetricsQuestions: () => {},
    setMetricItems: () => {},
    setActiveStepProgressBarIndex: () => {},
    setStepProgressBarItems: () => {},
    setMetricQuestionItems: () => {},
    setSelectedLanguages: () => {},
  });

export const InterviewDetailPageType = {
  LANGUAGE_SELECTION: "language-selection",
  DATE_SELECTION: "date-selection",
  REVIEW_METRIC_SELECTION: "review-metric-selection",
  REVIEW_METRIC_QUESTION_SELECTION: "review-metric-question-selection",
  SEE_IN_PREVIEW: "see-in-preview",
};

function InterviewDetailProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const { projectName } = useInterviewDetailVM();
  const projectId = searchParams.get("projectId") as string;
  const navigate = useNavigate();
  const { pageId } = useParams();
  const activePage =
    InterviewDetailPageType[pageId as keyof typeof InterviewDetailPageType];

  const [stepProgressBarItems, setStepProgressBarItems] = useState<
    NSStepProgressBarType.IStepProgressBarItem[]
  >([]);

  const [activeStepProgressBarIndex, setActiveStepProgressBarIndex] =
    useState<number>(0);
  const [isNoticeModalOpen, setNoticeIsModalOpen] = useState<boolean>(
    activeStepProgressBarIndex === 0
  );
  const [selectedLanguages, setSelectedLanguages] = useState<
    NSDropdown.IDropdownItem[]
  >([]);
  const [metricItems, setMetricItems] = useState<
    NSDragAndDropListType.ISortableItem[]
  >([]);

  const [isValidQuestions, setIsValidQuestions] = useState<boolean>(true);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false);

  const [metricQuestionItems, setMetricQuestionItems] = useState<any>(null);
  const [editedQuestionsIds, setEditedQuestionsIds] = useState<number[]>([]);

  const [isDataReadyToUpdate, setIsDataReadyToUpdate] =
    useState<boolean>(false);

  const activeStepId = stepProgressBarItems[activeStepProgressBarIndex]?.id;
  const isAllMetricQuestionsSelected =
    stepProgressBarItems.length === activeStepProgressBarIndex + 1;
  const checkQuestionSelection = () => {
    const items = metricQuestionItems[activeStepId] || [];

    const selectedCount = items.filter((item: any) => item.isSelected).length;

    return selectedCount >= 1 && selectedCount <= 2;
  };

  const onClickModalClose = () => {
    setNoticeIsModalOpen(false);
  };

  const { data: projectsData, refetch } = useGraphQLQuery(
    GetProjectEditInfoDocument,
    { enabled: false },
    {
      projectId,
    }
  );

  const { mutateAsync: insertInterview } = useGraphQLMutation(
    InsertInterviewTemplateDocument
  );
  const { mutateAsync: updateProjectTemplateId } = useGraphQLMutation(
    UpdateProjectInterviewTemplateDocument
  );

  const { mutateAsync: deleteInterviewTemplate } = useGraphQLMutation(
    DeleteInterviewTemplateDocument
  );

  const { mutateAsync: deleteQuestionsRelatedInterviewTemplate } =
    useGraphQLMutation(
      DeleteReviewMetricQuestionsRelatedInterviewTemplateDocument
    );

  const { mutateAsync: mutateInsertQuestion } = useGraphQLMutation(
    InsertReviewMetricQuestionDocument
  );

  const { data: questionsData, refetch: refetchQuestionsData } =
    useGraphQLQuery(
      GetReviewMetricQuestionsByIdsDocument,
      { enabled: false },
      {
        ids: editedQuestionsIds,
      }
    );

  const updateInterview = (data: any) => {
    const counts = Object.values(data).map(
      (arr: any) => arr.filter((item: any) => item.isSelected).length
    );
    const isValid = counts.every((count) => count === counts[0]);
    setIsValidQuestions(isValid);

    if (isValid) {
      const allSelectedQuestions = Object.values(data).flatMap((items: any) =>
        items.filter((item: any) => item.isSelected)
      );

      const weight =
        allSelectedQuestions.length > 0
          ? (1 / allSelectedQuestions.length).toFixed(3)
          : 0;

      const filteredData = allSelectedQuestions.map((item, index) => ({
        review_metric_question_id: item.id,
        weight: weight,
        max_duration: item.maxDuration,
        question_order: index + 1,
      }));
      insertInterview({
        objects: {
          template_name: projectName,
          interview_template_review_metric_questions: {
            data: filteredData,
          },
        },
      }).then(async (res) => {
        await refetch();

        updateProjectTemplateId({
          id: projectId,
          interview_template_id: res.insert_interview_template?.returning[0]
            .id as number,
        })
          .then(() => {
            deleteQuestionsRelatedInterviewTemplate({
              interviewTemplateId: projectsData?.project_by_pk
                ?.interview_template?.id as number,
            }).then(() => {
              deleteInterviewTemplate({
                id: projectsData?.project_by_pk?.interview_template
                  ?.id as number,
              });
            });

            navigate("/dashboard/projects");
          })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("interview.updateTemplateSuccess")}
                type="success"
              />,

              {
                id: "updateTemplateSuccess",
              }
            );

            navigate(`/projects/detail/${projectId}/interview`);
            setMetricQuestionItems(null);
            setStepProgressBarItems([]);
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("interview.updateTemplateError")}
                type="error"
              />,

              {
                id: "updateTemplateError",
              }
            );
          });
      });
    } else {
      setIsDataReadyToUpdate(false);
    }
  };

  useEffect(() => {
    if (isDataReadyToUpdate) {
      updateInterview(metricQuestionItems);
    }
  }, [isDataReadyToUpdate]);

  useEffect(() => {
    if (editedQuestionsIds.length > 0) {
      refetchQuestionsData();
    }
  }, [editedQuestionsIds]);

  useEffect(() => {
    if (questionsData) {
      const getNewQuestionName = (data: any, id: number) => {
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const items = data[key];
            for (const item of items) {
              if (item.id === id) {
                return item.name;
              }
            }
          }
        }
        return null;
      };

      const newQuestions = questionsData.review_metric_question.map(
        ({
          id,
          review_metric,
          review_metric_question_responses,
          company_id,
          description,
          question,
          ...item
        }) => ({
          ...item,
          review_metric_id: review_metric.id,
          description: getNewQuestionName(metricQuestionItems, id),
          question: getNewQuestionName(metricQuestionItems, id),
          review_metric_question_responses: {
            data: review_metric_question_responses.map(({ id, ...item }) => ({
              ...item,
            })),
          },
        })
      );
      mutateInsertQuestion({
        objects: newQuestions,
      })
        .then((res: any) => {
          const newIds = res.insert_review_metric_question?.returning.map(
            (item: any) => item.id
          );

          let idIndex = 0;
          const updatedMetricQuestionItems = Object.keys(
            metricQuestionItems
          ).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = metricQuestionItems[key].map((item) => {
              if (item.isEdited && item.isSelected) {
                if (idIndex < newIds.length) {
                  return {
                    ...item,
                    id: newIds[idIndex++],
                  };
                }
              }
              return item;
            });
            return acc;
          }, {});
          setMetricQuestionItems(updatedMetricQuestionItems);
        })
        .finally(() => {
          setIsDataReadyToUpdate(true);
        });
    }
  }, [questionsData]);

  const getNextMetricsQuestions = () => {
    if (!checkQuestionSelection()) {
      setWarningModalIsOpen(true);
      return;
    } else if (isAllMetricQuestionsSelected) {
      const editedQuestions = Object.values(metricQuestionItems)
        .flatMap((items: any) =>
          items.filter((item: any) => item.isSelected && item.isEdited)
        )
        .map((item: any) => item.id);
      if (editedQuestions.length === 0) {
        setIsDataReadyToUpdate(true);
        return;
      }
      setEditedQuestionsIds(editedQuestions);
    } else {
      setActiveStepProgressBarIndex(activeStepProgressBarIndex + 1);
    }
  };

  const getPreviousMetricsQuestions = () => {
    activeStepProgressBarIndex === 0
      ? navigate(
          `/projects/interview-detail/REVIEW_METRIC_SELECTION?projectId=${projectId}`
        )
      : setActiveStepProgressBarIndex(activeStepProgressBarIndex - 1);
  };

  const value = {
    activePage,
    stepProgressBarItems,
    selectedLanguages,
    activeStepProgressBarIndex,
    activeStepId,
    isAllMetricQuestionsSelected,
    metricQuestionItems,
    metricItems,
    isValidQuestions,
    warningModalIsOpen,
    isNoticeModalOpen,
    setNoticeIsModalOpen,
    setWarningModalIsOpen,
    setIsValidQuestions,
    setMetricItems,
    setMetricQuestionItems,
    setActiveStepProgressBarIndex,
    setSelectedLanguages,
    setStepProgressBarItems,
    onClickModalClose,
    getNextMetricsQuestions,
    getPreviousMetricsQuestions,
  };

  return (
    <InterviewDetailContext.Provider value={value}>
      {children}
    </InterviewDetailContext.Provider>
  );
}

function useInterviewDetailContext() {
  const context = useContext(InterviewDetailContext);
  if (!context) {
    throw Error("please use in InterviewDetailProvider");
  }

  return context;
}

export { InterviewDetailProvider, useInterviewDetailContext };
