import { Form } from "react-router-dom";
import styled from "styled-components";
import LinkButton from "../../../components/LinkButton/LinkButton";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";

export namespace NSLoginStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    padding-top: 9.86%;
  `;

  export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 1rem;
    width: 100%;
    max-width: 488px;
    padding: 2.5rem 0;
    margin-top: 4rem;
  `;

  export const Title = styled.h4`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    padding: 0 3rem;
  `;

  export const LoginForm = styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 3rem;
  `;

  export const SignupContainer = styled.div`
    display: flex;
  `;

  export const SignupText = styled.small`
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-size: ${(props) => props.theme.typography.size};
    line-height: ${(props) => props.theme.typography.lineHeight};
    font-weight: ${(props) => props.theme.typography.regular};
  `;

  export const SignupButton = styled(LinkButton)`
    font-size: inherit;
    font-weight: inherit;
  `;

  export const ForgetPasswordButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    ${NSMainButtonStyle.ButtonBase} {
      font-weight: 500;
    }
  `;
}
