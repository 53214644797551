/* eslint-disable react/jsx-props-no-spreading */
import colors from "../../style/colors";

import User from "../User/User";
import { NSSelectedAnswerReviewerInfoStyle } from "./SelectedAnswerReviewerInfo.style";
import { NSSelectedAnswerReviewerInfoType } from "./SelectedAnswerReviewerInfo.type";

function SelectedAnswerReviewerInfo({
  isOnlyAISelected,
  isAISelected,
  userInfo,
}: NSSelectedAnswerReviewerInfoType.ISelectedAnswerReviewerInfo) {
  return (
    <>
      {isAISelected && (
        <NSSelectedAnswerReviewerInfoStyle.AIContainer
          isOnlyAISelected={isOnlyAISelected}
        >
          <NSSelectedAnswerReviewerInfoStyle.AIIconContainer>
            <NSSelectedAnswerReviewerInfoStyle.IconContainer
              color={colors.primary.tint}
              name="AI"
            />
          </NSSelectedAnswerReviewerInfoStyle.AIIconContainer>
          AI
        </NSSelectedAnswerReviewerInfoStyle.AIContainer>
      )}
      {userInfo && (
        <NSSelectedAnswerReviewerInfoStyle.UserContainer
          isOnlyAISelected={isOnlyAISelected}
        >
          <User
            {...userInfo}
            avatarSize="xSmall"
            isAvatarCircle
            avatarType={userInfo.avatarUrl ? "image" : "white"}
          />
        </NSSelectedAnswerReviewerInfoStyle.UserContainer>
      )}
    </>
  );
}

export default SelectedAnswerReviewerInfo;
