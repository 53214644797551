import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import Modal2 from "../../components/Modal/Modal2";
import { NSInterviewDetailStyle } from "./Interview-Detail.style";
import InterviewDetailModalRenderer from "./Views/InterviewDetailModalRenderer";
import { InterviewDetailProvider } from "../../context/InterviewContext/Interview-Detail/InterviewDetailContext";

function InterviewDetail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId") as string;

  const closeHandler = () => {
    document.body.style.overflow = "auto";
    navigate(`/projects/detail/${projectId}/interview`, {
      state: { projectId },
    });
  };

  document.body.style.overflow = "hidden";
  return (
    <Modal2 onClose={closeHandler} isFullWidth>
      <NSInterviewDetailStyle.Container>
        <InterviewDetailProvider>
          <InterviewDetailModalRenderer />
          <Outlet />
        </InterviewDetailProvider>
      </NSInterviewDetailStyle.Container>
    </Modal2>
  );
}

export default InterviewDetail;
