import { Navigate, Outlet } from "react-router-dom";

import { useAuthContext } from "../../context/AuthContext/AuthContext";

function ProtectedRoute() {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    const redirectTo =
      location.pathname && location.pathname !== "/"
        ? `/auth?redirectTo=${location.pathname}`
        : "/auth";
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
