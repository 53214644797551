export default [
  { id: "1", role: "Backend Developer", experience: null, title: null },

  { id: "2", role: "Frontend Developer", experience: null, title: null },
  { id: "3", role: "Fullstack Developer", experience: null, title: null },
  { id: "4", role: "Mobil Developer", experience: null, title: null },
  { id: "5", role: "IOS Developer", experience: null, title: null },
  { id: "6", role: "Android Developer", experience: null, title: null },
  {
    id: "7",
    role: "Embedded Software Engineer",
    experience: null,
    title: null,
  },
  { id: "8", role: "Cloud Engineer", experience: null, title: null },
  { id: "9", role: "RPA Developer", experience: null, title: null },
  { id: "10", role: "R&D Engineer", experience: null, title: null },
  {
    id: "11",
    role: "Research And Development Engineer",
    experience: null,
    title: null,
  },
  {
    id: "12",
    role: "Business Development Engineer",
    experience: null,
    title: null,
  },
  { id: "13", role: "Business Analyst", experience: null, title: null },
  {
    id: "14",
    role: "Software Development Manager",
    experience: null,
    title: null,
  },
  { id: "15", role: "Scrum Master", experience: null, title: null },
  { id: "16", role: "Producut Manager", experience: null, title: null },
  { id: "17", role: "Project Manager", experience: null, title: null },
  { id: "18", role: "Product Owner", experience: null, title: null },
  { id: "19", role: "Agile Coach", experience: null, title: null },
  { id: "20", role: "SRE", experience: null, title: null },
  { id: "21", role: "Data Scientist", experience: null, title: null },
  { id: "22", role: "Data Engineer", experience: null, title: null },
  { id: "23", role: "Big Data Engineer", experience: null, title: null },
  { id: "24", role: "Data Analyst", experience: null, title: null },
  { id: "25", role: "Data Analytics Architect", experience: null, title: null },
  { id: "26", role: "Business intelligence", experience: null, title: null },
  { id: "27", role: "Network Engineer", experience: null, title: null },
  { id: "28", role: "System Engineer", experience: null, title: null },
  { id: "29", role: "IDM Specialist", experience: null, title: null },
  { id: "30", role: "DevOps Engineer", experience: null, title: null },
  {
    id: "31",
    role: "System Monitoring Engineer",
    experience: null,
    title: null,
  },
  { id: "32", role: "Network Security Admin", experience: null, title: null },
  { id: "33", role: "Cyber Security Analyst", experience: null, title: null },
  { id: "34", role: " Network  Engineer", experience: null, title: null },
  { id: "35", role: "Cyber Security Engineer", experience: null, title: null },
  {
    id: "36",
    role: "Senior Information Security Engineer",
    experience: null,
    title: null,
  },
  {
    id: "37",
    role: "Information Security Risk Compliance Specialist",
    experience: null,
    title: null,
  },
  {
    id: "38",
    role: "Information Security Officer",
    experience: null,
    title: null,
  },
  {
    id: "39",
    role: "Linux System Administrator",
    experience: null,
    title: null,
  },
  { id: "40", role: "Solution Architect", experience: null, title: null },
  { id: "41", role: "Software Architect", experience: null, title: null },
  { id: "42", role: "Cloud Solution Architect", experience: null, title: null },
  {
    id: "43",
    role: "Middleware System Administrator",
    experience: null,
    title: null,
  },
  { id: "44", role: "Database Administrator", experience: null, title: null },
  { id: "45", role: "SQL Developer", experience: null, title: null },
  { id: "46", role: "ETL Developer", experience: null, title: null },
  {
    id: "47",
    role: "Information Technology Auditor",
    experience: null,
    title: null,
  },
  {
    id: "48",
    role: "Information Security Risk Compliance",
    experience: null,
    title: null,
  },
  {
    id: "49",
    role: "Information Security Officer",
    experience: null,
    title: null,
  },
  { id: "50", role: "Security Risk Specialist", experience: null, title: null },
  { id: "51", role: "IT Support ", experience: null, title: null },
  { id: "52", role: "IT Compliance", experience: null, title: null },
  {
    id: "53",
    role: "Infrastructure and Cloud System Engineer",
    experience: null,
    title: null,
  },
  { id: "54", role: "Unreal Engine Developer", experience: null, title: null },
  { id: "55", role: "Unity Developer", experience: null, title: null },
  { id: "56", role: "Game Developer", experience: null, title: null },
  { id: "57", role: "3D Generalist", experience: null, title: null },
];
