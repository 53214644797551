import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import ReactDatePicker from "react-datepicker";
import CustomInput from "./CustomInput";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import Icon from "../Icon/Icon";
import { NSDatePickerStyle } from "./DatePicker.style";
import { NSDatePickerType } from "./DatePicker.type";
import SolidButton from "../SolidButton/SolidButton";
import dayjs from "../../library/dayjs";
import { useDetectClickOutside } from "../../hooks/useDetectClickOutside";
import i18n from "../../library/i18next";

const NUMBER_OF_WEEK = 8;
const DAY_NUMBER_IN_A_WEEK = 7;

function DatePicker({
  onChange,
  label,
  placeholder,
  defaultDates,
  minDate,
}: NSDatePickerType.IDatePicker) {
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    defaultDates?.[0] ? new Date(defaultDates?.[0]) : new Date()
  );
  const [endDate, setEndDate] = useState<Date | null | undefined>(
    defaultDates?.[1]
      ? new Date(defaultDates?.[1])
      : new Date(
          new Date().setDate(
            new Date().getDate() + NUMBER_OF_WEEK * DAY_NUMBER_IN_A_WEEK
          )
        )
  );

  const [isOpen, setIsOpen] = useState(false);

  const prepareDateString = (
    start?: Date | null | undefined,
    end?: Date | null | undefined
  ) => {
    let date = "";
    if (start !== undefined && start !== null) {
      date = dayjs(start).format("DD MMM");
    }

    if (
      end !== undefined &&
      end !== null &&
      dayjs(end).format("DD MMM") !== dayjs(start).format("DD MMM")
    ) {
      date += ` - ${dayjs(end).format("DD MMM")}`;
    }

    if (start !== undefined && start !== null) {
      date += `, ${dayjs(start).format("YYYY")}`;
    }

    return date;
  };

  const [dateString, setDateString] = useState<string>(
    prepareDateString(startDate, endDate)
  );

  useEffect(() => {
    onChange?.({ startDate, endDate });
  }, [isOpen]);

  const ref = useDetectClickOutside({
    onTrigger: () => {
      setIsOpen(false);
    },
  });
  const datePicker = useRef(null as null | HTMLDivElement);

  const onChangeDateHandler = (dates: NSDatePickerType.Dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    setDateString(prepareDateString(start, end));
  };

  const onClickButton = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClickClose = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setDateString("");
  }, []);

  const onClickInput = () => {
    setIsOpen(true);
  };

  const suffixItem = useMemo(() => {
    if (isOpen) {
      return (
        <NSDatePickerStyle.CloseButtonContainer id="CloseCircleFilledIconId">
          <GhostIconButton
            iconSize={24}
            icon="CloseCircleFilled"
            onClick={onClickClose}
          />
        </NSDatePickerStyle.CloseButtonContainer>
      );
    }
    if (startDate || endDate) {
      return (
        <Icon
          id="CalendarCheckIconId"
          width={24}
          height={24}
          name="CalendarCheck"
        />
      );
    }
    return (
      <Icon
        width={24}
        id="CalendarEmptyIconId"
        height={24}
        name="CalendarEmpty"
      />
    );
  }, [isOpen, startDate, endDate, onClickClose]);

  return (
    <div ref={ref}>
      <NSDatePickerStyle.Container
        ref={datePicker}
        datepickerWidth={datePicker.current?.offsetWidth}
      >
        <ReactDatePicker
          id="datePicker"
          minDate={minDate}
          locale="en"
          selected={startDate}
          startDate={startDate}
          onChange={onChangeDateHandler}
          endDate={endDate}
          onInputClick={() => setIsOpen(true)}
          open={isOpen}
          dateFormat="dd/MM/yyyy"
          placeholderText={placeholder}
          selectsRange
          customInput={
            <CustomInput
              suffixItem={suffixItem}
              label={label}
              placeholder={placeholder}
              dateString={dateString}
              onClick={onClickInput}
            />
          }
          showPopperArrow={false}
        >
          <NSDatePickerStyle.ButtonContainer>
            <SolidButton
              onClick={onClickButton}
              fullWidth
              label={i18n.t("general.apply")}
            />
          </NSDatePickerStyle.ButtonContainer>
        </ReactDatePicker>
      </NSDatePickerStyle.Container>
    </div>
  );
}

export default DatePicker;
