import styled from "styled-components";

export namespace NSNotificationBadgeStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.colors.primary.tint05};
    border-radius: 8px;
    padding: 16px;
    background: linear-gradient(
      97.18deg,
      #939eff -34.08%,
      #ffffff 20.5%,
      #ffffff 69.4%,
      #c8f6d3 142.78%
    );
  `;
}
