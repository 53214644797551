import { useCallback, useMemo, useState } from "react";

import Checkbox from "../Checkbox/CheckBox";
import { NSContextMenuStyle } from "./ContextMenu.style";
import { NSContextMenuType } from "./ContextMenu.type";

function CheckBoxContextMenu({
  items,
  onCheckedItems,
  divider,
}: NSContextMenuType.ICheckBoxContextMenu) {
  const [checkedItems, setCheckedItem] = useState<
    NSContextMenuType.ICheckedItems[]
  >(
    items.map((item) => ({
      item,
      isChecked: false,
    }))
  );

  const checkboxItems = useMemo(
    () =>
      items.map((item, index) => ({
        item,
        isChecked:
          index > checkedItems.length - 1
            ? false
            : checkedItems[index].isChecked,
      })),
    [checkedItems, items]
  );

  const changeChecked = useCallback(
    (index: number) => {
      const preparedItems = checkboxItems.map((item, checkedItemIndex) => {
        if (checkedItemIndex === index) {
          return {
            item: item.item,
            isChecked: !item.isChecked,
          };
        }
        return {
          ...item,
        };
      });
      setCheckedItem(preparedItems);

      const checkedContextMenuItems = items
        .filter(
          (_x, checkedItem) => preparedItems[checkedItem].isChecked === true
        )
        .map((item) => item);

      onCheckedItems?.(checkedContextMenuItems);
    },
    [checkboxItems, items, onCheckedItems]
  );

  return (
    <NSContextMenuStyle.InnerComponentContainer>
      {checkboxItems.map((checkboxItem, index) => (
        <div key={checkboxItem.item.label}>
          {checkboxItem.item.isSeperatedFromTop && divider}
          <NSContextMenuStyle.ContextMenuItem
            key={checkboxItem.item.label}
            onClick={() => {
              checkboxItem.item.onClick?.();
              changeChecked(index);
            }}
          >
            <NSContextMenuStyle.ItemLabel>
              <Checkbox
                label={checkboxItem.item.label}
                checked={checkboxItems[index].isChecked}
              />
            </NSContextMenuStyle.ItemLabel>
          </NSContextMenuStyle.ContextMenuItem>
        </div>
      ))}
    </NSContextMenuStyle.InnerComponentContainer>
  );
}

export default CheckBoxContextMenu;
