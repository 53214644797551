import styled, { css } from "styled-components";
import { NSStatsOverviewLabelType } from "./StatsOverviewLabel.type";

export namespace NSStatsOverviewLabel {
  const colorStyles = {
    default: css`
      background: ${(props) => props.theme.colors.neutrals.grey02};
      color: ${(props) => props.theme.colors.primary.primary};
    `,
    green: css`
      background: ${(props) => props.theme.colors.tertiary.light};
      color: ${(props) => props.theme.colors.tertiary.dark};
    `,
  };
  export const Label = styled.span`
    padding: 4px 12px;
    border-radius: 8px;
    text-align: center;
    width: fit-content;
    min-width: 72px;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    ${({
      color,
    }: {
      color: NSStatsOverviewLabelType.IStatsOverviewLabel["color"];
    }) => colorStyles[color ?? "default"]}
  `;
}
