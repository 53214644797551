import { SubmitHandler, useForm } from "react-hook-form";
import { NSSettingsProfileType } from "./Settings-Profile.type";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, ref, string } from "yup";
import i18n from "../../../library/i18next";
import {
  ChangePasswordDocument,
  GetSignedUrlDocument,
  UpdateUserAvatarDocument,
  UpdateUserTitleDocument,
} from "../../../gql/graphql";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { AVATARS, LOGIN_USER_INFO, PUT } from "../../../utils/globalConstants";
import { NSUserType } from "../../../components/User/User.type";
import useGetUserByPk from "../../../service/Users/getUserByPk";
import fileTypeHelper from "../../../utils/fileTypeHelper";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import { compressImageHelper } from "../../../utils/compressImageHelper";
import { NSFileUploadType } from "../../../components/FileUpload/FileUpload.type";

interface UserInfo {
  [key: string]: any;
}

const personalInfoSchema: NSSettingsProfileType.IPersonalInfoFormValues | any =
  object({
    title: string().required(i18n.t("forms.titleError")),
  }).required();

const changePasswordSchema:
  | NSSettingsProfileType.IChangePasswordFormValues
  | any = object({
  currentPassword: string().required(i18n.t("forms.currentPasswordError")),
  newPassword: string()
    .required(i18n.t("forms.newPasswordError"))
    .min(8, i18n.t("forms.passwordMinLength"))
    .matches(
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
      i18n.t("settings.changePasswordWarning")
    ),
  confirmNewPassword: string()
    .required(i18n.t("forms.confirmNewPasswordError"))
    .oneOf([ref("newPassword"), ""], i18n.t("forms.passwordMatchError")),
}).required();

const useSettingsProfileVm = () => {
  const { setUser } = useAuthContext();

  const userInfo = window.localStorage.getItem(LOGIN_USER_INFO);
  const parsedUserInfo = JSON.parse(userInfo!) as NSUserType.IAuth0User;

  const { mutateAsync: mutateUserTitle } = useGraphQLMutation(
    UpdateUserTitleDocument
  );
  const { mutateAsync: mutateUserAvatar } = useGraphQLMutation(
    UpdateUserAvatarDocument
  );
  const { mutateAsync: mutateChangePassword } = useGraphQLMutation(
    ChangePasswordDocument
  );

  const {
    data: user,
    refetch,
    isSuccess,
  } = useGetUserByPk(parsedUserInfo.sub!);
  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  function updateUserInfoField(
    fieldToUpdate: keyof UserInfo,
    updatedValue: string
  ) {
    const userInfoString = localStorage.getItem(LOGIN_USER_INFO);
    const userInfo = JSON.parse(userInfoString!);

    userInfo[fieldToUpdate] = updatedValue;
    localStorage.setItem(LOGIN_USER_INFO, JSON.stringify(userInfo));
    setUser(userInfo);
  }

  const initialPersonalInfoValues = {
    title: user?.user_by_pk?.title ?? "",
  };

  const onImageUpload = async (
    url: File | undefined,
    name: string | undefined
  ) => {
    const type = fileTypeHelper(name!);
    const compressedImage = await compressImageHelper(url!, 96, 96).catch(
      () => {
        toast(
          <ToastAlert
            description={i18n.t("settings.imageUploadError")}
            type="error"
          />,

          {
            id: "imageUploadError",
          }
        );
      }
    );
    await getSignedUrl({
      signedUrlInput: {
        contentType: type.type as string,
        filenames: type.fileNames as string[],
        folder: AVATARS,
        operation: PUT,
      },
    })
      .then(async (signedUrlResponse) => {
        if (signedUrlResponse.getSignedUrl) {
          const response = await fetch(
            `${signedUrlResponse?.getSignedUrl?.signedUrls[0]}`,
            {
              headers: {
                "x-amz-acl": "public-read",
                "Content-Type": type.type as string,
              },
              method: "PUT",
              body: compressedImage ?? url,
            }
          );

          if (response.ok) {
            const questionMarkIndex =
              signedUrlResponse?.getSignedUrl?.signedUrls[0].indexOf("?");
            const avatarUrl =
              signedUrlResponse?.getSignedUrl?.signedUrls[0].substring(
                0,
                questionMarkIndex
              );
            mutateUserAvatar({
              avatar: avatarUrl,
              id: parsedUserInfo.sub,
            })
              .then(async () => {
                updateUserInfoField("avatar", avatarUrl);
                toast(
                  <ToastAlert
                    description={i18n.t("settings.imageUploadSuccess")}
                    type="success"
                  />,

                  {
                    id: "imageUploadSuccess",
                  }
                );
                await refetch();
              })
              .catch(() => {
                toast(
                  <ToastAlert
                    description={i18n.t("settings.imageUploadError")}
                    type="error"
                  />,

                  {
                    id: "imageUploadError",
                  }
                );
              });
          } else {
            toast(
              <ToastAlert
                description={i18n.t("settings.imageUploadError")}
                type="error"
              />,

              {
                id: "imageUploadError",
              }
            );
          }
        }
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("settings.imageUploadError")}
            type="error"
          />,

          {
            id: "imageUploadError",
          }
        );
      });
  };

  const onErrorFileUpload = (message: NSFileUploadType.IError) => {
    toast(<ToastAlert type="error" description={message.label} />);
  };

  const {
    handleSubmit: handleSubmitPersonalInfo,
    control: personalInfoControl,
    getValues: getPersonalInfoValues,
  } = useForm<NSSettingsProfileType.IPersonalInfoFormValues>({
    resolver: yupResolver(personalInfoSchema),
    shouldFocusError: false,
    defaultValues: initialPersonalInfoValues,
    values: initialPersonalInfoValues,
  });

  const {
    handleSubmit: handleSubmitChangePassword,
    control: changePasswordControl,
    setValue: setChangePasswordValue,
  } = useForm<NSSettingsProfileType.IChangePasswordFormValues>({
    resolver: yupResolver(changePasswordSchema),
    shouldFocusError: false,
  });

  const onSubmitPersonalInfo: SubmitHandler<
    NSSettingsProfileType.IPersonalInfoFormValues
  > = (data, event) => {
    event?.preventDefault();
    mutateUserTitle({
      title: data.title,
      id: parsedUserInfo.sub,
    })
      .then(async () => {
        toast(
          <ToastAlert
            description={i18n.t("settings.updatePersonalInfoSuccess")}
            type="success"
          />,

          {
            id: "updatePersonalInfoSuccess",
          }
        );
        await refetch();
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("settings.updatePersonalInfoError")}
            type="error"
          />,

          {
            id: "updatePersonalInfoError",
          }
        );
      });
  };

  const personalInfoSubmitHandler = () =>
    handleSubmitPersonalInfo(onSubmitPersonalInfo);

  const onSubmitChangePassword: SubmitHandler<
    NSSettingsProfileType.IChangePasswordFormValues
  > = (data, event) => {
    event?.preventDefault();
    mutateChangePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    })
      .then(async (res) => {
        if (res.changePassword?.success) {
          toast(
            <ToastAlert
              description={i18n.t("settings.changePasswordSuccess")}
              type="success"
            />,

            {
              id: "changePasswordSuccess",
            }
          );
          setChangePasswordValue("confirmNewPassword", "");
          setChangePasswordValue("newPassword", "");
          setChangePasswordValue("currentPassword", "");
        } else {
          toast(
            <ToastAlert
              description={i18n.t(
                res.changePassword?.message
                  ? "settings.wrongPassword"
                  : "settings.changePasswordError"
              )}
              type="error"
            />,
            {
              id: "changePasswordError",
            }
          );
        }
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("settings.changePasswordError")}
            type="error"
          />,

          {
            id: "changePasswordError",
          }
        );
      });
  };

  const changePasswordSubmitHandler = () =>
    handleSubmitChangePassword(onSubmitChangePassword);

  const isLoading = !isSuccess;

  return {
    isLoading,
    user: user?.user_by_pk,
    personalInfoControl,
    changePasswordControl,
    onImageUpload,
    getPersonalInfoValues,
    handleSubmitPersonalInfo: personalInfoSubmitHandler,
    handleSubmitChangePassword: changePasswordSubmitHandler,
    onErrorFileUpload,
  };
};

export default useSettingsProfileVm;
