import { SubmitHandler, useForm } from "react-hook-form";
import { date, object, string } from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";

import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";

import { NSEditProjectType } from "./EditProject.type";
import { useGetClientDropdown } from "../../../service/Clients/getClients";

import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import {
  GetProjectEditInfoDocument,
  GetVideoInterviewByProjectIdDocument,
  Language_Enum,
  UpdateProjectDocument,
} from "../../../gql/graphql";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import useGetLanguagesDropdown from "../../../service/Languages/getLanguages";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import { useGetRolesDropdown } from "../../../service/Roles/getRoles";
import { useThrottle } from "@uidotdev/usehooks";
import useGetInterviewTemplatesDropdown from "../../../service/InterviewTemplates/getInterviewTemplates";
import useHomeVm from "../../Home/HomeVM";

const useEditProjectVm = () => {
  const { state, search } = useLocation();
  const { projectId } = state;
  const [roleSearchValue, setRoleSearchValue] = useState<string>("");
  const throttledRoleSearchValue = useThrottle(roleSearchValue, 300);
  const searchParams = new URLSearchParams(search);
  const seePreviewTemplates = searchParams.get("seePreviewTemplates");
  const homeVm = useHomeVm();

  const { data: projectData, isSuccess } = useGraphQLQuery(
    GetProjectEditInfoDocument,
    undefined,
    {
      projectId,
    }
  );

  const { data: videoInterviews } = useGraphQLQuery(
    GetVideoInterviewByProjectIdDocument,
    undefined,
    {
      projectId,
    }
  );

  const isInterviewTemplateEditable =
    videoInterviews?.client_candidate_project.every(
      (project) => project.video_interviews.length === 0
    );

  const initialValues = useMemo(() => {
    return {
      projectName: projectData?.project_by_pk?.project_name ?? "",
      description: projectData?.project_by_pk?.description ?? "",
      client: {
        id: projectData?.project_by_pk?.client.id,
        label: projectData?.project_by_pk?.client.client_name,
      },
      timePeriod: {
        startDate: projectData?.project_by_pk?.start_date,
        endDate: projectData?.project_by_pk?.end_date,
      },
      language: projectData?.project_by_pk?.language as Language_Enum,
      role: {
        id: projectData?.project_by_pk?.role?.id,
        label: projectData?.project_by_pk?.role?.title,
      },
      interviewTemplate: {
        id: projectData?.project_by_pk?.interview_template?.id,
        label: projectData?.project_by_pk?.interview_template?.template_name,
      },
    };
  }, [projectData]);

  // TODO: remove any from type
  const editProjectSchema: NSEditProjectType.IEditProjectFormValues | any =
    object({
      projectName: string().required(i18n.t("forms.projectNameError")),
      client: object({ id: string(), label: string() })
        .required(i18n.t("forms.selectClientError"))
        .default(initialValues.client),
      role: object({ id: string(), label: string() }).notRequired(),
      timePeriod: object({ startDate: date(), endDate: date() }).notRequired(),
      description: string().notRequired(),
      interviewTemplate: object({
        id: string(),
        label: string(),
      }).notRequired(),
      language: string()
        .oneOf(Object.values(Language_Enum))
        .label(i18n.t("forms.selectLanguageError"))
        .required()
        .default(initialValues.language),
    }).required();

  const {
    handleSubmit,
    formState: { errors },
    setFocus,
    control,
  } = useForm<NSEditProjectType.IEditProjectFormValues>({
    resolver: yupResolver(editProjectSchema),
    shouldFocusError: false,
    defaultValues: initialValues,
    values: initialValues,
  });
  const navigate = useNavigate();
  const { clients: clientsData, rest: clientsRest } = useGetClientDropdown();
  const { languages: languagesData } = useGetLanguagesDropdown();
  const { roles: rolesData } = useGetRolesDropdown(throttledRoleSearchValue);
  const { interviewTemplates: interviewTemplatesData } =
    useGetInterviewTemplatesDropdown(homeVm.activeUser.companyId!);

  const clients = useMemo(
    () =>
      clientsData?.map((clientItem) => ({
        id: clientItem.dropdown.id,
        label: clientItem.dropdown.label,
      })),
    [clientsRest]
  );

  const languages = useMemo(
    () =>
      languagesData?.map((languageItem) => ({
        id: languageItem.dropdown.id,
        label: languageItem.dropdown.label,
      })),

    [languagesData]
  );

  const interviewTemplates = useMemo(
    () =>
      interviewTemplatesData?.map((item) => ({
        id: item.dropdown.id,
        label: item.dropdown.label,
      })),

    [interviewTemplatesData]
  );

  const roles = useMemo(
    () =>
      rolesData?.map((roleItem) => ({
        id: roleItem.dropdown.id,
        label: roleItem.dropdown.label,
      })),

    [rolesData]
  );

  const defaultLanguage = useMemo(
    () =>
      ({
        id: initialValues?.language,
        label: initialValues?.language?.toString().toUpperCase(),
      } as NSDropdown.IDropdownItem),
    [initialValues.language]
  );

  const { mutateAsync: editProject } = useGraphQLMutation(
    UpdateProjectDocument
  );

  const isLoading = useMemo(
    () =>
      !isSuccess ||
      projectData === undefined ||
      initialValues.projectName === "" ||
      initialValues.client === undefined,
    [isSuccess, projectData, initialValues.client, initialValues.projectName]
  );

  const onSubmit: SubmitHandler<NSEditProjectType.IEditProjectFormValues> =
    useCallback(
      (data, event) => {
        event?.preventDefault();

        editProject({
          end_date: data.timePeriod.endDate,
          start_date: data.timePeriod.startDate,
          description: data.description,
          project_name: data.projectName,
          client_id: data.client.id,
          language: data.language as Language_Enum,
          role_id: data.role.id,
          interviewTemplateId: data?.interviewTemplate?.id as number,
          id: projectId,
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.projectUpdateSuccessMessage")}
                type="success"
              />,

              {
                id: "updateProjectSuccess",
              }
            );

            navigate("/projects");
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("projects.projectUpdateErrorMessage")}
                type="error"
              />,

              {
                id: "updateeProjectError",
              }
            );
          });
      },
      [editProject, projectId]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const onRoleSearchChange = (value: string | undefined) => {
    setRoleSearchValue(value ?? "");
  };

  const onClickSeePreviewTemplates = () => {
    navigate(`/projects/edit-project/${projectId}?seePreviewTemplates=true`, {
      state: { projectId: projectId },
    });
  };

  const onClickNotSeePreviewTemplates = () => {
    navigate(-1);
  };

  useEffect(() => {
    setFocus("projectName");
  }, [setFocus]);

  const onCloseDrawer = () => {
    navigate("/projects");
  };

  return {
    formErrors: errors,
    clients,
    roles,
    isLoading,
    projectData: projectData?.project_by_pk,
    languages,
    control,
    defaultLanguage,
    interviewTemplates,
    seePreviewTemplates,
    isInterviewTemplateEditable,
    onCloseDrawer,
    onClickSeePreviewTemplates,
    onClickNotSeePreviewTemplates,
    handleSubmit: submitHandler,
    onRoleSearchChange,
  };
};

export default useEditProjectVm;
