import styled from "styled-components";

import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSDatePickerStyle {
  export const Container = styled.div<{ datepickerWidth?: number }>`
    background-color: ${(props) => props.theme.colors.light.light};
    box-sizing: border-box;

    .react-datepicker-popper {
      width: ${(props) => props.datepickerWidth}px;
      background-color: ${(props) => props.theme.colors.light.light};
      padding-top: 24px;
    }

    .react-datepicker {
      font-family: greycliff-cf, sans-serif;
      width: 100%;
      border: 0;
      background-color: ${(props) => props.theme.colors.light.light};
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
      border-radius: 16px;

      &__children-container {
        width: 100%;
        padding: 0 20px 20px;
        margin: 0;
      }
    }

    .react-datepicker__month {
      margin: 0;

      &-container {
        float: unset;
        padding: 20px;
      }
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__day {
      width: 46px;
      margin: 0;
      height: 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      :hover {
        border-radius: 999px;
      }

      &--keyboard-selected {
        background-color: transparent;
      }

      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
      color: ${(props) => props.theme.colors.dark.dark};

      &--today {
        background-color: ${(props) => props.theme.colors.light.light};
        border: 1px solid ${(props) => props.theme.colors.primary.primary};
        border-radius: 999px;

        color: ${(props) => props.theme.colors.primary.primary};
      }

      &-name {
        width: 46px;
        margin: 0;
        height: 21px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        font-size: ${(props) => props.theme.typography.smallText1.size};
        line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
        font-weight: ${(props) => props.theme.typography.fontWeights.medium};
        color: ${(props) => props.theme.colors.neutrals.grey07};
      }

      &-names {
        margin: 26px 0 10px;
      }

      &--in-range {
        background-color: ${(props) => props.theme.colors.neutrals.grey02};
        border-radius: 999px;
      }
      &--in-selecting-range {
        background-color: ${(props) => props.theme.colors.neutrals.grey02};
        border-radius: 999px;
      }

      &--range-end {
        background-color: ${(props) => props.theme.colors.primary.primary};
        color: ${(props) => props.theme.colors.light.light};
        border-radius: 999px;
      }

      &--range-start {
        background-color: ${(props) => props.theme.colors.primary.primary};
        color: ${(props) => props.theme.colors.light.light};
        border-radius: 999px;
      }

      &--selected {
        background-color: ${(props) => props.theme.colors.primary.primary};
        color: ${(props) => props.theme.colors.light.light};
        border-radius: 999px;
        :hover {
          background-color: ${(props) => props.theme.colors.primary.primary};
          color: ${(props) => props.theme.colors.primary.primary};
          border-radius: 999px;
          color: ${(props) => props.theme.colors.light.light};
        }
      }
    }

    .react-datepicker__current-month {
      margin-bottom: 26px;
      font-size: ${(props) => props.theme.typography.pharagraph2.size};
      line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
      margin: 0;
    }

    .react-datepicker__header {
      background-color: ${(props) => props.theme.colors.light.light};
      border: 0;
      padding: 0;
    }

    .react-datepicker__navigation-icon::before {
      border-width: 2px 2px 0 0;
      height: 6px;
      width: 6px;
    }

    .react-datepicker__navigation {
      top: 10px;
      &--next {
        right: 10px;
      }
      &--previous {
        left: 20px;
      }
    }
  `;

  export const ButtonContainer = styled.div`
    width: 100%;
  `;

  export const CloseButtonContainer = styled.div`
    svg {
      width: 24px;

      height: 24px;
    }
    ${NSMainButtonStyle.ButtonBase} {
      padding: 0;
      width: fit-content;
      height: fit-content;
    }
  `;
}
