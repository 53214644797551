import styled, { css } from "styled-components";

export namespace NSStepProgressBarStyle {
  export const Container = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
  `;

  export const BarContainer = styled.div`
    display: flex;
    align-items: center;
  `;

  export const BarItemLabel = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: hsla(207, 5%, 56%, 1);
  `;

  export const BarItemOrder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: hsla(207, 5%, 56%, 1);
    border-radius: 50%;
    border: 2px solid hsla(207, 5%, 56%, 1);
    width: 32px;
    height: 32px;
  `;

  export const BarItem = styled.div<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    gap: 12px;

    ${(props) =>
      props.isActive &&
      css`
        ${BarItemLabel} {
          color: ${(props) => props.theme.colors.primary.primary};
        }

        ${BarItemOrder} {
          background-color: ${(props) => props.theme.colors.primary.primary};
          border: 0px;
          color: ${(props) => props.theme.colors.light.light};
        }
      `}
  `;

  export const Divider = styled.div`
    margin: 0 8px;
    height: 1px;
    width: 32px;
    border-top: 1px solid hsla(200, 5%, 87%, 1);
  `;
}
