import styled from "styled-components";
import { NSCardViewStyle } from "../../../components/CardView/CardView.style";
import { NSFreeInputStyle } from "../../../components/FreeInput/FreeInput.style";
import { NSJDGInputStyle } from "../../../components/JDGInput/JDGInput.style";
import { NSLayoutStyle } from "../../../layout/Layout.style";
import { NSMainInputStyle } from "../../../components/MainInput/MainInput.style";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import { NSDropdownStyle } from "../../../components/Dropdown/Dropdown.style";
import { NSJDGSalarySelectionStyle } from "../../../components/JDGSalarySelection/JDGSalarySelection.style";
import { NSTextAreaStyle } from "../../../components/TextArea/TextArea.style";

export namespace NSCreateJobDescriptionStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const FJContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.75rem 2rem;
  `;

  export const FJHeader = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;

    ${NSDropdownStyle.InnerDropdownTitle} {
      padding: 10px;
    }
  `;

  export const QRModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  `;

  export const Link = styled.a``;

  export const FJContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 2;
  `;

  export const Footer = styled.div<{ isInTheFirstPage?: boolean }>`
    width: ${(props) =>
      props.isInTheFirstPage ? "calc(100% - 4rem)" : "calc(100% - 6.25rem)"};
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    position: absolute;
    bottom: 2rem;

    left: ${(props) => (props.isInTheFirstPage ? "2rem" : "4.25rem")};

    right: 2rem;

    ${NSFreeInputStyle.Container} {
      ${NSMainInputStyle.InputWrapper} {
        width: 482px;
      }
    }
  `;

  export const FooterLeftContainer = styled.div`
    display: flex;
  `;

  export const FooterLeftContainerButtonGroup = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
  `;

  export const FooterRightContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    gap: 8px;
  `;

  export const WQContainer = styled(NSLayoutStyle.Container)`
    height: 100%;
    display: flex;
  `;

  export const WQLeftBox = styled(NSLayoutStyle.Sidebar)`
    padding: 0;
    background-color: transparent;
    justify-content: space-between;
  `;

  export const WQRightBox = styled(NSLayoutStyle.Content)`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  `;

  export const WQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4rem;
  `;

  export const WQQuestionItemsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 160px;
    ${NSJDGSalarySelectionStyle.InputContainer} {
      ${NSMainInputStyle.InputLabel} {
        background-color: ${(props) => props.theme.colors.neutrals.grey02};
      }
    }
  `;

  export const WQQuestionItemsContainerExperience = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    ${NSJDGInputStyle.Container} {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  `;

  export const WQQuestionItemsContainerFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  `;
  export const WQQuestionDragDropItemFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutrals.grey03};
      border-radius: 20px;
    }
  `;
  export const WQTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `;

  export const WQuestionTitle = styled.h3`
    font-size: ${(props) => props.theme.typography.titleh3.size};
    line-height: ${(props) => props.theme.typography.titleh3.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const WQSubTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
  `;

  export const WQuestionSubTitle = styled.span`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const FQuestion = styled.div`
    color: ${(props) => props.theme.colors.secondary.secondary};
    font-size: ${(props) => props.theme.typography.titleh2.size};
    line-height: ${(props) => props.theme.typography.titleh2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    text-align: center;
  `;

  export const FQQuestionLine = styled.h1``;

  export const FQButtonContainer = styled.div`
    display: flex;
  `;

  export const FQuestionButton = styled(OutlinedButton)`
    color: ${(props) => props.theme.colors.primary.primary};
    background-color: hsla(0, 0%, 0%, 0.04);
    border: 0;
    font-size: ${(props) => props.theme.typography.titleh2.size};
    line-height: ${(props) => props.theme.typography.titleh2.lineHeight};
    padding: 0.5rem 1rem;
    & svg {
      width: 48px;
      height: 48px;
      margin-left: 22px;
    }
  `;

  export const FQQuestionButtonContainer = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    align-items: flex-end;
    gap: 20px;
  `;

  export const WQQuestion = styled.p`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
  `;

  export const RoleContainer = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    & ${NSCardViewStyle.Container} {
      max-width: 673px;
    }
  `;

  export const GeneratedJobDescriptionPageContainer = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

  export const GeneratedJobDescriptionPageGreenEllipse = styled.div`
    position: absolute;
    top: 0;
    left: 0;
  `;

  export const GeneratedJobDescriptionPagePurplellipse = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
  `;

  export const LogoContainer = styled.div`
    margin: 47px auto 77px;
  `;

  export const WJobDescripitonQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 300px;
  `;

  export const IntroductionAnswerContainer = styled.div`
    ${NSTextAreaStyle.InputFlex} {
      height: 350px;
      width: 860px;
      padding: 16px;
    }

    ${NSTextAreaStyle.BaseInput} {
      height: 100%;
      width: 100%;
    }
  `;
}
