import Modal2 from "../../../components/Modal/Modal2";
import { NSTeamStyle } from "../Team.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import TextInput from "../../../components/TextInput/TextInput";
import i18n from "../../../library/i18next";
import useInviteMemberVm from "./Invite-MemberVM";

function InviteMember() {
  const inviteMemberVM = useInviteMemberVm();

  return (
    <Modal2 onClose={inviteMemberVM.closeHandler} isFullWidth={false}>
      <NSTeamStyle.ModalContainer>
        <NSTeamStyle.ModalTitle>
          {i18n.t("team.inviteUsertoTeam")}
        </NSTeamStyle.ModalTitle>
        <NSTeamStyle.ModalLabel>
          {i18n.t("team.inviteUsertoTeamDescription")}
        </NSTeamStyle.ModalLabel>
        <NSTeamStyle.InviteMemberForm onSubmit={inviteMemberVM.handleSubmit()}>
          <TextInput
            id="memberName"
            name="memberName"
            placeholder={i18n.t("forms.name")}
            error={!!inviteMemberVM.formErrors.memberName}
            errorText={inviteMemberVM.formErrors?.memberName?.message}
            onChange={(e) => inviteMemberVM.setValue("memberName", e)}
          />
          <TextInput
            id="memberSurname"
            name="memberSurname"
            placeholder={i18n.t("forms.surname")}
            error={!!inviteMemberVM.formErrors.memberSurname}
            errorText={inviteMemberVM.formErrors?.memberSurname?.message}
            onChange={(e) => inviteMemberVM.setValue("memberSurname", e)}
          />
          <TextInput
            id="emailAddress"
            name="emailAddress"
            placeholder={i18n.t("forms.emailAddress")}
            error={!!inviteMemberVM.formErrors.emailAddress}
            errorText={inviteMemberVM.formErrors?.emailAddress?.message}
            onChange={(e) => inviteMemberVM.setValue("emailAddress", e)}
          />
          <SolidButton
            type="submit"
            label={i18n.t("team.addNewUser")}
            fullWidth
          />
        </NSTeamStyle.InviteMemberForm>
      </NSTeamStyle.ModalContainer>
    </Modal2>
  );
}

export default InviteMember;
