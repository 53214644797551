const formatPhoneNumberHelper = (phoneNumber: string): string => {
  const sanitizedPhoneNumber = phoneNumber?.replace(/[^\d+]/g, "") ?? "";

  if (!sanitizedPhoneNumber) return "-";

  let localPhoneNumber = sanitizedPhoneNumber
    .replace(/^\+?90/, "")
    .replace(/^0/, "");

  if (localPhoneNumber.length !== 10) return "-";

  return `+90 (${localPhoneNumber.slice(0, 3)}) ${localPhoneNumber.slice(
    3,
    6
  )} ${localPhoneNumber.slice(6, 8)} ${localPhoneNumber.slice(8, 10)}`;
};

export default formatPhoneNumberHelper;
