import styled from "styled-components";
import { Video_Interview_Type_Enum } from "../../gql/graphql";

export namespace NSStatusBadgeStyle {
  export const Container = styled.div<{ type?: Video_Interview_Type_Enum }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 8px;
    width: max-content;
    padding: ${(props) =>
      props.type ? "4px 12px 4px 8px" : "6px 12px 6px 8px"};
  `;

  export const StatusDot = styled.div<{ dotColor?: string }>`
    width: 8px;
    height: 8px;
    background: ${(props) => props.dotColor};
    border-radius: 4px;
    margin-right: 8px;
  `;

  export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const StatusLabel = styled.div`
    font-family: greycliff-cf;
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const InterviewType = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    font-weight: ${(props) => props.theme.typography.smallText2.medium};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;
}
