import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";

import JDGInput from "../../../../../components/JDGInput/JDGInput";
import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSJDGInputType } from "../../../../../components/JDGInput/JDGInput.type";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import i18n from "../../../../../library/i18next";

const ExperienceSelectionContainer = styled.div``;

const ExperienceSelection = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const [experienceItems, setExperienceItems] = useState<
    NSJDGInputType.IJDGInput[]
  >(
    () =>
      activeQuestion?.answers.map((item, index) => ({
        label: item.value as string,
        isSelected: item.isSelected,
        onClick: () => {},
        id: index,
      })) ?? []
  );

  const getAnswersAsJson = useCallback(() => {
    const selectedExperienceItems = experienceItems.filter(
      (item) => item.isSelected
    );
    return selectedExperienceItems;
  }, [experienceItems]);

  const experienceClickHandler = (item: NSJDGInputType.IJDGInput) => {
    const updatedItems = experienceItems.map((currentItem) => ({
      ...currentItem,
      isSelected: currentItem.label === item.label,
    }));
    setExperienceItems(updatedItems);
  };

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
    }),
    [getAnswersAsJson]
  );

  const renderExperienceAnswers = () =>
    experienceItems.map((item) => (
      <JDGInput
        key={item.label}
        label={item.label}
        isSelected={item.isSelected}
        onClick={() => experienceClickHandler(item)}
        id={item.id}
      />
    ));

  return (
    <ExperienceSelectionContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionItemsContainerExperience>
          {renderExperienceAnswers()}
        </NSCreateJobDescriptionStyle.WQQuestionItemsContainerExperience>
      </NSCreateJobDescriptionStyle.WQuestionContainer>
      <NSCreateJobDescriptionStyle.Footer>
        <NSCreateJobDescriptionStyle.FooterLeftContainer />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            backgroundColor={ButtonColorVariant.grey}
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </ExperienceSelectionContainer>
  );
});

ExperienceSelection.displayName = "ExperienceSelection";

export default ExperienceSelection;
