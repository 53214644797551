import { ForgotPasswordDocument, LoginDocument, ResetPasswordDocument } from "../../gql/graphql";

import { ActionResponse } from "../../types/ActionResponse";
import { DbAuth } from "../authService/authService.type";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

const useDbAuthService = (): DbAuth => {

    const { mutateAsync: loginMutationAsync } = useGraphQLMutation(
        LoginDocument
    );
    const { mutateAsync: forgotPasswordMutationAsync } = useGraphQLMutation(
        ForgotPasswordDocument
    );
    const { mutateAsync: resetPasswordMutationAsync } = useGraphQLMutation(
        ResetPasswordDocument
    );
    async function login(username: string, password: string): Promise<string | undefined> {
        const response = await loginMutationAsync({ username, password });
        return response.login?.accessToken;
    };
    async function forgotPassword(username: string): Promise<ActionResponse> {
        const response = await forgotPasswordMutationAsync({ username });
        return response.forgotPassword!;
    };
    async function resetPassword(username: string, recoveryToken: string, password: string): Promise<ActionResponse> {
        const response = await resetPasswordMutationAsync({ username, recoveryToken, password });
        return response.resetPassword!;
    };
    return {
        login,
        forgotPassword,
        resetPassword
    };
};

export default useDbAuthService;
