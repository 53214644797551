import GhostButton from "../../../components/GhostButton/GhostButton";
import Logo from "../../../components/Logo/Logo";
import MetricPreviewBar from "../../../components/MetricPreviewBar/MetricPreviewBar";
/* eslint-disable react/jsx-props-no-spreading */
import Modal2 from "../../../components/Modal/Modal2";
import { NSCandidateReviewerQuestionStyle } from "./Candidate-Reviewer-Question.style";
import Question from "./Question";
import SolidButton from "../../../components/SolidButton/SolidButton";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import useCandidateReviewerQuestionVm from "./Candidate-Reviewer-QuestionVM";
import i18n from "../../../library/i18next";
import Drawer from "../../../components/Drawer/Drawer";
import TextArea from "../../../components/TextArea/TextArea";

function CandidateReviewerQuestion() {
  const candidateReviewerQuestionAnswerVM = useCandidateReviewerQuestionVm();
  return (
    <Modal2
      onClose={candidateReviewerQuestionAnswerVM.closeHandler}
      isFullWidth
    >
      <NSCandidateReviewerQuestionStyle.Container>
        <NSCandidateReviewerQuestionStyle.LogoContainer>
          <Logo type="onlyIcon" />
        </NSCandidateReviewerQuestionStyle.LogoContainer>

        {candidateReviewerQuestionAnswerVM.questionsWithMetric && (
          <NSCandidateReviewerQuestionStyle.ContentContainer>
            {candidateReviewerQuestionAnswerVM.barItems && (
              <MetricPreviewBar
                onClickItem={
                  candidateReviewerQuestionAnswerVM.onClickMetricItem
                }
                barItems={candidateReviewerQuestionAnswerVM.barItems}
                selectedId={
                  candidateReviewerQuestionAnswerVM.barItems[
                    candidateReviewerQuestionAnswerVM.activeMetric
                  ]?.id
                }
              />
            )}
            {candidateReviewerQuestionAnswerVM.activeQuestionProps && (
              <Question
                questionHeader={
                  candidateReviewerQuestionAnswerVM.activeQuestionProps
                    .questionHeader
                }
                question={
                  candidateReviewerQuestionAnswerVM.activeQuestionProps
                    ?.question?.questionLabel ?? ""
                }
                answerOptions={candidateReviewerQuestionAnswerVM.activeQuestionProps.question?.responses.sort(
                  (a, b) => a.score - b.score
                )}
                onClickOptionItem={
                  candidateReviewerQuestionAnswerVM.onClickOptionItem
                }
                description={
                  candidateReviewerQuestionAnswerVM.activeQuestionProps
                    .description
                }
                selectedAnswer={
                  candidateReviewerQuestionAnswerVM.selectedAnswer
                }
                isOnlyAISelected={
                  candidateReviewerQuestionAnswerVM.activeQuestionProps
                    .isOnlyAISelected
                }
                onClickReasonButton={
                  candidateReviewerQuestionAnswerVM.onClickReasonButton
                }
              />
            )}
            <NSCandidateReviewerQuestionStyle.ButtonContainer>
              <GhostButton
                label={i18n.t("general.back")}
                prefixIcon="ArrowUp"
                variant="label-w-icon-prefix"
                disabled={
                  candidateReviewerQuestionAnswerVM.isBackButtonDisabled
                }
                onClick={candidateReviewerQuestionAnswerVM.onClickBack}
              />
              <NSCandidateReviewerQuestionStyle.SecondButton>
                <SolidButton
                  {...candidateReviewerQuestionAnswerVM.continueButton}
                />
              </NSCandidateReviewerQuestionStyle.SecondButton>
            </NSCandidateReviewerQuestionStyle.ButtonContainer>
          </NSCandidateReviewerQuestionStyle.ContentContainer>
        )}
        {candidateReviewerQuestionAnswerVM.videoUrls && (
          <NSCandidateReviewerQuestionStyle.VideoPlayerContainer>
            <VideoPlayer
              urls={
                candidateReviewerQuestionAnswerVM.videoUrls.getSignedUrl
                  ?.signedUrls ?? []
              }
              title={
                candidateReviewerQuestionAnswerVM.candidateInformation.name
              }
              subtitle={
                candidateReviewerQuestionAnswerVM.candidateInformation.role
              }
              isSmall
            />
          </NSCandidateReviewerQuestionStyle.VideoPlayerContainer>
        )}
      </NSCandidateReviewerQuestionStyle.Container>

      {candidateReviewerQuestionAnswerVM.isDrawerOpen && (
        <Drawer
          blurOption="1px"
          isAddedSelfCloseFunction
          onClickCloseIcon={() =>
            candidateReviewerQuestionAnswerVM.setIsDrawerOpen(false)
          }
          title={i18n.t("candidate.reason")}
        >
          <NSCandidateReviewerQuestionStyle.DrawerContainer>
            {!candidateReviewerQuestionAnswerVM.isAIReview ? (
              <>
                <TextArea
                  label={i18n.t("candidate.reviewReason")}
                  id="reviewReasonId"
                  name="reviewReasonName"
                  value={candidateReviewerQuestionAnswerVM.reviewText}
                  onChange={(e) =>
                    candidateReviewerQuestionAnswerVM.setReviewText(
                      e.target.value
                    )
                  }
                />
                <SolidButton
                  label={i18n.t("general.apply")}
                  fullWidth
                  onClick={candidateReviewerQuestionAnswerVM.onClickApplyButton}
                />
              </>
            ) : (
              <NSCandidateReviewerQuestionStyle.ReviewText>
                {candidateReviewerQuestionAnswerVM.reviewText}
              </NSCandidateReviewerQuestionStyle.ReviewText>
            )}
          </NSCandidateReviewerQuestionStyle.DrawerContainer>
        </Drawer>
      )}
    </Modal2>
  );
}

export default CandidateReviewerQuestion;
