import styled from "styled-components";
import MainButton from "../../../../components/MainButton/MainButton";

export namespace NSAddCandidateFromExcelStyle {
  export const Container = styled.div`
    width: 100%;
    height: max-content;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  `;

  export const InformationText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const ErrorContainer = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 8px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.error.red02};
  `;

  export const ErrorTitle = styled.div`
    color: ${(props) => props.theme.colors.error.red01};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ErrorContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
  `;

  export const ErrorInfoContainer = styled.div``;

  export const ErrorTextContainer = styled.div``;

  export const ErrorText = styled.li`
    list-style-type: disc;
    margin-left: 16px;
    color: ${(props) => props.theme.colors.error.red01};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const ErrorInfo = styled.li`
    color: ${(props) => props.theme.colors.error.red03};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const Divider = styled.span`
    background-color: ${(props) => props.theme.colors.error.red03};
    height: 1px;
    width: 100%;
    :last-child {
      display: none;
    }
  `;

  export const DownloadListButton = styled(MainButton)`
    background: none;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 0;
  `;
}
