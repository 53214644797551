import { useNavigate } from "react-router-dom";
import Icon from "../../../components/Icon/Icon";
import Modal from "../../../components/Modal/Modal";
import i18n from "../../../library/i18next";
import { NSCreateProjectStyle } from "./CreateProject.style";
import useCreateProjectVm from "./CreateProjectVm";
import ContextMenuHOC from "../../../components/ContextMenuHOC/ContextMenuHOC";
import { Language_Enum } from "../../../gql/graphql";

const CreateInterviewModal = () => {
  const createProjectVm = useCreateProjectVm();
  const navigate = useNavigate();
  return (
    <Modal
      isDarkBackgroundActive
      isOpen={true}
      onClickCloseButton={() => navigate(-1)}
    >
      <NSCreateProjectStyle.ModalContainer>
        <NSCreateProjectStyle.ModalTitle>
          {i18n.t("interview.timeForQuestions")}
        </NSCreateProjectStyle.ModalTitle>
        <NSCreateProjectStyle.ModalSubTitle>
          {i18n.t("interview.chooseInterviewType")}
        </NSCreateProjectStyle.ModalSubTitle>
        <NSCreateProjectStyle.ModalContentTitle>
          {i18n.t("interview.interviewOptions")}
        </NSCreateProjectStyle.ModalContentTitle>
        <ContextMenuHOC
          id="pre-interview-context-menu"
          type="basic"
          items={[
            {
              label: i18n.t("interview.turkishQuestions"),
              onClick: () => {
                createProjectVm.onClickPreInterviewModule(Language_Enum.Tr);
              },
            },
            {
              label: i18n.t("interview.englishQuestions"),
              onClick: () => {
                createProjectVm.onClickPreInterviewModule(Language_Enum.En);
              },
            },
          ]}
          customButton={
            <NSCreateProjectStyle.PreInterviewModule>
              <NSCreateProjectStyle.ModuleContent>
                <NSCreateProjectStyle.ModuleTitle>
                  {i18n.t("interview.continuePreInterviewModule")}
                </NSCreateProjectStyle.ModuleTitle>
                <NSCreateProjectStyle.ModuleSubTitle>
                  {i18n.t("interview.includesQuestions")}
                </NSCreateProjectStyle.ModuleSubTitle>
              </NSCreateProjectStyle.ModuleContent>
              <NSCreateProjectStyle.IconContainer>
                <Icon name="ChevronRight" />
              </NSCreateProjectStyle.IconContainer>
            </NSCreateProjectStyle.PreInterviewModule>
          }
        />

        <NSCreateProjectStyle.LonglistModule
          onClick={createProjectVm.onClickLongListModule}
        >
          <NSCreateProjectStyle.ModuleContent>
            <NSCreateProjectStyle.ModuleTitle>
              {i18n.t("interview.continueLonglistModule")}
            </NSCreateProjectStyle.ModuleTitle>
            <NSCreateProjectStyle.ModuleSubTitle>
              {i18n.t("interview.includesQuestionsFromPool")}
            </NSCreateProjectStyle.ModuleSubTitle>
          </NSCreateProjectStyle.ModuleContent>
          <NSCreateProjectStyle.IconContainer>
            <Icon name="ChevronRight" />
          </NSCreateProjectStyle.IconContainer>
        </NSCreateProjectStyle.LonglistModule>
        <NSCreateProjectStyle.ModalContentTitle>
          {i18n.t("interview.testOptions")}
        </NSCreateProjectStyle.ModalContentTitle>
        <NSCreateProjectStyle.LanguageTestModule
          onClick={createProjectVm.onClickLanguageTestModule}
        >
          <NSCreateProjectStyle.ModuleContent>
            <NSCreateProjectStyle.ModuleTitle>
              {i18n.t("interview.continueLanguageTest")}
            </NSCreateProjectStyle.ModuleTitle>
          </NSCreateProjectStyle.ModuleContent>
          <NSCreateProjectStyle.IconContainer>
            <Icon name="ChevronRight" />
          </NSCreateProjectStyle.IconContainer>
        </NSCreateProjectStyle.LanguageTestModule>
        <NSCreateProjectStyle.Divider />
        <NSCreateProjectStyle.CreateInterviewLater>
          <NSCreateProjectStyle.Or>
            {i18n.t("interview.or")}
          </NSCreateProjectStyle.Or>
          <NSCreateProjectStyle.LaterButton
            label={i18n.t("interview.createInterviewLater")}
            onClick={createProjectVm.onClickCreateInterviewLater}
            type="button"
            size="medium"
          />
        </NSCreateProjectStyle.CreateInterviewLater>
        <NSCreateProjectStyle.NotificationBadge>
          <NSCreateProjectStyle.BadgeTitle>
            {i18n.t("interview.notificationBadgeTitle")}
          </NSCreateProjectStyle.BadgeTitle>
          <NSCreateProjectStyle.BadgeSubTitle>
            {i18n.t("interview.notificationBadgeSubTitle")}
          </NSCreateProjectStyle.BadgeSubTitle>
        </NSCreateProjectStyle.NotificationBadge>
      </NSCreateProjectStyle.ModalContainer>
    </Modal>
  );
};

export default CreateInterviewModal;
