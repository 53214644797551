import styled from "styled-components";

export namespace NSSearchModalStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 673px;
    font-family: greycliff-cf, sans-serif;
    background-color: ${(props) => props.theme.colors.light.light};
  `;
  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 24px;
  `;
  export const SubTitleContainer = styled.div`
    display: flex;
    margin-top: 48px;
    margin-bottom: 18px;
  `;
  export const ResultContainer = styled.div``;
  export const RecentDarkTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
  `;
  export const RecentGrayTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey05};
    margin-left: 24px;
  `;

  export const NoResultFindSecondTitle = styled(RecentGrayTitle)`
    margin-left: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
  `;
  export const SuggestionContainer = styled.div`
    max-height: 180px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.shadesOfdark.dark06};
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${(props) => props.theme.colors.shadesOfdark.dark05};
    }
  `;

  export const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transition: opacity 0.3s;
    transition-delay: 0.2s;
    opacity: 0;
    margin-left: 2px;
  `;

  export const SuggestionLabel = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.primary.primary};
    transition: all 0.5s;
  `;

  export const SuggestionItem = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 0px;
    flex-grow: 0;
    cursor: pointer;
    transition: background-color 0.5s linear;
    margin-bottom: 10px;
    position: relative;

    &:hover {
      background-color: ${(props) => props.theme.colors.shadesOfdark.dark05};
      border-radius: 4px;
      ${IconContainer} {
        opacity: 1;
        transition-delay: 0;
        transition-duration: 0.1s;
      }
      ${SuggestionLabel} {
        margin-left: 28px;
      }
    }
  `;
}
