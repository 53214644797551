import i18n from "../../../../../library/i18next";
import { NSAllNotesStyle } from "./All-Notes.style";
import { NSAllNotesType } from "./All-Notes.type";
import NoteCard from "../../../../../components/NoteCard/NoteCard";
import capitalizeFirstLetterHelper from "../../../../../utils/capitalizeFirstLetterHelper";
import dayjs from "dayjs";
import useAllNotesVm from "./All-NotesVM";
import { Controller } from "react-hook-form";
import TextArea from "../../../../../components/TextArea/TextArea";
import { NSNoteCardStyle } from "../../../../../components/NoteCard/NoteCard.style";

export default function AllNotes() {
  const {
    notes,
    control,
    selectedNoteId,
    handleEditNote,
    handleSubmit,
    handleDeleteNote,
  } = useAllNotesVm();
  return (
    <NSAllNotesStyle.Container>
      <NSAllNotesStyle.NotesContainer>
        {notes?.map((item: NSAllNotesType.INoteItem) =>
          item.id === selectedNoteId ? (
            <NSAllNotesStyle.FormContainer
              key={item.id}
              onSubmit={handleSubmit()}
            >
              <Controller
                name="note"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  const { ref, ...fieldProps } = field;
                  return (
                    <TextArea
                      id="noteId"
                      error={!!error?.message}
                      errorText={error?.message}
                      {...fieldProps}
                    />
                  );
                }}
              />
              <NSAllNotesStyle.SaveButton
                type="submit"
                label={i18n.t("general.save")}
              />
              <NSNoteCardStyle.HeaderInfo>
                {capitalizeFirstLetterHelper(
                  `${item.user.name} ${item.user.surname}`
                )}{" "}
                {dayjs(item.created_at).format("DD.MM.YYYY[,] hh:mm")}
              </NSNoteCardStyle.HeaderInfo>
            </NSAllNotesStyle.FormContainer>
          ) : (
            <NoteCard
              key={item.id}
              contextMenu={{
                type: "withIcon",
                items: [
                  {
                    iconName: "Edit",
                    label: i18n.t("general.edit"),
                    onClick: () => handleEditNote(item.id),
                  },

                  {
                    iconName: "Trash",
                    label: i18n.t("general.delete"),
                    onClick: () => handleDeleteNote(item.id),
                  },
                ],
              }}
              user={capitalizeFirstLetterHelper(
                `${item.user.name} ${item.user.surname}`
              )}
              createdAt={dayjs(item.created_at).format("DD.MM.YYYY[,] hh:mm")}
              note={item.note}
              isEditing={item.id === selectedNoteId}
            />
          )
        )}
      </NSAllNotesStyle.NotesContainer>
    </NSAllNotesStyle.Container>
  );
}
