import Icon from "../Icon/Icon";
import { NSJDGInputStyle } from "./JDGInput.style";
import { NSJDGInputType } from "./JDGInput.type";

function JDGInput({
  label,
  isSelected,
  onClick,
  isDisabled = false,
}: NSJDGInputType.IJDGInput) {
  return (
    <NSJDGInputStyle.Container
      isSelected={isSelected}
      onClick={onClick}
      disabled={isDisabled}
    >
      {label}

      <NSJDGInputStyle.IconContainer isSelected={isSelected}>
        <Icon id="Check" name="Check" height={12} width={18} />
      </NSJDGInputStyle.IconContainer>
    </NSJDGInputStyle.Container>
  );
}

export default JDGInput;
