import { InsertClientDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useInsertClient() {
  const { mutateAsync, ...rest } = useGraphQLMutation(InsertClientDocument);

  return {
    mutateAsync,
    rest,
  };
}

export default useInsertClient;
