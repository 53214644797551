import { NSCreateJobDescriptionStyle } from "../CreateJobDescription.style";
import SearchModal from "../../../../components/SearchModal/SearchModal";
import i18n from "../../../../library/i18next";
import useJobDescriptionContextRoleVm from "../ViewModels/JobDescriptionRoleVm";

interface IJobDescriptionRole {
  vm: () => ReturnType<typeof useJobDescriptionContextRoleVm>;
}

function JobDescriptionRole({ vm }: IJobDescriptionRole) {
  const jobDescriptionRoleVm = vm();

  return (
    <NSCreateJobDescriptionStyle.RoleContainer>
      <SearchModal
        isOpen
        onClickCloseButton={() => {
          jobDescriptionRoleVm.goBack();
        }}
        onClose={() => {
          jobDescriptionRoleVm.goBack();
        }}
        mainTitle={i18n.t("jd.searchModalMainTitle")}
        searchPlaceholder={i18n.t("jd.searchPlaceholder")}
        recentsSuggestionsList={jobDescriptionRoleVm.filteredRoles}
        resultList={jobDescriptionRoleVm.filteredRoles}
        onChangeSearch={jobDescriptionRoleVm.searchChangeHandler}
        onResultSelected={jobDescriptionRoleVm.resultSelectedHandler}
      />
    </NSCreateJobDescriptionStyle.RoleContainer>
  );
}

export default JobDescriptionRole;
