import { ReactNode, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import ProjectCard from "../../../components/ProjectCard/ProjectCard";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";
import { NSProjectsStyle } from "../Projects.style";

import Loading from "../../../components/Loading/Loading";
import EmptyView from "../../../components/EmptyView/EmptyView";
import Modal from "../../../components/Modal/Modal";
import { NSProjectListStyle } from "./ProjectList.style";
import SolidButton from "../../../components/SolidButton/SolidButton";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import useProjectsListVm from "./ProjectListVM";
import i18n from "../../../library/i18next";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";
import Pagination from "../../../components/Pagination/Pagination";

function ProjectList() {
  const projectsVM = useProjectsListVm();
  const navigate = useNavigate();

  const onClickProjectCard = useCallback(
    (id: string) => {
      navigate(`detail/${id}/overview`, { state: { projectId: id } });
    },
    [navigate]
  );

  const pageContent = useMemo(() => {
    let content: ReactNode;
    if (projectsVM.isLoading) {
      content = <Loading />;
    } else if (
      projectsVM.projectCardItems &&
      projectsVM.projectCardItems?.length !== 0
    ) {
      content = (
        <NSProjectListStyle.ContentContainer>
          <NSProjectsStyle.Content>
            {projectsVM.projectCardItems?.map((projectItem) => (
              <NSProjectsStyle.CardContainer key={projectItem.id}>
                <ProjectCard
                  id={projectItem.id}
                  onClick={() => onClickProjectCard(projectItem.id)}
                  contextMenu={{
                    type: "withIcon",
                    items: [
                      {
                        iconName: "Edit",
                        label: i18n.t("general.edit"),
                        onClick: () =>
                          navigate(`edit-project/${projectItem.id}`, {
                            state: { projectId: projectItem.id },
                          }),
                      },

                      {
                        iconName: "Trash",
                        label: i18n.t("projects.archive"),
                        onClick: () => projectsVM.onClickDelete(projectItem.id),
                      },
                    ],
                  }}
                  projectName={projectItem.projectName}
                  status={projectItem.status}
                  clientName={projectItem.clientName}
                  information={projectItem.information}
                  avatars={projectItem.avatars}
                  deadline={projectItem.deadline}
                />
              </NSProjectsStyle.CardContainer>
            ))}
          </NSProjectsStyle.Content>
          {projectsVM.totalItemCount >= PAGINATION_DEFAULT_LIMIT && (
            <NSProjectListStyle.PaginationContainer>
              <Pagination
                pagination={projectsVM.pagination}
                isLoading={projectsVM.isLoading}
                itemCount={projectsVM.totalItemCount}
                updateQueryLimit={(start, limit) =>
                  projectsVM.handleSetPagination(start, limit)
                }
              />
            </NSProjectListStyle.PaginationContainer>
          )}
        </NSProjectListStyle.ContentContainer>
      );
    } else {
      content = (
        <EmptyView
          buttonLabel={projectsVM.emptyView.buttonLabel}
          onClick={projectsVM.emptyView.onClick}
          errorMessage={projectsVM.emptyView.errorMessage}
        />
      );
    }

    return content;
  }, [navigate, onClickProjectCard, projectsVM]);

  return (
    <>
      <NSProjectListStyle.Container>
        <NSDashboardLayoutStyle.HeaderContainer>
          <DashboardHeader
            breadcrumb={projectsVM.breadcrumb}
            searchInput={projectsVM.searchInput}
            dropdowns={projectsVM.dropdownsItems}
          />
        </NSDashboardLayoutStyle.HeaderContainer>

        {pageContent}
      </NSProjectListStyle.Container>

      <Modal
        isDarkBackgroundActive
        isOpen={projectsVM.isDeleteModalOpen}
        onClickCloseButton={projectsVM.onClickCloseButton}
      >
        <NSProjectListStyle.ModalContainer>
          <NSProjectListStyle.ModalTitle>
            {i18n.t("projects.archiveProject")}
          </NSProjectListStyle.ModalTitle>
          {i18n.t("projects.archiveProjectLabel")}
          <NSProjectListStyle.ModalButtonContainer>
            <SolidButton
              fullWidth
              label={i18n.t("projects.archive")}
              onClick={() =>
                projectsVM.onArchiveProject(projectsVM.deletedProjectId)
              }
            />
            <OutlinedButton
              fullWidth
              label={i18n.t("general.giveUp")}
              onClick={() => {
                projectsVM.onClickCloseButton();
              }}
            />
          </NSProjectListStyle.ModalButtonContainer>
        </NSProjectListStyle.ModalContainer>
      </Modal>
    </>
  );
}

export default ProjectList;
