import { NSAvatarStyle } from "./Avatar.style";
import { NSAvatarType } from "./Avatar.type";

function Avatar({
  avatarUrl,
  size = "medium",
  type = "image",
  letter,
  status,
  isAvatarCircle,
}: NSAvatarType.IAvatar) {
  const content = {
    image: (
      <NSAvatarStyle.Image role="img" src={avatarUrl} alt="avatar-image" />
    ),
    white: letter && (
      <NSAvatarStyle.Letter size={size} type={type}>
        {letter}
      </NSAvatarStyle.Letter>
    ),
    solid: letter && (
      <NSAvatarStyle.Letter size={size} type={type}>
        {letter}
      </NSAvatarStyle.Letter>
    ),
  };

  return (
    <NSAvatarStyle.Container>
      {status && <NSAvatarStyle.Status status={status} />}
      <NSAvatarStyle.AvatarContainer
        type={type}
        avatarUrl={avatarUrl}
        size={size}
        isAvatarCircle={isAvatarCircle}
      >
        {content[type]}
      </NSAvatarStyle.AvatarContainer>
    </NSAvatarStyle.Container>
  );
}

export default Avatar;
