import { InsertProjectDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useInsertProject() {
  const { mutateAsync, ...rest } = useGraphQLMutation(InsertProjectDocument);

  return {
    mutateAsync,
    rest,
  };
}

export default useInsertProject;
