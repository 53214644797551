// eslint-disable-next-line import/no-extraneous-dependencies
import { darken, lighten } from "polished";

import styled from "styled-components";
import { ISolidButton } from "./SolidButton.type";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSSolidButtonStyle {
  export const SolidButtonBase = styled.div<ISolidButton>`
    width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
    ${NSMainButtonStyle.ButtonBase} {
      background-color: ${(props) => props.backgroundColor};
      border-width: 0;

      &:disabled {
        background-color: ${(props) =>
          props.isLoading
            ? props.backgroundColor
            : props.theme.colors.secondary.light} !important;
      }

      &:hover {
        background-color: ${(props) =>
          props.backgroundColor?.toString() ===
          `${props.theme.colors.neutrals.grey09}`
            ? lighten(0.24, props.backgroundColor)
            : darken(
                0.24,
                props.backgroundColor ?? props.theme.colors.primary.primary
              )};
      }
    }
  `;
}
