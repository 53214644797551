/* eslint-disable react/jsx-props-no-spreading */

import JobDescriptionListItem from "../../../../components/JobDescriptionListItem/JobDescriptionListItem";
import { Job_Description_Step_Enum } from "../../../../gql/graphql";
import Loading from "../../../../components/Loading/Loading";
import { PAGINATION_DEFAULT_LIMIT } from "../../../../utils/globalConstants";
import Pagination from "../../../../components/Pagination/Pagination";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import i18n from "../../../../library/i18next";
import { useNavigate } from "react-router-dom";
import { NSProjectDetailJobDescriptionStyle } from "./DetailJobDescription.style";
import useDetailJobDescriptionVm from "./DetailJobDescriptionVM";

function DetailJobDescription() {
  const navigate = useNavigate();
  const JobDescriptionVm = useDetailJobDescriptionVm();

  return JobDescriptionVm.isJobDescriptionLoading ? (
    <Loading />
  ) : (
    <NSProjectDetailJobDescriptionStyle.JobDescriptionContainer>
      {JobDescriptionVm.projectDetailData.jobDescriptions &&
      JobDescriptionVm.projectDetailData.jobDescriptions.length > 0 ? (
        JobDescriptionVm.projectDetailData.jobDescriptions?.map((item) => (
          <JobDescriptionListItem
            id={item.id}
            key={item.id}
            name={item.title || ""}
            user={{
              avatarType: "image",
              name: `${item.owner.name} ${item.owner.surname}`,
              avatarUrl: item.owner.avatar ?? "",
              date: item.created_at,
            }}
            contextMenu={{
              type: "withIcon",
              items: [
                {
                  iconName: "Trash",
                  label: i18n.t("general.delete"),
                  onClick: () => {
                    JobDescriptionVm.deleteJobDescriptionFunction(item.id);
                  },
                },
                {
                  iconName: "News",
                  label:
                    item.step === Job_Description_Step_Enum.Completed
                      ? i18n.t("general.view")
                      : i18n.t("general.continue"),
                  onClick: () => {
                    if (item.step === Job_Description_Step_Enum.Completed) {
                      navigate(`/job-description/detail/${item.id}`, {
                        state: { jdId: item.id },
                      });
                    } else {
                      navigate(`/job-description/create?jdId=${item.id}`);
                    }
                  },
                },
              ],
            }}
          />
        ))
      ) : (
        <NSProjectDetailJobDescriptionStyle.NotFoundContainer>
          <NSProjectDetailJobDescriptionStyle.NotFoundUpper>
            <NSProjectDetailJobDescriptionStyle.NotFoundText>
              {i18n.t("projects.jdEmpty")}
            </NSProjectDetailJobDescriptionStyle.NotFoundText>
            <SolidButton
              label={JobDescriptionVm.generateJDButton.label}
              onClick={JobDescriptionVm.generateJDButton.onClick}
            />
          </NSProjectDetailJobDescriptionStyle.NotFoundUpper>
        </NSProjectDetailJobDescriptionStyle.NotFoundContainer>
      )}
      {JobDescriptionVm.paginationComponent.itemCount >=
        PAGINATION_DEFAULT_LIMIT && (
        <NSProjectDetailJobDescriptionStyle.PaginationContainer>
          <Pagination
            isLoading={JobDescriptionVm.isJobDescriptionLoading}
            pagination={JobDescriptionVm.pagination}
            itemCount={JobDescriptionVm.paginationComponent.itemCount}
            updateQueryLimit={(start, limit) =>
              JobDescriptionVm.onSetPagination(start, limit)
            }
          />
        </NSProjectDetailJobDescriptionStyle.PaginationContainer>
      )}
    </NSProjectDetailJobDescriptionStyle.JobDescriptionContainer>
  );
}

export default DetailJobDescription;
