import styled from "styled-components";
import { NSUserType } from "./User.type";

export namespace NSUserStyle {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    font-family: greycliff-cf;
  `;

  export const InfoLeftContainer = styled.div<{
    avatarInfoMargin: NSUserType.AvatarInfoMarginVariant;
  }>`
    display: flex;
    flex-direction: column;
    margin-right: ${(props) =>
      NSUserType.AvatarInfoMarginTypes[props.avatarInfoMargin]};
  `;

  export const InfoRightContainer = styled.div<{
    avatarInfoMargin: NSUserType.AvatarInfoMarginVariant;
  }>`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) =>
      NSUserType.AvatarInfoMarginTypes[props.avatarInfoMargin]};
  `;

  export const CompanyNameText = styled.small`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey06};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const NameText = styled.small`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const UserJobText = styled.small`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.neutrals.grey06};
  `;

  export const DateText = styled.small`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const UsernameText = styled.small`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
  `;
}
