import { NSDropdown } from "../../components/Dropdown/Dropdown.type";
import { GetSectorDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetSectors() {
  const { data, ...rest } = useGraphQLQuery(GetSectorDocument);

  const sectors: NSDropdown.IDropdownItem[] =
    data?.sector.map((item) => ({
      label: item.sector_name,
      id: item.id,
    })) ?? [];

  return {
    sectors,
    rest,
  };
}
export default useGetSectors;
