import { useState } from "react";

import { ITextArea } from "./TextArea.type";
import { NSTextAreaStyle } from "./TextArea.style";

function TextArea({
  label,
  onBlur,
  errorText,
  id,
  error,
  onChange,
  name,
  placeholder,
  disabled = false,
  onKeyUp,
  onFocus,
  readonly = false,
  value,
}: ITextArea) {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const changeInputValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange?.(event);
  };

  return (
    <NSTextAreaStyle.InputWrapper
      onFocus={() => setIsFocused(true)}
      isFocused={isFocused}
      onBlur={() => setIsFocused(false)}
    >
      {label && (
        <NSTextAreaStyle.InputLabel htmlFor={id} error={error}>
          {label}
        </NSTextAreaStyle.InputLabel>
      )}
      <NSTextAreaStyle.InputFlex isError={error} isDisabled={disabled}>
        <NSTextAreaStyle.BaseInput
          id={id}
          readOnly={readonly}
          role="textbox"
          onKeyUp={onKeyUp}
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          value={value}
          onFocus={onFocus}
          onChange={changeInputValue}
          onBlur={onBlur}
        />
      </NSTextAreaStyle.InputFlex>
      {error && (
        <NSTextAreaStyle.ErrorText>{errorText}</NSTextAreaStyle.ErrorText>
      )}
    </NSTextAreaStyle.InputWrapper>
  );
}

export default TextArea;
