/* c8 ignore start */
import colors from "./colors";
import typography from "./typography";

export const defaultTheme = {
  default: colors.dark.dark,
  interactive: colors.primary.primary,
  secondary: colors.secondary.secondary,
  placeholder: colors.neutrals.grey05,
  disable: colors.neutrals.grey05,
  error: colors.error.red01,
  colors,
  typography,
};

export const darkTheme = {
  default: colors.light.light,
  interactive: colors.primary.primary,
  secondary: colors.shadesOflight.light85,
  placeholder: colors.shadesOflight.light65,
  disable: colors.shadesOflight.light45,
  error: colors.error.red01,
  colors,
  typography,
};
/* c8 ignore stop */
