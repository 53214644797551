import styled from "styled-components";

export namespace NSBadgeStyle {
  export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.primary.primary};
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: 1.125rem;
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.light.light};
    padding: 4px 8px;
    border-radius: 32px;
    display: flex;
    font-family: greycliff-cf, sans-serif;
    width: fit-content;
    align-items: center;
    justify-content: center;
  `;
}
