import { Trans } from "react-i18next";
import HistoryTooltip from "../../../../components/HistoryTooltip/HistoryTooltip";
import Icon from "../../../../components/Icon/Icon";
import Loading from "../../../../components/Loading/Loading";
import ScoreBadge from "../../../../components/ScoreBadge/ScoreBadge";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import i18n from "../../../../library/i18next";
import { NSInterviewDetailStyle } from "../../Interview-Detail.style";
import useInterviewDetailVM from "../../Interview-DetailVM";

const InterviewPreview = () => {
  const {
    isPreviewLoading,
    currentPreviewQuestion,
    notLastPreviewQuestion,
    currentPreviewQuestionIndex,
    previewQuestions,
    onClickNextPreviewQuestion,
  } = useInterviewDetailVM();
  return isPreviewLoading ? (
    <Loading />
  ) : (
    <NSInterviewDetailStyle.PreviewContainer>
      <NSInterviewDetailStyle.PreviewHeader>
        <NSInterviewDetailStyle.PreviewCandidateName>
          {i18n.t("interview.previewCandidateName")}
          <NSInterviewDetailStyle.PreviewSubtitle>
            {i18n.t("interview.previewCandidateSubtitle")}
          </NSInterviewDetailStyle.PreviewSubtitle>
        </NSInterviewDetailStyle.PreviewCandidateName>
        <NSInterviewDetailStyle.PreviewIcon>
          <Icon name="HCLogo" />
        </NSInterviewDetailStyle.PreviewIcon>
        <NSInterviewDetailStyle.PreviewRemainingTime>
          {i18n.t("interview.previewRemainingTime")}
          <NSInterviewDetailStyle.PreviewSubtitle>
            {i18n.t("interview.previewRemainingTimeSubtitle")}
          </NSInterviewDetailStyle.PreviewSubtitle>
        </NSInterviewDetailStyle.PreviewRemainingTime>
      </NSInterviewDetailStyle.PreviewHeader>
      <NSInterviewDetailStyle.PreviewContent>
        <NSInterviewDetailStyle.PreviewContentTitle>
          <Trans
            i18nKey="interview.previewQuestionTitle"
            values={{
              current: currentPreviewQuestionIndex + 1,
              total: previewQuestions?.length,
            }}
          />
        </NSInterviewDetailStyle.PreviewContentTitle>
        <NSInterviewDetailStyle.PreviewContentQuestion>
          {currentPreviewQuestion?.question}
        </NSInterviewDetailStyle.PreviewContentQuestion>
        <NSInterviewDetailStyle.PreviewTooltip>
          <HistoryTooltip
            id="preview-tooltip"
            label={i18n.t("interview.previewTooltipTitle")}
          >
            <NSInterviewDetailStyle.PreviewTooltipTitle>
              {i18n.t("interview.previewTooltipTitle")}
            </NSInterviewDetailStyle.PreviewTooltipTitle>
            {currentPreviewQuestion?.review_metric_question_responses.map(
              (response) => (
                <NSInterviewDetailStyle.PreviewTooltipScoreBadgeContainer>
                  <ScoreBadge
                    description={response.description}
                    score={response.score}
                  />
                </NSInterviewDetailStyle.PreviewTooltipScoreBadgeContainer>
              )
            )}
          </HistoryTooltip>
        </NSInterviewDetailStyle.PreviewTooltip>
        <NSInterviewDetailStyle.PreviewButton>
          <SolidButton
            fullWidth
            label={
              notLastPreviewQuestion
                ? i18n.t("interview.previewNextQuestion")
                : i18n.t("interview.previewFinish")
            }
            onClick={onClickNextPreviewQuestion}
          />
        </NSInterviewDetailStyle.PreviewButton>
      </NSInterviewDetailStyle.PreviewContent>
      <NSInterviewDetailStyle.PreviewFooter>
        <Icon name="InterviewCam" />
      </NSInterviewDetailStyle.PreviewFooter>
    </NSInterviewDetailStyle.PreviewContainer>
  );
};

export default InterviewPreview;
