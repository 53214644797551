import { useMemo } from "react";
import AvatarGroup from "../AvatarGroup/AvatarGroup";
import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";
import { NSProjectCardStyle } from "./ProjectCard.style";
import { NSProjectCardType } from "./ProjectCard.type";
import StatusBadge from "../StatusBadge/StatusBadge";
import dayjs from "../../library/dayjs";
import i18n from "../../library/i18next";

function ProjectCard({
  projectName,
  status,
  clientName,
  information,
  deadline,
  avatars,
  onClick,
  contextMenu,
}: NSProjectCardType.IProjectCard) {
  const deadLineText = useMemo(
    () => (deadline ? dayjs(deadline).format("DD.MM.YYYY") : "Not Set"),
    [deadline]
  );

  return (
    <NSProjectCardStyle.Container onClick={onClick}>
      <NSProjectCardStyle.HeaderContainer>
        {contextMenu && (
          <ContextMenuHOC
            items={contextMenu.items}
            onItemClick={contextMenu.onItemClick}
            type={contextMenu.type}
            buttonLabel={contextMenu.buttonLabel}
            isScrollable={contextMenu.isScrollable}
            title={contextMenu.title}
            showSearch={contextMenu.showSearch}
            containAddNewItemButton={contextMenu.containAddNewItemButton}
            onCheckedItems={contextMenu.onCheckedItems}
            onContextMenuOpen={contextMenu.onContextMenuOpen}
            shouldStopPropagation
          >
            <StatusBadge status={status} />
          </ContextMenuHOC>
        )}
      </NSProjectCardStyle.HeaderContainer>
      <NSProjectCardStyle.ProjectName>
        {projectName}
      </NSProjectCardStyle.ProjectName>
      <NSProjectCardStyle.InnerText>{information}</NSProjectCardStyle.InnerText>
      <NSProjectCardStyle.AdditionalInfoContainer>
        <NSProjectCardStyle.AdditionalInfo>
          <NSProjectCardStyle.AdditionalInfoLine>
            {i18n.t("general.client")} :
          </NSProjectCardStyle.AdditionalInfoLine>
          {clientName}
        </NSProjectCardStyle.AdditionalInfo>

        <NSProjectCardStyle.AdditionalInfo>
          <NSProjectCardStyle.AdditionalInfoLine>
            {i18n.t("projects.deadline")} :
          </NSProjectCardStyle.AdditionalInfoLine>
          <NSProjectCardStyle.Deadline>
            {deadLineText}
          </NSProjectCardStyle.Deadline>
        </NSProjectCardStyle.AdditionalInfo>
      </NSProjectCardStyle.AdditionalInfoContainer>

      {avatars && (
        <NSProjectCardStyle.AvatarContainer>
          <AvatarGroup size="xSmall" avatarItems={avatars} />
        </NSProjectCardStyle.AvatarContainer>
      )}
    </NSProjectCardStyle.Container>
  );
}

export default ProjectCard;
