import { NSPasswordSuccessCardStyle } from "./PasswordSuccessCard.style";
import { PasswordSuccessCardProps } from "./PasswordSuccessCard.type";
import SolidButton from "../SolidButton/SolidButton";
import i18n from "../../library/i18next";

import SuccessIllustration from "../../assets/illustration/email-search.png";

function PasswordSuccessCard({
  onClick,
  isShown = false,
  header,
  description,
  image,
}: PasswordSuccessCardProps) {
  return isShown ? (
    <NSPasswordSuccessCardStyle.Container>
      <NSPasswordSuccessCardStyle.Illustration
        src={image ?? SuccessIllustration}
        alt="Password Reset"
      />
      <NSPasswordSuccessCardStyle.Title>
        {header}
      </NSPasswordSuccessCardStyle.Title>
      <NSPasswordSuccessCardStyle.Description>
        {description}
      </NSPasswordSuccessCardStyle.Description>
      <SolidButton
        label={i18n.t("auth.passwordSuccessButton")}
        fullWidth
        onClick={onClick}
      />
    </NSPasswordSuccessCardStyle.Container>
  ) : null;
}

export default PasswordSuccessCard;
