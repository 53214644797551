import * as XLSX from "xlsx";

type ExcelData = Record<string, any>[];

export const excelExporter = (data: ExcelData, fileName: string): void => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFileXLSX(wb, fileName, { bookType: "xlsx", type: "file" });
};
