import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import Dropdown from "../Dropdown/Dropdown";
import { IPhoneNumber } from "./PhoneNumber.type";
import MainInput from "../MainInput/MainInput";
import { NSPhoneNumberStyle } from "./PhoneNumber.style";
import maskPhoneNumber from "../../hooks/useMaskPhoneNumber";
import { NSDropdown } from "../Dropdown/Dropdown.type";
import i18n from "../../library/i18next";

function PhoneNumberRef(
  {
    onChange,
    dropdownItems,
    error,
    errorText,
    id,
    name,
    defaultValue = "",
    defaultSelectedItem,
  }: IPhoneNumber,
  ref?: ForwardedRef<HTMLInputElement>
) {
  const [plainPhoneNumber, setPlainPhoneNumber] =
    useState<string>(defaultValue);

  useEffect(() => {
    setPlainPhoneNumber(defaultValue);
  }, [defaultValue]);

  const [selectedCode, setSelectedCode] = useState<string | undefined>(
    dropdownItems[0].label
  );
  const inputRef = useRef(null as null | HTMLDivElement);

  const onSelect = useCallback(
    (_id: number, selected?: NSDropdown.IDropdownItem | undefined) => {
      setSelectedCode(selected?.label);
      const phoneCode = `${selected?.label} ${plainPhoneNumber}`;
      onChange(phoneCode);
    },
    [onChange, plainPhoneNumber]
  );

  const prefixItem = (
    <>
      <Dropdown
        id={1}
        options={dropdownItems}
        type="basic"
        onSelect={onSelect}
        selectedDropdownItem={
          defaultSelectedItem ?? dropdownItems.find((i) => i.id === 90)
        }
      />
      <NSPhoneNumberStyle.Seperator />
    </>
  );

  const onChangeInput = useCallback(
    (value: string) => {
      const number = value.replace(/[^\d,.]/g, "");
      const maskedPhoneNumber = maskPhoneNumber(number);
      setPlainPhoneNumber(maskedPhoneNumber);
      let phoneCode = `${maskedPhoneNumber}`;
      phoneCode = phoneCode.replaceAll(/[ +()]/g, "");
      onChange(phoneCode);
    },
    [onChange, selectedCode]
  );

  return (
    <NSPhoneNumberStyle.PhoneNumberBase
      ref={inputRef}
      width={inputRef.current?.clientWidth ?? 0}
    >
      <MainInput
        name={name}
        ref={ref}
        prefixItem={prefixItem}
        error={error}
        errorText={errorText}
        value={plainPhoneNumber}
        variant="label-w-icon-prefix"
        id={id}
        type="tel"
        label={i18n.t("forms.phoneNumber")}
        maxLength={14}
        placeholder="(000) 000 0000"
        onChange={(e) => onChangeInput(e)}
      />
    </NSPhoneNumberStyle.PhoneNumberBase>
  );
}

const PhoneNumber = React.forwardRef(PhoneNumberRef);

export default PhoneNumber;
