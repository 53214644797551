import {
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../gql/graphql";
import dayjs from "../../library/dayjs";
import i18n from "../../library/i18next";
import { NSCandidateListStyle } from "../../routes/Project/Detail/Candidate-List/Candidate-List.style";
import candidateStatusHelper from "../../utils/candidateStatusHelper";
import StatusBadge from "../StatusBadge/StatusBadge";
import { NSUpdateHistoryStyle } from "./UpdateHistory.style";
import { IUpdateHistory } from "./UpdateHistory.type";

function UpdateHistory({ items }: IUpdateHistory) {
  return (
    <NSUpdateHistoryStyle.Container>
      <NSUpdateHistoryStyle.Title>
        {i18n.t("projects.updateHistory")}
      </NSUpdateHistoryStyle.Title>
      {items.map((item) => {
        return (
          <NSUpdateHistoryStyle.UpdateHistoryItem key={item.id}>
            {item.status &&
            item.status !== Video_Interview_Status_Enum.NotSet ? (
              <StatusBadge
                label={
                  candidateStatusHelper(
                    item.status as string,
                    item.score.toString()
                  ).label
                }
                color={
                  candidateStatusHelper(
                    item.status as string,
                    item.score.toString()
                  ).color
                }
                type={
                  (item.interviewType as Video_Interview_Type_Enum) ||
                  Video_Interview_Type_Enum.CompetencyAssessment
                }
              />
            ) : (
              <NSCandidateListStyle.NotActionStatus>
                {i18n.t("general.notActionYet")}
              </NSCandidateListStyle.NotActionStatus>
            )}
            <NSUpdateHistoryStyle.ProjectDate>
              {dayjs(item.createdAt).format("DD.MM.YYYY[,] hh:mm")}
            </NSUpdateHistoryStyle.ProjectDate>
          </NSUpdateHistoryStyle.UpdateHistoryItem>
        );
      })}
    </NSUpdateHistoryStyle.Container>
  );
}

export default UpdateHistory;
