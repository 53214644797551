import {
  InterviewDetailPageType,
  useInterviewDetailContext,
} from "../../../context/InterviewContext/Interview-Detail/InterviewDetailContext";
import DateSelectionDetail from "./ModalViews/DateSelectionDetail";
import InterviewPreview from "./ModalViews/InterviewPreview";
import LanguageSelectionDetail from "./ModalViews/LanguageSelectionDetail";
import ReviewMetricQuestionSelectionDetail from "./ModalViews/ReviewMetricQuestionSelectionDetail";
import ReviewMetricSelectionDetail from "./ModalViews/ReviewMetricSelectionDetail";

function InterviewDetailModalRenderer() {
  const { activePage } = useInterviewDetailContext();

  const renderPages = () => {
    switch (activePage) {
      case InterviewDetailPageType.LANGUAGE_SELECTION:
        return <LanguageSelectionDetail />;
      case InterviewDetailPageType.DATE_SELECTION:
        return <DateSelectionDetail />;
      case InterviewDetailPageType.REVIEW_METRIC_SELECTION:
        return <ReviewMetricSelectionDetail />;
      case InterviewDetailPageType.REVIEW_METRIC_QUESTION_SELECTION:
        return <ReviewMetricQuestionSelectionDetail />;
      case InterviewDetailPageType.SEE_IN_PREVIEW:
        return <InterviewPreview />;
      default:
        break;
    }
  };

  return renderPages();
}

export default InterviewDetailModalRenderer;
