import styled from "styled-components";
import typography from "../../style/typography";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";

export namespace NSLanguageSelectionStyle {
  export const Container = styled.div<{ isOpen?: boolean }>`
    ${NSDropdownStyle.InnerDropdownTitle} {
      border: 0;
      padding: 0;
    }

    ${NSDropdownStyle.DropdownItemsContainer} {
      width: 220px;
      top: -50px;
      left: ${(props) => (props.isOpen ? "328px" : "88px")};
    }

    ${NSDropdownStyle.InnerTitle} {
      width: 100%;
    }
  `;

  export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: greycliff-cf, sans-serif;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
    height: 32px;
  `;

  export const LabelAndIconContainer = styled.div`
    display: flex;
    align-items: center;
  `;

  export const Label = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.demiBold};
    margin-left: 8px;
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const Left = styled.div`
    width: fit-content;
    display: flex;
  `;
}
