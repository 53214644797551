import Drawer from "../../../../components/Drawer/Drawer";
import Loading from "../../../../components/Loading/Loading";
import i18n from "../../../../library/i18next";
import { Outlet } from "react-router-dom";
import TabbedView from "../../../../components/TabbedView/TabbedView";
import useNoteDetailVm from "./Note-DetailVM";
import { NSNoteDetailStyle } from "./Note-Detail.style";

export default function NoteDetail() {
  const { isLoading, tabItems, activeTab, onTabChange, handleCloseDrawer } =
    useNoteDetailVm();
  return isLoading ? (
    <Loading />
  ) : (
    <NSNoteDetailStyle.DrawerContainer>
      <Drawer
        isAddedSelfCloseFunction
        onClickCloseIcon={handleCloseDrawer}
        title={i18n.t("projects.notes")}
      >
        <NSNoteDetailStyle.Container>
          <TabbedView
            onTabChange={(tab) => onTabChange(tab)}
            tabItems={tabItems}
            activeTab={activeTab}
          />

          <Outlet />
        </NSNoteDetailStyle.Container>
      </Drawer>
    </NSNoteDetailStyle.DrawerContainer>
  );
}
