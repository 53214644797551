import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";

import JDGSalarySelection from "../../../../../components/JDGSalarySelection/JDGSalarySelection";
import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import i18n from "../../../../../library/i18next";

const SelectSalaryContainer = styled.div``;
const SelectSalaryAnswerContainer = styled.div`
  height: 50px;
  display: flex;
`;

const SelectSalary = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const salaryValues = activeQuestion?.answers.map((item) =>
    parseFloat(item.value as string)
  );
  const [minSalary, setMinSalary] = useState(() =>
    salaryValues ? Math.min(...salaryValues) : 0
  );
  const [maxSalary, setMaxSalary] = useState(() =>
    salaryValues ? Math.max(...salaryValues) : 0
  );

  const [currency, setCurrency] = useState("usd");
  const [period, setPeriod] = useState("monthly");
  const getAnswersAsJson = useCallback(
    () => [
      { max: "true", value: maxSalary, period, currency },
      { min: "true", value: minSalary, period, currency },
    ],
    [maxSalary, period, currency, minSalary]
  );

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
    }),
    [getAnswersAsJson]
  );

  const isValidationErrorActive = useMemo(
    () =>
      minSalary !== undefined &&
      maxSalary !== undefined &&
      minSalary > maxSalary,
    [maxSalary, minSalary]
  );

  return (
    <SelectSalaryContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
          <SelectSalaryAnswerContainer>
            <JDGSalarySelection
              onSelectPeriod={(_id, selected) =>
                setPeriod((selected?.id as string) ?? "")
              }
              onSelectCurrency={(_id, selected) =>
                setCurrency((selected?.id as string) ?? "")
              }
              minSalary={minSalary}
              maxSalary={maxSalary}
              isValidationErrorActive={isValidationErrorActive}
              onChangeMinSalary={(value) => setMinSalary(parseFloat(value))}
              onChangeMaxSalary={(value) => setMaxSalary(parseFloat(value))}
            />
          </SelectSalaryAnswerContainer>
        </NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
      </NSCreateJobDescriptionStyle.WQuestionContainer>

      <NSCreateJobDescriptionStyle.Footer>
        <NSCreateJobDescriptionStyle.FooterLeftContainer />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            backgroundColor={ButtonColorVariant.grey}
            disabled={isValidationErrorActive}
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </SelectSalaryContainer>
  );
});

SelectSalary.displayName = "SelectSalary";

export default SelectSalary;
