import { GetSignedUrlDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetVideoInterviewVideos(filenames: string[]) {
    const { data, ...rest } = useGraphQLQuery(
        GetSignedUrlDocument,
        { enabled: filenames.length > 0 },
        {
            signedUrlInput: {
                contentType: "video/mp4",
                filenames,
                folder: "video-interview-videos",
                operation: "get"
            },
        }
    );

    return {
        data,
        rest,
    };
}
export default useGetVideoInterviewVideos;
