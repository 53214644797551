import Icon from "../Icon/Icon";
import { NSAnimatedSideBarStyle } from "./AnimatedSiderBar.style";
import { NSAnimatedSideBarType } from "./AnimatedSideBar.type";
import ProgressBar from "../ProgressBar/ProgressBar";

function AnimatedSideBar({
  label,
  progress,
}: NSAnimatedSideBarType.IAnimatedSideBar) {
  return (
    <NSAnimatedSideBarStyle.Container>
      <NSAnimatedSideBarStyle.GreenOrb />
      <NSAnimatedSideBarStyle.BlueOrb />
      <NSAnimatedSideBarStyle.GreenOrb />
      <NSAnimatedSideBarStyle.BlueOrb />
      <NSAnimatedSideBarStyle.InnerContainer>
        <Icon name="Logo" width={40} height={40} />
        <NSAnimatedSideBarStyle.SideBarLabel>
          {label}
        </NSAnimatedSideBarStyle.SideBarLabel>
        <ProgressBar progress={progress} />
      </NSAnimatedSideBarStyle.InnerContainer>
    </NSAnimatedSideBarStyle.Container>
  );
}

export default AnimatedSideBar;
