import { Controller } from "react-hook-form";
import Icon from "../../../../components/Icon/Icon";
import Logo from "../../../../components/Logo/Logo";
import i18n from "../../../../library/i18next";
import { NSCreateInterviewStyle } from "../../../Interview-Create/Interview-Create.style";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import useInterviewDetailVM from "../../Interview-DetailVM";
import Loading from "../../../../components/Loading/Loading";

const LanguageSelectionDetail = () => {
  const {
    projectName,
    controlLanguage,
    languageOptions,
    initialLanguages,
    isLoading,
    handleSubmitLanguage,
  } = useInterviewDetailVM();
  return (
    <NSCreateInterviewStyle.FormContainer onSubmit={handleSubmitLanguage()}>
      <NSCreateInterviewStyle.Header>
        <Logo type="onlyIcon" />
        <NSCreateInterviewStyle.ProjectNameBadge>
          <Icon name="Folders" />
          <NSCreateInterviewStyle.ProjectName>
            {projectName}
          </NSCreateInterviewStyle.ProjectName>
        </NSCreateInterviewStyle.ProjectNameBadge>
      </NSCreateInterviewStyle.Header>

      {isLoading ? (
        <Loading />
      ) : (
        <NSCreateInterviewStyle.Content>
          <NSCreateInterviewStyle.ContentTitle>
            {i18n.t("interview.languageInterview")}
          </NSCreateInterviewStyle.ContentTitle>
          <NSCreateInterviewStyle.ContentSubTitle>
            {i18n.t("interview.languageInterviewSubTitle")}
          </NSCreateInterviewStyle.ContentSubTitle>
          <NSCreateInterviewStyle.LanguageDropdownContainer>
            <Controller
              name="language"
              control={controlLanguage}
              render={({ field, fieldState: { error } }) => {
                const { ref, ...fieldProps } = field;
                return (
                  <Dropdown
                    id={1}
                    type="withCheckbox"
                    placeholder={i18n.t("forms.language")}
                    options={languageOptions}
                    onCheckedItems={(items) => field.onChange(items)}
                    defaultCheckedItems={initialLanguages}
                    error={!!error?.message}
                    errorText={error?.message}
                    {...fieldProps}
                  />
                );
              }}
            />
          </NSCreateInterviewStyle.LanguageDropdownContainer>
        </NSCreateInterviewStyle.Content>
      )}
      <NSCreateInterviewStyle.Fragment />
      <NSCreateInterviewStyle.Footer>
        <SolidButton type="submit" label={i18n.t("general.save")} />
      </NSCreateInterviewStyle.Footer>
    </NSCreateInterviewStyle.FormContainer>
  );
};

export default LanguageSelectionDetail;
