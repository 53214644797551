import styled, { css } from "styled-components";

import { Link } from "react-router-dom";
import typography from "../../style/typography";

export namespace NSSideMenuItemStyle {
  export const Container = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: greycliff-cf, sans-serif;
    cursor: pointer;
    text-decoration: none;
  `;

  export const LabelAndIconContainer = styled.div<{ isActive?: boolean }>`
    display: flex;
    align-items: center;

    ${(props) =>
      props.isActive &&
      css`
        svg {
          path {
            stroke: ${props.theme.colors.primary.primary};
          }
        }
      `}
    :hover {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.primary.primary};
        }
      }
    }
  `;

  export const Label = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    font-weight: ${typography.fontWeights.demiBold};
    margin-left: 8px;
    color: ${(props) => props.theme.colors.dark.dark};
  `;
}
