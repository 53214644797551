import { RouteObject, createBrowserRouter } from "react-router-dom";
import Auth, { loader as AuthLoader } from "./Auth/Auth";
import AddCandidate from "./Project/Detail/Add-Candidate/Add-Candidate";

import ClientDetail from "./Client/Detail/Client-Detail";
import ClientList from "./Client/List/Client-List";
import Clients from "./Client/Clients";

import CreateClient from "./Client/Create/Create-Client";
import CreateJobDescription from "./Job-Description/Create-Job-Description/CreateJobDescription";
import CreateProject from "./Project/Create/CreateProject";

import ForgotPassword from "./Forgot-Password/ForgotPassword";
import Home from "./Home/Home";
import JobDescription from "./Job-Description/Job-Description";
import JobDescriptionDetail from "./Job-Description/Detail/Job-Description-Detail";

import ProjectList from "./Project/List/ProjectList";
import ProjectsPageIndex from "./Project/Projects";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import Register from "./Register/Register";
import RegisterAccount from "./Register/Account/RegisterAccount";
import RegisterCompany from "./Register/Company/RegisterCompany";
import RegisterFinal from "./Register/Final/RegisterFinal";
import ResetPassword from "./Reset-Password/Reset-Password";

import Team from "./Team/Team";
import useJobDescriptionContextRoleVm from "./Job-Description/Create-Job-Description/ViewModels/JobDescriptionRoleVm";

import JobDescriptionExperienceAndSPosition from "./Job-Description/Create-Job-Description/Views/JobDescriptionExperienceAndPosition";
import CreateCandidate from "./Project/Detail/Create-Candidate/Create-Candidate";
import EditClient from "./Client/Edit/Edit-Client";
import EditProject from "./Project/Edit/EditProject";
import Overview from "./Project/Detail/Project-Detail-Overview/Overview";
import ProjectDetailJobDescription from "./Project/Detail/Project-Detail-Job-Description/DetailJobDescription";
import CandidatePage from "./Candidate/Candidate";
import CandidateReviewerQuestion from "./Candidate/Candidate-Reviewer-Question/Candidate-Reviewer-Question";
import CandidateVideoPreview from "./Candidate/Candidate-Video-Preview/Candidate-Video-Preview";
import CandidateScoreResult from "./Candidate/Candidate-Score-Result/Candidate-Score-Result";
import ProjectDetailPageIndex from "./Project/Detail/Project-Detail";
import NotFound from "./Not-Found/Not-Found";
import JobDescriptionRole from "./Job-Description/Create-Job-Description/Views/JobDescriptionRole";
import Logout from "./Auth/Logout/Logout";
import VideoPreview from "./Project/Detail/Video-Preview/Video-Preview";
import EditCandidate from "./Project/Detail/Edit-Candidate/Edit-Candidate";
import TeamList from "./Team/Team-List/Team-List";
import InviteMember from "./Team/Invite-Member/Invite-Member";
import CandidateList from "./Project/Detail/Candidate-List/Candidate-List";
import {
  ACCESS_TOKEN,
  CANDIDATE_APPLIEDLIST,
  CANDIDATE_LONGLIST,
  CANDIDATE_SHORTLIST,
  LOGIN_USER_INFO,
} from "../utils/globalConstants";
import AddCandidateFromExcel from "./Project/Detail/Add-Candidate-From-Excel/Add-Candidate-From-Excel";
import Settings from "./Settings/Settings";
import SettingsProfile from "./Settings/Settings-Profile/Settings-Profile";
import SettingsMyPlans from "./Settings/Settings-MyPlans/Settings-MyPlans";
import SettingsIntegration from "./Settings/Settings-Integration/Settings-Integration";
import AddNote from "./Project/Detail/Note-Detail/Add-Note/Add-Note";
import NoteDetail from "./Project/Detail/Note-Detail/Note-Detail";
import AllNotes from "./Project/Detail/Note-Detail/All-Notes/All-Notes";
import CreateInterview from "./Interview-Create/Interview-Create";
import DetailInterview from "./Interview-Detail/Interview-Detail";
import QuestionDetail from "./Interview-Create/Question/QuestionDetail";
import ProjectDetailInterview from "./Project/Detail/Project-Detail-Interview/Interview";
import CreateInterviewModal from "./Project/Create/CreateInterviewModal";

export const paths = (): RouteObject[] => [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <NotFound />,
        children: [
          {
            path: "projects",
            children: [
              {
                element: <ProjectsPageIndex />,
                children: [
                  { index: true, element: <ProjectList /> },
                  {
                    path: "create",
                    element: <CreateProject />,
                  },
                  {
                    path: "edit-project/:id",
                    element: <EditProject />,
                  },
                  {
                    path: "create-interview",
                    element: <CreateInterviewModal />,
                  },
                  {
                    path: "interview",
                    element: <CreateInterview />,
                    children: [
                      {
                        path: "question/:questionId",
                        element: <QuestionDetail />,
                      },
                    ],
                  },
                  {
                    path: "interview-detail/:pageId",
                    element: <DetailInterview />,
                    children: [
                      {
                        path: "question/:questionId",
                        element: <QuestionDetail />,
                      },
                    ],
                  },
                  {
                    path: "interview-detail/see-in-preview",
                    element: <DetailInterview />,
                  },
                  {
                    path: "detail/:projectId/candidate",
                    children: [
                      {
                        element: <CandidatePage />,
                        children: [
                          {
                            index: true,
                            path: "candidate-video-preview/:interviewId",
                            element: <CandidateVideoPreview />,
                          },
                          {
                            path: "candidate-reviewer-questions/:interviewId",
                            element: <CandidateReviewerQuestion />,
                          },
                          {
                            path: "candidate-score-result/:interviewId/:baseType",
                            element: <CandidateScoreResult />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "detail/:projectId",
                    children: [
                      {
                        element: <ProjectDetailPageIndex />,
                        children: [
                          {
                            index: true,
                            path: "overview",
                            element: <Overview />,
                          },
                          {
                            index: true,
                            path: "interview",
                            element: <ProjectDetailInterview />,
                          },
                          {
                            index: true,
                            path: "job-descriptions",
                            element: <ProjectDetailJobDescription />,
                          },
                          {
                            path: "applied",
                            element: (
                              <CandidateList type={CANDIDATE_APPLIEDLIST} />
                            ),
                          },
                          {
                            path: "long-list",
                            element: (
                              <CandidateList type={CANDIDATE_LONGLIST} />
                            ),
                          },
                          {
                            path: "short-list",
                            element: (
                              <CandidateList type={CANDIDATE_SHORTLIST} />
                            ),
                          },
                          {
                            path: "add-candidate",
                            element: <AddCandidate />,
                          },
                          {
                            path: "add-candidate-from-excel",
                            element: <AddCandidateFromExcel />,
                          },
                          {
                            path: "create-candidate",
                            element: <CreateCandidate />,
                          },

                          {
                            path: "video-preview/:interviewId",
                            element: <VideoPreview />,
                          },
                          {
                            path: "edit-candidate/:candidateId",
                            element: <EditCandidate />,
                          },
                          {
                            path: "notes/:candidateId/:candidateProjectId/:type",
                            children: [
                              {
                                element: <NoteDetail />,
                                children: [
                                  {
                                    path: "all-notes",
                                    element: <AllNotes />,
                                  },
                                  {
                                    path: "add-note",
                                    element: <AddNote />,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "job-description",
            element: <JobDescription />,
            children: [
              {
                path: "create",
                element: <CreateJobDescription />,
                children: [
                  {
                    path: "role",
                    element: (
                      <JobDescriptionRole vm={useJobDescriptionContextRoleVm} />
                    ),
                  },
                  {
                    path: "experience-position",
                    element: (
                      <JobDescriptionExperienceAndSPosition
                        vm={useJobDescriptionContextRoleVm}
                      />
                    ),
                  },
                ],
              },
              {
                path: "detail/:jdId",
                element: <JobDescriptionDetail />,
              },
            ],
          },
          {
            path: "team",
            children: [
              {
                element: <Team />,
                children: [
                  { index: true, element: <TeamList /> },
                  {
                    path: "invite-member",
                    element: <InviteMember />,
                  },
                ],
              },
            ],
          },
          {
            path: "clients",
            children: [
              {
                element: <Clients />,
                children: [
                  { index: true, element: <ClientList /> },
                  {
                    path: "create",
                    element: <CreateClient />,
                  },
                  {
                    path: "edit/:clientId",
                    element: <EditClient />,
                  },
                  {
                    path: "detail/:clientId",
                    children: [{ index: true, element: <ClientDetail /> }],
                  },
                ],
              },
            ],
          },
          {
            path: "settings",
            children: [
              {
                element: <Settings />,
                children: [
                  {
                    index: true,
                    path: "profile",
                    element: <SettingsProfile />,
                  },
                  {
                    index: true,
                    path: "plans",
                    element: <SettingsMyPlans />,
                  },
                  {
                    index: true,
                    path: "integration",
                    element: <SettingsIntegration />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/auth",
    element: <Auth />,
    errorElement: <NotFound />,
    loader: AuthLoader,
    children: [
      {
        path: "logout",
        element: <Logout />,
      },
    ],
  },
  {
    path: "/register",
    errorElement: <NotFound />,
    element: <Register />,
    children: [
      {
        path: "account",
        element: <RegisterAccount />,
      },
      {
        path: "company",
        element: <RegisterCompany />,
      },
      {
        path: "final",
        element: <RegisterFinal />,
      },
    ],
  },
  {
    errorElement: <NotFound />,
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    errorElement: <NotFound />,
    path: "/reset-password/:token/:email",
    element: <ResetPassword />,
    loader: async () => {
      if (
        localStorage.getItem(ACCESS_TOKEN) &&
        localStorage.getItem(LOGIN_USER_INFO)
      ) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(LOGIN_USER_INFO);

        window.location.reload();
      }

      return null;
    },
  },
];

export const router = createBrowserRouter(paths());
