import { ReactNode, useMemo } from "react";
import ClientInfoCard from "../../../components/ClientInfoCard/ClientInfoCard";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import EmptyView from "../../../components/EmptyView/EmptyView";
import Loading from "../../../components/Loading/Loading";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";
import { NSClientListStyle } from "./Client-List.style";
import Modal from "../../../components/Modal/Modal";
import SolidButton from "../../../components/SolidButton/SolidButton";
import OutlinedButton from "../../../components/OutlinedButton/OutlinedButton";
import useClientListVm from "./Client-ListVM";
import i18n from "../../../library/i18next";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";
import Pagination from "../../../components/Pagination/Pagination";

export default function ClientList() {
  const clientsVM = useClientListVm();

  const pageContent = useMemo(() => {
    let content: ReactNode;
    if (clientsVM.isLoading) {
      content = <Loading />;
    } else if (
      clientsVM.clientListItems &&
      clientsVM.clientListItems.length !== 0
    ) {
      content = (
        <NSClientListStyle.ContentContainer>
          {clientsVM.clientListItems.map((clientItem) => (
            <NSClientListStyle.CardContainer key={clientItem.id}>
              <ClientInfoCard
                contextMenu={{
                  type: "withIcon",
                  items: [
                    {
                      iconName: "Edit",
                      label: i18n.t("general.edit"),
                      onClick: () => clientsVM.onClickEditButton(clientItem.id),
                    },
                    {
                      iconName: "Notification",
                      label: i18n.t("general.delete"),
                      onClick: () => clientsVM.onClickDelete(clientItem.id),
                    },
                  ],
                }}
                companyName={clientItem.clientName}
                sector={clientItem.sector ?? ""}
                information={clientItem.information}
                location={clientItem.location}
                isDefault={clientItem.isDefault}
                onClick={() => clientsVM.onClickCard(clientItem.id)}
              />
            </NSClientListStyle.CardContainer>
          ))}
          {clientsVM.totalItemCount >= PAGINATION_DEFAULT_LIMIT && (
            <NSClientListStyle.PaginationContainer>
              <Pagination
                pagination={clientsVM.pagination}
                isLoading={clientsVM.isLoading}
                itemCount={clientsVM.totalItemCount}
                updateQueryLimit={(start, limit) =>
                  clientsVM.handleSetPagination(start, limit)
                }
              />
            </NSClientListStyle.PaginationContainer>
          )}
        </NSClientListStyle.ContentContainer>
      );
    } else {
      content = (
        <NSClientListStyle.EmptyContainer>
          <EmptyView
            errorMessage={clientsVM.emptyView.errorMessage}
            onClick={clientsVM.emptyView.onClick}
            buttonLabel={clientsVM.emptyView.buttonLabel}
          />
        </NSClientListStyle.EmptyContainer>
      );
    }

    return content;
  }, [clientsVM]);

  return (
    <>
      <NSClientListStyle.Container>
        <NSDashboardLayoutStyle.HeaderContainer>
          <DashboardHeader
            breadcrumb={clientsVM.breadcrumb}
            searchInput={clientsVM.searchInput}
          />
        </NSDashboardLayoutStyle.HeaderContainer>
        {pageContent}
      </NSClientListStyle.Container>
      <Modal
        isDarkBackgroundActive
        isOpen={clientsVM.isDeleteModalOpen}
        onClickCloseButton={clientsVM.onClickCloseButton}
      >
        <NSClientListStyle.ModalContainer>
          <NSClientListStyle.ModalTitle>
            {i18n.t("clients.deleteClient")}
          </NSClientListStyle.ModalTitle>
          {i18n.t("clients.deleteClientLabel")}
          <NSClientListStyle.ModalButtonContainer>
            <SolidButton
              fullWidth
              label={i18n.t("general.delete")}
              onClick={() =>
                clientsVM.onDeleteClient(clientsVM.deletedClientId)
              }
            />

            <OutlinedButton
              fullWidth
              label={i18n.t("general.giveUp")}
              onClick={() => clientsVM.onClickCloseButton()}
            />
          </NSClientListStyle.ModalButtonContainer>
        </NSClientListStyle.ModalContainer>
      </Modal>
    </>
  );
}
