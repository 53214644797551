import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import { NSSwitchButtonStyle } from "../SwitchButton/SwitchButton.style";
import { NSUserStyle } from "../User/User.style";

export namespace NSSummarySidebarStyle {
  export const Container = styled.div`
    width: 331px;
    height: 100%;
    flex: 1;
    box-sizing: border-box;
    padding: 0 32px;
  `;

  export const Header = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Content = styled.div`
    margin-top: 16px;
  `;

  export const NotSet = styled.div`
    color: ${(props) => props.theme.colors.neutrals.greay05};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const DateString = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const InfoName = styled.div`
    width: 128px;
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const Information = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
  `;

  export const ClientName = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const InfoContent = styled.div`
    ${NSSwitchButtonStyle.SwitchButtonLabel} {
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.regular};
      margin-left: 12px;
    }

    ${NSDropdownStyle.InnerDropdownTitle} {
      border: 0;
      padding: 0;
    }

    ${NSDropdownStyle.DropdownTitle} {
      width: 140px;
    }
    ${NSUserStyle.NameText} {
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    }
  `;
}
