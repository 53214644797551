import styled from "styled-components";
import { ILinkButton } from "./LinkButton.type";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSLinkButtonStyle {
  export const LinkButtonBase = styled.div<ILinkButton>`
    ${NSMainButtonStyle.ButtonBase} {
      background-color: transparent;
      border-width: 0;
      height: fit-content;
      padding: 0;
      color: ${(props) => props.theme.colors.primary.primary};

      &:hover {
        text-decoration: underline;
        color: ${(props) => props.theme.colors.primary.dark};
      }

      &:disabled {
        color: ${(props) => props.theme.colors.neutrals.grey06};
        text-decoration: none;
      }
    }
  `;

  export const ButtonLabel = styled.a``;
}
