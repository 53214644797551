import Loading from "../../../../components/Loading/Loading";
import { NSCandidateListStyle } from "./Candidate-List.style";
import { NSCandidateListType } from "./Candidate-List.type";
import { PAGINATION_DEFAULT_LIMIT } from "../../../../utils/globalConstants";
import Pagination from "../../../../components/Pagination/Pagination";
import TableList from "../../../../components/TableList/TableList";
import useCandidateListVm from "./Candidate-ListVM";
import BatchAction from "../../../../components/BatchAction/BatchAction";
import Modal from "../../../../components/Modal/Modal";
import Icon from "../../../../components/Icon/Icon";
import SolidButton from "../../../../components/SolidButton/SolidButton";

function CandidateList({ type }: NSCandidateListType.ICandidateList) {
  const candidateListVm = useCandidateListVm({ type });

  return candidateListVm.isLoading ? (
    <Loading />
  ) : (
    <>
      {candidateListVm.candidateData &&
      candidateListVm.candidateData[type].length > 0 ? (
        <NSCandidateListStyle.TableListContainer>
          {candidateListVm.selectedTableRowsCount > 0 && (
            <BatchAction
              key="candidateListBatchAction"
              candidateCount={candidateListVm.selectedTableRowsCount}
              onClickClearButton={candidateListVm.clearCheckBoxStates}
              dropdownOptions={candidateListVm.batchActionContextMenu[type]}
            />
          )}
          <TableList
            tableItemList={candidateListVm.candidateData[type]}
            headerItemList={candidateListVm.headerItemList[type]}
          />
        </NSCandidateListStyle.TableListContainer>
      ) : (
        candidateListVm.notFound[type]
      )}

      {candidateListVm.totalItemCount >= PAGINATION_DEFAULT_LIMIT && (
        <NSCandidateListStyle.PaginationContainer>
          <Pagination
            pagination={candidateListVm.pagination}
            isLoading={candidateListVm.isLoading}
            itemCount={candidateListVm.totalItemCount}
            updateQueryLimit={(start, limit) =>
              candidateListVm.handleSetPagination(start, limit)
            }
          />
        </NSCandidateListStyle.PaginationContainer>
      )}
      {candidateListVm.modalContent && (
        <Modal isDarkBackgroundActive isOpen={true} hiddenCloseButton>
          <NSCandidateListStyle.ModalContent>
            <Icon name="BatchAction" />
            <NSCandidateListStyle.ModalTitle>
              {candidateListVm.modalContent.title}
            </NSCandidateListStyle.ModalTitle>
            <NSCandidateListStyle.ModalMessage>
              {candidateListVm.modalContent.message}
            </NSCandidateListStyle.ModalMessage>
            <NSCandidateListStyle.ModalButtonsContainer>
              <SolidButton
                label={candidateListVm.modalContent.actionButtonLabel}
                onClick={() => {
                  candidateListVm?.modalContent?.actionButtonHandler();
                  candidateListVm.onClickModalCloseButton();
                }}
              />
              <NSCandidateListStyle.ModalCloseButton
                label={candidateListVm?.modalContent?.closeButtonLabel}
                onClick={candidateListVm.onClickModalCloseButton}
              />
            </NSCandidateListStyle.ModalButtonsContainer>
          </NSCandidateListStyle.ModalContent>
        </Modal>
      )}
    </>
  );
}

export default CandidateList;
