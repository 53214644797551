/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";
import DragAndDropList from "../../../../../components/DragAndDropList/DragAndDropList";
import FreeInput from "../../../../../components/FreeInput/FreeInput";

import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSDragAndDropListType } from "../../../../../components/DragAndDropList/DragAndDropList.type";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import i18n from "../../../../../library/i18next";

const RequirementsSelectionContainer = styled.div``;

const RequirementsSelection = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const [requirementItems, setRequirementItems] = useState<
    NSDragAndDropListType.ISortableItem[]
  >(
    () =>
      activeQuestion?.answers.map((item, index) => ({
        name: item.value as string,
        isSelected: item.isSelected,
        id: index + 1,
      })) ?? []
  );

  const [freeInputValue, setFreeInputValue] = useState<string>("");
  const getAnswersAsJson = useCallback(
    () => requirementItems.filter((item) => item.isSelected),
    [requirementItems, activeQuestion]
  );

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
    }),
    [getAnswersAsJson]
  );

  const onClickAddFunction = useCallback(() => {
    if (freeInputValue !== "") {
      const newItem: NSDragAndDropListType.ISortableItem = {
        name: freeInputValue as string,
        isSelected: true,
        id: requirementItems.length + 1,
      };

      const findIndex = requirementItems.findIndex(
        (item) => item.name === freeInputValue
      );
      const newItems =
        findIndex === -1 ? requirementItems.concat(newItem) : requirementItems;
      setRequirementItems(newItems);
      setFreeInputValue("");
    }
  }, [freeInputValue, requirementItems]);

  return (
    <RequirementsSelectionContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionDragDropItemFlexColumn>
          <DragAndDropList
            list={requirementItems}
            setItems={setRequirementItems}
          />
        </NSCreateJobDescriptionStyle.WQQuestionDragDropItemFlexColumn>
      </NSCreateJobDescriptionStyle.WQuestionContainer>
      <NSCreateJobDescriptionStyle.Footer>
        <FreeInput
          id="reqSelectionId"
          name="reqSelectionName"
          onClickAdd={onClickAddFunction}
          value={freeInputValue}
          onChange={(value) => setFreeInputValue(value)}
        />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            backgroundColor={ButtonColorVariant.grey}
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </RequirementsSelectionContainer>
  );
});

RequirementsSelection.displayName = "RequirementsSelection";

export default RequirementsSelection;
