import React, { ForwardedRef } from "react";

import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { ISearchInput } from "./SearchInput.type";
import Icon from "../Icon/Icon";
import MainInput from "../MainInput/MainInput";
import { NSSearchInputStyle } from "./SearchInput.style";

function SearchInputRef(
  {
    id,
    name,
    value,
    placeholder,
    disabled = false,
    onFocus,
    onChange,
    error,
    maxLength,
    errorText,
    infoText,
    label,
    onBlur,
    type,
    readonly,
    onClick,
    suffixItem,
    showPrefix = true,
    autoComplete,
  }: ISearchInput,
  ref?: ForwardedRef<HTMLInputElement>
) {
  const prefixItem = <Icon width={24} height={24} name="Search" />;
  const onCloseClick = () => {
    onChange?.("");
  };

  const preparedSuffixItem =
    value !== "" ? (
      <GhostIconButton
        role="button"
        onClick={onCloseClick}
        icon="CloseCircleFilled"
      />
    ) : (
      suffixItem ?? <Icon width={43} height={32} name="KeyboardIcon" />
    );

  return (
    <NSSearchInputStyle.SearchInputBase>
      <MainInput
        autoComplete={autoComplete}
        ref={ref}
        prefixItem={showPrefix && prefixItem}
        suffixItem={preparedSuffixItem}
        variant="label-w-icon-prefix-&-suffix"
        id={id}
        showLabel={false}
        value={value}
        onChange={onChange}
        error={error}
        name={name}
        placeholder={placeholder ?? "Search"}
        maxLength={maxLength}
        errorText={errorText}
        infoText={infoText}
        label={label}
        onBlur={onBlur}
        type={type}
        readonly={readonly}
        onClick={onClick}
        disabled={disabled}
        onFocus={onFocus}
      />
    </NSSearchInputStyle.SearchInputBase>
  );
}

const SearchInput = React.forwardRef(SearchInputRef);

export default SearchInput;
