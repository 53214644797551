import styled from "styled-components";

export namespace NSStatsOverview {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    border: 1px solid #dde0e5;
    border-radius: 16px;
    padding: 16px;
    width: 383px;
  `;

  export const OverviewItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
  `;

  export const Text = styled.p`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
  `;
}
