import React, { ForwardedRef } from "react";
import { NSDatePickerType } from "./DatePicker.type";
import TextInput from "../TextInput/TextInput";

function CustomInputRef(
  {
    dateString,
    onClick,
    placeholder,
    label,
    suffixItem,
  }: NSDatePickerType.ICustomInput,
  ref?: ForwardedRef<HTMLInputElement>
) {
  return (
    <TextInput
      ref={ref}
      className="custom-date-input"
      readonly
      onClick={onClick}
      id="id"
      name="name"
      variant="label-w-icon-suffix"
      suffixItem={suffixItem}
      value={dateString}
      label={label}
      placeholder={placeholder}
    />
  );
}

const CustomInput = React.forwardRef(CustomInputRef);

export default CustomInput;
