import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
import { NSProjectSelectionStyle } from "./ProjectSelection.style";
import { NSProjectSelectionType } from "./ProjectSelection.type";

function ProjectSelection({
  id,
  projects,
  selectedProject,
  onSelect,
}: NSProjectSelectionType.IProjectSelection) {
  return (
    <NSProjectSelectionStyle.Container>
      <Dropdown
        isSearchActive
        id={id}
        options={projects}
        type="basic"
        selectedDropdownItem={selectedProject}
        prefixItem={<Icon name="Folders" />}
        showSuffixItems={projects.length > 1}
        onSelect={onSelect}
      />
    </NSProjectSelectionStyle.Container>
  );
}

export default ProjectSelection;
