import { useMemo, useRef } from "react";
import {
  Job_Description_Step_Enum,
  UpdateJobDescriptionEducationTypeDocument,
  UpdateJobDescriptionSkillsDocument,
} from "../../../../gql/graphql";
import {
  gqUpdateJobDescriptionBenefits,
  gqUpdateJobDescriptionExperienceLevel,
  gqUpdateJobDescriptionIntroduction,
  gqUpdateJobDescriptionRequirements,
  gqUpdateJobDescriptionResponsibilities,
  gqUpdateJobDescriptionSalary,
  gqUpdateJobDescriptionSkillsRating,
} from "../../../../graphql/updateJobDescription.graphql";

import { NSJobDescriptionContextType } from "../../../../context/JobDescriptionContext/JobDescriptionContext.type";
import { NSWithQuestionJobDescriptionType } from "../Types/WithQuestionJobDescription.type";
import { useGraphQLMutation } from "../../../../hooks/useGraphQL";
import { useJobDescriptionContext } from "../../../../context/JobDescriptionContext/JobDescriptionContext";
import i18n from "../../../../library/i18next";

function useWithQuestionJobDescriptionVm() {
  const {
    roleButtonLabel,
    activeQuestion,
    nextQuestion,
    jid,
    selectedClientName,
  } = useJobDescriptionContext();
  const ref = useRef<NSWithQuestionJobDescriptionType.IQuestionItems>(null);
  const { mutateAsync: updateJobDescriptionSkills, status: skillsStatus } =
    useGraphQLMutation(UpdateJobDescriptionSkillsDocument);
  const {
    mutateAsync: updateJobDescriptionEducationType,
    status: educationStatus,
  } = useGraphQLMutation(UpdateJobDescriptionEducationTypeDocument);

  const {
    mutateAsync: gqUpdateJobDescriptionSkillsRatingType,

    status: skillsRatingStatus,
  } = useGraphQLMutation(gqUpdateJobDescriptionSkillsRating);

  const {
    mutateAsync: updateJobDescriptionExperienceLevel,

    status: experienceLevelStatus,
  } = useGraphQLMutation(gqUpdateJobDescriptionExperienceLevel);

  const {
    mutateAsync: updateJobDescriptionRequirements,

    status: requirementsStatus,
  } = useGraphQLMutation(gqUpdateJobDescriptionRequirements);

  const {
    mutateAsync: updateJobDescriptionResponsibilities,

    status: responsibilitiesStatus,
  } = useGraphQLMutation(gqUpdateJobDescriptionResponsibilities);

  const {
    mutateAsync: updateJobDescriptionBenefits,

    status: benefitsStatus,
  } = useGraphQLMutation(gqUpdateJobDescriptionBenefits);

  const { mutateAsync: updateJobDescriptionSalary, status: salaryStatus } =
    useGraphQLMutation(gqUpdateJobDescriptionSalary);

  const {
    mutateAsync: updateJobDescriptionIntroduction,
    status: introductionStatus,
  } = useGraphQLMutation(gqUpdateJobDescriptionIntroduction);

  const getNextQuestion = () => {
    // we need to get this from question item component
    // so they have to use forwardRef

    const json = ref.current?.getAnswersAsJson();
    const introduction = ref.current?.getAnswerAsString?.() ?? "";
    // send json to update job description
    switch (activeQuestion?.page) {
      case NSJobDescriptionContextType.PageType.SKILL_SELECTION:
        updateJobDescriptionSkills({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SkillsSelected,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_SKILL_RATE:
        gqUpdateJobDescriptionSkillsRatingType({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectEducation,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_EDUCATION:
        updateJobDescriptionEducationType({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectExperience,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_EXPERIENCE:
        updateJobDescriptionExperienceLevel({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectSalary,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_SALARY:
        updateJobDescriptionSalary({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectRequirements,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_REQUIREMENTS:
        updateJobDescriptionRequirements({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectResponsibilities,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_RESPONSIBILITES:
        updateJobDescriptionResponsibilities({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectBenefits,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_BENEFITS:
        updateJobDescriptionBenefits({
          id: { _eq: jid },
          values: json,
          step: Job_Description_Step_Enum.SelectIntroduction,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      case NSJobDescriptionContextType.PageType.SELECT_INTRODUCTION:
        updateJobDescriptionIntroduction({
          id: { _eq: jid },
          values: introduction,
          step: Job_Description_Step_Enum.GenerateJd,
        })
          .then(() => {
            nextQuestion();
          })
          .catch(() => {});
        break;
      default:
        break;
    }
  };

  const isButtonLoading = useMemo(() => {
    let isLoading = false;

    switch (activeQuestion?.page) {
      case NSJobDescriptionContextType.PageType.SKILL_SELECTION:
        isLoading = skillsStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_SKILL_RATE:
        isLoading = skillsRatingStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_EDUCATION:
        isLoading = educationStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_EXPERIENCE:
        isLoading = experienceLevelStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_SALARY:
        isLoading = salaryStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_REQUIREMENTS:
        isLoading = requirementsStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_RESPONSIBILITES:
        isLoading = responsibilitiesStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_BENEFITS:
        isLoading = benefitsStatus !== "idle";
        break;
      case NSJobDescriptionContextType.PageType.SELECT_INTRODUCTION:
        isLoading = introductionStatus !== "idle";
        break;
      default:
        break;
    }
    return isLoading;
  }, [
    activeQuestion?.page,
    benefitsStatus,
    educationStatus,
    experienceLevelStatus,
    introductionStatus,
    requirementsStatus,
    responsibilitiesStatus,
    salaryStatus,
    skillsRatingStatus,
    skillsStatus,
  ]);

  const animatedSideBarLabel = useMemo(() => {
    const label = i18n.t("jd.animatedSideBarLabel", {
      roleButtonLabel,
      selectedClientName,
    });
    return label;
  }, [roleButtonLabel, selectedClientName]);

  return {
    ref,
    animatedSideBarLabel,
    isButtonLoading,
    roleButtonLabel,
    activeQuestion,
    getNextQuestion,
  };
}

export default useWithQuestionJobDescriptionVm;
