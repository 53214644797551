import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import colors from "../../style/colors";
// eslint-disable-next-line import/order
import styled from "styled-components";

export namespace NSProjectSelectionStyle {
  export const Container = styled.div`
    ${NSDropdownStyle.DropdownBase} {
      width:fit-content;
      min-width:184px;
      
    }
    ${NSDropdownStyle.InnerDropdownTitle}{
      background-color:${colors.shadesOfdark.dark05};
      border:none;
      box-sizing:border-box;
      border:2px solid transparent;
      color:${colors.primary.primary};
      &:hover {
        background-color:transparent;
        border:2px solid ${colors.primary.primary};
      }

    }
  `;
}
