import { Form } from "react-router-dom";
import styled from "styled-components";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";

export namespace NSEditProjectStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    gap: 32px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  `;

  export const EditProjectForm = styled(Form)`
    gap: 32px;
    display: flex;
    flex-direction: column;
  `;

  export const ControllerContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${NSMainButtonStyle.ButtonLabel} {
      text-align: left;
    }

    ${NSMainButtonStyle.ButtonBase} {
      margin-bottom: 8px;
    }
  `;

  export const WarningText = styled.div`
    margin-top: 8px;
  `;
}
