import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import toast from "react-hot-toast";
import {
  GenerateShareableJdLinkDocument,
  GetJobDescriptionByPkDocument,
} from "../../../gql/graphql";
import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import GeneratedJobDescription from "../../../components/GeneratedJobDescription/GeneratedJobDescription";
import Icon from "../../../components/Icon/Icon";
import Logo from "../../../components/Logo/Logo";
import Modal2 from "../../../components/Modal/Modal2";
import { NSCreateJobDescriptionStyle } from "../Create-Job-Description/CreateJobDescription.style";
import { NSGeneratedJobDescriptionType } from "../../../components/GeneratedJobDescription/GeneratedJobDescription.type";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import Modal from "../../../components/Modal/Modal";
import SolidButton from "../../../components/SolidButton/SolidButton";
import QRCode from "qrcode.react";

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-cap */

function JobDescriptionDetail() {
  const navigate = useNavigate();
  const { jdId } = useParams();
  const { data } = useGraphQLQuery(GetJobDescriptionByPkDocument, undefined, {
    jdId,
  });

  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const [jDLink, setJDLink] = useState<string>("");

  const { mutateAsync: generateShareableJdLink } = useGraphQLMutation(
    GenerateShareableJdLinkDocument
  );
  const closeHandler = () => {
    navigate("/job-description");
  };

  const jdData = useMemo(() => {
    const jd = data?.job_description_by_pk;
    const sectionProps = [
      {
        title: "",
        body: jd?.sections.introduction,
      },
      {
        title: i18n.t("jd.qualifications"),
        body: jd?.job_description_requirements?.map(
          (req: { name: string }) => req.name
        ),
      },
      {
        title: i18n.t("jd.responsibilities"),
        body: jd?.job_description_responsibilities?.map(
          (res: { name: string }) => res.name
        ),
      },
      {
        title: i18n.t("jd.benefits"),
        body: jd?.job_description_benefits?.map(
          (ben: { name: string }) => ben.name
        ),
      },
      {
        title: i18n.t("jd.abouttheCompany"),
        body: jd?.project?.client?.description,
      },
    ];
    return {
      sections: sectionProps,
      position: jd?.title,
      company: jd?.project.client.client_name,
      location: jd?.project.client?.client_locations
        ?.map(
          (location: { city: { city_name: string } }) => location.city.city_name
        )
        .join(","),
      workingType: jd?.job_environment
        ?.map((wt: { value: string }) => wt.value)
        .join(","),
      numberOfEmployees: jd?.project?.client?.employee_count,
    } as NSGeneratedJobDescriptionType.JdData;
  }, [data]);

  const downloadButton = () => {
    const content = document.getElementById("content") as HTMLElement;
    html2canvas(content, {
      height: window.outerHeight + window.innerHeight,
      windowHeight: window.outerHeight + window.innerHeight,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("portrait", "pt", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, 600, 850);
        pdf.save(`${jdData.position ?? "generatedJob"}.pdf`);
      })
      .then(() => {})
      .catch(() => {});
  };
  // const downloadButton = () => {
  //   const content = document.getElementById("container") as HTMLElement;

  //   const doc = new jsPDF("p", "mm", "a4");

  //   doc
  //     .html(content, {
  //       callback: (d: jsPDF) => {
  //         d.save(`${jdData.position ?? "g neratedJob"}.pdf`);
  //       },

  //       x: 15,
  //       y: 15,
  //       width: 180,
  //       windowWidth: 1360,
  //     })
  //     .catch(() => {});
  // };

  const copyToClipboard = () => {
    const container = document.getElementById("container")?.innerText;

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(container as string)
        ?.then(() => {})
        .catch(() => {});
    }
  };
  const shareJD = () => {
    if (jdId) {
      generateShareableJdLink({ jdId })
        .then((response) => {
          if (response?.generateShareableJDLink?.shareLink) {
            setJDLink(response?.generateShareableJDLink?.shareLink);
            setIsQRModalOpen(true);
          } else {
            throw Error();
          }
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("jd.generateSharebleLinkError")}
              type="error"
            />,
            {
              id: "jdLinkError",
            }
          );
        });
    }
  };

  const downloadQR = () => {
    const qrCodeElement = document.getElementById("qr-code");

    html2canvas(qrCodeElement as HTMLElement)
      .then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "qr-code.png";
        link.click();
      })
      .then(() => {
        toast(
          <ToastAlert
            description={i18n.t("jd.downloadQRSuccess")}
            type="success"
          />,
          {
            id: "downloadQRSuccess",
            duration: 15000,
          }
        );
      })
      .catch(() => {
        toast(
          <ToastAlert
            description={i18n.t("jd.downloadQRError")}
            type="error"
          />,
          {
            id: "downloadQRError",
            duration: 15000,
          }
        );
      });
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Modal2 onClose={closeHandler} isFullWidth>
      <NSCreateJobDescriptionStyle.Container id="content">
        <NSCreateJobDescriptionStyle.GeneratedJobDescriptionPageContainer>
          <NSCreateJobDescriptionStyle.GeneratedJobDescriptionPageGreenEllipse>
            <Icon name="GreenEllipse" />
          </NSCreateJobDescriptionStyle.GeneratedJobDescriptionPageGreenEllipse>
          <NSCreateJobDescriptionStyle.WJobDescripitonQuestionContainer>
            <NSCreateJobDescriptionStyle.LogoContainer>
              <Logo type="sideBySide" />
            </NSCreateJobDescriptionStyle.LogoContainer>
            <GeneratedJobDescription
              jdData={jdData}
              onSaveClicked={downloadButton}
              onCopyClicked={copyToClipboard}
              onShareClicked={shareJD}
            />
          </NSCreateJobDescriptionStyle.WJobDescripitonQuestionContainer>
          <NSCreateJobDescriptionStyle.GeneratedJobDescriptionPagePurplellipse>
            <Icon name="PurpleEllipse" />
          </NSCreateJobDescriptionStyle.GeneratedJobDescriptionPagePurplellipse>
        </NSCreateJobDescriptionStyle.GeneratedJobDescriptionPageContainer>
      </NSCreateJobDescriptionStyle.Container>
      <Modal
        isOpen={isQRModalOpen}
        onClickCloseButton={() => setIsQRModalOpen(false)}
      >
        <NSCreateJobDescriptionStyle.QRModalContent>
          <QRCode id="qr-code" value={jDLink} />
          <NSCreateJobDescriptionStyle.Link target="_blank" href={jDLink}>
            {jDLink}
          </NSCreateJobDescriptionStyle.Link>
          <SolidButton fullWidth label="Download QR" onClick={downloadQR} />
        </NSCreateJobDescriptionStyle.QRModalContent>
      </Modal>
    </Modal2>
  );
}

export default JobDescriptionDetail;
