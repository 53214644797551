import { ForwardedRef, forwardRef } from "react";

import GhostIconButton from "../GhostIconButton/GhostIconButton";
import ModalContainer from "react-modal";
import { NSModalStyle } from "./Modal.styles";
import { NSModalType } from "./Modal.types";

function Modal(
  {
    isOpen,
    children,
    onClose,
    onClickCloseButton,
    isDarkBackgroundActive = false,
    hiddenCloseButton,
  }: NSModalType.IModal,
  ref?: ForwardedRef<ReactModal>
) {
  return (
    <>
      <ModalContainer
        ref={ref}
        ariaHideApp={false}
        isOpen={isOpen}
        style={NSModalStyle.customModalStyle}
        onRequestClose={onClose}
      >
        <NSModalStyle.CloseButtonContainer>
          {!hiddenCloseButton && (
            <GhostIconButton icon="Close" onClick={onClickCloseButton} />
          )}
        </NSModalStyle.CloseButtonContainer>

        {children}
      </ModalContainer>
      {isOpen && isDarkBackgroundActive && <NSModalStyle.BluredBackground />}
    </>
  );
}

export default forwardRef(Modal);
