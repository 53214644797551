import styled, { keyframes } from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSDrawer {
  export const drawerEnterAnimation = keyframes`
    from {
      right: -33%;
    }
    to {
      right: 0;
    }
  `;

  export const drawerExitAnimation = keyframes`
    from {
      right: 0;
    }
    to {
      right: -33%;
    }
  `;

  export const Container = styled.div<{ blurOption: string }>`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark02};
    backdrop-filter: blur(${(props) => props.blurOption});
    position: fixed;
    overflow: hidden;
    z-index: 4;
  `;

  export const MenuContainer = styled.div<{ animation: "enter" | "exit" }>`
    position: fixed;
    top: 0;
    right: ${(props) => (props.animation === "enter" ? "0" : "-33%")};
    width: 33%;
    height: 100%;
    background: ${(props) => props.theme.colors.light.light};
    box-sizing: border-box;
    padding: 40px 48px;
    overflow-y: scroll;
    animation: ${(props) =>
        props.animation === "enter"
          ? drawerEnterAnimation
          : drawerExitAnimation}
      1s ease-in-out forwards;
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.size};
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${NSMainButtonStyle.ButtonBase} {
      width: 24px;
      height: 24px;
      padding: 0;
    }
  `;
}
