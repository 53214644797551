import { NSBarChartData } from "./BarChart.type";
import { NSBarChartStyle } from "./BarChart.style";

function BarChart({ data }: NSBarChartData.IBarChart) {
  const colors = {
    0: "hsl(228,71%,98%)",
    25: "hsla(349, 73%, 56%, 1)",
    50: "hsla(54, 84%, 58%, 1)",
    75: "hsla(39, 85%, 59%, 1)",
    100: "hsla(137, 93%, 38%, 1)",
  };

  const getColor = (value: number): string => {
    if (value != 0 && value <= 25) return colors[25];
    if (value > 25 && value <= 50) return colors[50];
    if (value > 50 && value <= 75) return colors[75];
    if (value > 75 && value <= 100) return colors[100];
    return colors[0];
  };

  return (
    <NSBarChartStyle.Container>
      {data.map((item) => (
        <NSBarChartStyle.ScoreContainer key={item.label}>
          <NSBarChartStyle.Label>{item.label}</NSBarChartStyle.Label>
          <NSBarChartStyle.EmptyBar>
            <NSBarChartStyle.ScoreThumb
              width={(item.value * 560) / 100}
              backgroundColor={getColor(item.value)}
            />
          </NSBarChartStyle.EmptyBar>
          <NSBarChartStyle.Value>{item.value}%</NSBarChartStyle.Value>
        </NSBarChartStyle.ScoreContainer>
      ))}
    </NSBarChartStyle.Container>
  );
}

export default BarChart;
