import styled from "styled-components";
import { IGhostButton } from "./GhostButton.type";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSGhostButtonStyle {
  export const GhostButtonBase = styled.div<IGhostButton>`
    width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
    ${NSMainButtonStyle.ButtonBase} {
      background-color: transparent;
      border-width: 0;
      color: ${(props) => props.theme.colors.primary.primary};

      &:hover {
        background-color: ${(props) => props.theme.colors.light.light};
      }

      &:disabled {
        color: ${(props) => props.theme.colors.secondary.light};
      }
    }
  `;
}
