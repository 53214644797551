import styled from "styled-components";

export namespace NSDashboardLayoutStyle {
  export const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    display: flex;
  `;

  export const LeftContent = styled.div<{ isOpen: boolean }>`
    height: 100%;
    width: ${(props) => (props.isOpen ? 300 : 80)}px;
  `;

  export const RightContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 68px;
    overflow-y: auto;
  `;

  export const HeaderContainer = styled.div`
    width: 100%;
    margin-bottom: 24px;
  `;

  export const BGBlur = styled.div`
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark02};
    backdrop-filter: blur(5px);
    position: fixed;
    overflow: hidden;
    z-index: 3;
  `;
}
