import styled from "styled-components";

export namespace NSOtherProjectStyle {
  export const Container = styled.div`
    width: 337px;
    height: fit-content;
    background-color: ${(props) => props.theme.colors.light.light};
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const OtherProjectItem = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.shadesOfdark.dark06};

    &:last-child {
      border-bottom: 0;
    }
  `;

  export const ProjectTitle = styled.div`
    white-space: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;
}
