import { ReactNode } from "react";
import CardView from "../CardView/CardView";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { NSModal2Style } from "./Modal2.style";

function Modal2({
  children,
  isFullWidth,
  onClose,
}: {
  children: ReactNode;
  isFullWidth: boolean;
  onClose: () => void;
}) {
  document.body.style.overflow = "hidden";
  const modalCloseHandler = () => {
    document.body.style.overflow = "auto";
    onClose();
  };
  return (
    <NSModal2Style.Container isFullWidth={isFullWidth}>
      {isFullWidth ? (
        <>
          {children}
          <NSModal2Style.CloseButtonContainer onClick={modalCloseHandler}>
            <GhostIconButton icon="Close" />
          </NSModal2Style.CloseButtonContainer>
        </>
      ) : (
        <CardView>
          {children}
          <NSModal2Style.LittleCloseButtonContainer onClick={modalCloseHandler}>
            <GhostIconButton icon="Close" size="medium" />
          </NSModal2Style.LittleCloseButtonContainer>
        </CardView>
      )}
    </NSModal2Style.Container>
  );
}

export default Modal2;
