import { forwardRef, useCallback, useImperativeHandle, useState } from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";

import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSRateSliderType } from "../../../../../components/RateSlider/RateSlider.type";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import RateSlider from "../../../../../components/RateSlider/RateSlider";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import i18n from "../../../../../library/i18next";

const TechnicalSkillRatingContainer = styled.div``;

const TechnicalSkillRating = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const [rateItems, setRateItems] = useState<NSRateSliderType.IRateSlider[]>(
    () =>
      activeQuestion?.answers.map((item) => ({
        label: item.value as string,
        selectedRate: 0,
      })) ?? []
  );

  const changeRateHandler = useCallback(
    (rate: number, label: string) => {
      const updatedItems = rateItems.map((item) => {
        if (item.label === label) {
          return {
            ...item,
            selectedRate: rate === item.selectedRate ? rate - 1 : rate,
          };
        }
        return item;
      });
      setRateItems(updatedItems);
    },
    [rateItems]
  );

  const getAnswersAsJson = useCallback(() => {
    const rateItemsWithValue = rateItems.map((item) => ({
      value: item.label,
      selectedRate: item.selectedRate,
    }));
    return rateItemsWithValue;
  }, [rateItems]);

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
    }),
    [getAnswersAsJson]
  );

  const renderRateSliders = () =>
    rateItems.map((item) => (
      <RateSlider
        key={item.label}
        label={item.label}
        onRateClicked={(rate) => changeRateHandler(rate, item.label)}
        selectedRate={item.selectedRate}
      />
    ));
  return (
    <TechnicalSkillRatingContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionItemsContainerFlexColumn>
          {renderRateSliders()}
        </NSCreateJobDescriptionStyle.WQQuestionItemsContainerFlexColumn>
      </NSCreateJobDescriptionStyle.WQuestionContainer>
      <NSCreateJobDescriptionStyle.Footer>
        <NSCreateJobDescriptionStyle.FooterLeftContainer />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
            backgroundColor={ButtonColorVariant.grey}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </TechnicalSkillRatingContainer>
  );
});

TechnicalSkillRating.displayName = "TechnicalSkillRating";

export default TechnicalSkillRating;
