import Loading from "../../../components/Loading/Loading";
import PlanCard from "../../../components/PlanCard/PlanCard";
import i18n from "../../../library/i18next";
import { NSSettingsMyPlansStyle } from "./Settings-MyPlans.style";
import useSettingsMyPlansVm from "./Settings-MyPlansVM";

function SettingsMyPlans() {
  const {
    isLoading,
    endDate,
    startDate,
    price,
    totalCredit,
    usedCredit,
    hasCompanySubscription,
  } = useSettingsMyPlansVm();
  return isLoading ? (
    <Loading />
  ) : (
    <NSSettingsMyPlansStyle.Container>
      <NSSettingsMyPlansStyle.Title>
        {i18n.t("settings.myPlans")}
      </NSSettingsMyPlansStyle.Title>
      {hasCompanySubscription ? (
        <NSSettingsMyPlansStyle.PlansContainer>
          <NSSettingsMyPlansStyle.PlanName>
            {i18n.t("settings.startUpPlan")}
          </NSSettingsMyPlansStyle.PlanName>
          <PlanCard
            endDate={endDate}
            startDate={startDate}
            price={price}
            totalCredit={totalCredit!}
            usedCredit={usedCredit!}
          />
        </NSSettingsMyPlansStyle.PlansContainer>
      ) : (
        <NSSettingsMyPlansStyle.NotFoundText>
          {i18n.t("projects.freeTrialPlanContactSupport")}
          <NSSettingsMyPlansStyle.SupportButton
            to={"mailto:info@hiringcycle.ai"}
          >
            {i18n.t("projects.contactSupport")}
          </NSSettingsMyPlansStyle.SupportButton>
        </NSSettingsMyPlansStyle.NotFoundText>
      )}
    </NSSettingsMyPlansStyle.Container>
  );
}

export default SettingsMyPlans;
