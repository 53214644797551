import styled from "styled-components";

export namespace NSCandidateSearchEmptyViewStyle {
  export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: auto;
    padding: 12px 18px;
    background-color: ${(props) => props.theme.colors.warning.yellow02};
    svg {
      width: 24px;
      height: 24px;

      path {
        stroke: ${(props) => props.theme.colors.error.red01};
      }
    }
  `;
  export const Content = styled.div`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 8px;
  `;
  export const Information = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;
}
