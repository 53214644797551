import styled from "styled-components";
import { NSDropdownStyle } from "../../../../components/Dropdown/Dropdown.style";

export namespace NSProjectDetailJobDescriptionStyle {
  export const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
  `;

  export const NotFoundText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const NotFoundUpper = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const PaginationContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 20px;
    margin-top: 20px;
    ${NSDropdownStyle.DropdownItemsContainer} {
      padding: 8px 0;
    }
  `;

  export const JobDescriptionContainer = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin: 8px 0 32px;
    color: ${(props) => props.theme.colors.neutrals.grey07};
    overflow-y: scroll;
    height: 400px;
    gap: 8px;
    width: 100%;
    padding-right: 100px;
    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutrals.grey03};
      border-radius: 20px;
    }
  `;
}
