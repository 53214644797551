import { Outlet } from "react-router-dom";

import { NSProjectsStyle } from "./Candidate.style";

function CandidatePage() {
  return (
    <NSProjectsStyle.Container>
      <Outlet />
    </NSProjectsStyle.Container>
  );
}

export default CandidatePage;
