import { NSDropdown } from "../components/Dropdown/Dropdown.type";

const parsePhoneNumberHelper = (
  phoneCodeDropdown: NSDropdown.IDropdownItem[],
  phoneNumber?: string
) => {
  if (phoneNumber) {
    const plainPhoneNumber =
      phoneNumber.length <= 10 ? phoneNumber : phoneNumber.slice(-10);

    const code = `+${phoneNumber.slice(
      0,
      phoneNumber.length - plainPhoneNumber.length
    )}`;
    const codeFromArray = phoneCodeDropdown.find((i) => i.label === code);

    return { codeFromArray, plainPhoneNumber };
  }
  return { codeFromArray: undefined, plainPhoneNumber: undefined };
};

export default parsePhoneNumberHelper;
