import styled from "styled-components";

export namespace NSClientStyle {
  export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  `;
}
