import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import { NSExperienceSelectionModalType } from "../../../../components/ExperienceSelectionModal/ExperienceSelectionModal.type";
import { NSSearchModalType } from "../../../../components/SearchModal/SearchModal.type";
import i18n from "../../../../library/i18next";
import rolesData from "../../../../data/roles";
import { useJobDescriptionContext } from "../../../../context/JobDescriptionContext/JobDescriptionContext";

const experienceLevelList: NSExperienceSelectionModalType.ITitleDetailPill[] = [
  { id: "1", isPrefix: true, label: i18n.t("jd.junior") },
  { id: "2", isPrefix: true, label: i18n.t("jd.midLevel") },
  { id: "3", isPrefix: true, label: i18n.t("jd.senior") },
];

const titlePositionList: NSExperienceSelectionModalType.ITitleDetailPill[] = [
  { id: "4", isPrefix: false, label: i18n.t("jd.manager") },
  { id: "5", isPrefix: false, label: i18n.t("jd.lead") },
];

function useJobDescriptionContextRoleVm() {
  const [filteredRoles, setFilteredRoles] = useState<
    NSSearchModalType.ISuggestionItem[]
  >(
    rolesData.slice(0, 5).map((role) => ({
      id: role.id,
      label: role.role,
    }))
  );

  const { selectExperience, selectPosition, selectRole, selectedRoleItem } =
    useJobDescriptionContext();

  const navigate = useNavigate();
  // const { data: rolesData } = useGraphQLQuery(GetRolesDocument);
  const goBack = () => {
    navigate(-1);
  };

  const searchChangeHandler = useCallback((value: string) => {
    const suggestionData: NSSearchModalType.ISuggestionItem[] = [{
      label: value,
      id: "0",
    }];
    const filteredSuggesionData = rolesData
      .filter((role) => role.role.toLowerCase().includes(value.toLowerCase()))
      .map((role) => ({
        id: role.id,
        label: role.role,
      }));
    setFilteredRoles([...suggestionData, ...filteredSuggesionData]);

  }, []);

  const experienceSelectHandler = () => {
    navigate(-1);
  };

  const resultSelectedHandler = async (
    selectedResult: NSSearchModalType.ISuggestionItem
  ) => {
    /*
      const defaultExperience = rolesData?.role.filter(
        (i) => i.id === selectedResult.id
      )[0].experience_level;
      const defaultPosition = rolesData?.role.filter(
        (i) => i.id === selectedResult.id
      )[0].position;
  */
    selectRole({
      id: selectedResult.id,
      label: selectedResult.label,
      isPrefix: false,
    });
    selectExperience(undefined);
    selectPosition(undefined);
    /*
      if (defaultExperience) {
        selectExperience({ id: defaultExperience, label: defaultExperience });
      }
      if (defaultPosition) {
        selectPosition({ id: defaultPosition, label: defaultPosition });
      }
  */
    navigate(-1);
  };

  return {
    filteredRoles,
    selectedRoleItem,
    searchChangeHandler,
    resultSelectedHandler,
    experienceSelectHandler,
    goBack,
    experienceLevelList,
    titlePositionList,
  };
}

export default useJobDescriptionContextRoleVm;
