import { useCallback, useDeferredValue, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Order_By,
  Project_Order_By,
  Project_Status_Enum,
  UpdateProjectStatusDocument,
} from "../../../gql/graphql";
import { NSBreadcrumbType } from "../../../components/Breadcrumb/Breadcrumb.type";
import { NSDropdown } from "../../../components/Dropdown/Dropdown.type";
import { NSDropdownWithCaptionType } from "../../../components/DropdownWithCaption/DropdownWithCaption.type";
import { NSEmptyViewType } from "../../../components/EmptyView/EmptyView.type";
import { useGraphQLMutation } from "../../../hooks/useGraphQL";
import { useProjects } from "../../../service/Project/getProjects";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import { NSProjectCardType } from "../../../components/ProjectCard/ProjectCard.type";
import { PAGINATION_DEFAULT_LIMIT } from "../../../utils/globalConstants";

type projectOrdersType = {
  [key: number]: Project_Order_By;
};

const useProjectsListVm = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({ start: 0, limit: PAGINATION_DEFAULT_LIMIT });
  const [searchString, setSearchString] = useState("");
  const deferredSearchString = useDeferredValue(searchString);
  const [deletedProjectId, setDeletedProjectId] = useState<string>("");

  const [selectedItem, setSelectedItem] = useState<number>(1);
  const [checkedItems, setCheckItems] = useState([
    { status: { _eq: Project_Status_Enum.Active } },
  ]);

  const projectOrders: projectOrdersType = useMemo(
    () => ({
      1: { client: { client_name: Order_By.Asc } },
      2: { end_date: Order_By.Asc },
      3: { created_at: Order_By.Asc },
    }),
    []
  );

  const projectFilter = useMemo(
    () => ({
      _and: [
        {
          _or: [
            { project_name: { _ilike: `%${deferredSearchString}%` } },
            {
              client: { client_name: { _ilike: `%${deferredSearchString}%` } },
            },
          ],
        },
        { _or: checkedItems },
      ],
    }),
    [deferredSearchString, checkedItems]
  );

  const { projects, rest } = useProjects(
    pagination.limit,
    pagination.start,
    [projectOrders[selectedItem as keyof projectOrdersType]],
    projectFilter
  );

  const navigate = useNavigate();
  const { mutateAsync: archiveProject } = useGraphQLMutation(
    UpdateProjectStatusDocument
  );

  const onArchiveProject = useCallback(
    (id: string) => {
      archiveProject({ status: Project_Status_Enum.Archived, projectId: id })
        .then(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.archiveSuccessMessage")}
              type="success"
            />,
            {
              id: "archiveProjectSuccess",
            }
          );
          navigate(0);
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.archiveErrorMessage")}
              type="error"
            />,
            {
              id: "archiveProjectError",
            }
          );
        });
    },
    [archiveProject, navigate]
  );

  const projectCardItems: NSProjectCardType.IProjectCard[] | undefined =
    useMemo(
      () =>
        projects?.project.map((projectItem) => ({
          id: projectItem.id,
          information: projectItem.description ?? "",
          deadline: projectItem.end_date
            ? new Date(projectItem.end_date)
            : undefined,
          projectName: projectItem.project_name ?? "",
          status: projectItem.status,
          created: projectItem.created_at,
          clientName: projectItem.client.client_name ?? "",
          avatars: projectItem.project_users.map((userItem) => ({
            letter: userItem.user.name[0] ?? "",
            type: "solid",
            avatarUrl: userItem.user.avatar ?? "",
            name: userItem.user.name,
          })),
        })),
      [projects?.project]
    );

  const searchInput = {
    id: "dashboardSearchInputId",
    name: "dashboardSearchInputName",
    onChange: (value: string) => {
      setSearchString(value);
    },
    value: deferredSearchString,
  };

  const breadcrumb: NSBreadcrumbType.IBreadcrumb = {
    pageName: i18n.t("general.projects"),
  };

  const onClickEmptyViewButton = useCallback(() => {
    setSearchString("");
    setCheckItems([
      { status: { _eq: Project_Status_Enum.Active } },
      { status: { _eq: Project_Status_Enum.Archived } },
      { status: { _eq: Project_Status_Enum.Cancelled } },
      { status: { _eq: Project_Status_Enum.Done } },
    ]);
  }, []);

  const emptyView: NSEmptyViewType.IEmptyView = {
    buttonLabel: i18n.t("general.tryAgain"),
    errorMessage: i18n.t("general.emptyViewErrorMessage"),
    onClick: onClickEmptyViewButton,
  };

  const handleSetPagination = useCallback((start: number, limit: number) => {
    setPagination({ start, limit });
  }, []);

  const onCheckedItems = useCallback((items: NSDropdown.IDropdownItem[]) => {
    const preparedItems = items.map((item) => ({
      status: {
        _eq: item.label
          ? (item.id as Project_Status_Enum)
          : Project_Status_Enum.Archived,
      },
    }));
    setCheckItems(preparedItems);
  }, []);

  const filterDropdownItems: NSDropdown.IDropdownItem[] = useMemo(
    () => [
      {
        id: Project_Status_Enum.Active,
        label: i18n.t("general.active"),
      },
      {
        id: Project_Status_Enum.Archived,
        label: i18n.t("general.archived"),
      },
      {
        id: Project_Status_Enum.Cancelled,
        label: i18n.t("general.cancelled"),
      },
      {
        id: Project_Status_Enum.Done,
        label: i18n.t("general.done"),
      },
    ],
    [i18n]
  );

  const dropdownsItems: NSDropdownWithCaptionType.IDropdownWithCaption[] =
    useMemo(
      () => [
        {
          id: 1,
          prefixTitle: i18n.t("projects.sortBy"),
          type: "basic",
          selectedDropdownItem: {
            id: 3,
            label: i18n.t("projects.createDate"),
          },
          onSelect: (_id, selected) => {
            setSelectedItem((selected?.id as number) ?? 1);
          },
          options: [
            {
              id: 1,
              label: i18n.t("general.client"),
            },
            {
              id: 2,
              label: i18n.t("projects.deadline"),
            },
            {
              id: 3,
              label: i18n.t("projects.createDate"),
            },
          ],
        },
        {
          prefixTitle: i18n.t("projects.filterBy"),
          id: 2,
          placeholder: i18n.t("projects.status"),
          type: "withCheckbox",
          defaultCheckedItems: [filterDropdownItems[0]],
          onCheckedItems: onCheckedItems,
          options: filterDropdownItems,
        },
      ],
      [filterDropdownItems, i18n, onCheckedItems, setSelectedItem]
    );

  const onClickCloseButton = useCallback(() => {
    document.body.style.overflow = "auto";
    setIsDeleteModalOpen(false);
  }, []);

  const onClickDelete = useCallback((id: string) => {
    document.body.style.overflow = "hidden";
    setIsDeleteModalOpen(true);
    setDeletedProjectId(id);
  }, []);

  return {
    searchInput,
    breadcrumb,
    projectCardItems,
    dropdownsItems,
    isLoading: rest.isLoading,
    deletedProjectId,
    isDeleteModalOpen,
    pagination,
    emptyView,
    totalItemCount: projects?.project_aggregate.aggregate?.count ?? 0,
    setIsDeleteModalOpen,
    onArchiveProject,
    onClickCloseButton,
    onClickDelete,
    handleSetPagination,
  };
};

export default useProjectsListVm;
