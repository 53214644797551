import { Controller } from "react-hook-form";
import i18n from "../../../library/i18next";
import { NSSettingsProfileStyle } from "./Settings-Profile.style";
import useSettingsProfileVm from "./Settings-ProfileVM";
import TextInput from "../../../components/TextInput/TextInput";
import SolidButton from "../../../components/SolidButton/SolidButton";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import capitalizeFirstLetterHelper from "../../../utils/capitalizeFirstLetterHelper";
import FileUpload from "../../../components/FileUpload/FileUpload";
import Loading from "../../../components/Loading/Loading";

function SettingsProfile() {
  const {
    isLoading,
    user,
    personalInfoControl,
    changePasswordControl,
    onImageUpload,
    handleSubmitPersonalInfo,
    handleSubmitChangePassword,
    onErrorFileUpload,
  } = useSettingsProfileVm();
  return isLoading ? (
    <Loading />
  ) : (
    <NSSettingsProfileStyle.Container>
      <NSSettingsProfileStyle.Title>
        {i18n.t("settings.accountSetting")}
      </NSSettingsProfileStyle.Title>
      <NSSettingsProfileStyle.Subtitle>
        {i18n.t("settings.accountSettingSubtitle")}
      </NSSettingsProfileStyle.Subtitle>
      <NSSettingsProfileStyle.UserCard>
        <NSSettingsProfileStyle.UserImage
          src={user?.avatar as string}
          alt="user-image"
        />
        <NSSettingsProfileStyle.UserInfo>
          <NSSettingsProfileStyle.Username>
            {capitalizeFirstLetterHelper(user?.name + " " + user?.surname)}
          </NSSettingsProfileStyle.Username>
          <NSSettingsProfileStyle.Email>
            {user?.email}
          </NSSettingsProfileStyle.Email>
          <NSSettingsProfileStyle.ButtonContainer>
            <FileUpload
              onUpload={onImageUpload}
              uploadButtonLabel={i18n.t("settings.changePhoto")}
              fileFormat={{ "file/jpeg": [".jpeg"], "file/png": [".png"] }}
              onError={(error) => onErrorFileUpload(error!)}
              uploadByButton
            />
          </NSSettingsProfileStyle.ButtonContainer>
        </NSSettingsProfileStyle.UserInfo>
      </NSSettingsProfileStyle.UserCard>
      <NSSettingsProfileStyle.PersonalInfoForm
        onSubmit={handleSubmitPersonalInfo()}
      >
        <Controller
          name="title"
          control={personalInfoControl}
          render={({ field, fieldState: { error } }) => (
            <NSSettingsProfileStyle.InputContainer>
              <TextInput
                id="personalInfoTitle"
                placeholder={i18n.t("jd.title")}
                error={!!error?.message}
                {...field}
              />
              {error?.message && (
                <NSSettingsProfileStyle.ErrorText>
                  {error.message}
                </NSSettingsProfileStyle.ErrorText>
              )}
            </NSSettingsProfileStyle.InputContainer>
          )}
        />
        <NSSettingsProfileStyle.ButtonContainer>
          <SolidButton
            label={i18n.t("settings.savePersonalInfo")}
            type="submit"
            fullWidth
          />
        </NSSettingsProfileStyle.ButtonContainer>
      </NSSettingsProfileStyle.PersonalInfoForm>
      <NSSettingsProfileStyle.ChangePasswordTitle>
        {i18n.t("settings.changePassword")}
      </NSSettingsProfileStyle.ChangePasswordTitle>
      <NSSettingsProfileStyle.ChangePasswordSubtitle>
        {i18n.t("settings.changePasswordSubtitle")}
      </NSSettingsProfileStyle.ChangePasswordSubtitle>
      <NSSettingsProfileStyle.ChangePasswordForm
        onSubmit={handleSubmitChangePassword()}
      >
        <Controller
          name="currentPassword"
          control={changePasswordControl}
          render={({ field, fieldState: { error } }) => (
            <NSSettingsProfileStyle.InputContainer>
              <PasswordInput
                id="currentPassword"
                placeholder={i18n.t("forms.currentPassword")}
                error={!!error?.message}
                {...field}
              />
              {error?.message && (
                <NSSettingsProfileStyle.ErrorText>
                  {error.message}
                </NSSettingsProfileStyle.ErrorText>
              )}
            </NSSettingsProfileStyle.InputContainer>
          )}
        />
        <NSSettingsProfileStyle.WarningMessage>
          {i18n.t("settings.changePasswordWarning")}
        </NSSettingsProfileStyle.WarningMessage>
        <Controller
          name="newPassword"
          control={changePasswordControl}
          render={({ field, fieldState: { error } }) => (
            <NSSettingsProfileStyle.InputContainer>
              <PasswordInput
                id="newPassword"
                placeholder={i18n.t("forms.newPassword")}
                error={!!error?.message}
                {...field}
              />
              {error?.message && (
                <NSSettingsProfileStyle.ErrorText>
                  {error.message}
                </NSSettingsProfileStyle.ErrorText>
              )}
            </NSSettingsProfileStyle.InputContainer>
          )}
        />
        <Controller
          name="confirmNewPassword"
          control={changePasswordControl}
          render={({ field, fieldState: { error } }) => (
            <NSSettingsProfileStyle.InputContainer>
              <PasswordInput
                id="confirmNewPassword"
                placeholder={i18n.t("forms.confirmNewPassword")}
                error={!!error?.message}
                {...field}
              />
              {error?.message && (
                <NSSettingsProfileStyle.ErrorText>
                  {error.message}
                </NSSettingsProfileStyle.ErrorText>
              )}
            </NSSettingsProfileStyle.InputContainer>
          )}
        />
        <NSSettingsProfileStyle.ButtonContainer>
          <SolidButton
            label={i18n.t("settings.savePassword")}
            type="submit"
            fullWidth
          />
        </NSSettingsProfileStyle.ButtonContainer>
      </NSSettingsProfileStyle.ChangePasswordForm>
    </NSSettingsProfileStyle.Container>
  );
}

export default SettingsProfile;
