import { useCallback, useMemo, useState } from "react";

import { NSTagStackStyle } from "./TagStack.style";
import { NSTagStackType } from "./TagStack.type";
import { NSTagType } from "../Tag/Tag.type";
import Tag from "../Tag/Tag";

function TagStack({
  tagList,
  onSelectedChanged,
  numberOfVisibleTag,
  setTaglist,
  singleSelect = false,
}: NSTagStackType.ITagStack) {
  const [onClickedShowMore, setOnClickedShowMore] = useState(false);

  const toggleTag = (id: string) => {
    const newState = tagList.map((obj) => {
      if (obj.id === id) {
        return { ...obj, isSelected: !obj.isSelected };
      }
      return { ...obj };
    });
    const selectedItems = newState.filter((tag) => tag.isSelected);

    const slicedArray = singleSelect
      ? selectedItems.filter((item) => item.id === id)
      : selectedItems;

    const slicedState = singleSelect
      ? newState.map((item) => {
          if (slicedArray.includes(item)) {
            return { ...item };
          }
          return { ...item, isSelected: false };
        })
      : newState;
    onSelectedChanged(slicedArray);
    setTaglist?.(slicedState);
  };

  const tagClickHandler = (tag: NSTagType.ITag) => {
    toggleTag(tag.id);
  };

  const numberOfVisibleTagState = useMemo(
    () =>
      onClickedShowMore ? tagList.length : numberOfVisibleTag ?? tagList.length,
    [numberOfVisibleTag, onClickedShowMore, tagList.length]
  );

  const numberOfHiddenTags = useMemo(
    () => tagList.length - numberOfVisibleTagState,
    [tagList.length, numberOfVisibleTagState]
  );

  const showMoreButton = useMemo(
    () => numberOfHiddenTags > 0,
    [numberOfHiddenTags]
  );

  const showMoreHandler = useCallback(() => {
    setOnClickedShowMore(true);
  }, []);

  return (
    <NSTagStackStyle.Container>
      {tagList.map(
        (tag, index) =>
          index < numberOfVisibleTagState &&
          tagList.length > 0 && (
            <Tag
              id={tag.id}
              key={tag.id}
              isSelected={tag.isSelected}
              onClick={() => tagClickHandler(tag)}
              value={tag.value}
            />
          )
      )}
      {showMoreButton && (
        <NSTagStackStyle.ShowMore onClick={showMoreHandler}>
          {numberOfHiddenTags} more
        </NSTagStackStyle.ShowMore>
      )}
    </NSTagStackStyle.Container>
  );
}

export default TagStack;
