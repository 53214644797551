import { NSSideMenuStyle } from "./SideMenu.style";
import { NSSideMenuType } from "./SideMenu.type";
import SideMenuItem from "../SideMenuItem/SideMenuItem";

function SideMenu({
  activePath,
  items,
  isOpen = true,
}: NSSideMenuType.ISideMenu) {
  return (
    <NSSideMenuStyle.Container>
      {items.map((item) => (
        <SideMenuItem
          activePath={activePath}
          key={item.name}
          icon={item.icon}
          // onClick={item.onClick}
          name={item.name}
          path={item.path}
          isOpen={isOpen}
        />
      ))}
    </NSSideMenuStyle.Container>
  );
}

export default SideMenu;
