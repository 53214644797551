import styled from "styled-components";
export namespace NSProgressBarStyle {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
  `;
  export const ProgressNumbers = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  `;
  export const ProgressText = styled.div`
    font-family: greycliff-cf, sans-serif;
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey06};
  `;
  export const EmptyBar = styled.div`
    width: 100%;
    height: 4px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const TotalNumberText = styled.div`
    width: max-content;
    display: inline-block;
    white-space: nowrap;
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;
  export const ProgressThumb = styled.div<{ progress: number }>`
    height: 4px;
    background-color: ${(props) => props.theme.colors.primary.primary};
    width: ${(props) => props.progress}%;
    transition: all 1s;
  `;
}
