import { UpdateProjectStatusDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

function useUpdateProjectStatus() {
  const { mutate, mutateAsync, ...rest } = useGraphQLMutation(
    UpdateProjectStatusDocument
  );

  return {
    mutate,
    mutateAsync,
    rest,
  };
}

export default useUpdateProjectStatus;
