import styled from "styled-components";
import Icon from "../Icon/Icon";

export namespace NSStatsChartList {
  export const StatsChartListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    height: fit-content;
  `;

  export const StatsChartListItem = styled.div`
    display: flex;
    align-items: stretch;
  `;

  export const StyledIcon = styled(Icon)`
    margin: auto 0;
  `;
}
