import styled from "styled-components";
import { NSAvatarStyle } from "../Avatar/Avatar.style";

namespace NSAvatarGroupStyle {
  export const AvatarGroupContainer = styled.div`
    display: flex;
  `;

  export const AvatarContainer = styled.div`
    margin-left: -16px;
    :first-child {
      margin-left: 0px;
    }
  `;

  export const ImageAvatarContainer = styled(AvatarContainer)`
    ${NSAvatarStyle.AvatarContainer} {
      border: 2px solid ${(props) => props.theme.colors.light.light};
    }
  `;
}
export default NSAvatarGroupStyle;
