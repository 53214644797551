import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import { NSStatsChartCard } from "./StatsChartCard.style";
import { IStatChartCard } from "./StatsChartCard.type";

function calculatePercentage(totalValue: number, statValue: number): number {
  if (totalValue === 0) {
    return statValue === 0 ? 0 : 100;
  }

  const percentage = (statValue / totalValue) * 100;
  return parseFloat(percentage.toFixed(1));
}

function StatsChartCard({ title, totalValue, statValue }: IStatChartCard) {
  const percentage = calculatePercentage(totalValue, statValue);
  return (
    <NSStatsChartCard.CardContainer>
      <NSStatsChartCard.Title>{title}</NSStatsChartCard.Title>
      <NSStatsChartCard.SpinnerContainer>
        <CircularProgressBar percentage={percentage} />
        <NSStatsChartCard.Text>
          <NSStatsChartCard.StatValue>{statValue}</NSStatsChartCard.StatValue>
          <NSStatsChartCard.Separator>/</NSStatsChartCard.Separator>
          <NSStatsChartCard.TotalValue>
            {totalValue}
          </NSStatsChartCard.TotalValue>
        </NSStatsChartCard.Text>
      </NSStatsChartCard.SpinnerContainer>
      <NSStatsChartCard.Badge>%{percentage}</NSStatsChartCard.Badge>
    </NSStatsChartCard.CardContainer>
  );
}
export default StatsChartCard;
