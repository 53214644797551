import styled from "styled-components";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSGeneratedJobDescriptionStyle {
  export const BorderContainer = styled.div`
    border-radius: 16px;
    border-top: 3.5px solid transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to left, #56ba6c, #2638d9);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
  `;
  export const Container = styled.div`
    font-family: greycliff-cf, sans-serif;
    background-color: ${colors.light.light};
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
  `;
  export const Position = styled.div`
    font-size: ${typography.titleh3.size};
    line-height: ${typography.titleh3.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    margin-bottom: 4px;
  `;
  export const CompanyAndLocation = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: ${typography.pharagraph2.lineHeight};
    margin-bottom: 24px;
    text-transform: capitalize;
  `;
  export const WorkingType = styled(CompanyAndLocation)`
    color: ${colors.neutrals.grey06};
    margin-bottom: 32px;
  `;
  export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    position: absolute;
    top: 182px;
    left: 20px;
    width: 100%;
  `;
  export const SubTitle = styled.div`
    font-size: ${typography.pharagraph1.size};
    line-height: ${typography.pharagraph1.lineHeight};
    font-weight: ${typography.fontWeights.bold};
    margin-bottom: 8px;
    margin-top: 32px;
  `;
  export const MainText = styled.div`
    font-size: ${typography.pharagraph2.size};
    line-height: 28px;
  `;

  export const SectionsContainer = styled.div`
    margin-top: 65px;
  `;
}
