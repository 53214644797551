import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { NSFormErrorBoxStyle } from "./FormErrorBox.style";
import { NSFormErrorBoxType } from "./FormErrorBox.type";

function FormErrorBox({
  error,
  description,
  isShown = false,
  onClickClose,
}: NSFormErrorBoxType.IFormErrorBox) {
  return isShown ? (
    <NSFormErrorBoxStyle.Container>
      <NSFormErrorBoxStyle.InnerContainer>
        {!!error && (
          <NSFormErrorBoxStyle.ErrorText>{error}</NSFormErrorBoxStyle.ErrorText>
        )}
        {!!description && (
          <NSFormErrorBoxStyle.Description>
            {description}
          </NSFormErrorBoxStyle.Description>
        )}

        <NSFormErrorBoxStyle.CloseContainer>
          <GhostIconButton id="Close" icon="Close" onClick={onClickClose} />
        </NSFormErrorBoxStyle.CloseContainer>
      </NSFormErrorBoxStyle.InnerContainer>
    </NSFormErrorBoxStyle.Container>
  ) : null;
}

export default FormErrorBox;
