import { IGhostButton } from "./GhostButton.type";
import MainButton from "../MainButton/MainButton";
import { NSGhostButtonStyle } from "./GhostButton.style";

function GhostButton({
  variant = "basic",
  fullWidth,
  disabled,
  onClick,
  size,
  label,
  iconColor,
  suffixIcon,
  type,
  prefixIcon,
}: IGhostButton) {
  return (
    <NSGhostButtonStyle.GhostButtonBase fullWidth={fullWidth}>
      <MainButton
        fullWidth={fullWidth}
        variant={variant}
        prefixIcon={prefixIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
        label={label}
        type={type}
        iconColor={iconColor}
        suffixIcon={suffixIcon}
      />
    </NSGhostButtonStyle.GhostButtonBase>
  );
}
export default GhostButton;
