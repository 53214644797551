import styled from "styled-components";

export namespace NSCardViewStyle {
  export const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 2.5rem 2rem;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.light.light};
    position: relative;
  `;
}
