import { GetJobDescriptionWithPaginationDocument } from "../../gql/graphql";

import { useGraphQLQuery } from "../../hooks/useGraphQL";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useGetJobDescriptionWithPagination(
  limit: number,
  offset: number,
  projectId: string
) {
  const { data, isLoading, refetch, ...rest } = useGraphQLQuery(
    GetJobDescriptionWithPaginationDocument,
    undefined,
    {
      limit,
      offset,
      projectId,
    }
  );

  return {
    isLoading,
    data,
    refetch,
    rest,
  };
}
export default useGetJobDescriptionWithPagination;
