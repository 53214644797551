import { ICheckBox } from "./CheckBox.type";
import Icon from "../Icon/Icon";
import { NSCheckboxStyle } from "./CheckBox.style";

function Checkbox({
  checked = false,
  label,
  error,
  icon,
  ...props
}: ICheckBox) {
  return (
    <NSCheckboxStyle.CheckboxBase>
      <NSCheckboxStyle.CheckboxContainer
        checked={checked}
        onClick={props.onClick}
      >
        <NSCheckboxStyle.HiddenCheckbox
          checked={checked}
          onChange={() => {}}
          type="checkbox"
          role="checkbox"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <NSCheckboxStyle.StyledCheckbox error={error} checked={checked}>
          {checked && <Icon name={icon ?? "Check"} />}
        </NSCheckboxStyle.StyledCheckbox>
      </NSCheckboxStyle.CheckboxContainer>
      <NSCheckboxStyle.CheckboxLabel id="checkBoxLabel" onClick={props.onClick}>
        {label}
      </NSCheckboxStyle.CheckboxLabel>
    </NSCheckboxStyle.CheckboxBase>
  );
}

export default Checkbox;
