import styled from "styled-components";
import { NSCardViewStyle } from "../../components/CardView/CardView.style";

export namespace NSForgotPasswordStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    padding-top: 64px;
    gap: 60px;

    & > ${NSCardViewStyle.Container} {
      max-width: 456px;
    }
  `;

  export const FormContainer = styled.div<{ isShown: boolean }>`
    width: 100;
    display: ${(props) => (props.isShown ? "flex" : "none")};
    flex-direction: column;
    gap: 2rem;
  `;

  export const UpContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  `;

  export const Title = styled.h4`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Description = styled.p`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `;

  export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `;
}
