import { cloneElement, useEffect, useMemo, useRef, useState } from "react";
import AddNewItem from "../AddNewItem/AddNewItem";
import Icon from "../Icon/Icon";
import { NSDropdown } from "./Dropdown.type";
import { NSDropdownStyle } from "./Dropdown.style";
import { NSMainInputStyle } from "../MainInput/MainInput.style";
import SearchInput from "../SearchInput/SearchInput";

function BasicDropdown({
  options,
  isOpen,
  onClickTitle,
  showLabel = true,
  error,
  suffixItem,
  label,
  placeholder,
  titleWithPrefixItem,
  containAddNewItemButton,
  title,
  children,
  prefixItem,
  selectedDropdownItem,
  onSelect,
  showSuffixItems = true,
  showHoverIcon,
  addNewItemButtonNode,
  searchText,
  setSearchText,
  isSearchActive,
  position,
  shouldBehaveLikeMenu,
}: Readonly<NSDropdown.IInnerDropdown>) {
  const [hoveredItem, setHoveredItem] = useState<number | string>();
  const [selectedItem, setSelectedItem] = useState<
    NSDropdown.IDropdownItem | undefined
  >(selectedDropdownItem);

  const dropdown = useRef(null as null | HTMLDivElement);
  const onChange = (item: NSDropdown.IDropdownItem | undefined) => {
    setSelectedItem(item);
    onSelect?.(item);
    setSearchText?.(undefined);
  };

  useEffect(() => {
    selectedDropdownItem && setSelectedItem(selectedDropdownItem);
  }, [selectedDropdownItem]);

  // eslint-disable-next-line consistent-return
  const dropdownLabel = useMemo(() => {
    if (label) {
      return label;
    }

    if (placeholder && selectedItem) {
      return placeholder;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label, placeholder, selectedItem, selectedDropdownItem]);

  const optionsAfterSearch: NSDropdown.IDropdownItem[] = useMemo(() => {
    // eslint-disable-next-line consistent-return
    let searchOptions;
    if (searchText) {
      searchOptions = options.filter((item) =>
        item.label?.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())
      );
    } else {
      searchOptions = options;
    }
    return searchOptions;
  }, [options, searchText]);

  const Element = children
    ? cloneElement(children as React.ReactElement<any>, {
        onClick: onClickTitle,
        style: {
          position: "relative",
        },
      })
    : null;

  return (
    <>
      {Element ?? (
        <NSDropdownStyle.DropdownTitle ref={dropdown} onClick={onClickTitle}>
          {showLabel && dropdownLabel && (
            <NSMainInputStyle.InputLabel>
              {dropdownLabel}
            </NSMainInputStyle.InputLabel>
          )}

          <NSDropdownStyle.InnerDropdownTitle isError={error}>
            {prefixItem}
            <NSDropdownStyle.InnerTitle>
              {titleWithPrefixItem ?? selectedItem?.label ?? (
                <NSDropdownStyle.Placeholder>
                  {placeholder}
                </NSDropdownStyle.Placeholder>
              )}
            </NSDropdownStyle.InnerTitle>
            {showSuffixItems && (
              <NSDropdownStyle.SuffixItemContainer>
                {suffixItem}
              </NSDropdownStyle.SuffixItemContainer>
            )}
          </NSDropdownStyle.InnerDropdownTitle>
        </NSDropdownStyle.DropdownTitle>
      )}
      {isOpen && (
        <NSDropdownStyle.DropdownItemsContainer
          topPosition={dropdown.current?.offsetHeight}
          position={position}
        >
          <NSDropdownStyle.ScrollableArea>
            {title && (
              <NSDropdownStyle.MenuTitle>{title}</NSDropdownStyle.MenuTitle>
            )}

            {isSearchActive && (
              <NSDropdownStyle.SeachInputContainer>
                <SearchInput
                  onChange={(value) => setSearchText?.(value)}
                  id="dropdownSearchId"
                  name="dropdownSearchName"
                  autoComplete="on"
                />
              </NSDropdownStyle.SeachInputContainer>
            )}
            {optionsAfterSearch?.map((item) => (
              <NSDropdownStyle.DropdownItemContainer
                isSelected={
                  shouldBehaveLikeMenu && item.id === selectedDropdownItem?.id
                }
                key={item.id}
              >
                <NSDropdownStyle.DropdownItem
                  onMouseLeave={() => setHoveredItem(undefined)}
                  onMouseEnter={() => setHoveredItem(item.id)}
                  onClick={() => onChange(item)}
                >
                  {hoveredItem === item.id && showHoverIcon && (
                    <NSDropdownStyle.IconContainer>
                      <Icon
                        role="img"
                        width={24}
                        height={24}
                        name="ArrowDownRight"
                      />
                    </NSDropdownStyle.IconContainer>
                  )}
                  <NSDropdownStyle.DropdownItemText>
                    {item.label}
                  </NSDropdownStyle.DropdownItemText>
                </NSDropdownStyle.DropdownItem>
              </NSDropdownStyle.DropdownItemContainer>
            ))}
            {containAddNewItemButton && (
              <NSDropdownStyle.AddNewItemButtonContainer>
                {addNewItemButtonNode ?? (
                  <AddNewItem type="dropdown" items={options} />
                )}
              </NSDropdownStyle.AddNewItemButtonContainer>
            )}
          </NSDropdownStyle.ScrollableArea>
        </NSDropdownStyle.DropdownItemsContainer>
      )}
    </>
  );
}

export default BasicDropdown;
