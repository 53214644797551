import { NSCandidateInfoCardStyle } from "./CandidateInfoCard.style";
import { NSCandidateInfoCardType } from "./CandidateInfoCard.type";

function CandidateInfoCard({
  candidateName,
  phoneNumber,
  emailAddress,
}: NSCandidateInfoCardType.ICandidateInfoCard) {
  return (
    <NSCandidateInfoCardStyle.Container>
      <NSCandidateInfoCardStyle.SelectedCandidateTitle>
        {candidateName}
      </NSCandidateInfoCardStyle.SelectedCandidateTitle>
      <NSCandidateInfoCardStyle.SelectedCandidateInfo>
        {phoneNumber}
      </NSCandidateInfoCardStyle.SelectedCandidateInfo>
      <NSCandidateInfoCardStyle.SelectedCandidateInfo>
        {emailAddress}
      </NSCandidateInfoCardStyle.SelectedCandidateInfo>
    </NSCandidateInfoCardStyle.Container>
  );
}

export default CandidateInfoCard;
