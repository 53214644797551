import { Outlet } from "react-router-dom";
import DashboardLayout from "../../layout/DashboardLayout/DashboardLayout";
import { NSHomeStyle } from "./Home.style";
import useHomeVm from "./HomeVM";

export default function Home() {
  const homeVm = useHomeVm();
  homeVm.redirectToProject();
  return (
    <DashboardLayout
      user={homeVm.activeUser!}
      hasCompanySubscription={
        !!homeVm.companySubscriptionData?.company_subscription[0]?.id ||
        homeVm.isLoading
      }
    >
      <NSHomeStyle.Container>
        <NSHomeStyle.Content>
          <Outlet />
        </NSHomeStyle.Content>
      </NSHomeStyle.Container>
    </DashboardLayout>
  );
}
