import RadioButton from "../RadioButton/RadioButton";
import SelectedAnswerReviewerInfo from "../SelectedAnswerReviewerInfo/SelectedAnswerReviewerInfo";
import { NSAnswerOptionStyle } from "./AnswerOption.style";
import { NSAnswerOptionType } from "./AnswerOption.type";
import LinkButton from "../LinkButton/LinkButton";
import i18n from "../../library/i18next";

function AnswerOption({
  label,
  isSelected,
  onClick,
  isAISelected,
  id,
  isOnlyAISelected = true,
  userInfo,
  onClickReasonButton,
  hasReason = false,
}: NSAnswerOptionType.IAnswerOption) {
  const width = window.innerWidth;
  return (
    <NSAnswerOptionStyle.Container
      isSelected={isSelected}
      isAISelected={isAISelected}
      isOnlyAISelected={isOnlyAISelected}
      maxWidth={width * 0.8}
    >
      <RadioButton
        id={id}
        disabled={isAISelected}
        checked={isSelected || isAISelected}
        labelContent={
          <NSAnswerOptionStyle.AnswerLabelContainer>
            <NSAnswerOptionStyle.AnswerLabel
              onClick={onClick}
              maxWidth={width * 0.65}
            >
              {label}
            </NSAnswerOptionStyle.AnswerLabel>
            <SelectedAnswerReviewerInfo
              isOnlyAISelected={isOnlyAISelected}
              isAISelected={isAISelected}
              userInfo={userInfo}
            />

            {isAISelected && hasReason && (
              <LinkButton
                variant="label-w-icon-prefix"
                prefixIcon="News"
                label={i18n.t("candidate.viewReason")}
                onClick={onClickReasonButton}
              />
            )}

            {isSelected && (
              <LinkButton
                variant="label-w-icon-prefix"
                prefixIcon={hasReason ? "Edit" : "Add"}
                label={
                  hasReason
                    ? i18n.t("candidate.editReason")
                    : i18n.t("candidate.addReason")
                }
                onClick={onClickReasonButton}
              />
            )}
          </NSAnswerOptionStyle.AnswerLabelContainer>
        }
      />
    </NSAnswerOptionStyle.Container>
  );
}

export default AnswerOption;
