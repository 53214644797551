import { Outlet } from "react-router-dom";
import { NSClientStyle } from "./Clients.style";

export default function Clients() {
  return (
    <NSClientStyle.Container>
      <Outlet />
    </NSClientStyle.Container>
  );
}
