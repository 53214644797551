import Badge from "../Badge/Badge";
import Icon from "../Icon/Icon";
import { NSSideMenuItemStyle } from "./SideMenuItem.style";
import { NSSideMenuItemType } from "./SideMenuItem.type";

function SideMenuItem({
  name,
  icon,
  badgeLabel,
  isOpen = true,
  path,
  customPath,
  activePath,
}: NSSideMenuItemType.ISideMenuItem) {
  return (
    <div>
      <NSSideMenuItemStyle.Container to={path}>
        <NSSideMenuItemStyle.LabelAndIconContainer
          isActive={activePath === (customPath ?? path)}
        >
          <Icon role="img" width={32} height={32} name={icon} />
          {isOpen && (
            <NSSideMenuItemStyle.Label>{name}</NSSideMenuItemStyle.Label>
          )}
        </NSSideMenuItemStyle.LabelAndIconContainer>
        {badgeLabel && isOpen && <Badge label={badgeLabel} />}
      </NSSideMenuItemStyle.Container>
    </div>
  );
}

export default SideMenuItem;
