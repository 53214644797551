import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";
import GhostIconButton from "../GhostIconButton/GhostIconButton";
import { NSDrawer } from "./Drawer.style";
import { NSDrawerType } from "./Drawer.type";

function Drawer({
  children,
  title,
  onClickCloseIcon,
  isAddedSelfCloseFunction,
  blurOption = "5px",
}: NSDrawerType.IDrawer) {
  const [animation, setAnimation] = useState<"enter" | "exit">("enter");
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    setAnimation("exit");
    if (isAddedSelfCloseFunction) {
      setTimeout(() => {
        onClickCloseIcon?.();
      }, 1000);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [isAddedSelfCloseFunction, navigate, onClickCloseIcon]);

  return (
    <NSDrawer.Container blurOption={blurOption}>
      <NSDrawer.MenuContainer animation={animation}>
        <NSDrawer.Header>
          <NSDrawer.Title>{title}</NSDrawer.Title>
          <GhostIconButton onClick={goBack} icon="Close" iconSize={24} />
        </NSDrawer.Header>
        {children}
      </NSDrawer.MenuContainer>
    </NSDrawer.Container>
  );
}

export default Drawer;
