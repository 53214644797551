import { useLocation, useNavigate } from "react-router-dom";

import { Toaster } from "react-hot-toast";
import { useMemo, useState } from "react";
import DashboardMenuSidebar from "../../components/DashboardMenuSidebar/DashboardMenuSidebar";
import { NSContextMenuType } from "../../components/ContextMenu/ContextMenu.type";
import { NSDashboardLayoutStyle } from "./DashboardLayout.style";
import { NSDashboardLayoutType } from "./DashboardLayout.type";
import { NSSideMenuItemType } from "../../components/SideMenuItem/SideMenuItem.type";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import i18n from "../../library/i18next";

const navbarTopItems: NSSideMenuItemType.ISideMenuItem[] = [
  {
    icon: "Project",
    name: i18n.t("general.projects"),
    path: "projects",
  },
  {
    icon: "News",
    name: i18n.t("general.jds"),
    path: "job-description",
  },
  {
    icon: "Layer",
    name: i18n.t("general.clients"),
    path: "clients",
  },
  {
    icon: "Users",
    name: i18n.t("general.team"),
    path: "team",
  },
];

const navbarBottomItems: NSSideMenuItemType.ISideMenuItem[] = [
  {
    icon: "Help",
    name: i18n.t("general.support"),
    path: "mailto:info@hiringcycle.ai",
  },
];

function DashboardLayout({
  children,
  isOpen = true,
  user,
  hasCompanySubscription,
}: NSDashboardLayoutType.IDashboardLayout) {
  const { logout } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const contextMenu: NSContextMenuType.IContextMenu = {
    type: "basic",
    onContextMenuOpen: (value) => setIsMenuOpen(value),
    items: [
      {
        label: i18n.t("general.createNewProject"),
        onClick: () => {
          navigate("/projects/create");
        },
      },
      {
        label: i18n.t("general.createJD"),
        onClick: () => {
          navigate("/job-description/create");
        },
      },
      {
        label: i18n.t("general.createNewClient"),
        onClick: () => {
          navigate("/clients/create");
        },
      },
    ],
  };

  const closedPaths = useMemo(() => ["/projects/detail", "sdfsdf"], []);

  const isIncludeClosedPaths = useMemo(
    () => closedPaths.map((i) => location.pathname.includes(i)),
    [closedPaths, location.pathname]
  );

  return (
    <NSDashboardLayoutStyle.Container>
      <NSDashboardLayoutStyle.LeftContent
        isOpen={isIncludeClosedPaths.some((i) => i === true) ? false : isOpen}
      >
        <DashboardMenuSidebar
          activePath={location.pathname.split("/")[1]}
          onClickLogout={logout}
          isDashboardOpen={
            isIncludeClosedPaths.some((i) => i === true) ? false : isOpen
          }
          navbarTopItems={navbarTopItems}
          navbarBottomItems={navbarBottomItems}
          user={user!}
          contextMenu={contextMenu}
          hasCompanySubscription={hasCompanySubscription}
        />
      </NSDashboardLayoutStyle.LeftContent>
      <NSDashboardLayoutStyle.RightContent>
        {children}
        <Toaster position="bottom-right" />
      </NSDashboardLayoutStyle.RightContent>
      {isMenuOpen && <NSDashboardLayoutStyle.BGBlur />}
    </NSDashboardLayoutStyle.Container>
  );
}

export default DashboardLayout;
