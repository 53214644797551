import StatsChartCard from "../StatsChartCard/StatsChartCard";
import { NSStatsChartList } from "./StatsChartList.style";
import { NSStatsChartListType } from "./StatsChartList.type";

function StatsChartList({
  data,
}: {
  data: NSStatsChartListType.IStatChartList;
}) {
  if (!data.length) return null;
  return (
    <NSStatsChartList.StatsChartListContainer>
      {data.map((item, index) => (
        <NSStatsChartList.StatsChartListItem key={index}>
          <StatsChartCard
            statValue={item.statValue}
            title={item.title}
            totalValue={item.totalValue}
          />
          {index !== data.length - 1 && (
            <NSStatsChartList.StyledIcon
              data-testid={`arrow-icon-${index}`}
              id={`ArrowNextRightIconId${index}`}
              width={24}
              height={24}
              name="Right"
            />
          )}
        </NSStatsChartList.StatsChartListItem>
      ))}
    </NSStatsChartList.StatsChartListContainer>
  );
}
export default StatsChartList;
