import styled from "styled-components";

export namespace NSAddCandidateStyle {
  export const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    margin-top: 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;
  `;

  export const SearchResultText = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};

    padding-left: 12px;
  `;

  export const ResultContainer = styled.div``;
  export const InputContainer = styled.div`
    margin-bottom: 24px;
  `;

  export const ResultItem = styled.div`
    width: 100%;
    height: 32px;
    padding: 9.5px 12px;
    cursor: pointer;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    :hover {
      background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};
      color: ${(props) => props.theme.colors.primary.primary};
    }
  `;
}
