import { GetCitiesDropdownDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetCitiesDropdown() {
  const { data, ...rest } = useGraphQLQuery(GetCitiesDropdownDocument);

  const cities = data?.city.map((cityItem) => ({
    dropdown: {
      id: cityItem.id,
      label: cityItem.city_name,
    },
  }));

  return {
    cities,
    rest,
  };
}

export default useGetCitiesDropdown;
