import styled from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSGhostIconButtonStyle {
  export const GhostIconButtonBase = styled.div`
    ${NSMainButtonStyle.ButtonBase} {
      padding: 0;
      svg {
        color: ${(props) => props.theme.colors.neutrals.grey04};
      }
      &:hover {
        background-color: transparent;
        svg {
          color: ${(props) => props.theme.colors.neutrals.grey05};
        }
      }
      :disabled {
        background-color: transparent;
      }
    }
  `;
}
