import styled from "styled-components";
import { NSMainInputStyle } from "../MainInput/MainInput.style";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";

export namespace NSJDGSalarySelectionStyle {
  export const Container = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 8px;
    ${NSMainInputStyle.InputFlex} {
      border-color: ${(props) => props.theme.colors.dark.dark};
      &:hover {
        border-color: ${(props) => props.theme.colors.primary.primary};
      }
    }

    ${NSMainInputStyle.BaseInput} {
      font-size: ${(props) => props.theme.typography.pharagraph1.size};
      line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
      width: 150px;
    }

    ${NSDropdownStyle.InnerDropdownTitle} {
      border-color: ${(props) => props.theme.colors.dark.dark};
      font-size: ${(props) => props.theme.typography.pharagraph1.size};
      line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    }

    ${NSDropdownStyle.DropdownBase} {
      min-width: 78px;
      max-width: fit-content;
    }
  `;

  export const InputContainer = styled.div`
    width: 200px;
  `;
}
