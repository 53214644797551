import styled, { css } from "styled-components";
import { NSContextMenuType } from "./ContextMenu.type";

export namespace NSContextMenuStyle {
  export const ContextMenuBase = styled.div`
    display: block;
    position: relative;
    font-family: greycliff-cf;
  `;

  export const ContextMenuContent = styled.div<{
    isVisible: boolean;
    menuPosition: NSContextMenuType.ContextMenuPosition;
    contentHeight: number;
  }>`
    visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
    position: absolute;
    left: 0;
    background-color: ${(props) => props.theme.colors.light.light};
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
    top: ${(props) =>
      props.menuPosition === "up"
        ? `-${props.contentHeight - 32}px`
        : props.menuPosition === "center"
        ? `-${props.contentHeight / 2 - 32}px`
        : "0"};
    z-index: 2;
    padding: 8px 0;
    border-radius: 8px;
    width: 100%;
    min-width: max-content;
  `;

  export const ContextMenuItemContent = styled.div<{ isScrollable?: boolean }>`
    ${(props) =>
      props.isScrollable &&
      css`
        max-height: 192px;
        overflow-y: scroll;
      `}

    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutrals.grey03};
      border-radius: 20px;
    }
  `;

  export const ContextMenuButton = styled.span`
    cursor: pointer;
  `;

  export const ItemLabel = styled.span`
    font-size: 14px;
    line-height: 21px;
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
  `;

  export const InnerComponent = styled.div``;
  export const ContextMenuItemContainer = styled.div``;

  export const ContextMenuItem = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 16px;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};
    color: ${(props) => props.theme.colors.neutrals.grey04};

    ${(props) =>
      !props.isDisabled &&
      css`
        background-color: ${(props) => props.theme.colors.light.light};
        color: ${(props) => props.theme.colors.dark.dark};
        cursor: pointer;

        :hover {
          background-color: ${(props) =>
            props.theme.colors.shadesOfdark.dark06};

          ${ItemLabel} {
            color: ${(props) => props.theme.colors.primary.primary};
          }
          svg {
            path {
              stroke: ${(props) => props.theme.colors.primary.primary};
            }
          }
        }
      `};
  `;

  export const InnerComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const Divider = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.colors.neutrals.grey03};
    height: 1px;
  `;

  export const DividerContainer = styled.div`
    padding: 4px 16px;
  `;

  export const MenuTitle = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: 16px;
    line-height: 24px;
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    margin-bottom: 25.5px;
  `;

  export const ButtonContainer = styled.div`
    margin: 24px 24px 0;
  `;

  export const AddNewItemContainer = styled.div`
    padding: 0 24px;
    margin-top: 16px;
  `;

  export const CascadingMenuContent = styled.div<{
    itemIndex: number;
    width?: number;
    openDirection: "left" | "right";
  }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    left: ${(props) =>
      props.openDirection === "right" ? `${props.width ?? 0}px` : "auto"};
    right: ${(props) =>
      props.openDirection === "left" ? `${props.width ?? 0}px` : "auto"};
    top: ${(props) => props.itemIndex * 40}px;
    background-color: ${(props) => props.theme.colors.light.light};
    box-shadow: 0px 0px 24px 0px #00000052;
    z-index: 2;
    padding: 8px 0;
    border-radius: 8px;
    width: 100%;
    min-width: max-content;
  `;

  export const CascadingContextMenuItem = styled(ContextMenuItem)<{
    isDisabled?: boolean;
  }>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 16px;
    background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};
    color: ${(props) => props.theme.colors.neutrals.grey04};

    ${(props) =>
      !props.isDisabled &&
      css`
        background-color: ${(props) => props.theme.colors.light.light};
        color: ${(props) => props.theme.colors.dark.dark};
        cursor: pointer;

        :hover {
          background-color: ${(props) =>
            props.theme.colors.shadesOfdark.dark06};

          ${ItemLabel} {
            color: ${(props) => props.theme.colors.primary.primary};
          }
          svg {
            path {
              stroke: ${(props) => props.theme.colors.primary.primary};
            }
          }
        }
      `};
  `;

  export const SearchInputContainer = styled.div`
    padding: 0 24px;
    margin-bottom: 25.5px;
  `;
}
