/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-cap */

import { NSGeneratedJobDescriptionStyle } from "./GeneratedJobDescription.style";
import { NSGeneratedJobDescriptionType } from "./GeneratedJobDescription.type";
import OutlinedButton from "../OutlinedButton/OutlinedButton";
import SolidButton from "../SolidButton/SolidButton";
import colors from "../../style/colors";
import i18n from "../../library/i18next";

function GeneratedJobDescription({
  jdData,
  onSaveClicked,
  onCopyClicked,
  onShareClicked,
}: NSGeneratedJobDescriptionType.IGeneratedJobDescription) {
  const getSectionBody = (sectionBody: string | string[]) => {
    if (Array.isArray(sectionBody)) {
      return (
        <ul>
          {sectionBody.map((section: string) => (
            <li key={section}>{section}</li>
          ))}
        </ul>
      );
    }
    return sectionBody;
  };

  return (
    <NSGeneratedJobDescriptionStyle.BorderContainer>
      <NSGeneratedJobDescriptionStyle.Container id="container">
        <NSGeneratedJobDescriptionStyle.Position>
          {jdData?.position}
        </NSGeneratedJobDescriptionStyle.Position>
        <NSGeneratedJobDescriptionStyle.CompanyAndLocation>
          {jdData?.company} {jdData?.location && `-${jdData?.location}`}
        </NSGeneratedJobDescriptionStyle.CompanyAndLocation>
        <NSGeneratedJobDescriptionStyle.WorkingType>
          {jdData?.workingType}
          <br />
          {jdData?.numberOfEmployees &&
            `${jdData?.numberOfEmployees} ${i18n.t("jd.employees")}`}
        </NSGeneratedJobDescriptionStyle.WorkingType>

        <NSGeneratedJobDescriptionStyle.SectionsContainer>
          {jdData?.sections?.map((item) => (
            <div key={item.title}>
              <NSGeneratedJobDescriptionStyle.SubTitle>
                {item.title}
              </NSGeneratedJobDescriptionStyle.SubTitle>
              <NSGeneratedJobDescriptionStyle.MainText>
                {getSectionBody(item.body)}
              </NSGeneratedJobDescriptionStyle.MainText>
            </div>
          ))}
        </NSGeneratedJobDescriptionStyle.SectionsContainer>
      </NSGeneratedJobDescriptionStyle.Container>

      <NSGeneratedJobDescriptionStyle.ButtonsContainer>
        <SolidButton
          id="saveJobButton"
          label={i18n.t("jd.saveJd")}
          variant="label-w-icon-prefix"
          prefixIcon="News"
          iconColor={colors.light.light}
          onClick={onSaveClicked}
        />
        <OutlinedButton
          id="coppyButton"
          label={i18n.t("jd.copyJd")}
          variant="label-w-icon-prefix"
          prefixIcon="Add"
          onClick={onCopyClicked}
        />
        <OutlinedButton
          id="coppyButton"
          label={i18n.t("jd.getShareLink")}
          variant="label-w-icon-prefix"
          prefixIcon="Add"
          onClick={onShareClicked}
        />
      </NSGeneratedJobDescriptionStyle.ButtonsContainer>
    </NSGeneratedJobDescriptionStyle.BorderContainer>
  );
}

export default GeneratedJobDescription;
