import styled from "styled-components";

export namespace NSRadioButtonStyle {
  export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  export const RadioButtonInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-clip: content-box;
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey04};
    background-color: transparent;
    border-radius: 50%;

    :checked {
      padding: 4px;
      background-color: ${(props) => props.theme.colors.primary.primary};
      accent-color: ${(props) => props.theme.colors.primary.primary};
      border: 2px solid ${(props) => props.theme.colors.primary.primary};
    }
  `;

  export const RadioButtonLabel = styled.label`
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-left: 8px;
    cursor: pointer;
  `;
}
