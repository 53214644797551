import styled from "styled-components";
import MainButton from "../../../components/MainButton/MainButton";
import { Form } from "react-router-dom";
import { NSMainInputStyle } from "../../../components/MainInput/MainInput.style";

export namespace NSSettingsProfileStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 32px;
  `;

  export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `;

  export const ErrorText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    color: rgb(225, 61, 91);
  `;

  export const Title = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Subtitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const UserCard = styled.div`
    width: 483px;
    margin-top: 40px;
    display: flex;
    gap: 24px;
    padding: 10px 8px;
    border-radius: 32px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    align-items: center;
  `;

  export const UserImage = styled.img`
    width: 96px;
    height: 96px;
    border-radius: 24px;
  `;
  export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
  `;
  export const Username = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;
  export const Email = styled.div`
    margin-top: 4px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin-bottom: 12px;
  `;

  export const ChangePhotoButton = styled(MainButton)`
    background: none;
    border: none;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 0;
  `;

  export const PersonalInfoForm = styled(Form)`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${NSMainInputStyle.InputWrapper} {
      width: 308px;
    }
  `;

  export const ChangePasswordTitle = styled.div`
    margin-top: 32px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ChangePasswordSubtitle = styled.div`
    margin-top: 4px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const ChangePasswordForm = styled(Form)`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${NSMainInputStyle.InputWrapper} {
      width: 308px;
    }
  `;

  export const ButtonContainer = styled.div`
    width: 308px;
  `;

  export const WarningMessage = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;
}
