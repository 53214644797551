import styled, { css } from "styled-components";
import { NSAvatarStyle } from "../Avatar/Avatar.style";
import { NSUserStyle } from "../User/User.style";
import Icon from "../Icon/Icon";

export namespace NSSelectedAnswerReviewerInfoStyle {
  export const AIContainer = styled.div<{ isOnlyAISelected?: boolean }>`
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: ${(props) => props.theme.colors.primary.tint};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
    gap: 4px;
    border-radius: 8px;

    ${(props) =>
      !props.isOnlyAISelected &&
      css`
        color: ${props.theme.colors.primary.tint03};
      `}
  `;

  export const UserContainer = styled.div<{ isOnlyAISelected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 8px;

    ${NSAvatarStyle.AvatarContainer} {
      width: 24px;
      height: 24px;
    }

    ${NSUserStyle.InfoRightContainer} {
      margin-left: 4px;
    }

    ${NSUserStyle.NameText} {
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.bold};
      color: ${(props) => props.theme.colors.light.light};
    }
  `;

  export const AIIconContainer = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.light.light};
    position: relative;
  `;

  export const IconContainer = styled(Icon)`
    position: absolute;
    top: -3px;
    left: -5.5px;
  `;
}
