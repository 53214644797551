import styled from "styled-components";

export namespace NSTagStackStyle {
  export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  `;
  export const ShowMore = styled.button`
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.primary.primary};
    border: none;
    background-color: transparent;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    cursor: pointer;
  `;
}
