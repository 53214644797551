import Modal2 from "../../../../components/Modal/Modal2";
import useVideoPreviewVM from "./Video-PreviewVm";
import Logo from "../../../../components/Logo/Logo";
import { NSVideoPreviewStyle } from "./Video-Preview.style";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";

export default function VideoPreview() {
  const videoPreviewVM = useVideoPreviewVM();

  return (
    <Modal2 onClose={videoPreviewVM.closeHandler} isFullWidth>
      <NSVideoPreviewStyle.Container>
        <Logo type="onlyIcon" />

        <NSVideoPreviewStyle.VideoContainer>
          <VideoPlayer
            urls={videoPreviewVM.videoUrls?.getSignedUrl?.signedUrls ?? []}
          />
        </NSVideoPreviewStyle.VideoContainer>
      </NSVideoPreviewStyle.Container>
    </Modal2>
  );
}
