import styled from "styled-components";

export namespace NSScoreChartStyle {
  export const Container = styled.div`
    width: fit-content;
    height: fit-content;
    position: relative;
    .RCP {
      height: 150px;
      overflow: hidden;
    }
  `;

  export const ProgressText = styled.div<{
    leftPosition: number;
    progressColor?: string;
  }>`
    position: absolute;
    top: 86px;
    left: ${(props) => props.leftPosition}px;

    color: ${(props) =>
      props.progressColor ?? props.theme.colors.warning.yellow01};
    font-size: ${(props) => props.theme.typography.titleh3.size};
    line-height: ${(props) => props.theme.typography.titleh3.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const IconContainer = styled.div`
    position: absolute;
    top: 28px;
    left: 28px;
    svg {
      width: 168px;
      height: auto;
    }
  `;
}
