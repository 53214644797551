import styled from "styled-components";

export namespace NSHomeStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const Content = styled.section`
    width: 100%;
  `;
}
