import styled from "styled-components";
import { NSDashboardNotificationType } from "./DashboardNotification.type";

export namespace NSDashboardNotificationStyle {
  export const TypeLabelText = styled.div`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const Container = styled.div<NSDashboardNotificationType.IDashboardNotification>`
    background-color: transparent;
    width: max-content;
    bottom: 42px;
    display: flex;

    align-items: center;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    padding: 12px 16px;
    border-left: 4px solid
      ${(props) =>
        props.borderColor
          ? NSDashboardNotificationType.TypeColorVariant[props.borderColor]
          : NSDashboardNotificationType.TypeColorVariant[props.type]};
    gap: 10px;

    ${TypeLabelText} {
      color: ${(props) =>
        NSDashboardNotificationType.TypeColorVariant[props.type]};
    }
  `;

  export const MessageText = styled.div`
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
  `;
}
