import { Form } from "react-router-dom";
import styled from "styled-components";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";
import MainButton from "../../../components/MainButton/MainButton";
import { NSContextMenuStyle } from "../../../components/ContextMenu/ContextMenu.style";

export namespace NSCreateProjectStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    gap: 32px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
  `;

  export const CreateProjectForm = styled(Form)`
    gap: 32px;
    display: flex;
    flex-direction: column;
  `;

  export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 88px;
    min-width: 758px;

    ${NSContextMenuStyle.ContextMenuBase} {
      width: 100%;
    }

    ${NSContextMenuStyle.ContextMenuContent} {
      width: 40%;
    }
  `;

  export const ModuleContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
  `;

  export const ModalTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};

    margin-bottom: 4px;
  `;

  export const ModalSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-bottom: 12px;
  `;

  export const ModalContentTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};

    margin: 8px 0;
  `;

  export const IconContainer = styled.div`
    padding: 8px;
    border-radius: 8px;
  `;

  export const PreInterviewModule = styled.div`
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;

    :hover {
    border: 2px solid ${(props) => props.theme.colors.primary.primary};
    background: unset;


    ${IconContainer} {
      background-color: ${(props) => props.theme.colors.neutrals.grey01};
    }

    svg {
      path {
        stroke: ${(props) => props.theme.colors.primary.primary};
    }
  }
  `;

  export const LonglistModule = styled(PreInterviewModule)`
    margin: 12px 0;
  `;

  export const LanguageTestModule = styled(PreInterviewModule)`
    margin: 0 0 24px;
    padding: 8px 16px;
  `;

  export const ModuleTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const ModuleSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.neutrals.grey04};
  `;

  export const CreateInterviewLater = styled.div`
    display: flex;
    margin-top: 24px;
    gap: 24px;
    align-items: center;
  `;
  export const Or = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;
  export const LaterButton = styled(MainButton)`
    ${NSMainButtonStyle.ButtonLabel} {
      color: ${(props) => props.theme.colors.primary.primary};
    }
    background-color: ${(props) => props.theme.colors.light.light};
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey04};
  `;

  export const NotificationBadge = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    border: 1px solid ${(props) => props.theme.colors.primary.tint05};
    background: linear-gradient(
      97.18deg,
      #939eff -34.08%,
      #ffffff 20.5%,
      #ffffff 69.4%,
      #c8f6d3 142.78%
    );
    margin-top: 24px;
    max-width: 584px;
    border-radius: 8px;
    gap: 4px;
  `;

  export const BadgeTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;
  export const BadgeSubTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;
}
