import styled from "styled-components";
import { NSContextMenuStyle } from "../ContextMenu/ContextMenu.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSContextMenuHOCStyle {
  export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${NSContextMenuStyle.ContextMenuContent} {
      right: 8px;
      left: unset;
    }

    ${NSMainButtonStyle.ButtonBase} {
      padding: 0;
      margin-right: 16px;
      width: fit-content;
      height: fit-content;
      svg {
        width: 24px;
        height: 24px;
      }

      :hover {
        svg {
          path {
            stroke: ${(props) => props.theme.colors.primary.primary};
          }
        }
      }
    }
  `;
}
