import { NSContextMenuStyle } from "./ContextMenu.style";
import { NSContextMenuType } from "./ContextMenu.type";

function BasicContextMenu({
  id,
  items,
  divider,
  onClickItem,
}: NSContextMenuType.IInnerContextMenu) {
  return (
    <NSContextMenuStyle.InnerComponentContainer>
      {items.map((item, index) => (
        <div key={item.label}>
          {item.isSeperatedFromTop && divider}
          <NSContextMenuStyle.ContextMenuItem
            key={item.label}
            onClick={() => {
              item.onClick?.(index, id);
              onClickItem?.();
            }}
          >
            <NSContextMenuStyle.ItemLabel>
              {item.label}
            </NSContextMenuStyle.ItemLabel>
          </NSContextMenuStyle.ContextMenuItem>
        </div>
      ))}
    </NSContextMenuStyle.InnerComponentContainer>
  );
}

export default BasicContextMenu;
