import { GetUsersCountDocument, GetUsersDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

export function useGetUsers(
  limit?: number,
  offset?: number,
  searchString?: string
) {
  const { data, ...rest } = useGraphQLQuery(GetUsersDocument, undefined, {
    limit,
    offset,
    searchString,
  });

  return {
    rest,
    data,
  };
}

export function useGetUsersCount() {
  const { data, ...rest } = useGraphQLQuery(GetUsersCountDocument);

  return {
    rest,
    data,
  };
}
