import { Controller } from "react-hook-form";
import Loading from "../../../components/Loading/Loading";
import i18n from "../../../library/i18next";
import { NSSettingsIntegrationStyle } from "./Settings-Integration.style";
import useSettingsIntegrationVm from "./Settings-IntegrationVM";
import TextInput from "../../../components/TextInput/TextInput";
import Dropdown from "../../../components/Dropdown/Dropdown";
import SolidButton from "../../../components/SolidButton/SolidButton";
import Icon from "../../../components/Icon/Icon";

function SettingsIntegration() {
  const {
    isLoading,
    control,
    expirations,
    selectedExpiration,
    isExpirationTextVisible,
    newGeneratedToken,
    userKeys,
    languageCode,
    setSelectedExpiration,
    copyTokenToClipboard,
    handleSubmit,
    handleRevokeAccess,
  } = useSettingsIntegrationVm();

  return isLoading ? (
    <Loading />
  ) : (
    <NSSettingsIntegrationStyle.Container>
      <NSSettingsIntegrationStyle.Title>
        {i18n.t("settings.integrationsManagement")}
      </NSSettingsIntegrationStyle.Title>
      <NSSettingsIntegrationStyle.Subtitle>
        {i18n.t("settings.integrationsManagementSubtitle")}
      </NSSettingsIntegrationStyle.Subtitle>
      <NSSettingsIntegrationStyle.TitleContainer>
        <NSSettingsIntegrationStyle.TokenTitle>
          {i18n.t("settings.personalAccessTokens")}
        </NSSettingsIntegrationStyle.TokenTitle>
        <NSSettingsIntegrationStyle.TokenSubtitle>
          {i18n.t("settings.personalAccessTokensSubtitle")}
        </NSSettingsIntegrationStyle.TokenSubtitle>
      </NSSettingsIntegrationStyle.TitleContainer>
      <NSSettingsIntegrationStyle.FormContainer onSubmit={handleSubmit}>
        <Controller
          name="tokenName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              id="tokenName"
              placeholder={i18n.t("settings.tokenName")}
              error={!!error?.message}
              errorText={error?.message}
              {...field}
            />
          )}
        />
        <NSSettingsIntegrationStyle.DropdownContainer>
          <Controller
            name="expiration"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const { ref, ...fieldProps } = field;
              return (
                <Dropdown
                  id={1}
                  type="basic"
                  options={expirations ?? []}
                  onSelect={(_id, selected) => {
                    field.onChange(selected);
                    setSelectedExpiration(selected!);
                  }}
                  selectedDropdownItem={selectedExpiration}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...fieldProps}
                />
              );
            }}
          />
          {isExpirationTextVisible && (
            <NSSettingsIntegrationStyle.IntegrationText>
              {i18n.t("settings.noExpirationText")}
            </NSSettingsIntegrationStyle.IntegrationText>
          )}
        </NSSettingsIntegrationStyle.DropdownContainer>
        <SolidButton
          type="submit"
          label={i18n.t("settings.generateToken")}
          fullWidth
        />
      </NSSettingsIntegrationStyle.FormContainer>
      {newGeneratedToken && (
        <NSSettingsIntegrationStyle.CopyTokenContainer>
          <NSSettingsIntegrationStyle.CopyTokenTitle>
            <Icon name="Key" />
            {newGeneratedToken.label}
          </NSSettingsIntegrationStyle.CopyTokenTitle>
          <NSSettingsIntegrationStyle.InputContainer language={languageCode!}>
            <TextInput
              id="token"
              name="token"
              value={newGeneratedToken.key}
              suffixItem={
                <>
                  <Icon name="Copy" />
                  <NSSettingsIntegrationStyle.CopyTokenButton
                    onClick={copyTokenToClipboard}
                    label={i18n.t("settings.copyToken")}
                  />
                </>
              }
              variant="label-w-icon-suffix"
              disabled
            />

            <NSSettingsIntegrationStyle.InputText>
              {i18n.t("settings.copyTokenInputText")}
            </NSSettingsIntegrationStyle.InputText>
          </NSSettingsIntegrationStyle.InputContainer>
        </NSSettingsIntegrationStyle.CopyTokenContainer>
      )}
      {userKeys?.user_key && userKeys.user_key.length > 0 && (
        <NSSettingsIntegrationStyle.TokenContainer>
          <NSSettingsIntegrationStyle.TokenTitle>
            {i18n.t("settings.tokens")}
          </NSSettingsIntegrationStyle.TokenTitle>
          <NSSettingsIntegrationStyle.GeneratedTokenContainer>
            {userKeys?.user_key.map((item) => (
              <NSSettingsIntegrationStyle.GeneratedTokenItem key={item.id}>
                <NSSettingsIntegrationStyle.GeneratedTokenLeft>
                  <NSSettingsIntegrationStyle.CopyTokenTitle>
                    <Icon name="Key" />
                    {item.label}
                  </NSSettingsIntegrationStyle.CopyTokenTitle>
                  {/*  <NSSettingsIntegrationStyle.ExpirationInfo>
                    This token will never expire.
                  </NSSettingsIntegrationStyle.ExpirationInfo> */}
                </NSSettingsIntegrationStyle.GeneratedTokenLeft>
                <NSSettingsIntegrationStyle.GeneratedTokenRight>
                  {/*  <NSSettingsIntegrationStyle.UsageInfo>
                    Never Used
                  </NSSettingsIntegrationStyle.UsageInfo> */}
                  {!item.expired_at && (
                    <NSSettingsIntegrationStyle.CopyTokenButton
                      onClick={() => handleRevokeAccess(item.id)}
                      label="Revoke access"
                    />
                  )}
                </NSSettingsIntegrationStyle.GeneratedTokenRight>
              </NSSettingsIntegrationStyle.GeneratedTokenItem>
            ))}
          </NSSettingsIntegrationStyle.GeneratedTokenContainer>
        </NSSettingsIntegrationStyle.TokenContainer>
      )}
    </NSSettingsIntegrationStyle.Container>
  );
}

export default SettingsIntegration;
