import { GetVideoInterviewDocument } from "../../gql/graphql";

import { useGraphQLQuery } from "../../hooks/useGraphQL";

function useGetVideoInterview(id: string) {
  const { data, refetch, ...rest } = useGraphQLQuery(
    GetVideoInterviewDocument,
    undefined,
    { id }
  );

  return {
    refetch,
    data,
    rest,
  };
}
export default useGetVideoInterview;
