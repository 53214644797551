import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import colors from "../../style/colors";
import typography from "../../style/typography";

export namespace NSBreadcrumbStyle {
  export const Container = styled.div`
    font-family: greycliff-cf, sans-serif;
    display: flex;
    width: 100%;
    align-items: center;

    ${NSDropdownStyle.InnerDropdownTitle} {
      padding: 0;
      border: 0;
      gap: 8px;
    }
    ${NSDropdownStyle.SuffixItemContainer} {
      width: 24px;
      height: 24px;
    }

    ${NSDropdownStyle.Placeholder} {
      font-size: ${typography.titleh4.size};
      line-height: ${typography.titleh4.lineHeight};
      font-weight: ${typography.fontWeights.demiBold};
      color: ${colors.dark.dark};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 260px;
    }

    ${NSDropdownStyle.InnerDropdownTitle} {
      font-size: ${typography.titleh4.size};
      line-height: ${typography.titleh4.lineHeight};
      font-weight: ${typography.fontWeights.demiBold};
      color: ${colors.dark.dark};
    }

    ${NSDropdownStyle.DropdownItemsContainer} {
      width: 260px;
      left: 0;
    }
  `;

  export const RouteLabel = styled.div`
    font-size: ${typography.titleh4.size};
    line-height: ${typography.titleh4.lineHeight};
    font-weight: ${typography.fontWeights.demiBold};
    color: ${colors.dark.dark};
  `;

  export const GeneralRouteLabel = styled.a`
    font-size: ${typography.smallText1.size};
    line-height: ${typography.smallText1.lineHeight};
    font-weight: ${typography.fontWeights.medium};
    color: ${colors.primary.primary};
    min-width: max-content;
    margin-right: 26px;
    text-decoration: none;
  `;
}
