import styled from "styled-components";
import { NSJDGInputStyle } from "../../../components/JDGInput/JDGInput.style";
import { NSTextAreaStyle } from "../../../components/TextArea/TextArea.style";

export namespace NSCandidateReviewerQuestionStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 32px 28px;
  `;

  export const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  export const LogoContainer = styled.div`
    position: absolute;
    top: 45px;
    left: 28px;
  `;

  export const QuestionContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 88px 0 0 60px;
  `;

  export const QuestionHeader = styled.div`
    color: ${(props) => props.theme.colors.neutrals.grey06};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const Question = styled.div`
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-top: 30px;
  `;

  export const QuestionDescription = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    margin-top: 8px;
  `;

  export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
    ${NSJDGInputStyle.Container} {
      padding: 16px;
      width: 500px;
      justify-content: flex-start;
      text-align: start;
    }
  `;

  export const ButtonContainer = styled.div`
    display: flex;
    margin: 0 32px 32px 0;
    width: 100%;
    gap: 12px;
    justify-content: flex-end;
    align-items: flex-end;
  `;

  export const SecondButton = styled.div`
    svg {
      path {
        stroke: ${(props) => props.theme.colors.light.light};
      }
    }
  `;

  export const VideoPlayerContainer = styled.div`
    position: absolute;
    bottom: 409px;
    right: 384px;
    top: unset;
    left: unset;
  `;

  export const DrawerContainer = styled.div`
    width: 100%;

    box-sizing: border-box;
    margin-top: 32px;
    display: flex;

    gap: 16px;
    flex-direction: column;

    ${NSTextAreaStyle.InputFlex} {
      align-items: flex-start;
      padding: 8px 16px;
      height: 144px;
    }

    ${NSTextAreaStyle.BaseInput} {
      height: 100%;
    }
  `;

  export const ReviewText = styled.div``;
}
