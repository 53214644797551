import { useCallback, useMemo } from "react";

import Modal from "../Modal/Modal";
import { NSExperienceSelectionModalStyle } from "./ExperienceSelectionModal.style";
import { NSExperienceSelectionModalType } from "./ExperienceSelectionModal.type";
import { NSTagType } from "../Tag/Tag.type";
import SolidButton from "../SolidButton/SolidButton";
import TagStack from "../TagStack/TagStack";
import { useJobDescriptionContext } from "../../context/JobDescriptionContext/JobDescriptionContext";
import i18n from "../../library/i18next";

enum EExperienceSelection {
  experience,
  title_position,
}

interface IExperienceSelectionItem {
  type: EExperienceSelection;
  item: NSExperienceSelectionModalType.ITitleDetailPill;
  isSelected: boolean;
}

function ExperienceSelectionModal({
  onClose,
  isOpen,
  title,
  titlePositionList,
  experienceLevelList,
  onApplyClicked,
  onClickCloseButton,
}: NSExperienceSelectionModalType.IExperienceSelectionModal) {
  const {
    selectedExperienceItem,
    selectedPositionItem,
    selectExperience,
    selectPosition,
  } = useJobDescriptionContext();
  const items = useMemo<IExperienceSelectionItem[]>(() => {
    const experienceTags = experienceLevelList.map((e) => ({
      item: e,
      type: EExperienceSelection.experience,
      isSelected: e.id === selectedExperienceItem?.id,
    }));
    const titlePositionTags = titlePositionList.map((e) => ({
      item: e,
      type: EExperienceSelection.title_position,
      isSelected: e.id === selectedPositionItem?.id,
    }));

    return experienceTags.concat(titlePositionTags);
  }, [
    experienceLevelList,
    selectedExperienceItem?.id,
    selectedPositionItem?.id,
    titlePositionList,
  ]);

  const selectedItem:
    | NSExperienceSelectionModalType.ITitleDetailPill
    | undefined = useMemo(() => {
    const tempSelectedItem = items.find((i) => i.isSelected);
    return tempSelectedItem
      ? {
          id: tempSelectedItem.item.id,
          isPrefix: tempSelectedItem.item.isPrefix,
          label: tempSelectedItem.item.label,
        }
      : undefined;
  }, [items]);

  const experienceLevelListTag: NSTagType.ITag[] = useMemo(
    () =>
      items
        .filter((i) => i.type === EExperienceSelection.experience)
        .map((i) => ({
          id: i.item.id,
          value: i.item.label,
          isSelected: i.isSelected,
          isPrefix: i.item.isPrefix,
        })),
    [items]
  );

  const titlePositionListTag: NSTagType.ITag[] = useMemo(
    () =>
      items
        .filter((i) => i.type === EExperienceSelection.title_position)
        .map((i) => ({
          id: i.item.id,
          value: i.item.label,
          isSelected: i.isSelected,
          isPrefix: i.item.isPrefix,
        })),
    [items]
  );

  const applyHandler = useCallback(() => {
    onApplyClicked();
  }, [onApplyClicked]);

  const experienceTagSelectHandler = useCallback(
    (tagList: NSTagType.ITag[]) => {
      const tmpItem = tagList[0];
      selectPosition(undefined);
      selectExperience({
        id: tmpItem.id,
        label: tmpItem.value,
        isPrefix: items.find((item) => item.item.id === tmpItem.id)?.item
          .isPrefix,
      });
    },
    [items, selectExperience, selectPosition]
  );

  const positonTagSelectHandler = useCallback(
    (tagList: NSTagType.ITag[]) => {
      const tmpItem = tagList[0];
      selectExperience(undefined);
      selectPosition({
        id: tmpItem.id,
        label: tmpItem.value,
        isPrefix: items.find((item) => item.item.id === tmpItem.id)?.item
          .isPrefix,
      });
    },
    [items, selectExperience, selectPosition]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClickCloseButton={onClickCloseButton}
    >
      <NSExperienceSelectionModalStyle.Container>
        <NSExperienceSelectionModalStyle.TitleContainer>
          <NSExperienceSelectionModalStyle.PrefixSuffix>
            {selectedItem && selectedItem.isPrefix && `${selectedItem.label} `}
          </NSExperienceSelectionModalStyle.PrefixSuffix>
          {title}
          <NSExperienceSelectionModalStyle.PrefixSuffix>
            {selectedItem &&
              !selectedItem?.isPrefix &&
              ` ${selectedItem.label}`}
          </NSExperienceSelectionModalStyle.PrefixSuffix>
        </NSExperienceSelectionModalStyle.TitleContainer>
        <NSExperienceSelectionModalStyle.PillContainer>
          <NSExperienceSelectionModalStyle.SubTitle>
            {i18n.t("jd.addExpreinceLevel")}
          </NSExperienceSelectionModalStyle.SubTitle>
          {experienceLevelListTag.length > 0 && (
            <TagStack
              singleSelect
              tagList={experienceLevelListTag}
              onSelectedChanged={experienceTagSelectHandler}
            />
          )}
          <NSExperienceSelectionModalStyle.SubTitle>
            {i18n.t("jd.titlePosition")}
          </NSExperienceSelectionModalStyle.SubTitle>
          {titlePositionListTag.length > 0 && (
            <TagStack
              singleSelect
              tagList={titlePositionListTag}
              onSelectedChanged={positonTagSelectHandler}
            />
          )}
        </NSExperienceSelectionModalStyle.PillContainer>
        <SolidButton
          label={i18n.t("general.apply")}
          fullWidth
          onClick={applyHandler}
        />
      </NSExperienceSelectionModalStyle.Container>
    </Modal>
  );
}

export default ExperienceSelectionModal;
