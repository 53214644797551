import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useCallback } from "react";
import Modal2 from "../../components/Modal/Modal2";
import { NSCreateInterviewStyle } from "./Interview-Create.style";
import InterviewModalRenderer from "./Views/InterviewModalRenderer";
import { InterviewProvider } from "../../context/InterviewContext/Interview-Create/InterviewContext";

function CreateInterview() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const closeHandler = useCallback(() => {
    document.body.style.overflow = "auto";
    navigate(-1);
  }, [navigate, state?.projectId]);

  document.body.style.overflow = "hidden";
  return (
    <Modal2 onClose={closeHandler} isFullWidth>
      <NSCreateInterviewStyle.Container>
        <InterviewProvider>
          <InterviewModalRenderer />
          <Outlet />
        </InterviewProvider>
      </NSCreateInterviewStyle.Container>
    </Modal2>
  );
}

export default CreateInterview;
