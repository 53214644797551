import styled, { css } from "styled-components";

export namespace NSCheckboxStyle {
  export const CheckboxBase = styled.div`
    display: flex;
    align-items: center;

    * {
      user-select: none;
    }
  `;

  export const CheckboxLabel = styled.div`
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-left: 8px;
    cursor: pointer;
  `;

  export const Icon = styled.div`
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.theme.colors.primary.primary};
    position: absolute;
  `;

  export const CheckboxContainer = styled.div<{
    checked: boolean;
  }>`
    display: flex;
    cursor: pointer;
    background: ${(props) =>
      props.checked ? props.theme.colors.primary.primary : "transparent"};
    border-radius: 8px;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
  `;

  // Hide checkbox visually but remain accessible to screen readers.
  export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `;

  export const StyledIcon = styled(Icon)<{ checked: boolean; name: string }>`
    display: hidden;
    ${(props) =>
      props.checked &&
      css`
        display: block;
      `}
  `;

  export const StyledCheckbox = styled.div<{
    checked: boolean;
    error?: boolean;
  }>`
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey04};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${(props) =>
      props.error &&
      css`
        border-color: ${(props) => props.theme.colors.error.red01};
      `}

    ${(props) =>
      props.checked &&
      css`
        outline: none;
        border: none;

        svg {
          border-radius: 2px;
        }
      `}
  `;
}
