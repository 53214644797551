import styled from "styled-components";

export namespace NSPasswordSuccessCardStyle {
  export const Container = styled.div`
    padding: 0 16px;
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 456px;
    box-sizing: border-box;
  `;

  export const Illustration = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 24px;
  `;

  export const Title = styled.div`
    font-family: greycliff-cf;
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    font-size: ${(props) => props.theme.typography.titleh4.size};
    color: ${(props) => props.theme.colors.dark.dark};
    ${(props) => props.theme.typography.titleh4.lineHeight};
    margin-bottom: 8px;
    text-align: center;
    width: 360px;
    height: 31px;
  `;
  export const Description = styled.div`
    font-family: greycliff-cf;
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    margin-bottom: 32px;
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    text-align: center;
    width: 360px;
    height: 72px;
  `;

  export const StoryContainer = styled.div`
    padding: 24px;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
}
