import { InviteMemberDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

export function useInviteMember() {
  const { mutateAsync, ...rest } = useGraphQLMutation(InviteMemberDocument);

  return {
    inviteMember: mutateAsync,
    inviteMemberRest: rest,
  };
}

export default useInviteMember;
