import { DeleteJobDescriptionDocument } from "../../../../gql/graphql";
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ISolidButton } from "../../../../components/SolidButton/SolidButton.type";
import { PAGINATION_DEFAULT_LIMIT } from "../../../../utils/globalConstants";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import i18n from "../../../../library/i18next";
import toast from "react-hot-toast";
import { useGraphQLMutation } from "../../../../hooks/useGraphQL";
import useGetJobDescriptionWithPagination from "../../../../service/JobDescription/getJobDescriptionWithPagination";

const useDetailJobDescriptionVm = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({ start: 0, limit: PAGINATION_DEFAULT_LIMIT });

  const onSetPagination = useCallback((start: number, limit: number) => {
    setPagination({ start, limit });
  }, []);

  const { projectId } = useParams();

  const { data, isLoading, refetch } = useGetJobDescriptionWithPagination(
    pagination.limit,
    pagination.start,
    projectId!
  );
  const { mutateAsync: deleteJobDescription } = useGraphQLMutation(
    DeleteJobDescriptionDocument
  );

  const projectDetailData = useMemo(
    () => ({
      jobDescriptions: data?.project_by_pk?.job_descriptions,
    }),
    [data]
  );

  const deleteJobDescriptionFunction = useCallback(
    (id: string) => {
      deleteJobDescription({ id })
        .then(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.jdDeleteSuccess")}
              type="success"
            />,

            {
              id: "deleteJDSuccess",
            }
          );
          refetch();
        })
        .catch(() => {
          toast(
            <ToastAlert
              description={i18n.t("projects.jdDeleteError")}
              type="error"
            />,

            {
              id: "deleteJdError",
            }
          );
        });
    },
    [deleteJobDescription, refetch]
  );

  const paginationComponent = useMemo(
    () => ({
      itemCount:
        data?.project_by_pk?.job_descriptions_aggregate.aggregate?.count ?? 0,
    }),
    [data?.project_by_pk?.job_descriptions_aggregate.aggregate?.count]
  );

  const isJobDescriptionLoading = useMemo(
    () => isLoading,

    [isLoading]
  );

  const generateJDButton: ISolidButton = useMemo(
    () => ({
      label: i18n.t("projects.generateJobDescription"),
      onClick: () =>
        navigate("/job-description/create", { state: { projectId } }),
    }),
    [navigate, projectId]
  );

  return {
    generateJDButton,
    paginationComponent,
    pagination,
    isJobDescriptionLoading,
    projectDetailData,
    deleteJobDescriptionFunction,
    onSetPagination,
  };
};

export default useDetailJobDescriptionVm;
