import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

:root {
  --rt-opacity: 1 !important ;
}

  body {
    margin: 0;
    background-color: hsla(216, 24%, 96%, 1);
    font-family: greycliff-cf, sans-serif;
  }



  html,
  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    .go2072408551{
      background-color:transparent ;
      box-shadow: none ;
      max-width: unset;
      min-width:290px ;
      width:fit-content ;
      right: 20px;
    }
  }

  *, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  html * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

`;

export default GlobalStyle;
