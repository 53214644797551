import styled, { css } from "styled-components";

import { NSAvatarType } from "./Avatar.type";

export namespace NSAvatarStyle {
  export const Container = styled.div`
    width: fit-content;
    position: relative;
  `;
  export const AvatarContainer = styled.div<NSAvatarType.IAvatar>`
    width: ${(props) =>
      props.size
        ? NSAvatarType.AvatarSizeTypes[props.size]
        : NSAvatarType.AvatarSizeTypes.medium};
    height: ${(props) =>
      props.size
        ? NSAvatarType.AvatarSizeTypes[props.size]
        : NSAvatarType.AvatarSizeTypes.medium};
    border-radius: ${(props) =>
      props.isAvatarCircle
        ? NSAvatarType.CircleBorderRadiusTypes[props.size ?? "medium"]
        : NSAvatarType.RoundedBorderRadiusTypes[props.size ?? "medium"]};
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    ${(props) =>
      props.type === "white" &&
      css`
        border: 2px solid ${props.theme.colors.neutrals.grey03};
        background-color: ${props.theme.colors.light.light};
      `}

    ${(props) =>
      props.type === "solid" &&
      css`
        background-color: ${props.theme.colors.secondary.secondary};
      `}
  `;

  export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `;

  export const Letter = styled.p<{
    type: NSAvatarType.AvatarType;
    size: NSAvatarType.AvatarSizeVariant;
  }>`
    font-family: greycliff-cf, sans-serif;
    font-size: ${(props) => NSAvatarType.FontSizeTypes[props.size]};
    line-height: ${(props) => NSAvatarType.LineHeightTypes[props.size]};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) =>
      props.type === "white"
        ? `${props.theme.colors.dark.dark}`
        : `${props.theme.colors.light.light}`};
  `;

  export const Status = styled.div<{ status: NSAvatarType.AvatarStatus }>`
    width: 16px;
    height: 16px;
    border: 1.71px solid ${(props) => props.theme.colors.light.light};
    border-radius: 8px;
    background-color: ${(props) => NSAvatarType.StatusColorTypes[props.status]};
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
  `;
}
