import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import colors from "../../style/colors";

export namespace NSPhoneNumberStyle {
  export const PhoneNumberBase = styled.div<{ width: number }>`
    ${NSDropdownStyle.InnerDropdownTitle} {
      border: 0;
      width: 64px;
      padding: 0;
      border-radius: 0;
    }

    ${NSDropdownStyle.Suffix} {
      right: 8px;
    }
    ${NSDropdownStyle.DropdownItemsContainer} {
      left: -16px;
      width: ${(props) => props.width}px;
    }
  `;

  export const Seperator = styled.div`
    height: 24px;
    width: 2px;
    margin-right: 8px;
    margin-left: 8px;
    background-color: ${colors.neutrals.grey04};
  `;
}
