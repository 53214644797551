/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */

import { Outlet } from "react-router-dom";
import { NSProjectDetailStyle } from "./Project-Detail.style";
import TabbedView from "../../../components/TabbedView/TabbedView";
import useProjectDetailVm from "./Project-DetailVM";
import DashboardHeader from "../../../components/DashboardHeader/DashboardHeader";
import { NSDashboardLayoutStyle } from "../../../layout/DashboardLayout/DashboardLayout.style";

function ProjectDetailPageIndex() {
  const projectDetailVm = useProjectDetailVm();

  return (
    <NSProjectDetailStyle.Container>
      <NSDashboardLayoutStyle.HeaderContainer>
        <DashboardHeader {...projectDetailVm.dasboardHeader} />
      </NSDashboardLayoutStyle.HeaderContainer>
      <NSProjectDetailStyle.PageContent
        fullHeight={
          projectDetailVm.activeTab === 1 || projectDetailVm.activeTab === 2
        }
      >
        <TabbedView
          onTabChange={(tab) => projectDetailVm.onTabChange(tab)}
          tabItems={projectDetailVm.tabItems}
          activeTab={projectDetailVm.activeTab}
        />

        <Outlet />
      </NSProjectDetailStyle.PageContent>
    </NSProjectDetailStyle.Container>
  );
}

export default ProjectDetailPageIndex;
