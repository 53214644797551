import { useEffect, useRef } from "react";

interface IUseDetectClickOutside {
  onTrigger(): void;
}

export function useDetectClickOutside({ onTrigger }: IUseDetectClickOutside) {
  const ref = useRef<HTMLDivElement>(null);

  const handleOutSideClick = (event: globalThis.MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onTrigger();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutSideClick);
    return () => {
      document.removeEventListener("click", handleOutSideClick);
    };
  }, [ref]);

  return ref;
}
