import TextInput from "../TextInput/TextInput";
import { NSFreeInputType } from "./FreeInput.type";

import { NSFreeInputStyle } from "./FreeInput.style";
import GhostIconButton from "../GhostIconButton/GhostIconButton";

function FreeInput(props: NSFreeInputType.IFreeInput) {
  const { onBlur, onFocus, onClickAdd } = props;

  const onFocusHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    onFocus?.(e);
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    onBlur?.(e);
  };
  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClickAdd?.();
    }
  };

  return (
    <NSFreeInputStyle.Container>
      <TextInput
        onKeyUp={(e) => onPressEnter(e)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        variant="label-w-icon-suffix"
        suffixItem={
          <GhostIconButton icon="ArrowDownLeft" onClick={onClickAdd} />
        }
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        showLabel={false}
      />
    </NSFreeInputStyle.Container>
  );
}

export default FreeInput;
