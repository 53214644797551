import styled from "styled-components";

export namespace NSProjectsStyle {
  export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
  `;

  export const Content = styled.div`
    height: fit-content;
    display: flex;
    flex-flow: row wrap;

    padding: 0;
    margin: 0;
    list-style: none;

    gap: 24px;
  `;

  export const CardContainer = styled.div`
    width: 312px;
    height: 279px;
  `;
}
