import styled, { css } from "styled-components";

import { NSCheckboxStyle } from "../Checkbox/CheckBox.style";
import { NSMainInputStyle } from "../MainInput/MainInput.style";
import { NSDropdown } from "./Dropdown.type";

export namespace NSDropdownStyle {
  export const DropdownBase = styled.div<{ isOpen: boolean }>`
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: greycliff-cf, sans-serif;
    width: 100%;

    ${(props) =>
      props.isOpen &&
      css`
        border-color: ${props.theme.colors.primary.primary};
      `}

    ${NSMainInputStyle.InputFlex} {
    }
  `;

  export const DropdownItemsContainer = styled.div<{
    isError?: boolean;
    topPosition?: number;
    position?: NSDropdown.IDropdownPosition;
  }>`
    background-color: #fff;
    position: absolute;
    top: 18px;
    width: 100%;
    padding: 24px 0;
    margin-top: 20px;
    background-color: ${(props) => props.theme.colors.light.light};
    box-shadow: 0px 8px 24px hsla(0, 0%, 0%, 0.08);
    border-radius: 16px;
    overflow: hidden;
    z-index: 3;

    ${(props) =>
      props.position === "top" &&
      css`
        top: -280px;
      `}
  `;

  export const DropdownItemText = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;
  export const SeachInputContainer = styled.div`
    padding: 0 16px;
    margin-bottom: 26px;
    ${NSMainInputStyle.InputFlex} {
      height: 48px;
      padding: 0 8px;
    }

    ${NSMainInputStyle.ErrorText} {
      display: none;
    }
  `;

  export const DropdownItem = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 24px;
  `;

  export const DropdownItemContainer = styled.div<{ isSelected?: boolean }>`
    width: 100%;
    ${NSCheckboxStyle.StyledCheckbox} {
      svg {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }

    ${(props) =>
      props.isSelected &&
      css`
        background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};

        ${DropdownItemText} {
          color: ${(props) => props.theme.colors.primary.primary};
        }

        ${NSCheckboxStyle.CheckboxLabel} {
          color: ${(props) => props.theme.colors.primary.primary};
        }
      `}

    :hover {
      background-color: ${(props) => props.theme.colors.shadesOfdark.dark06};

      ${DropdownItemText} {
        color: ${(props) => props.theme.colors.primary.primary};
      }

      ${NSCheckboxStyle.CheckboxLabel} {
        color: ${(props) => props.theme.colors.primary.primary};
      }
    }
  `;

  export const MenuTitle = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 24px;
  `;

  export const IconContainer = styled.div`
    margin-right: 8px;
  `;

  export const AddNewItemButtonContainer = styled.div`
    padding: 0 24px;
    margin-top: 16px;
  `;

  export const CountryBadgeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 24px;
    padding: 0 4px 0 8px;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 4px;
    gap: 4px;
  `;

  export const BadgeLabel = styled.div`
    color: ${(props) => props.theme.colors.dark.dark};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
  `;

  export const CloseIconContainer = styled.button`
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  `;

  export const DropdownTitle = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  `;

  export const InnerDropdownTitle = styled.div<{
    isDisabled?: boolean;
    isError?: boolean;
  }>`
    position: relative;

    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 8px;
    padding: 14px 16px;
    border: 2px solid ${(props) => props.theme.colors.neutrals.grey03};
    min-height: 24px;
    height: auto;
    gap: 4px;
    justify-content: space-between;
    &:hover {
      border-color: ${(props) => props.theme.colors.primary.primary};
    }

    ${(props) =>
      props.isDisabled &&
      css`
        border-color: ${props.theme.colors.neutrals.grey03};
        background-color: ${props.theme.colors.neutrals.grey02};

        &:hover {
          border-color: ${props.theme.colors.neutrals.grey03};
        }
      `}

    ${(props) =>
      props.isError &&
      css`
        border-color: ${props.theme.colors.error.red01};
        &:hover {
          border-color: ${props.theme.colors.error.red01};
        }
      `}
  `;

  export const Suffix = styled.div`
    width: 24px;
    height: 24px;
  `;

  export const Placeholder = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey05};
  `;

  export const InnerContainer = styled.div`
    width: 100%;
  `;

  export const SuffixItemContainer = styled.div``;
  export const InnerTitle = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
  `;

  export const ScrollableArea = styled.div`
    max-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.neutrals.grey03};
      border-radius: 20px;
    }
  `;
}
