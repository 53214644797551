import { Form } from "react-router-dom";
import styled from "styled-components";
import MainButton from "../../../../../components/MainButton/MainButton";
import { NSTextAreaStyle } from "../../../../../components/TextArea/TextArea.style";
import { NSNoteCardStyle } from "../../../../../components/NoteCard/NoteCard.style";
import { NSContextMenuStyle } from "../../../../../components/ContextMenu/ContextMenu.style";

export namespace NSAllNotesStyle {
  export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 16px;
    flex-direction: column;
  `;

  export const NotesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    ${NSContextMenuStyle.InnerComponent} {
      ${NSContextMenuStyle.ItemLabel} {
        color: ${(props) => props.theme.colors.primary.primary};
      }

      svg {
        path {
          stroke: ${(props) => props.theme.colors.primary.primary};
        }
      }
      :last-child {
        ${NSContextMenuStyle.ItemLabel} {
          color: ${(props) => props.theme.colors.error.red01};
        }

        svg {
          path {
            stroke: ${(props) => props.theme.colors.error.red01};
          }
        }

        :hover {
          ${NSContextMenuStyle.ItemLabel} {
            color: ${(props) => props.theme.colors.error.red01};
          }

          svg {
            path {
              stroke: ${(props) => props.theme.colors.error.red01};
            }
          }
        }
      }
    }
  `;

  export const FormContainer = styled(Form)`
    gap: 8px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.colors.light.light};
    border: 2px solid ${(props) => props.theme.colors.primary.primary};
    border-radius: 8px;
    padding: 12px 16px;

    ${NSTextAreaStyle.InputFlex} {
      border-color: ${(props) => props.theme.colors.light.light};
      padding: 0;
      height: 100%;
    }

    ${NSNoteCardStyle.HeaderInfo} {
      padding-top: 8px;
      border-top: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
    }
  `;

  export const SaveButton = styled(MainButton)`
    background: none !important;
    border: none;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 0;
  `;
}
