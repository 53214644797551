import { NSCreateInterviewStyle } from "../../Interview-Create.style";
import i18n from "../../../../library/i18next";
import useCreateInterviewVm from "../../Interview-CreateVM";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import Logo from "../../../../components/Logo/Logo";
import DragAndDropList from "../../../../components/DragAndDropList/DragAndDropList";
import StepProgressBar from "../../../../components/StepProgressBar/StepProgressBar";
import Loading from "../../../../components/Loading/Loading";
import Modal from "../../../../components/Modal/Modal";
import Icon from "../../../../components/Icon/Icon";
import { Trans } from "react-i18next";

const ReviewMetricQuestionSelection = () => {
  const {
    isLoadingReviewMetricQuestions,
    metricQuestionItems,
    isAllMetricQuestionsSelected,
    activeStepId,
    stepProgressBarItems,
    isNoticeModalOpen,
    warningModalIsOpen,
    setWarningModalIsOpen,
    isValidQuestions,
    onClickInfoIcon,
    updateMetricQuestionItems,
    setIsValidQuestions,
    onClickModalClose,
    getPreviousMetricsQuestions,
    getNextMetricsQuestions,
  } = useCreateInterviewVm();

  return isLoadingReviewMetricQuestions ? (
    <Loading />
  ) : (
    <NSCreateInterviewStyle.Wrapper>
      <NSCreateInterviewStyle.Header hasMargin>
        <Logo type="onlyIcon" />
        <NSCreateInterviewStyle.ProgressBarContainer>
          <StepProgressBar
            barItems={stepProgressBarItems}
            activeId={activeStepId}
          />
        </NSCreateInterviewStyle.ProgressBarContainer>
      </NSCreateInterviewStyle.Header>
      <NSCreateInterviewStyle.Content fullWidth>
        <NSCreateInterviewStyle.ContentTitle>
          {i18n.t("interview.analysisSkill")}
        </NSCreateInterviewStyle.ContentTitle>
        <NSCreateInterviewStyle.DragAndDropListContainer height72px>
          <DragAndDropList
            list={metricQuestionItems?.[activeStepId] || []}
            setItems={updateMetricQuestionItems}
            withMaxDuration={{
              onClickInfoIcon: (value: number) => {
                onClickInfoIcon(value);
              },
            }}
          />
        </NSCreateInterviewStyle.DragAndDropListContainer>
      </NSCreateInterviewStyle.Content>
      <NSCreateInterviewStyle.Fragment />
      <NSCreateInterviewStyle.Footer>
        <NSCreateInterviewStyle.FooterContainer>
          <NSCreateInterviewStyle.BackButton
            label={i18n.t("general.back")}
            isIconButton
            prefixIcon="ArrowRight"
            onClick={getPreviousMetricsQuestions}
            variant="label-w-icon-prefix"
          />
          <NSCreateInterviewStyle.NextButtonContainer>
            <SolidButton
              label={
                isAllMetricQuestionsSelected
                  ? i18n.t("interview.createInterview")
                  : i18n.t("interview.goToNextCompetency")
              }
              suffixIcon="ArrowLeft"
              onClick={getNextMetricsQuestions}
              variant="label-w-icon-suffix"
            />
          </NSCreateInterviewStyle.NextButtonContainer>
        </NSCreateInterviewStyle.FooterContainer>
      </NSCreateInterviewStyle.Footer>
      <Modal
        isOpen={isNoticeModalOpen}
        hiddenCloseButton
        onClose={onClickModalClose}
      >
        <NSCreateInterviewStyle.ModalContainer>
          <NSCreateInterviewStyle.ModalTitle>
            {i18n.t("interview.noticeModalTitle")}
          </NSCreateInterviewStyle.ModalTitle>
          <NSCreateInterviewStyle.ModalContent>
            <NSCreateInterviewStyle.ModalContentItem>
              <Icon name="BlueEllipse" />
              <Trans
                i18nKey="interview.noticeModalItem1"
                components={{
                  bold: <NSCreateInterviewStyle.BoldText />,
                }}
              />
            </NSCreateInterviewStyle.ModalContentItem>
            <NSCreateInterviewStyle.ModalContentItem>
              <Icon name="BlueEllipse" />
              <Trans
                i18nKey="interview.noticeModalItem2"
                components={{
                  bold: <NSCreateInterviewStyle.BoldText />,
                }}
              />
            </NSCreateInterviewStyle.ModalContentItem>
            <NSCreateInterviewStyle.ModalContentItem>
              <Icon name="BlueEllipse" />
              {i18n.t("interview.noticeModalItem3")}
            </NSCreateInterviewStyle.ModalContentItem>
            <NSCreateInterviewStyle.ModalContentItem>
              <Icon name="BlueEllipse" />
              {i18n.t("interview.noticeModalItem4")}
            </NSCreateInterviewStyle.ModalContentItem>
          </NSCreateInterviewStyle.ModalContent>
        </NSCreateInterviewStyle.ModalContainer>
        <SolidButton
          fullWidth
          label={i18n.t("interview.startChooseQuestion")}
          onClick={onClickModalClose}
        />
      </Modal>
      <Modal
        isOpen={warningModalIsOpen || !isValidQuestions}
        hiddenCloseButton
        onClose={setWarningModalIsOpen}
      >
        <NSCreateInterviewStyle.WarningModalContainer>
          <Icon name="Puzzle" />
          <NSCreateInterviewStyle.WarningModalTitle>
            {!isValidQuestions
              ? i18n.t("interview.warningModalTitle2")
              : i18n.t("interview.warningModalTitle")}
          </NSCreateInterviewStyle.WarningModalTitle>
          <NSCreateInterviewStyle.WarningModalSubtitle>
            {i18n.t("interview.warningModalSubtitle")}
          </NSCreateInterviewStyle.WarningModalSubtitle>
          <NSCreateInterviewStyle.WarningModalButton
            label={i18n.t("interview.chooseQuestionAgain")}
            onClick={() => {
              !isValidQuestions
                ? setIsValidQuestions(true)
                : setWarningModalIsOpen(false);
            }}
          />
        </NSCreateInterviewStyle.WarningModalContainer>
      </Modal>
    </NSCreateInterviewStyle.Wrapper>
  );
};

export default ReviewMetricQuestionSelection;
