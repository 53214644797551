import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import duration from "dayjs/plugin/duration";

import("dayjs/locale/tr");
import("dayjs/locale/en");

dayjs.locale("en");
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);

export default dayjs;
