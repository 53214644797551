import i18n from "../../library/i18next";
import Badge from "../Badge/Badge";
import Icon from "../Icon/Icon";
import ProgressBar from "../ProgressBar/ProgressBar";
import { NSPlanCardStyle } from "./PlanCard.style";
import { NSPlanCardType } from "./PlanCard.type";

function PlanCard({
  startDate,
  endDate,
  price,
  totalCredit,
  usedCredit,
}: NSPlanCardType.IPlanCard) {
  return (
    <NSPlanCardStyle.Container>
      <Icon name="StartUpPlan" />
      <NSPlanCardStyle.Content>
        <Badge label={i18n.t("settings.currentPlan")} />
        <NSPlanCardStyle.Price>
          {price}
          <NSPlanCardStyle.PriceDetail>
            {"/ " + i18n.t("settings.billedMonthly")}
          </NSPlanCardStyle.PriceDetail>
        </NSPlanCardStyle.Price>
        <ProgressBar
          progress={usedCredit ?? 0}
          totalNumber={totalCredit}
          withoutPercentage
        />
        {totalCredit - usedCredit <= 20 && (
          <NSPlanCardStyle.WarningText>
            {i18n.t("settings.yourCreditIsAboutExpire")}
          </NSPlanCardStyle.WarningText>
        )}
        <NSPlanCardStyle.DateBadgeContainer>
          <Badge
            label={
              <NSPlanCardStyle.Date>
                {startDate + " - " + endDate}
                <NSPlanCardStyle.BillingCycle>
                  {i18n.t("settings.billingCycle")}
                </NSPlanCardStyle.BillingCycle>
              </NSPlanCardStyle.Date>
            }
          />
        </NSPlanCardStyle.DateBadgeContainer>
      </NSPlanCardStyle.Content>
    </NSPlanCardStyle.Container>
  );
}

export default PlanCard;
