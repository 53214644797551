/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from "react";
import { IIconButton, IconColor } from "./IconButton.type";
import Icon from "../Icon/Icon";
import MainButton from "../MainButton/MainButton";
import { NSIconButtonStyle } from "./IconButton.style";
/* eslint-disable react/jsx-props-no-spreading */

function IconButton({
  label,
  isWithBackground,
  iconColor,
  iconButtonShape = "rounded",
  iconButtonType = "primary",
  icon,
  size,
  iconSize,
  isCloseButton,
  ...props
}: IIconButton) {
  const iconButtonTypeProps = useMemo(() => {
    if (isCloseButton) {
      return "grey";
    }
    if (iconButtonShape === "circle") {
      return "primary";
    }
    return iconButtonType;
  }, [iconButtonShape, iconButtonType, isCloseButton]);

  return (
    <NSIconButtonStyle.IconButtonBase
      size={size}
      icon={isCloseButton ? "Close" : icon}
      isWithBackground={isWithBackground}
      iconColor={iconColor}
      iconButtonType={isCloseButton ? "grey" : iconButtonTypeProps}
      iconButtonShape={iconButtonShape}
    >
      <MainButton {...props} size={size} isIconButton>
        <Icon
          role="img"
          name={isCloseButton ? "Close" : icon}
          fill={IconColor[iconButtonTypeProps]}
          width={iconSize ?? 40}
          height={iconSize ?? 40}
        />
      </MainButton>
    </NSIconButtonStyle.IconButtonBase>
  );
}
export default IconButton;
