import styled from "styled-components";

export namespace NSSwitchButtonStyle {
  export const Label = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
  `;

  export const Switch = styled.div`
    position: relative;
    width: 32px;
    height: 20px;
    background: ${(props) => props.theme.colors.neutrals.grey02};
    border-radius: 10px;
    transition: 300ms all;

    &:before {
      transition: 300ms all;
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 35px;
      top: 50%;
      left: -12px;
      background: ${(props) => props.theme.colors.neutrals.grey05};
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      transform: translate(0, -50%);
    }
  `;

  export const Input = styled.input`
    display: none;

    &:checked + ${Switch} {
      &:before {
        left: 0px;
        transform: translate(20px, -50%);
        background: ${(props) => props.theme.colors.primary.primary};
      }
    }
  `;

  export const SwitchButtonLabel = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-right: 16px;
  `;

  export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;
}
