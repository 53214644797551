import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import styled from "styled-components";
import { ButtonColorVariant } from "../../../../../components/MainButton/MainButton.type";
import FreeInput from "../../../../../components/FreeInput/FreeInput";
import Icon from "../../../../../components/Icon/Icon";
import { NSCreateJobDescriptionStyle } from "../../CreateJobDescription.style";
import { NSTagType } from "../../../../../components/Tag/Tag.type";
import { NSWithQuestionJobDescriptionType } from "../../Types/WithQuestionJobDescription.type";
import SolidButton from "../../../../../components/SolidButton/SolidButton";
import TagStack from "../../../../../components/TagStack/TagStack";
import i18n from "../../../../../library/i18next";

const SkillSelectionContainer = styled.div``;

const SkillSelection = forwardRef<
  NSWithQuestionJobDescriptionType.IQuestionItems,
  NSWithQuestionJobDescriptionType.IPillQuestions
>(({ activeQuestion, getNextQuestion, isButtonLoading }, ref) => {
  const [pillItems, setPillItems] = useState<NSTagType.ITag[]>(
    () =>
      activeQuestion?.answers.map((item) => {
        // Weird, we do not need this check.
        const isSelected = (item.isSelected as unknown as string) === "true";
        return {
          value: item.value as string,
          isSelected,
          id: item.value as string,
        } as NSTagType.ITag;
      }) ?? []
  );
  const [freeInputValue, setFreeInputValue] = useState<string>("");
  const selectedPillLength = useMemo(
    () => pillItems.filter((p) => p.isSelected).length,
    [pillItems]
  );

  const isActiveQuestionValid: boolean = useMemo(() => {
    if (
      activeQuestion?.answerType.selectAtLeast &&
      activeQuestion?.answerType.selectAtLeast > 0
    ) {
      return selectedPillLength >= activeQuestion?.answerType.selectAtLeast;
    }
    return true;
  }, [activeQuestion?.answerType.selectAtLeast, selectedPillLength]);

  const selectPillHandler = useCallback((tagList: NSTagType.ITag[]) => {
    setPillItems((prevList) =>
      prevList.map((i) => ({
        id: i.id,
        value: i.value,
        isSelected: tagList.find((tag) => tag.id === i.id) !== undefined,
      }))
    );
  }, []);

  const getAnswersAsJson = useCallback(() => {
    const selectedPillItems = pillItems.filter((item) => item.isSelected);
    return selectedPillItems;
  }, [pillItems]);

  useImperativeHandle(
    ref,
    () => ({
      getAnswersAsJson,
    }),
    [getAnswersAsJson]
  );

  const onClickAddFunction = useCallback(() => {
    if (freeInputValue !== "") {
      const newItem: NSTagType.ITag = {
        id: freeInputValue,
        value: freeInputValue,
        isSelected: true,
      };

      const findIndex = pillItems.findIndex(
        (item) => item.value === freeInputValue
      );
      const newItems =
        findIndex === -1 ? [newItem].concat(pillItems) : pillItems;
      setPillItems(newItems);
      setFreeInputValue("");
    }
  }, [freeInputValue, pillItems]);

  const isButtonDisabled = useMemo(() => {
    let selectedCount = 0;
    pillItems.forEach((item) => {
      selectedCount = item.isSelected ? selectedCount + 1 : selectedCount;
    });
    return selectedCount < 3;
  }, [pillItems]);

  return (
    <SkillSelectionContainer>
      <NSCreateJobDescriptionStyle.WQuestionContainer>
        <NSCreateJobDescriptionStyle.WQTitleContainer>
          <NSCreateJobDescriptionStyle.WQuestionTitle>
            {activeQuestion?.title}
          </NSCreateJobDescriptionStyle.WQuestionTitle>
          {activeQuestion?.subTitle && (
            <NSCreateJobDescriptionStyle.WQSubTitleContainer>
              <NSCreateJobDescriptionStyle.WQuestionSubTitle>
                {activeQuestion?.subTitle}
              </NSCreateJobDescriptionStyle.WQuestionSubTitle>
              {isActiveQuestionValid ? (
                <Icon name="Check" />
              ) : (
                <Icon name="Close" />
              )}
            </NSCreateJobDescriptionStyle.WQSubTitleContainer>
          )}
        </NSCreateJobDescriptionStyle.WQTitleContainer>
        <NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
          <TagStack
            setTaglist={setPillItems}
            tagList={pillItems}
            onSelectedChanged={selectPillHandler}
            numberOfVisibleTag={activeQuestion?.answerType.maxVisibleCount}
          />
        </NSCreateJobDescriptionStyle.WQQuestionItemsContainer>
      </NSCreateJobDescriptionStyle.WQuestionContainer>
      <NSCreateJobDescriptionStyle.Footer>
        <FreeInput
          id="skillSelectionId"
          name="skillSelectionName"
          onClickAdd={onClickAddFunction}
          value={freeInputValue}
          onChange={(value) => setFreeInputValue(value)}
        />
        <NSCreateJobDescriptionStyle.FooterRightContainer>
          <SolidButton
            iconColor="hsla(0, 0%, 100%,1)"
            backgroundColor={ButtonColorVariant.grey}
            variant="label-w-icon-suffix"
            label={i18n.t("general.continue")}
            isLoading={isButtonLoading}
            isIconButton
            disabled={isButtonDisabled}
            suffixIcon="DownLeft"
            onClick={getNextQuestion}
          />
        </NSCreateJobDescriptionStyle.FooterRightContainer>
      </NSCreateJobDescriptionStyle.Footer>
    </SkillSelectionContainer>
  );
});

SkillSelection.displayName = "SkillSelection";

export default SkillSelection;
