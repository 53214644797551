import { NSNotificationBadgeStyle } from "./NotificationBadge.style";
import { NSNotificationBadgeType } from "./NotificationBadge.type";

function NotificationBadge({
  children,
}: NSNotificationBadgeType.INotificationBadge) {
  return (
    <NSNotificationBadgeStyle.Container>
      {children}
    </NSNotificationBadgeStyle.Container>
  );
}

export default NotificationBadge;
