import { GetRolesDropdownDocument } from "../../gql/graphql";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

export function useGetRolesDropdown(title: string) {
  const { data, ...rest } = useGraphQLQuery(
    GetRolesDropdownDocument,
    { enabled: !!title },
    { title: "%" + title + "%" }
  );

  const roles = data?.role.map((item) => ({
    dropdown: {
      id: item.id,
      label: item.title,
    },
  }));

  return {
    roles,
    rest,
  };
}
