import React from "react";
import { NSScoreBadgeType } from "./ScoreBadge.type";
import { NSScoreBadgeStyle } from "./ScoreBadge.style";
import colors from "../../style/colors";
import i18n from "../../library/i18next";

const getBadgeColor = (score: number) => {
  switch (score) {
    case 10:
    case 25:
      return { color: colors.error.red03, backgroundColor: colors.error.red02 };
    case 45:
    case 50:
      return {
        color: "hsla(54, 83%, 26%, 1)",
        backgroundColor: "hsla(54, 70%, 86%, 1)",
      };
    case 60:
    case 75:
      return {
        color: "hsla(39, 86%, 26%, 1)",
        backgroundColor: "hsla(44, 76%, 84%, 1)",
      };
    case 85:
    case 100:
      return {
        color: colors.success.green03,
        backgroundColor: colors.success.green02,
      };
    default:
      return { color: colors.error.red03, backgroundColor: colors.error.red02 };
  }
};

const ScoreBadge: React.FC<NSScoreBadgeType.IScoreBadge> = ({
  description,
  score,
}) => {
  return (
    <NSScoreBadgeStyle.Container>
      <NSScoreBadgeStyle.Description>
        {description}
      </NSScoreBadgeStyle.Description>
      <NSScoreBadgeStyle.Badge
        color={getBadgeColor(score).color}
        backgroundColor={getBadgeColor(score).backgroundColor}
      >
        {score} {i18n.t("interview.point")}
      </NSScoreBadgeStyle.Badge>
    </NSScoreBadgeStyle.Container>
  );
};

export default ScoreBadge;
