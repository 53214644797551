import { SubmitHandler, useForm } from "react-hook-form";
import { ObjectSchema, array, number, object, string } from "yup";
import { useCallback, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { NSCreateClientType } from "./Create-Client.type";
import useGetCountries from "../../../service/Countries/getCountries";
import useInsertClient from "../../../service/Clients/insertClient";
import useGetSectors from "../../../service/Sector/getSector";
import i18n from "../../../library/i18next";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";
import {
  Address_Type_Enum,
  Client_Location_Insert_Input,
} from "../../../gql/graphql";

// TODO: change any
const createClientSchema: ObjectSchema<
  NSCreateClientType.ICreateClientFormValues | any
> = object({
  clientName: string().required(i18n.t("forms.clientNameError")),
  phoneNumber: string().required(i18n.t("forms.phoneNumberError")),
  country: object({ id: number(), label: string() })
    .required(i18n.t("forms.countryError"))
    .default(undefined),
  city: object({ id: number(), label: string() })
    .required(i18n.t("forms.cityError"))
    .default(undefined),
  bussinessAddress: string()
    .required(i18n.t("forms.bussinessAddrError"))
    .default(undefined),
  sector: object({ id: number(), label: string() })
    .required(i18n.t("forms.sectorError"))
    .default(undefined),
  typeOfCompany: object({ id: number(), label: string() })
    .required(i18n.t("forms.companyTypeError"))
    .default(undefined),
  companySize: object({ id: number(), label: string() })
    .required(i18n.t("forms.companySizeError"))
    .default(undefined),
  aboutCompany: string().notRequired(),
  multiLocation: array().notRequired(),
}).required();

const typeOfCompany = [
  { id: "1", label: i18n.t("clients.partnership") },
  { id: "2", label: i18n.t("clients.cooperative") },
  { id: "3", label: i18n.t("clients.corporation") },
  { id: "4", label: i18n.t("clients.limited") },
];

const companySize = [
  { id: 1, label: "10" },
  { id: 2, label: "50" },
  { id: 3, label: "100" },
  { id: 4, label: "1000" },
];

const phoneCode = [
  {
    label: "+90",
    id: 90,
  },
  {
    label: "+56",
    id: 56,
  },
  {
    label: "+86",
    id: 86,
  },
  {
    label: "+45",
    id: 45,
  },
];

const useCreateClientVm = () => {
  const {
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    control,
  } = useForm<NSCreateClientType.ICreateClientFormValues>({
    resolver: yupResolver(createClientSchema),
    shouldFocusError: false,
  });

  const navigate = useNavigate();

  const { data: countriesData, rest: countriesRest } = useGetCountries();
  const { sectors } = useGetSectors();

  const country = useMemo(
    () =>
      countriesData?.country.map((countryItem) => ({
        id: countryItem.id,
        label: countryItem.country_name,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countriesRest]
  );

  const sectorField = useMemo(() => sectors, [sectors]);

  const citiesItems = useMemo(
    () =>
      countriesData?.country[0]?.cities.map((citiesItem) => ({
        id: citiesItem.id,
        label: citiesItem.city_name,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countriesRest]
  );
  const { mutateAsync } = useInsertClient();

  const [inputValue, setInputValue] = useState<string>("");
  const [checkboxList, setCheckboxList] = useState<
    NSCreateClientType.ICheckboxWithID[]
  >([]);

  const onSubmit: SubmitHandler<NSCreateClientType.ICreateClientFormValues> =
    useCallback(
      (data, event) => {
        const firstLocation: Client_Location_Insert_Input = {
          address: data?.bussinessAddress,
          address_phone: data?.phoneNumber,
          city_id: Number(data.city?.id),
          address_type: Address_Type_Enum.Main,
        };

        const locations: Client_Location_Insert_Input[] = [firstLocation];

        if (checkboxList.length) {
          checkboxList.map(
            (checkboxItem: NSCreateClientType.ICheckboxWithID) => {
              if (checkboxItem.checked) {
                const newLocation = {
                  ...firstLocation,
                  address: checkboxItem.label ?? "",
                  address_type: Address_Type_Enum.Branch,
                };

                locations.push(newLocation as Client_Location_Insert_Input);
              }
            }
          );
        }

        event?.preventDefault();

        mutateAsync({
          clientName: data.clientName,
          description: data.aboutCompany,
          employeeCount: parseInt(data.companySize.label!, 10),
          hasMultilocation: !!data.hasMultiLocation,
          working_type: String(data.typeOfCompany?.id),
          addresses: data.hasMultiLocation ? locations : locations[0],
          sector_id: Number(data.sector?.id),
        })
          .then(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.clientCreateSuccess")}
                type="success"
              />,
              {
                id: "createClientSuccess",
              }
            );
            navigate(-1);
          })
          .catch(() => {
            toast(
              <ToastAlert
                description={i18n.t("clients.clientCreateError")}
                type="error"
              />,

              {
                id: "createClientError",
              }
            );
          });
      },
      [mutateAsync, navigate, checkboxList]
    );

  const submitHandler = () => handleSubmit(onSubmit);

  const onClickInputSuffix = () => {
    const newChecboxList = checkboxList;
    newChecboxList.push({
      id: checkboxList.length.toString(),
      checked: true,
      label: inputValue,
    });
    setCheckboxList([...newChecboxList]);
    setInputValue("");
  };

  const onClickCheckbox = (key: number) => {
    const newChecboxList = checkboxList;
    newChecboxList[key].checked = !newChecboxList[key].checked;
    setCheckboxList([...newChecboxList]);
  };

  return {
    formErrors: errors,
    citiesItems,
    country,
    phoneCode,
    control,
    inputValue,
    checkboxList,
    sectorField,
    typeOfCompany,
    companySize,
    handleSubmit: submitHandler,
    setValue,
    onClickInputSuffix,
    getValues,
    onClickCheckbox,
    setInputValue,
    watch,
  };
};

export default useCreateClientVm;
