import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { NSForgotPasswordType } from "./ForgotPassword.type";
import { useAuthContext } from "../../context/AuthContext/AuthContext";

const forgotPasswordSchema = object({
  email: string().email().required("Please enter your email"),
}).required();

const useForgotPasswordVm = () => {
  const { forgotPassword } = useAuthContext();
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors, touchedFields },
  } = useForm<NSForgotPasswordType.IForgotPasswordValues>({
    resolver: yupResolver(forgotPasswordSchema),
    shouldFocusError: false,
  });

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_error, setError] = useState();
  const [emailValue, setEmailValue] = useState("");
  const [result, setResult] = useState();
  const forgotPasswordHandler: SubmitHandler<NSForgotPasswordType.IForgotPasswordValues> =
    useCallback(
      async (data, event) => {
        event?.preventDefault();
        forgotPassword(data.email)
          .then((value) => {
            setResult(value);
          })
          .catch((error) => {
            setError(error);
          });
      },
      [forgotPassword]
    );
  const submitHandler = () => handleSubmit(forgotPasswordHandler);

  const onClickBack = useCallback(() => {
    navigate("/auth");
  }, [navigate]);

  const isErrorBarActive: boolean = useMemo(
    () =>
      errors.email !== undefined ||
      !(
        forgotPasswordSchema.isValidSync({
          email: emailValue,
        }) && touchedFields.email === true
      ),
    [emailValue, errors.email, touchedFields.email]
  );

  return {
    isErrorBarActive,
    touchedFields,
    register,
    submitHandler,
    errors,
    setValue,
    result,
    navigate,
    onClickBack,

    setEmailValue,
  };
};

export default useForgotPasswordVm;
