/* eslint-disable react/jsx-props-no-spreading */
import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
import { NSBreadcrumbStyle } from "./Breadcrumb.style";
import { NSBreadcrumbType } from "./Breadcrumb.type";

function Breadcrumb({
  dropdown,
  pageName,
  innerPageTitle,
  path,
}: NSBreadcrumbType.IBreadcrumb) {
  return (
    <NSBreadcrumbStyle.Container>
      {dropdown && innerPageTitle ? (
        <>
          <NSBreadcrumbStyle.GeneralRouteLabel href={path}>
            {`${pageName} /`}
          </NSBreadcrumbStyle.GeneralRouteLabel>

          <Dropdown
            {...dropdown}
            suffixItem={<Icon width={24} height={24} name="Chevron" />}
            placeholder={innerPageTitle}
          />
        </>
      ) : (
        <NSBreadcrumbStyle.RouteLabel>{pageName}</NSBreadcrumbStyle.RouteLabel>
      )}
    </NSBreadcrumbStyle.Container>
  );
}

export default Breadcrumb;
