import styled, { keyframes } from "styled-components";

export namespace NSCircularProgressBar {
  const fillAnimation = (percentage: number) => keyframes`
  0% {
    stroke-dashoffset: 251.2;
    transform: rotate(270deg);
  }
  100% {
    stroke-dashoffset: ${251.2 - (251.2 * percentage) / 100};
    transform: rotate(270deg);
  }
`;

  export const SVG = styled.svg`
    width: 100%;
    height: 100%;
  `;

  export const Circle = styled.circle`
    fill: none;
    stroke: ${(props) => props.theme.colors.primary.tint};
    stroke-width: 12;
  `;

  export const ProgressCircle = styled.circle<{ percentage: number }>`
    fill: none;
    stroke: ${(props) =>
      props.percentage !== 0
        ? props.percentage > 50
          ? props.theme.colors.primary.primary
          : props.theme.colors.warning.yellow01
        : props.theme.colors.primary.tint};
    stroke-width: 12;
    stroke-linecap: round;
    transform: translate(5px, 5px);
    transform-origin: center;
    stroke-dasharray: 251.2;
    animation: ${({ percentage }) => fillAnimation(percentage)} 1s ease forwards;
  `;
}
