import { Outlet } from "react-router-dom";

import DashboardHeader from "../../components/DashboardHeader/DashboardHeader";
import EmptyView from "../../components/EmptyView/EmptyView";
import Loading from "../../components/Loading/Loading";
import { NSDashboardLayoutStyle } from "../../layout/DashboardLayout/DashboardLayout.style";
import { NSJobDescriptionStyle } from "./Job-Description.style";
import { PAGINATION_DEFAULT_LIMIT } from "../../utils/globalConstants";
import Pagination from "../../components/Pagination/Pagination";
import TableList from "../../components/TableList/TableList";
import useJobDescripitonVm from "./Job-DescriptionVM";

function JobDescription() {
  const jobDescripitonVM = useJobDescripitonVm();

  const queryLimitHandler = (start: number, limit: number) => {
    jobDescripitonVM.setPagination({ start, limit });
  };
  return (
    <NSJobDescriptionStyle.Container>
      <NSDashboardLayoutStyle.HeaderContainer>
        <DashboardHeader
          breadcrumb={jobDescripitonVM.breadcrumb}
          searchInput={jobDescripitonVM.searchInput}
        />
      </NSDashboardLayoutStyle.HeaderContainer>
      {jobDescripitonVM.jobDescriptionTableListItems &&
      jobDescripitonVM.jobDescriptionTableListItems.length !== 0 ? (
        jobDescripitonVM.totalItemCount && (
          <NSJobDescriptionStyle.TableContainer>
            {jobDescripitonVM.isLoading ? (
              <Loading />
            ) : (
              <TableList
                headerItemList={jobDescripitonVM.headerList}
                tableItemList={jobDescripitonVM.jobDescriptionTableListItems}
              />
            )}
          </NSJobDescriptionStyle.TableContainer>
        )
      ) : (
        <NSJobDescriptionStyle.EmptyContainer>
          <EmptyView
            errorMessage={jobDescripitonVM.emptyView.errorMessage}
            onClick={jobDescripitonVM.emptyView.onClick}
            buttonLabel={jobDescripitonVM.emptyView.buttonLabel}
          />
        </NSJobDescriptionStyle.EmptyContainer>
      )}

      {jobDescripitonVM?.totalItemCount &&
        jobDescripitonVM?.totalItemCount >= PAGINATION_DEFAULT_LIMIT && (
          <NSJobDescriptionStyle.PaginationContainer>
            <Pagination
              isLoading={jobDescripitonVM.isLoading}
              pagination={jobDescripitonVM.pagination}
              itemCount={jobDescripitonVM.totalItemCount}
              updateQueryLimit={queryLimitHandler}
            />
          </NSJobDescriptionStyle.PaginationContainer>
        )}
      <Outlet />
    </NSJobDescriptionStyle.Container>
  );
}

export default JobDescription;
