import dayjs from "dayjs";
import Avatar from "../Avatar/Avatar";
import { NSUserStyle } from "./User.style";
import { NSUserType } from "./User.type";

function User({
  name,
  job,
  avatarUrl,
  username,
  date,
  email,
  isAvatarInLeft = true,
  isAvatarCircle,
  avatarType = "image",
  avatarSize = "small",
  avatarInfoMargin = "big",
  companyName,
}: NSUserType.IUser) {
  return isAvatarInLeft ? (
    <NSUserStyle.Container>
      <Avatar
        type={avatarType}
        letter={name?.toLocaleUpperCase()[0]}
        isAvatarCircle={isAvatarCircle}
        size={avatarSize}
        avatarUrl={avatarUrl}
      />
      <NSUserStyle.InfoRightContainer avatarInfoMargin={avatarInfoMargin}>
        {name && <NSUserStyle.NameText>{name}</NSUserStyle.NameText>}
        {companyName && (
          <NSUserStyle.CompanyNameText>
            {companyName}
          </NSUserStyle.CompanyNameText>
        )}
        {job && <NSUserStyle.UserJobText>{job}</NSUserStyle.UserJobText>}
        {email && <NSUserStyle.DateText>{email}</NSUserStyle.DateText>}
      </NSUserStyle.InfoRightContainer>
    </NSUserStyle.Container>
  ) : (
    <NSUserStyle.Container>
      <NSUserStyle.InfoLeftContainer avatarInfoMargin={avatarInfoMargin}>
        {date && (
          <NSUserStyle.DateText>{`${dayjs(date).format(
            "MMM DD, YYYY [at] hh:mm"
          )}`}</NSUserStyle.DateText>
        )}
        {username && (
          <NSUserStyle.UsernameText aria-label="user-name">
            {`@${username}`}
          </NSUserStyle.UsernameText>
        )}
      </NSUserStyle.InfoLeftContainer>

      <Avatar
        type={avatarType}
        letter={name?.toLocaleUpperCase()[0]}
        size={avatarSize}
        isAvatarCircle={isAvatarCircle}
        avatarUrl={avatarUrl}
      />
    </NSUserStyle.Container>
  );
}

export default User;
