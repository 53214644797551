import styled from "styled-components";

export namespace NSHistoryTooltipStyle {
  export const HistoryTooltipBase = styled.div`
    width: max-content;

    .react-tooltip {
      background-color: ${(props) => props.theme.colors.light.light};
      padding: 16px;
      border-radius: 8px;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
      z-index: 10;
    }
  `;
  export const HistoryTooltipContent = styled.div``;
  export const HistoryTooltipLabel = styled.span`
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    padding: 4px 0;
    border-bottom: 1px dashed ${(props) => props.theme.colors.primary.tint03};
    cursor: pointer;
    :hover {
      color: ${(props) => props.theme.colors.primary.primary};
    }
  `;
}
