import ContextMenuHOC from "../ContextMenuHOC/ContextMenuHOC";
import { NSJobDescriptionListItemStyle } from "./JobDescriptionListItem.style";
import { NSJobDescriptionListItemType } from "./JobDescriptionListItem.type";
import User from "../User/User";

function JobDescriptionListItem({
  id,
  name,
  contextMenu,
  user,
  onClick,
}: NSJobDescriptionListItemType.IJobDescriptionListItem) {
  return (
    <NSJobDescriptionListItemStyle.Container onClick={() => onClick?.(id)}>
      <ContextMenuHOC
        items={contextMenu.items}
        onItemClick={contextMenu.onItemClick}
        type={contextMenu.type}
        buttonLabel={contextMenu.buttonLabel}
        isScrollable={contextMenu.isScrollable}
        title={contextMenu.title}
        showSearch={contextMenu.showSearch}
        containAddNewItemButton={contextMenu.containAddNewItemButton}
        onCheckedItems={contextMenu.onCheckedItems}
        onContextMenuOpen={contextMenu.onContextMenuOpen}
      >
        <NSJobDescriptionListItemStyle.LeftItems>
          <>
            <NSJobDescriptionListItemStyle.JobDescriptionName>
              {name}
            </NSJobDescriptionListItemStyle.JobDescriptionName>
            <User
              isAvatarCircle
              isAvatarInLeft={false}
              avatarUrl={user.avatarUrl}
              job={user.job}
              username={user.username}
              name={user.name}
              date={user.date}
            />
          </>
        </NSJobDescriptionListItemStyle.LeftItems>
      </ContextMenuHOC>
    </NSJobDescriptionListItemStyle.Container>
  );
}

export default JobDescriptionListItem;
