import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  GetNextQuestionDocument,
  JdPageMeta,
  Job_Description_Step_Enum,
} from "../../gql/graphql";

import { NSJobDescriptionContextType } from "./JobDescriptionContext.type";
import i18n from "../../library/i18next";
import { useGraphQLQuery } from "../../hooks/useGraphQL";

const JobDescriptionContext =
  createContext<NSJobDescriptionContextType.IJobDescriptionContext>({
    selectedRoleItem: undefined,
    selectedExperienceItem: undefined,
    selectedPositionItem: undefined,
    selectedClientName: undefined,
    selectRole: () => { },
    selectExperience: () => { },
    selectPosition: () => { },
    selectClientName: () => { },
    nextQuestion: () => undefined,
    getFirstQuestion: () => { },
    roleButtonLabel: "",
    activeQuestion: undefined,
    jid: undefined,
  });

function JobDescriptionProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();

  const [selectedClientName, setSelectedClientName] = useState<string>();
  const [selectedRoleItem, setSelectedRoleItem] =
    useState<NSJobDescriptionContextType.ISelectedItem>();
  const [selectedExperienceItem, setSelectedExperienceItem] =
    useState<NSJobDescriptionContextType.ISelectedItem>();
  const [selectedPositionItem, setSelectedPositionItem] =
    useState<NSJobDescriptionContextType.ISelectedItem>();
  const [activeQuestion, setActivequestion] =
    useState<NSJobDescriptionContextType.IActiveQuestion<unknown>>();
  const [jid, setJid] = useState<string>();
  const { refetch } = useGraphQLQuery(
    GetNextQuestionDocument,
    {
      enabled: false,
    },
    { jid }
  );
  const navigate = useNavigate();

  const selectRole = useCallback(
    (role: NSJobDescriptionContextType.ISelectedItem) => {
      setSelectedRoleItem(role);
    },
    []
  );

  const fetchQuestion = useCallback(async () => {
    const res = await refetch();

    const q = res.data?.getNextJDQuestion;
    if (
      q?.step === Job_Description_Step_Enum.Completed ||
      q?.step === Job_Description_Step_Enum.GenerateJd
    ) {
      return navigate(`/job-description/detail/${jid}`);
    }
    const tempActiveQuestion = {
      title: q?.title ?? "",
      subTitle: q?.subtitle ?? "",
      answers:
        q?.answers?.map((i) => ({
          value: i.value,
          isSelected: i.isSelected ?? false,
          body: i.body,
          title: i.title,
        })) ?? [],
      answerType: {
        canAddMore: res.data?.getNextJDQuestion?.meta?.canAddMore ?? false,
        maxVisibleCount:
          res.data?.getNextJDQuestion?.meta?.maxVisibleCount ?? 0,
        selectAtLeast: res.data?.getNextJDQuestion?.validation?.count?.min ?? 0,
        type: NSJobDescriptionContextType.EAnswerType.pill,
      },
      meta: res.data?.getNextJDQuestion?.meta as JdPageMeta,
      completed: q?.completed ?? 0,
      page: q?.page,
    };
    setActivequestion(tempActiveQuestion);
    return true;
  }, [jid, navigate, refetch]);

  useEffect(() => {
    if (searchParams.get("jdId")) {
      setJid(searchParams.get("jdId")?.toString());
    }
  }, [searchParams]);
  useEffect(() => {
    if (jid) {
      fetchQuestion();
    }
  }, [fetchQuestion, jid]);

  const getFirstQuestion = useCallback((jidArg: string) => {
    setJid(jidArg);
  }, []);

  const nextQuestion = useCallback(() => {
    fetchQuestion();
  }, [fetchQuestion]);

  const selectExperience = useCallback(
    (experience: NSJobDescriptionContextType.ISelectedItem | undefined) => {
      setSelectedExperienceItem(experience);
    },
    []
  );

  const selectPosition = useCallback(
    (position: NSJobDescriptionContextType.ISelectedItem | undefined) => {
      setSelectedPositionItem(position);
    },
    []
  );
  const selectClientName = useCallback((clientName: string | undefined) => {
    setSelectedClientName(clientName);
  }, []);
  const roleButtonLabel = useMemo(() => {
    const positionLabel = activeQuestion?.meta?.position
      ? activeQuestion?.meta?.position
      : i18n.t("jd.addYourRoleHere");
    let defaultRoleButtonLabel = selectedRoleItem
      ? selectedRoleItem.label
      : positionLabel;
    if (selectedExperienceItem) {
      defaultRoleButtonLabel = selectedExperienceItem.isPrefix
        ? `${selectedExperienceItem?.label} ${defaultRoleButtonLabel} `
        : `${defaultRoleButtonLabel} ${selectedExperienceItem?.label}`;
    }
    if (selectedPositionItem) {
      defaultRoleButtonLabel = selectedPositionItem.isPrefix
        ? `${selectedPositionItem?.label} ${defaultRoleButtonLabel} `
        : `${defaultRoleButtonLabel} ${selectedPositionItem?.label}`;
    }
    return defaultRoleButtonLabel.trim();
  }, [
    selectedRoleItem,
    selectedExperienceItem,
    selectedPositionItem,
    activeQuestion?.meta?.position,
  ]);

  const value = useMemo(
    () => ({
      selectedRoleItem,
      selectedExperienceItem,
      selectedPositionItem,
      selectedClientName,
      selectRole,
      selectExperience,
      selectPosition,
      selectClientName,
      nextQuestion,
      roleButtonLabel,
      activeQuestion,
      getFirstQuestion,
      jid,
    }),
    [
      selectedRoleItem,
      selectedExperienceItem,
      selectedPositionItem,
      selectedClientName,
      selectRole,
      selectExperience,
      selectPosition,
      selectClientName,
      nextQuestion,
      roleButtonLabel,
      activeQuestion,
      getFirstQuestion,
      jid,
    ]
  );

  return (
    <JobDescriptionContext.Provider value={value}>
      {children}
    </JobDescriptionContext.Provider>
  );
}

function useJobDescriptionContext() {
  const context = useContext(JobDescriptionContext);
  if (!context) {
    throw Error(" please use in JobDescriptionProvider");
  }

  return context;
}

export { JobDescriptionProvider, useJobDescriptionContext };
