/* eslint-disable import/no-extraneous-dependencies */
import ProgressBar from "react-customizable-progressbar";

import { useMemo } from "react";
import { NSScoreChartStyle } from "./ScoreChart.style";
import { NSScoreChartType } from "./ScoreChart.type";

import Icon from "../Icon/Icon";
import colors from "../../style/colors";

const CENTER_LEFT_POSITION = 104;
const ONE_DIGIT_SIZE = 10;
function ScoreChart({
  progress,
  stringProgressValue,
}: NSScoreChartType.IScoreChart) {
  const progressTextPosition = useMemo(
    () =>
      CENTER_LEFT_POSITION -
      ONE_DIGIT_SIZE * (progress.toFixed(0).replace(".", "").length - 1),
    [progress]
  );

  // eslint-disable-next-line consistent-return
  const progressColor = useMemo(() => {
    let color;
    if (progress > 75) {
      color = "hsla(137, 93%, 38%, 1)";
    } else if (progress > 50 && progress <= 75) {
      color = "hsla(39, 85%, 59%, 1)";
    } else if (progress > 25 && progress <= 50) {
      color = "hsla(54, 84%, 58%, 1)";
    } else {
      color = "hsla(349, 73%, 56%, 1)";
    }

    return color;
  }, [progress]);

  return (
    <NSScoreChartStyle.Container>
      <ProgressBar
        progress={progress}
        radius={92}
        transition="1.5s ease 0.5s"
        trackTransition="0s ease"
        cut={180}
        steps={100}
        strokeWidth={10}
        trackStrokeWidth={10}
        trackStrokeColor={colors.neutrals.grey02}
        strokeColor={progressColor ?? colors.warning.yellow01}
        rotate={-180}
      >
        <NSScoreChartStyle.IconContainer>
          <Icon name="ScoreChartDots" />
        </NSScoreChartStyle.IconContainer>

        <NSScoreChartStyle.ProgressText
          progressColor={progressColor}
          leftPosition={progressTextPosition}
        >
          {stringProgressValue ?? progress.toFixed(0)}
        </NSScoreChartStyle.ProgressText>
      </ProgressBar>
    </NSScoreChartStyle.Container>
  );
}

export default ScoreChart;
