import ExperienceSelectionModal from "../../../../components/ExperienceSelectionModal/ExperienceSelectionModal";
import { NSCreateJobDescriptionStyle } from "../CreateJobDescription.style";

import useJobDescriptionContextRoleVm from "../ViewModels/JobDescriptionRoleVm";

interface IJobDescriptionExperienceAndSPosition {
  vm: () => ReturnType<typeof useJobDescriptionContextRoleVm>;
}

function JobDescriptionExperienceAndSPosition({
  vm,
}: IJobDescriptionExperienceAndSPosition) {
  const jobDescriptionRoleVm = vm();

  return (
    <NSCreateJobDescriptionStyle.RoleContainer>
      <ExperienceSelectionModal
        isOpen
        onClickCloseButton={() => {
          jobDescriptionRoleVm.goBack();
        }}
        onClose={() => {
          jobDescriptionRoleVm.goBack();
        }}
        title={jobDescriptionRoleVm?.selectedRoleItem?.label ?? ""}
        experienceLevelList={jobDescriptionRoleVm.experienceLevelList}
        titlePositionList={jobDescriptionRoleVm.titlePositionList}
        onApplyClicked={jobDescriptionRoleVm.experienceSelectHandler}
      />
    </NSCreateJobDescriptionStyle.RoleContainer>
  );
}

export default JobDescriptionExperienceAndSPosition;
