function maskPhoneNumber(phoneNumber: string) {
  let number = phoneNumber.replace(/[^\d]/g, "");
  number = number.replace(/\D/g, "");
  const phoneNumberLength = number.length;

  if (phoneNumberLength < 4) return number;

  if (phoneNumberLength < 7) {
    return `(${number.slice(0, 3)}) ${number.slice(3)}`;
  }

  return `(${number.slice(0, 3)}) ${number.slice(3, 6)} ${number.slice(6, 10)}`;
}

export default maskPhoneNumber;
