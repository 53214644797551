import styled from "styled-components";
import { NSMainInputStyle } from "../MainInput/MainInput.style";
import colors from "../../style/colors";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSFreeInputStyle {
  export const Container = styled.div`
    ${NSMainInputStyle.InputFlex} {
      &:hover {
        svg {
          stroke: ${colors.primary.primary};
        }
      }
      svg {
        cursor: pointer;
        width: 24px;
        height: 24px;
        fill: none;
        padding: 0;
      }
    }

    ${NSMainButtonStyle.ButtonBase} {
      width: 24px;
      height: 24px;
    }
    display: flex;
    width: 75%;
  `;
}
