import Drawer from "../../../../components/Drawer/Drawer";
import i18n from "../../../../library/i18next";
import { NSAddCandidateFromExcelStyle } from "./Add-Candidate-From-Excel.style";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import useAddCandidateFromExcelVm from "./Add-Candidate-From-ExcelVM";

export default function AddCandidateFromExcel() {
  const {
    candidateList,
    candidatesNotSent,
    inappropriateFile,
    candidateWithMissingInfo,
    onClickUploadCandidate,
    onUploadFile,
    onClickDownloadExampleFile,
    onErrorFileUpload,
  } = useAddCandidateFromExcelVm();

  return (
    <Drawer title={i18n.t("projects.uploadFromExcel")}>
      <NSAddCandidateFromExcelStyle.Container>
        <NSAddCandidateFromExcelStyle.InformationText>
          {i18n.t("projects.uploadFromExcelInformation")}
          <NSAddCandidateFromExcelStyle.DownloadListButton
            label={i18n.t("candidate.downloadExampleFile")}
            onClick={onClickDownloadExampleFile}
          />
        </NSAddCandidateFromExcelStyle.InformationText>

        <FileUpload
          onUpload={(url) => {
            onUploadFile(url);
          }}
          fileFormat={{
            "file/xlsx": [".xlsx"],
            "file/xls": [".xls"],
            "file/csv": [".csv"],
          }}
          fileFormatText={i18n.t("candidate.xlsxAndXlsAndCsv")}
          onError={(message) => onErrorFileUpload(message!)}
        />
        <SolidButton
          fullWidth
          onClick={onClickUploadCandidate}
          label={i18n.t("projects.upload")}
          disabled={candidateList.length === 0}
        />

        {inappropriateFile && (
          <NSAddCandidateFromExcelStyle.ErrorContainer>
            <NSAddCandidateFromExcelStyle.ErrorTitle>
              {i18n.t("candidate.uploadAppropriateFileMessage")}
            </NSAddCandidateFromExcelStyle.ErrorTitle>
          </NSAddCandidateFromExcelStyle.ErrorContainer>
        )}

        {candidateWithMissingInfo && (
          <NSAddCandidateFromExcelStyle.ErrorContainer>
            <NSAddCandidateFromExcelStyle.ErrorTitle>
              {i18n.t("candidate.candidatesNotSent")}
            </NSAddCandidateFromExcelStyle.ErrorTitle>
            <NSAddCandidateFromExcelStyle.ErrorContent>
              {candidatesNotSent.map((candidate) => (
                <>
                  <NSAddCandidateFromExcelStyle.ErrorInfoContainer
                    key={candidate.name}
                  >
                    {Object.entries(candidate).map(
                      ([key, value]) =>
                        key !== "errors" && (
                          <NSAddCandidateFromExcelStyle.ErrorInfo key={key}>
                            {key}: {value || " -"}
                          </NSAddCandidateFromExcelStyle.ErrorInfo>
                        )
                    )}
                  </NSAddCandidateFromExcelStyle.ErrorInfoContainer>
                  <NSAddCandidateFromExcelStyle.ErrorTextContainer>
                    {candidate.errors.map((error) => (
                      <NSAddCandidateFromExcelStyle.ErrorText key={error}>
                        {error}
                      </NSAddCandidateFromExcelStyle.ErrorText>
                    ))}
                  </NSAddCandidateFromExcelStyle.ErrorTextContainer>

                  <NSAddCandidateFromExcelStyle.Divider />
                </>
              ))}
            </NSAddCandidateFromExcelStyle.ErrorContent>
          </NSAddCandidateFromExcelStyle.ErrorContainer>
        )}
      </NSAddCandidateFromExcelStyle.Container>
    </Drawer>
  );
}
