import styled from "styled-components";
import { NSDrawer } from "../../../../components/Drawer/Drawer.style";
import { NSTabbedViewStyle } from "../../../../components/TabbedView/TabbedView.style";

export namespace NSNoteDetailStyle {
  export const DrawerContainer = styled.div`
    /*  ${NSDrawer.MenuContainer} {
      width: 50%;
      right: -50%;
    }

    ${NSDrawer.drawerEnterAnimation} {
      from {
        right: -50%;
      }
      to {
        right: 0;
      }
    }

    ${NSDrawer.drawerExitAnimation} {
      from {
        right: 0;
      }
      to {
        right: -50%;
      }
    } */
  `;
  export const Container = styled.div`
    margin-top: 8px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 12px;
    flex-direction: column;

    ${NSTabbedViewStyle.Container},${NSTabbedViewStyle.HeaderContainer}, ${NSTabbedViewStyle.Header},${NSTabbedViewStyle.HeaderItem} {
      width: 100%;
    }

    ${NSTabbedViewStyle.HeaderItem} {
      justify-content: center;
    }
  `;
}
