import { FileRejection, useDropzone } from "react-dropzone";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useRef, useState } from "react";

import GhostIconButton from "../GhostIconButton/GhostIconButton";
import Icon from "../Icon/Icon";
import LinkButton from "../LinkButton/LinkButton";
import { NSFileUploadStyle } from "./FileUpload.style";
import { NSFileUploadType } from "./FileUpload.type";
import i18n from "../../library/i18next";

function FileUpload({
  onUpload,
  onError,
  uploadedFileName,
  onClickDownload,
  uploadedFile,
  onClickClose,
  fileFormatText = i18n.t("candidate.pdfOrDoc"),
  fileFormat = { "file/pdf": [".pdf"], "file/doc": [".doc"] },
  uploadByButton,
  uploadButtonLabel = i18n.t("candidate.chooseFile"),
}: Readonly<NSFileUploadType.IFileUpload>) {
  const ref = useRef(null as null | HTMLDivElement);
  const [errorFile, setErrorFile] = useState<string | undefined>();
  const [uploadedFileProps, setUploadedFile] = useState<File | undefined>(
    uploadedFile
  );

  const errors = {
    "file-too-large": {
      title: i18n.t("candidate.fileUploadLargeErrorTitle"),
      label: i18n.t("candidate.fileUploadLargeErrorLabel"),
    },
    "file-invalid-type": {
      title: i18n.t("candidate.fileUploadInvalidTypeErrorTitle", { errorFile }),
      label: i18n.t("candidate.fileUploadInvalidTypeErrorLabel"),
    },
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections?: FileRejection[]) => {
      if (fileRejections && fileRejections.length > 0) {
        setErrorFile(fileRejections[0].file.name);

        onError?.(
          errors[
            fileRejections[0].errors[0].code as NSFileUploadType.IErrorType
          ]
        );
      }

      const url = acceptedFiles[0];

      onUpload?.(url, acceptedFiles[0]?.name ?? "");
      setUploadedFile(acceptedFiles[0]);
    },
    [errors, onError, onUpload]
  );

  const downloadFile = (file: File) => {
    const el = document.createElement("a");
    el.href = file?.name;
    el.target = "_blank";
    el.download = file?.name;
    el.click();
    el.remove();
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: fileFormat,
    maxFiles: 1,
    maxSize: 2097152,
    multiple: false,
  });

  const onClickCloseButton = useCallback(() => {
    setUploadedFile(undefined);
    setErrorFile(undefined);
    onError?.(undefined);
    onUpload?.(undefined, undefined);
    onClickClose?.();
  }, []);

  return (
    <NSFileUploadStyle.Container>
      {!uploadByButton ? (
        <>
          {uploadedFileName || uploadedFileProps ? (
            <NSFileUploadStyle.UploadedFileContainer>
              <NSFileUploadStyle.UploadedFileName
                onClick={() =>
                  uploadedFileProps
                    ? downloadFile(uploadedFileProps)
                    : onClickDownload?.()
                }
              >
                {uploadedFileProps?.name ?? uploadedFileName}
              </NSFileUploadStyle.UploadedFileName>
              <GhostIconButton icon="Close" onClick={onClickCloseButton} />
            </NSFileUploadStyle.UploadedFileContainer>
          ) : (
            <NSFileUploadStyle.UploadContainer
              {...getRootProps({ className: "dropzone" })}
              dragging={isDragActive}
              ref={ref}
              id="drag_zone"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon name="Upload" />
              <input type="file" {...getInputProps()} id="uploadInput" />
              <NSFileUploadStyle.Label>
                {i18n.t("candidate.dragDrop")}
              </NSFileUploadStyle.Label>
              <LinkButton label={uploadButtonLabel} onClick={open} />
              <NSFileUploadStyle.InfoLabel>
                {i18n.t("candidate.supportFile")}
                {fileFormatText}
              </NSFileUploadStyle.InfoLabel>
              <NSFileUploadStyle.InfoLabel>
                {i18n.t("candidate.maxFile")}
              </NSFileUploadStyle.InfoLabel>
            </NSFileUploadStyle.UploadContainer>
          )}
        </>
      ) : (
        <>
          <input type="file" {...getInputProps()} id="uploadInput" />
          <LinkButton label={uploadButtonLabel} onClick={open} />
        </>
      )}
    </NSFileUploadStyle.Container>
  );
}

export default FileUpload;
