import styled, { css } from "styled-components";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSDashboardMenuSidebarStyle {
  export const Container = styled.div<{ isOpen: boolean }>`
    display: flex;
    box-sizing: border-box;
    width: ${(props) => (props.isOpen ? 300 : 80)}px;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 40px 24px;
    background-color: ${(props) => props.theme.colors.light.light};
    position: relative;
    box-sizing: border-box;
  `;

  export const BottomItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const LogoContainer = styled.div`
    margin-bottom: 34px;
  `;

  export const TopItemsContainer = styled.div<{ isOpen: boolean }>`
    display: flex;
    flex-direction: column;

    ${(props) =>
      !props.isOpen &&
      css`
        margin-top: 70px;
        ${LogoContainer} {
          top: 40px;
          position: absolute;
          left: calc(50% - 27px);
        }
      `}
  `;

  export const UserContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 26.5px;
    border-bottom: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
    margin-bottom: 24px;
    margin-top: 24px;
    svg {
      fill: transparent;
    }

    ${NSMainButtonStyle.ButtonBase} {
      width: 24px;
      height: 24px;
      padding: 0;
    }
  `;

  export const DashboardButtonoContainer = styled.div`
    position: absolute;
    top: 110px;
    right: -32px;
    z-index: 4;
  `;
  export const LanguageSelectionContainer = styled.div`
    margin-bottom: 16px;
  `;

  export const SettingsContainer = styled.div`
    margin-bottom: 16px;
  `;
}
