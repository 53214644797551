import { useNavigate, useParams } from "react-router-dom";

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import useGetVideoInterview from "../../../../service/VideoInterview/getVideoInterview";
import useGetVideoInterviewVideos from "../../../../service/VideoInterview/getVideoInterviewVideos";

const useVideoPreviewVM = () => {
  const { interviewId } = useParams();

  const navigate = useNavigate();

  const closeHandler = useCallback(() => {
    navigate(-1);
  }, []);

  const { data } = useGetVideoInterview(interviewId!);

  const videoFilenames = data?.video_interview_by_pk?.video_interview_questions
    .filter((q) => q.review_metric_question.is_shown_to_candidate)
    .map(
      (viteoItem) =>
        `${data?.video_interview_by_pk?.id}-${viteoItem.review_metric_question.id}.mp4`
    );

  const { data: videoUrls } = useGetVideoInterviewVideos(videoFilenames || []);

  return {
    closeHandler,
    videoUrls,
  };
};

export default useVideoPreviewVM;
