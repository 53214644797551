import styled from "styled-components";
import MainButton from "../MainButton/MainButton";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSNoteListStyle {
  export const Container = styled.div`
    width: 372px;
    height: fit-content;
    background-color: ${(props) => props.theme.colors.light.light};
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const Title = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-bottom: 8px;
  `;

  export const NoteListItem = styled.div`
    width: 100%;
    height: fit-content;
    padding: 8px 0;
    border-bottom: 1px solid
      ${(props) => props.theme.colors.shadesOfdark.dark06};
  `;

  export const Note = styled.div`
    max-width: 349px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
  `;

  export const InfoContainer = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.medium};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;

  export const ButtonContainer = styled.div`
    position: sticky;
    bottom: 0;
    margin-top: 12px;
    display: flex;
    justify-content: start;
    gap: 8px;
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 0 0 16px;

    ${NSMainButtonStyle.ButtonBase} {
      padding: 0 15px;
      height: 32px;
      font-size: ${(props) => props.theme.typography.smallText1.size};
      line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    }
  `;

  export const AddNoteButton = styled(MainButton)`
    background: none;
    border: none;
    color: ${(props) => props.theme.colors.primary.primary};
    padding: 6px 0 !important;

    ${NSMainButtonStyle.IconContainer} {
      margin: 0;
    }
  `;
}
