import Drawer from "../../../../components/Drawer/Drawer";

import { NSCreateCandidateStyle } from "./Create-Candidate.style";
import useCreateCandidateVm from "./Create-CandidateVm";

import TextInput from "../../../../components/TextInput/TextInput";
import PhoneNumber from "../../../../components/PhoneNumber/PhoneNumber";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import i18n from "../../../../library/i18next";

export default function CreateCandidate() {
  const createCandidateVM = useCreateCandidateVm();

  return (
    <Drawer title={i18n.t("projects.createNewCandidate")}>
      <NSCreateCandidateStyle.Container>
        <NSCreateCandidateStyle.CreateCandidateForm
          onSubmit={createCandidateVM.handleSubmit?.()}
        >
          <TextInput
            id="candidateNameId"
            placeholder={i18n.t("forms.name")}
            error={!!createCandidateVM.formErrors.candidateName}
            errorText={createCandidateVM.formErrors?.candidateName?.message}
            onChange={(e) => createCandidateVM.setValue("candidateName", e)}
            name="candidateName"
          />
          <TextInput
            id="candidateSurnameId"
            placeholder={i18n.t("forms.surname")}
            error={!!createCandidateVM.formErrors.candidateSurname}
            errorText={createCandidateVM.formErrors?.candidateSurname?.message}
            onChange={(e) => createCandidateVM.setValue("candidateSurname", e)}
            name="candidateSurname"
          />

          <TextInput
            id="emailAddressId"
            placeholder={i18n.t("forms.emailAddress")}
            error={!!createCandidateVM.formErrors.emailAddress}
            errorText={createCandidateVM.formErrors?.emailAddress?.message}
            onChange={(e) => createCandidateVM.setValue("emailAddress", e)}
            name="emailAddress"
          />

          <PhoneNumber
            id="phoneNumberId"
            name="phoneNumber"
            onChange={(phoneNumber) => {
              createCandidateVM.setValue("phoneNumber", phoneNumber);
            }}
            dropdownItems={createCandidateVM.phoneCode}
            error={!!createCandidateVM.formErrors.phoneNumber}
            errorText={createCandidateVM.formErrors.phoneNumber?.message}
          />
          <FileUpload
            onUpload={(url, name) => {
              createCandidateVM.onUploadFile(url, name);
            }}
            onError={(error) => {
              createCandidateVM.onErrorFileUpload(error!);
            }}
          />
          <SolidButton
            label={i18n.t("projects.createCandidate")}
            type="submit"
            fullWidth
          />
        </NSCreateCandidateStyle.CreateCandidateForm>
      </NSCreateCandidateStyle.Container>
    </Drawer>
  );
}
