import Icon from "../Icon/Icon";
import { NSLogoStyle } from "./Logo.style";
import { NSLogoType } from "./Logo.type";

function Logo({ type = "basic", width, height }: NSLogoType.ILogo) {
  const logoPosition = {
    basic: (
      <NSLogoStyle.Container title="Logo">
        <Icon
          id="Logo"
          role="img"
          width={width ?? 66}
          height={height ?? 31.53}
          name="Logo"
        />
        <Icon
          id="LogoText"
          role="img"
          width={120}
          height={23.75}
          name="LogoText"
        />
      </NSLogoStyle.Container>
    ),

    onlyIcon: (
      <NSLogoStyle.Container title="Logo">
        <Icon
          id="isOnlyIconLogo"
          role="img"
          width={width ?? 54}
          height={height ?? 26}
          name="Logo"
        />
      </NSLogoStyle.Container>
    ),

    sideBySide: (
      <NSLogoStyle.SideBySideLogoContainer>
        <Icon
          id="LogoText"
          role="img"
          width={102}
          height={19}
          name="LogoText"
        />
        <Icon
          id="Logo"
          role="img"
          width={width ?? 56}
          height={height ?? 26}
          name="Logo"
        />
      </NSLogoStyle.SideBySideLogoContainer>
    ),
  };

  return (
    <NSLogoStyle.Container title="Logo">
      {logoPosition[type]}
    </NSLogoStyle.Container>
  );
}

export default Logo;
