import FirstJobCreation from "./FirstJobDescriptionCreation";
import WithQuestionJobDescription from "./WithQuestionJobDescription";
import useFirstJobCreationVm from "../ViewModels/FirstJobCreationVm";
import { useJobDescriptionContext } from "../../../../context/JobDescriptionContext/JobDescriptionContext";

function QuestionModalRenderer() {
  const { activeQuestion } = useJobDescriptionContext();
  return activeQuestion ? (
    <WithQuestionJobDescription />
  ) : (
    <FirstJobCreation vm={useFirstJobCreationVm} />
  );
}

export default QuestionModalRenderer;
