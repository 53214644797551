import styled from "styled-components";
import { NSBadgeStyle } from "../Badge/Badge.style";
import { NSProgressBarStyle } from "../ProgressBar/ProgressBar.style";

export namespace NSPlanCardStyle {
  export const Container = styled.div`
    border-radius: 16px;
    border: 1px solid ${(props) => props.theme.colors.neutrals.grey03};
    padding: 16px;
    display: flex;
    gap: 24px;
    width: 485px;

    ${NSProgressBarStyle.ProgressThumb} {
      background-color: ${(props) => props.theme.colors.primary.tint02};
      border-radius: 50px;
    }

    ${NSProgressBarStyle.EmptyBar} {
      border-radius: 50px;
    }
  `;

  export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${NSBadgeStyle.Container} {
      background-color: ${(props) => props.theme.colors.neutrals.grey02};
      border-radius: 8px;
      padding: 8px 12px;
      font-size: ${(props) => props.theme.typography.smallText2.size};
      line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.medium};
      color: ${(props) => props.theme.colors.dark.dark};
      margin-top: 3px;
    }
  `;

  export const DateBadgeContainer = styled.div`
    ${NSBadgeStyle.Container} {
      width: 100%;
      justify-content: flex-start;
    }
  `;

  export const Price = styled.div`
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.typography.titleh4.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    color: ${(props) => props.theme.colors.dark.dark};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const PriceDetail = styled.span`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    color: ${(props) => props.theme.colors.neutrals.grey07};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin-left: 4px;
  `;

  export const Date = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const BillingCycle = styled.span`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey06};
    margin-left: 6px;
  `;

  export const WarningText = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};
  `;
}
