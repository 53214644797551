import { Outlet } from "react-router-dom";
import { NSTeamStyle } from "./Team.style";

function Team() {
  return (
    <NSTeamStyle.Container>
      <Outlet />
    </NSTeamStyle.Container>
  );
}

export default Team;
