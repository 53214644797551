import { ILinkButton } from "./LinkButton.type";
import MainButton from "../MainButton/MainButton";
import { NSLinkButtonStyle } from "./LinkButton.style";

function LinkButton({
  variant = "basic",
  fullWidth,
  disabled,
  onClick,
  size,
  label,
  iconColor,
  suffixIcon,
  prefixIcon,
  type,
}: ILinkButton) {
  return (
    <NSLinkButtonStyle.LinkButtonBase>
      <MainButton
        type={type}
        fullWidth={fullWidth}
        variant={variant}
        prefixIcon={prefixIcon}
        disabled={disabled}
        onClick={onClick}
        size={size}
        label={label}
        iconColor={iconColor}
        suffixIcon={suffixIcon}
      />
    </NSLinkButtonStyle.LinkButtonBase>
  );
}
export default LinkButton;
