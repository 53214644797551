import { SubmitHandler, useForm } from "react-hook-form";
import { object, string } from "yup";
import { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { NSLoginType } from "./Login.type";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import i18n from "../../../library/i18next";

const loginFormSchema = object({
  email: string().email().required(i18n.t("forms.emailError")),
  password: string().required(i18n.t("forms.passwordError")),
}).required();

const useLoginVm = () => {
  const { error, isLoading, login, user, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm<NSLoginType.ILoginFormValues>({
    resolver: yupResolver(loginFormSchema),
    shouldFocusError: false,
  });
  const [isErrorShown, setIsErrorShown] = useState(!!error);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get("redirectTo");
  const loginHandler: SubmitHandler<NSLoginType.ILoginFormValues> = useCallback(
    async (data, event) => {
      event?.preventDefault();
      try {
        const response = await login({
          email: data.email,
          password: data.password,
        });
        if (response) {
          window.location.href = redirectTo ?? "/projects";
        }
      } catch {
        setIsErrorShown(true);
      }
    },
    [login]
  );

  const submitHandler = () => handleSubmit(loginHandler);

  const goToForgotPassword = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const goToRegister = useCallback(() => {
    navigate("/register");
  }, [navigate]);

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const closeErrorBox = useCallback(() => {
    setIsErrorShown(!isErrorShown);
  }, [isErrorShown]);

  return {
    error,
    isLoading,
    setValue,
    isErrorShown,
    closeErrorBox,
    isAuthenticated,
    user,
    login,
    register,
    handleSubmit: submitHandler,
    formErrors: errors,
    goToForgotPassword,
    goToRegister,
  };
};

export default useLoginVm;
