import styled from "styled-components";
import { IOutlinedButton } from "./OutlinedButton.type";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";

export namespace NSOutlinedButtonStyle {
  export const OutlinedButtonBase = styled.div<IOutlinedButton>`
    width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
    ${NSMainButtonStyle.ButtonBase} {
      background-color: ${(props) => props.theme.colors.light.light};
      border-color: ${(props) => props.theme.colors.primary.primary};
      color: ${(props) => props.theme.colors.primary.primary};
      border-style: solid;

      &:hover {
        background-color: ${(props) => props.theme.colors.primary.primary};
        color: ${(props) => props.theme.colors.light.light};
      }

      &:disabled {
        border-color: ${(props) => props.theme.colors.secondary.light};
        color: ${(props) => props.theme.colors.secondary.light};
      }
    }
  `;
}
