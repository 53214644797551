import AnimatedSideBar from "../../../../components/AnimatedSideBar/AnimatedSideBar";
import BenefitsSelection from "./QuestionViews/BenefitsSelection";
import EducationBackground from "./QuestionViews/EducationBackground";
import ExperienceSelection from "./QuestionViews/ExperienceSelection";
import { NSCreateJobDescriptionStyle } from "../CreateJobDescription.style";
import { NSJobDescriptionContextType } from "../../../../context/JobDescriptionContext/JobDescriptionContext.type";
import RequirementsSelection from "./QuestionViews/RequirementsSelection";
import ResponsibilitiesSelection from "./QuestionViews/ResponsibilitiesSelection";
import SalarySelection from "./QuestionViews/SelectSalary";
import SkillSelection from "./QuestionViews/SkillSelection";
import TechnicalSkillRating from "./QuestionViews/TechnicalSkillRating";
import useWithQuestionJobDescriptionVm from "../ViewModels/WithQuestionJobDescriptionVm";
import i18n from "../../../../library/i18next";
import SelectIntroduction from "./QuestionViews/SelectIntroduction";

function WithQuestionJobDescription() {
  const {
    ref,
    isButtonLoading,
    activeQuestion,
    getNextQuestion,
    animatedSideBarLabel,
  } = useWithQuestionJobDescriptionVm();
  if (!activeQuestion) {
    throw new Error(i18n.t("jd.noActiveQuestion"));
  }

  // eslint-disable-next-line consistent-return
  const renderPages = () => {
    switch (activeQuestion.page) {
      case NSJobDescriptionContextType.PageType.SKILL_SELECTION:
        return (
          <SkillSelection
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_SKILL_RATE:
        return (
          <TechnicalSkillRating
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_EDUCATION:
        return (
          <EducationBackground
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_EXPERIENCE:
        return (
          <ExperienceSelection
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_SALARY:
        return (
          <SalarySelection
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_REQUIREMENTS:
        return (
          <RequirementsSelection
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_BENEFITS:
        return (
          <BenefitsSelection
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_RESPONSIBILITES:
        return (
          <ResponsibilitiesSelection
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      case NSJobDescriptionContextType.PageType.SELECT_INTRODUCTION:
        return (
          <SelectIntroduction
            isButtonLoading={isButtonLoading}
            ref={ref}
            activeQuestion={activeQuestion}
            getNextQuestion={getNextQuestion}
          />
        );
      default:
        break;
    }
  };

  return (
    <NSCreateJobDescriptionStyle.WQContainer>
      <NSCreateJobDescriptionStyle.WQLeftBox>
        <AnimatedSideBar
          label={animatedSideBarLabel}
          progress={activeQuestion?.completed}
        />
      </NSCreateJobDescriptionStyle.WQLeftBox>
      <NSCreateJobDescriptionStyle.WQRightBox>
        {/* burada soru tipine gore yazdigimiz component i gosterecegiz */}

        {renderPages()}
      </NSCreateJobDescriptionStyle.WQRightBox>
    </NSCreateJobDescriptionStyle.WQContainer>
  );
}

export default WithQuestionJobDescription;
